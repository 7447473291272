import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import { variantDictionary, sizeDictionary } from "./FilterPill.styles";

const FilterPill = ({ children, disabled, id: idFromProps, onClick, selected, variant, size }) => {
  const sizeClasses = sizeDictionary[size];
  const variantClasses = variantDictionary[variant];
  const id = useGivenOrGeneratedId("filter-pill", idFromProps);

  return (
    <span>
      <input
        id={id}
        type="checkbox"
        disabled={disabled}
        checked={selected}
        onChange={onClick}
        className="tw-peer tw-sr-only"
      />

      <label htmlFor={id} className={`${sizeClasses} ${variantClasses}`}>
        {children}
      </label>
    </span>
  );
};

FilterPill.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  variant: PropTypes.oneOf(["gray", "primary"]),
  size: PropTypes.oneOf(["small", "medium"]),
};

FilterPill.defaultProps = {
  disabled: false,
  id: null,
  onClick: null,
  selected: false,
  variant: "gray",
  size: "small",
};

export default FilterPill;
