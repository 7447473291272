import React, { Component } from "react";
import css from "./person-detail-styles.module.css";
import { Alert } from "react-bootstrap";
import { updatePersonPromise } from "../actions/updatePerson";
import DateTime from "react-datetime";
import DatePicker from "../../shared/v2/DatePicker";
import IconButton from "../../shared/v2/IconButton";
import { PencilSolidV6, XmarkSolidV6 } from "../../shared/v2/Icomoon";
import Button from "../../shared/v2/Button";

class EditableDate extends Component {
  state = {
    editingDate: false,
    editablePerson: JSON.parse(JSON.stringify(this.props.person)),
    errors: null,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  hideYear = () => this.props.elementType === "birthdate";

  dateName = () => {
    const { date, elementType } = this.props;
    let name = elementType === "anniversary" ? "home anniversary" : elementType;
    return date.name?.split("_").join(" ") || name;
  };

  onEditDate = () => {
    this.setState({ editingDate: true });
  };

  onDismissDateEdit = () => {
    this.setState({
      editingDate: false,
      editablePerson: JSON.parse(JSON.stringify(this.props.person)),
      errors: null,
    });
  };

  handleCustomDateChange = (dateId, newDateOrTime, type) => {
    let customDates = this.state.editablePerson.data.attributes.custom_dates;
    const indexOfDate = customDates.indexOf(customDates.find((date) => date.id == dateId));

    if (type == "date") {
      customDates[indexOfDate].date = newDateOrTime;
    } else {
      customDates[indexOfDate].time_of_day_string = newDateOrTime;
    }

    this.setState({
      editablePerson: {
        ...this.state.editablePerson,
        data: {
          ...this.state.editablePerson.data,
          attributes: {
            ...this.state.editablePerson.data.attributes,
            custom_dates: customDates,
          },
        },
      },
    });
  };

  handleDateChange = (property, value) => {
    this.setState({
      editablePerson: {
        ...this.state.editablePerson,
        data: {
          ...this.state.editablePerson.data,
          attributes: {
            ...this.state.editablePerson.data.attributes,
            [property]: {
              ...this.state.editablePerson.data.attributes[property],
              date: value,
            },
          },
        },
      },
    });
  };

  onDateSave = () => {
    updatePersonPromise(this.state.editablePerson)
      .then((r) => {
        this.props.updatePersonData(r.data);
        this.setState({ editingDate: false, errors: null });
      })
      .catch((err) => {
        this.setState({ errors: err.data.errors });
      });
  };

  renderDate = () => {
    const { date } = this.props;
    const format = `MM/DD${this.hideYear() ? "" : "/YYYY"}`;
    const dateValue = this.hideYear() ? `2000-${date.date.substring(5)}` : date.date;
    const formattedDate = moment(dateValue).format(format);
    if (date.include_time) {
      return `${formattedDate} at ${date.time_of_day_string}`;
    }
    return formattedDate;
  };

  renderAlert = () => (
    <Alert bsStyle="danger">
      {this.state.errors.map((error) => (
        <span>
          <strong>Error: </strong> {error}
          <br />
        </span>
      ))}
    </Alert>
  );

  renderButtons = () => (
    <div className="tw-flex tw-gap-[8px]">
      <Button onClick={this.onDateSave}>Save</Button>
      <IconButton size="small" onClick={this.onDismissDateEdit}>
        <XmarkSolidV6 />
      </IconButton>
    </div>
  );

  editDateFields = () =>
    this.props.elementType == "custom_date" ? this.renderCustomDateFields() : this.renderDateFields();

  renderCustomDateFields = () => {
    const customDates = this.state.editablePerson.data.attributes.custom_dates;
    const indexOfDate = customDates.indexOf(customDates.find((date) => date.id == this.props.date.id));
    const customDate = customDates[indexOfDate];
    const date = moment(customDate.date).format("MM/DD/YYYY");

    return (
      <div className="tw-flex tw-justify-between tw-gap-[8px]">
        <div className="tw-flex-1 tw-flex tw-flex-col">
          {this.state.errors && this.renderAlert()}
          <DatePicker
            inputProps={{ size: 10 }}
            name={customDate.name}
            value={date}
            onChange={(value) => this.handleCustomDateChange(customDate.id, moment(value), "date")}
            noYear={this.props.noYear}
          />
          {customDate.include_time && (
            <DateTime
              dateFormat={false}
              value={customDate.time_of_day_string}
              onChange={(value) =>
                this.handleCustomDateChange(customDate.id, moment(value).format("h:mm A"), "time")
              }
              timeConstraints={{ minutes: { step: 15 } }}
            />
          )}
        </div>
        {this.renderButtons()}
      </div>
    );
  };

  renderDateFields = () => {
    const { elementType } = this.props;
    let initialDate = this.state.editablePerson.data.attributes[elementType].date;
    // since initialDate is either a string or moment object, we need to check if it's a moment object
    if (this.hideYear()) {
      if (moment.isMoment(initialDate)) {
        initialDate = initialDate.year(2000);
      } else {
        initialDate = `2000-${initialDate.substring(5)}`;
      }
    }

    const date = moment(initialDate).format("MM/DD/YYYY");

    return (
      <div className="tw-flex tw-gap-[8px] tw-justify-between">
        <div className="tw-flex-1 tw-flex tw-flex-col">
          {this.state.errors && this.renderAlert()}
          <DatePicker
            inputProps={{ size: 10 }}
            dateFormat={`MM/DD${this.hideYear() ? "" : "/YYYY"}`}
            name={elementType}
            value={date}
            onChange={(value) => this.handleDateChange(elementType, moment(value, `MM/DD${this.hideYear() ? "" : "/YYYY"}`).format("YYYY-MM-DD"))}
            noYear={this.props.noYear}
          />
        </div>
        {this.renderButtons()}
      </div>
    );
  };

  render() {
    const { editingDate } = this.state;
    return (
      <div className="tw-flex tw-justify-between tw-gap-[8px] hover:tw-bg-gray-5 tw-group tw-py-[8px] tw-px-[20px]">
        <div className="tw-flex tw-flex-col tw-w-full">
          <div className={css.brivityPersonDetailsLabel}>{this.dateName()}</div>
          {editingDate ? this.editDateFields() : this.renderDate()}
        </div>
        {!editingDate && (
          <div className="tw-invisible group-hover:tw-visible">
            <IconButton size="small" onClick={this.onEditDate}>
              <PencilSolidV6 />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
}

export default EditableDate;
