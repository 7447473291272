import React from "react";
import PropTypes from "prop-types";
import EmailMessageInput from "../EmailMessageInput";
import DripInput from "../DripInput";
import DripFrequency from "../DripFrequency";
import TextInput from "../../../shared/v2/TextInput/TextInput";

const Panel2Form = ({ setFormData, formData }) => (
  <form className="tw-flex-none tw-w-[416px] tw-px-[4px] tw-h-full tw-overflow-y-auto tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-ml-[12px]">
    <div className="tw-mb-[24px]">
      <TextInput
        label="Market Report Name"
        helperText="This field is visible to your contacts"
        value={formData.name}
        onChange={(e) => {
          setFormData({ name: e.target.value });
        }}
      />
    </div>
    <EmailMessageInput
      value={formData.email_message}
      onChange={(e) => {
        setFormData({ email_message: e.target.value });
      }}
    />
    <DripInput
      value={formData.drip_enabled}
      onChange={(val) => {
        setFormData({
          drip_enabled: val,
          recurring_frequency: !val ? "never" : formData.recurring_frequency,
        });
      }}
    />
    {formData.drip_enabled && (
      <DripFrequency
        value={formData.recurring_frequency}
        onChange={(val) => {
          setFormData({ recurring_frequency: val });
        }}
      />
    )}
  </form>
);

Panel2Form.propTypes = {
  formData: PropTypes.shape({
    drip_enabled: PropTypes.bool,
    email_message: PropTypes.string,
    name: PropTypes.string,
    recurring_frequency: PropTypes.string,
  }),
  setFormData: PropTypes.func,
};

Panel2Form.defaultProps = {
  formData: {
    drip_enabled: 0,
    email_message: "",
    name: "",
    recurring_frequency: "never",
  },
  setFormData: null,
};
export default Panel2Form;
