import namespaceAction from "../../reducers/helpers";

const REDUCER_NAME = "BusinessSettingsReducer";
const wrap = namespaceAction(REDUCER_NAME);

export const getBusinessSettings = () => wrap({ type: "GET_BUSINESS_SETTINGS" });
export const getBusinessSettingsSuccess = (data) => wrap({ type: "GET_BUSINESS_SETTINGS_SUCCESS", data });
export const getBusinessSettingsError = (error) => wrap({ type: "GET_BUSINESS_SETTINGS_ERROR", error });

export const saveBusinessSettings = () => wrap({ type: "SAVE_BUSINESS_SETTINGS" });
export const saveBusinessSettingsSuccess = (data) => wrap({ type: "SAVE_BUSINESS_SETTINGS_SUCCESS", data });
export const saveBusinessSettingsError = (error) => wrap({ type: "SAVE_BUSINESS_SETTINGS_ERROR", error });

export const removeBusinessImage = () => wrap({ type: "DELETE_BUSINESS_IMAGE" });
export const removeBusinessImageSuccess = (data) => wrap({ type: "DELETE_BUSINESS_IMAGE_SUCCESS", data });
export const removeBusinessImageError = (error) => wrap({ type: "DELETE_BUSINESS_IMAGE_ERROR", error });

export const addOffice = () => wrap({ type: "ADD_OFFICE" });
export const addOfficeSuccess = (data) => wrap({ type: "ADD_OFFICE_SUCCESS", data });
export const addOfficeError = (error) => wrap({ type: "ADD_OFFICE_ERROR", error });

export const setValue = (key, value) => wrap({ type: "SET_BUSINESS_VALUE", key, value });

export const addImage = (key, value) => wrap({ type: "ADD_IMAGE", key, value });

export const setOfficeValue = (key, value) => wrap({ type: "SET_OFFICE_VALUE", key, value });
export const resetOffice = () => wrap({ type: "RESET_OFFICE" });
