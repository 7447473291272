/* use initialState somewhere */
const initialState = {
    searchResults: [],
    searchTerm: '',
    isFetching: false,
    hasResults: false,
    searchValid: false,
}

const quickSearch = (state = {}, action) => {

  switch (action.type) {
      case 'UPDATE_SEARCH_TERM':
      return {
        ...state,
        searchValid: action.searchValid,
        hasResults: action.searchValid,
        searchTerm: action.searchTerm
      }
    case 'FETCH_RESULTS':
      return {
        ...state,
        isFetching: true,
        searchValid: true,
        hasResults: state.hasResults,
      }
    case 'SEARCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        searchValid: true,
        searchResults: action.results,
        hasResults: action.hasResults
      }
    case 'SEARCH_VALID':
      return {
        ...state,
        searchValid: action.searchValid
      }
    default:
      return state;
  }
}

export default quickSearch;
