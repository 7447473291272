import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

import PreviewPlaceholder from "./PreviewPlaceholder";

const PreviewPdf = ({ url }) => {
  const pageRef = useRef(null);

  const [numPages, setNumPages] = useState(0);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const pages = useMemo(() => new Array(numPages).fill(null).map((_, index) => index + 1), [numPages]);

  const handleLoadSuccess = ({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  };

  const handleResize = useMemo(
    () =>
      debounce(() => {
        if (!pageRef.current) {
          return;
        }

        const pageElement = pageRef.current.pageElement.current;
        const width = pageElement.clientWidth;
        const height = pageElement.clientHeight;
        setSize({ width, height });
      }, 100),
    [pageRef.current],
  );

  useEffect(() => {
    setNumPages(0);
    setSize({ width: 0, height: 0 });
  }, [url]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <Document
      file={url}
      loading={<PreviewPlaceholder>Loading document...</PreviewPlaceholder>}
      error={<PreviewPlaceholder>Failed to load document.</PreviewPlaceholder>}
      onLoadSuccess={handleLoadSuccess}
    >
      {pages.map((page) => (
        <Page
          key={page}
          ref={pageRef}
          className="tw-flex tw-my-12px first:tw-mt-0 last:tw-mb-0"
          width={size.width}
          height={size.height}
          pageNumber={page}
          renderAnnotationLayer={false}
          onLoadSuccess={handleResize}
        />
      ))}
    </Document>
  );
};

PreviewPdf.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PreviewPdf;
