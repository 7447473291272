import format from "@shared/formatters";
import { HouseSolidV6 } from "@shared/v2/Icomoon";
import React from "react";

const intentMap = {
  buyer: "Buying",
  seller: "Selling",
  buyer_seller: "Both",
};

const HomeInfoOverlay = ({ timelineFields }) => (
  <div className="tw-relative tw-flex tw-justify-center tw-items-center tw-w-[260px] tw-h-[150px] tw-rounded-[4px] tw-overflow-hidden">
    {timelineFields.photo && <img className="tw-max-w-full" src={timelineFields.photo} alt="Property" />}
    <div className="tw-flex tw-flex-col tw-gap-[4x] tw-justify-end tw-absolute tw-inset-0 tw-bg-black/50 tw-p-[16px] tw-text-white">
      {timelineFields.price && <div className="tw-text-18d">{format.currency(timelineFields.price, 0)}</div>}
      <div>
        <div>{timelineFields.street_address}</div>
        <div>
          {timelineFields.city}, {timelineFields.state} {timelineFields.postal_code}
        </div>
        <div>
          {typeof timelineFields.beds === "number" && <span>{timelineFields.beds} Bed</span>}{" "}
          {typeof timelineFields.baths === "number" && <span>{timelineFields.baths} Bath</span>}
        </div>
      </div>
    </div>
  </div>
);

const OpenHouseEvent = ({ event, event: { timeline_fields: timelineFields } }) => (
  <div className="timeline-event tw-px-[20px] tw-flex tw-gap-[16px]">
    <div className="tw-flex-1 tw-flex tw-gap-[16px]">
      <div className="tw-flex tw-justify-center tw-items-center tw-w-[40px] tw-h-[40px] tw-flex-none tw-rounded-full tw-border-solid tw-border-[2px]">
        <HouseSolidV6 size="l" />
      </div>
      <div className="tw-break-all tw-flex tw-flex-col tw-gap-[4px]">
        <div>
          <span className="tw-font-semibold">{timelineFields.contact || "No Name"}</span> attended an open
          house
        </div>
        <HomeInfoOverlay timelineFields={timelineFields} />
        <div>
          Address: {timelineFields.street_address} {timelineFields.city}, {timelineFields.state}{" "}
          {timelineFields.postal_code}
        </div>
        <div>Host: {timelineFields.host}</div>
        {timelineFields.intent && <div>Buying or Selling: {intentMap[timelineFields.intent]}</div>}
        {timelineFields.timline && <div>{timelineFields.timeline}</div>}
        {timelineFields.home_value_address && (
          <div>Home Value Address: {timelineFields.home_value_address}</div>
        )}
        {timelineFields.working_with_an_agent && (
          <div>Working with an agent: {timelineFields.working_with_an_agent}</div>
        )}
      </div>
    </div>
    <span className="time-ago">{event.interacted_at_absolute}</span>
  </div>
);

export default OpenHouseEvent;
