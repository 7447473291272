import React, { useState, useEffect, useRef } from "react";

import ListingStatus from "../ListingStatus";
import ListingImages from "../ListingImages";
import BuyerMatches from "./BuyerMatches";
import { formatNumber, formatValue } from "../helpers";

const ListingDetails = ({ listing, setSharedListing, buyerMatches, sendToLead, onShowAgentDetails, agentDetailsEnabled, onClose }) => {
  return (
    <div className="tw-flex tw-h-full tw-box-border tw-flex-col">
      <Row className="tw-mb-18px">
        <ListingHeader listing={listing} shareListing={() => setSharedListing(listing)} onShowAgentDetails={onShowAgentDetails} agentDetailsEnabled={agentDetailsEnabled} onClose={onClose} />
      </Row>
      {listing.photos && listing.photos.length && (
        <Row>
          <Images images={listing.photos} />
        </Row>
      )}
      <Row className="tw-mt-36px">
        <SectionTitle text="About the Listing" />
        <p className="tw-mt-8px tw-text-14px tw-text-gray-75">{listing.remarks}</p>
      </Row>
      <HorizontalDivider />
      {buyerMatches?.matches?.length > 0 && (
        <>
          <Row>
            <BuyerMatches
              buyerMatches={buyerMatches}
              sendToLead={sendToLead}
              listingId={listing.blossorId}
            />
          </Row>
          <HorizontalDivider />
        </>
      )}
      <Row>
        <SectionTitle text="Listing Facts" />
        <Row>
          <Column className="tw-w-1/2 tw-pr-48px">
            <DetailItem field="Property Type" value={listing.propertyType} />
            <DetailItem field="MLS#" value={listing.mlsNum} />
            <DetailItem field="Year Built" value={listing.year} />
            <DetailItem
              field="Sq. Ft."
              value={formatNumber(listing.squareFeet)}
              verticalMargin={"tw-mt-12px"}
            />
          </Column>
          <Column className="tw-w-1/2 tw-pl-48px">
            <DetailItem field="Lot/Acreage" value={listing.lotSize} />
            <DetailItem field="County" value={listing.county} />
            <DetailItem field="Neighborhood" value={listing.neighborhood} />
            <DetailItem field="Area" value={listing.area} verticalMargin={"tw-mt-12px"} />
          </Column>
        </Row>
      </Row>
      <HorizontalDivider />
      <Row>
        <Column className="tw-w-1/2 tw-pr-48px">
          <SectionTitle text="Interior Details" />
          <DetailItem field="Bedrooms" value={listing.bedrooms} />
          <DetailItem field="Full Bathrooms" value={listing.fullBaths} />
          <DetailItem field="Half Bathrooms" value={listing.halfBaths} />
          <DetailItem field="Cooling" value={listing.cooling} />
          <DetailItem field="Heating" value={listing.heating} />
          <DetailItem field="Fireplaces" value={listing.hasFireplace} />
          <DetailItem field="Above Ground Sq Ft" value={listing.aboveGroundSqft} />
          <DetailItem field="Below Ground Sq Ft" value={listing.belowGroundSqft} />
          <DetailItem field="Basement" value={listing.hasBasement} />
          <DetailItem field="Other" value={listing.interiorFeatures} />
          <DetailItem field="Water" value={listing.water} verticalMargin={"tw-mt-12px"} />
        </Column>
        <Column className="tw-w-1/2 tw-pl-48px">
          <SectionTitle text="Exterior Details" />
          <DetailItem field="Lot Description" value={listing.lotFeatures} />
          <DetailItem field="Garage Capacity" value={listing.garageSpaces} />
          <DetailItem field="Parking Spaces" value={listing.parkingSpaces} />
          <DetailItem field="Others" value={listing.exteriorFeatures} />
          <DetailItem field="Sewer" value={listing.sewer} />
          <DetailItem
            field="Parking Features"
            value={listing.parkingFeatures}
            verticalMargin={"tw-mt-12px"}
          />
        </Column>
      </Row>
      <HorizontalDivider />
      <Row>
        <Column className="tw-w-1/2 tw-pr-48px">
          <SectionTitle text="Financial Details" />
          <DetailItem field="Tax Amount" value={listing.taxAmount} asPrice={true} />
          <DetailItem field="Taxes w/ Assessments" value={listing.taxWithAssessments} asPrice={true} />
          <DetailItem field="Tax/Property ID" value={listing.taxId} />
          <DetailItem field="Tax Year" value={listing.taxYear} />
        </Column>
      </Row>
      {listing.lat && listing.lng && (
        <Row className="tw-pb-40px">
          <Column className="tw-w-full">
            <SectionTitle text="Location" />
            <Location lat={listing.lat} lng={listing.lng} address={listing.address} />
          </Column>
        </Row>
      )}
    </div>
  );
};

export default ListingDetails;

const Row = ({ children, className = "", ...props }) => {
  return (
    <div className={`tw-flex tw-flex-row tw-flex-wrap tw-w-full ${className}`} {...props}>
      {children}
    </div>
  );
};

const Column = ({ children, reverse, className = "", ...props }) => {
  return (
    <div
      className={`tw-flex ${reverse ? "tw-flex-col-reverse" : "tw-flex-col"} tw-flex-none ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const ListingHeader = ({ listing, shareListing, onShowAgentDetails, agentDetailsEnabled, onClose }) => {
  return (
    <>
      <Row className="tw-my-6px tw-items-center">
        <Row>
          <span className="tw-text-18px">{`${listing.streetAddress}, ${listing.city} ${listing.state} ${listing.postalCode}`}</span>
          <div
            onClick={() => shareListing(listing.blossorId)}
            className="tw-flex tw-justify-center tw-items-center tw-ml-16px tw-h-24px tw-w-24px tw-bg-color-ebebeb tw-rounded-112px tw-cursor-pointer"
          >
            <i className="fa fa-share-square-o tw-text-gray-75 tw-text-[8px]" />
          </div>
        </Row>
      </Row>
      <Row className="tw-my-6px tw-justify-between">
        <Column>
          <span className="tw-font-bold tw-text-gray-99 tw-text-32px tw-my-6px">{`${formatValue(
            listing.price,
            true,
          )}`}</span>
          <Row className="tw-mt-6px">
            <ListingStatus status={listing.standardStatus} includeIcon={true} />
            <span className="tw-w-1px tw-h-20px tw-bg-gray-10 tw-mx-12px"></span>
            <span className="tw-text-gray-50">
              Days on Site <span className="tw-text-gray-75">{listing.daysOnSite}</span>
            </span>
            <span className="tw-w-1px tw-h-20px tw-bg-gray-10 tw-mx-12px"></span>
            <span className="tw-text-gray-50">
              Listed by <span className={`${agentDetailsEnabled ? "tw-text-teal tw-cursor-pointer" : "tw-text-gray-75"}`} onClick={() => { onClose(); onShowAgentDetails(listing); }}>{formatValue(listing.listAgentName)}</span>
            </span>
          </Row>
        </Column>
        <Column reverse>
          <Row>
            <Column>
              <span className="tw-font-bold tw-text-18px tw-text-gray-99">{`${formatValue(
                listing.bedrooms,
              )}`}</span>
              <span className="tw-text-gray-50 tw-mt-8px">Beds</span>
            </Column>
            <Column className="tw-ml-24px">
              <span className="tw-font-bold tw-text-18px tw-text-gray-99">{`${formatValue(
                listing.bathsTotalDecimal,
              )}`}</span>
              <span className="tw-text-gray-50 tw-mt-8px">Baths</span>
            </Column>
            <Column className="tw-ml-24px">
              <span className="tw-font-bold tw-text-18px tw-text-gray-99">{`${formatNumber(
                listing.squareFeet,
              )}`}</span>
              <span className="tw-text-gray-50 tw-mt-8px">Sq Ft</span>
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  );
};

const Images = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const onSelectImage = (index) => {
    setImageIndex(index);
  };

  const scrollToImage = (index) => {
    setImageIndex(index);
  };

  return (
    <Row className="tw-h-[354px]">
      <Column className="tw-w-3/4 tw-relative">
        <ListingImages
          images={images}
          size="large"
          imageToMoveTo={imageIndex}
          scrollToImage={scrollToImage}
        />
      </Column>
      <Column className="tw-w-1/4 tw-pl-8px tw-overflow-y-auto tw-h-[354px]">
        <SecondaryImages images={images} imageIndex={imageIndex} onSelectImage={onSelectImage} />
      </Column>
    </Row>
  );
};

const SecondaryImages = ({ images, imageIndex, onSelectImage }) => {
  const imageRefs = useRef({});

  useEffect(() => {
    if (imageRefs.current[imageIndex]) {
      imageRefs.current[imageIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [imageIndex]);

  return (
    <div className="tw-bg-black tw-h-full">
      {images.map(({ url }, i) => (
        <img
          className={`tw-max-w-full ${imageIndex === i ? "tw-border-2 tw-border-solid tw-border-teal" : ""}`}
          key={i}
          ref={(e) => (imageRefs.current[i] = e)}
          src={url}
          width={165}
          height={100}
          onClick={() => onSelectImage(i)}
        />
      ))}
    </div>
  );
};

const Location = ({ lat, lng, address }) => {
  useEffect(() => {
    const latLng = { lat, lng };
    const mapSettings = {
      zoom: 11,
      center: latLng,
      mapTypeId: "roadmap",
      panControl: false,
      disableDefaultUI: true,
    };

    const google = window.google;
    const map = new google.maps.Map(document.getElementById("listing-map"), mapSettings);
    new google.maps.Marker({
      position: latLng,
      map,
      title: address,
    });
  }, []);

  return <div id="listing-map" className="tw-mt-16px tw-h-[238px] tw-w-full"></div>;
};

const HorizontalDivider = () => {
  return (
    <Row className="tw-my-40px">
      <span className="tw-w-full tw-h-1px tw-bg-gray-10"></span>
    </Row>
  );
};

const SectionTitle = ({ text }) => {
  return <span className="tw-text-18px tw-font-bold tw-text-gray-75 tw-mb-4px">{text}</span>;
};

const DetailItem = ({ field, value, asPrice = false, verticalMargin = "tw-my-12px" }) => {
  return (
    <div className={`tw-flex ${verticalMargin} tw-justify-end`}>
      <span className="tw-flex tw-mr-auto tw-text-gray-50">{field}</span>
      <span className="tw-text-gray-75 tw-w-1/2 tw-ml-auto tw-text-right">{formatValue(value, asPrice)}</span>
    </div>
  );
};
