import React from "react";

const StatusText = ({ interaction, userNumber, person }) => {
  switch (interaction.status) {
    case "queued":
      return `Calling you at: ${userNumber}, please answer your phone`;
    case "ringing":
      return `Connecting to ${person.first_name}...`;
    case "answered":
      return `Connected to ${person.first_name}`;
    case "completed":
      return "Call ended";
  }
};

export default StatusText;
