// Extract to generic helper for use elsewhere
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import AiAssistant from "@shared/AiAssistant";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import IconButton from "@shared/v2/IconButton";
import TextInput from "@shared/v2/TextInput";
import Tooltip from "@shared/v2/Tooltip";
import TextLink from "@shared/v2/TextLink";
import { ImageSolidV6, TriangleExclamationSolidV6, XmarkSolidV6 } from "@shared/v2/Icomoon";
import MmsMediaModal from "@shared/MmsMediaModal";
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import EmptySmsHistory from "@shared/EmptySmsHistory";
import AiSummary from "@shared/AiAssistant/AiSummary";
import * as messagingActions from "../../reducers/messagingReducer";
import { PlaceholderControl, RecipientSearchOption } from "./CustomDropdownComponents";
import ConversationMessage from "./ConversationMessage";
import RecipientNumberDropdown from "./RecipientNumberDropdown";
import useLeadSearch from "../hooks/useLeadSearch";
import Loading from "../../Goals/components/Loader";
import useDraftConversationMessage from "../hooks/useDraftConversationMessage";
import {
  useAccountSenderPlaceholders,
  useConflictConversation,
  useDraftTextMessages,
  useFocusedConversation,
  useHasMoreMessages,
  useIsOptedOut,
  useLoadingMessages,
  useMessagesErrors,
  useMessagesPage,
  useNewSmsMediaError,
  useNewSmsMediaFile,
  useNewSmsMediaPreview,
  useRecipientsHash,
  useSelectedMessages,
  useSendingTextMessage,
} from "../../reducers/messagingReducer/selectors";
import { useCurrentUser, useFeatureFlags } from "../../reducers/layoutReducer/selectors";

const getTooltipMessage = (focusedConversation, isOptedOut) => {
  if (focusedConversation.isArchived) return "You cannot send messages to an archived conversation";
  if (isOptedOut) return "This number has opted out of text communication and cannot be texted.";
  return "One or more leads in your list of recipients cannot be texted. Please remove those recipients to send";
};

const ConversationMessages = () => {
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const accountSenderPlaceholders = useAccountSenderPlaceholders();
  const currentUser = useCurrentUser();
  const draftMessages = useDraftTextMessages();
  const focusedConversation = useFocusedConversation();
  const draftMessage = draftMessages[focusedConversation.personId || focusedConversation.uuid] || "";
  const newSmsMediaError = useNewSmsMediaError();
  const newSmsMediaFile = useNewSmsMediaFile();
  const newSmsMediaPreview = useNewSmsMediaPreview();
  const loadingMessages = useLoadingMessages();
  const hasMoreMessages = useHasMoreMessages();
  const messagesErrors = useMessagesErrors();
  const messagesPage = useMessagesPage();
  const selectedMessages = useSelectedMessages();
  const sendingTextMessage = useSendingTextMessage();
  const isOptedOut = useIsOptedOut();
  const recipientsHash = useRecipientsHash();
  const conflictConversation = useConflictConversation();
  const recipients = Object.values(recipientsHash).map((r) => r.recipient);
  const maxRecipientsReached = featureFlags.groupSms ? recipients.length > 8 : recipients.length > 0;
  const conversationOwner = focusedConversation?.conversationUsers?.find((u) => u.isConversationOwner);
  const conversationParticipant = focusedConversation?.conversationUsers?.find((u) => !u.isConversationOwner);
  const messagesRef = useRef(null);
  const messagesEndRef = useRef(null);
  const textMessageInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [mediaModal, setMediaModal] = useState({ message: null, index: null });
  const {
    actions: leadSearchActions,
    data: leadSearchData,
    loading: leadSearchLoading,
    searchTerm,
  } = useLeadSearch();

  useEffect(() => {
    const input = textMessageInputRef.current;
    if (!input) return () => {};
    const onInput = () => {
      input.style.height = "auto";
      input.style.height = `${Math.min(100, Math.max(36, input.scrollHeight))}px`;
      input.style.overflow = input.scrollHeight > 100 ? "auto" : "hidden";
    };
    input.addEventListener("input", onInput);
    return () => {
      input.removeEventListener("input", onInput);
    };
  }, [textMessageInputRef.current]);

  useEffect(() => {
    if (messagesPage === 1) messagesEndRef.current?.scrollIntoView({ block: "end" });
  }, [messagesEndRef.current, selectedMessages, messagesPage]);

  useEffect(() => {
    const messages = messagesRef.current;
    const messagesEnd = messagesEndRef.current;
    if (!messages && !messagesEnd) return () => {};
    const resizeObserver = new ResizeObserver(() => {
      const scrollOffset = messagesEnd.offsetTop - (messages.scrollTop + messages.clientHeight);
      if (scrollOffset < 300) messagesEnd.scrollIntoView({ block: "end" });
    });
    resizeObserver.observe(messages);
    return () => resizeObserver.disconnect();
  }, [messagesRef.current, messagesEndRef.current]);

  useDraftConversationMessage();

  const addRecipient = (recipient) => {
    leadSearchActions.setSearchTerm();
    if (recipient) dispatch(messagingActions.addRecipient({ recipient }));
  };
  const removeRecipient = (recipient) => dispatch(messagingActions.removeRecipient({ recipient }));

  const openMmsMediaModal = (message, index) => setMediaModal({ message, index });
  const closeMmsMediaModal = () => setMediaModal({ message: null, index: null });

  const placeholderOptions = () =>
    accountSenderPlaceholders
      .filter((placeholder) => placeholder !== undefined)
      .map((placeholder) => ({ label: placeholder.text, value: placeholder.value }));

  const insertPlaceholder = (opt) => {
    const { selectionStart, selectionEnd } = textMessageInputRef.current;

    const prefix = textMessageInputRef.current.value.substring(0, selectionStart);
    const suffix = textMessageInputRef.current.value.substring(
      selectionEnd,
      textMessageInputRef.current.value.length,
    );
    const updatedValue = prefix + opt.value + suffix;

    dispatch(messagingActions.updateDraft(updatedValue));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.style.outline = "2px dashed #999999";
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.target.style.outline = "";
  };

  const handleDragDrop = (e) => {
    e.preventDefault();
    e.target.style.outline = "";
    dispatch(messagingActions.setMedia(e.dataTransfer.files?.[0]));
  };

  const onMediaFileChange = (e) => dispatch(messagingActions.setMedia(e.target.files?.[0]));

  const onSendMessage = () => dispatch(messagingActions.sendMessage());

  const disableInputs =
    focusedConversation.isArchived ||
    isOptedOut ||
    Boolean(conflictConversation) ||
    sendingTextMessage ||
    !focusedConversation.conversationUsers?.some(
      (user) => user.isConversationOwner && user.personUuid === currentUser.person.uuid,
    );

  const unsubscribedRecipient = Object.values(recipientsHash).find(
    (r) => r.number?.meta?.smsStatus === "unsubscribed",
  );

  const emptyNumberRecipient = Object.values(recipientsHash).find((r) => r.number?.empty);

  const originalPeople =
    focusedConversation.uuid === "temp"
      ? recipients?.map((r) => r.label).join(", ")
      : focusedConversation.conversationUsers
          ?.filter((u) => !u.isConversationOwner)
          .map((u) => u.alias)
          .join(", ");
  const conflictPeople =
    conflictConversation?.conversationUsers
      ?.filter((u) => !u.isConversationOwner)
      .map((u) => u.alias)
      .join(", ") || "";

  if (!focusedConversation.mostRecentMessage?.uuid && !focusedConversation.uuid) {
    return (
      <div className="tw-flex tw-flex-col tw-flex-1 tw-justify-center tw-items-center">
        <div className="tw-text-24d">No message selected</div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-[16px] tw-py-[16px]">
      {focusedConversation.uuid === "temp" && (
        <div className="tw-px-[24px] tw-flex tw-flex-col tw-gap-[16px]">
          <h3 className="tw-m-0 tw-text-18d tw-font-bold">New Message</h3>
          <Dropdown
            components={{ Option: RecipientSearchOption }}
            label="Add Recipient"
            placeholder="Search by name or primary number"
            isRequired
            shouldFilterOptions={false}
            isDisabled={maxRecipientsReached || unsubscribedRecipient || emptyNumberRecipient}
            options={leadSearchLoading ? [] : leadSearchData}
            isLoading={leadSearchLoading}
            isSearchable
            onChange={addRecipient}
            inputValue={searchTerm}
            onInputChange={leadSearchActions.setSearchTerm}
            helperText={
              maxRecipientsReached
                ? `Maximum ${recipients.length} recipients for group texts. Remove one to add another.`
                : ""
            }
          />

          {recipients.length > 0 && (
            <div className="tw-flex tw-flex-wrap tw-justify-start tw-gap-[12px]">
              {recipients.map((recipient) => (
                <RecipientNumberDropdown
                  key={recipient.value}
                  recipient={recipient}
                  onRemove={(e) => {
                    e.preventDefault();
                    removeRecipient(recipient);
                  }}
                />
              ))}
            </div>
          )}

          {unsubscribedRecipient && (
            <Alert
              title={`Unable to send to ${unsubscribedRecipient.recipient.label}`}
              text={`${unsubscribedRecipient.recipient.label}’s number is set to “opted-out” and cannot be texted. In order to proceed, you must remove the contact from your list of recipients.`}
              variant="error"
            />
          )}

          {emptyNumberRecipient && (
            <Alert
              title={`Unable to send to ${emptyNumberRecipient.recipient.label}`}
              text={`${emptyNumberRecipient.recipient.label} doesn’t have a number on record. Please remove that contact from the list of recipients to continue creating your group message.`}
              variant="error"
            />
          )}
        </div>
      )}

      {focusedConversation.uuid !== "temp" && (
        <div className="tw-px-[24px] tw-flex tw-flex-col">
          {messagesErrors && (
            <Alert title="Unable to send message" text={messagesErrors[0]} variant="error" />
          )}
          <div className="tw-flex tw-justify-start tw-items-center tw-gap-[12px]">
            <span className="tw-text-18d tw-font-semibold">
              {focusedConversation.conversationUsers
                ?.filter((u) => !u.isConversationOwner)
                .map((u, i, a) => (
                  <React.Fragment key={u.personUuid}>
                    <Tooltip
                      content={formatPhoneNumber(u.address)}
                      trigger={<a href={`/people/${u.slug}`}>{u.alias || formatPhoneNumber(u.address)}</a>}
                      placement="bottom"
                    />
                    {i < a.length - 1 && ", "}
                  </React.Fragment>
                ))}
            </span>
          </div>
          <div className="tw-text-14d tw-text-gray-40">To: {conversationOwner?.alias}</div>
        </div>
      )}

      {focusedConversation.isArchived && (
        <Alert
          title="Archived Conversation"
          text="This conversation was archived because the phone number was edited or removed from the contact record."
          variant="error"
        />
      )}

      <div
        ref={messagesRef}
        className={`tw-px-[24px] tw-relative tw-overflow-auto tw-flex-1 tw-flex tw-flex-col tw-gap-[16px] tw-border-solid tw-border-[1px] tw-border-x-0 ${focusedConversation.isAiControlled ? "tw-border-b-0" : ""} tw-border-gray-10`}
      >
        {focusedConversation.uuid !== "temp" && hasMoreMessages && (
          <div className="tw-flex tw-justify-center tw-p-[8px]">
            <Button
              size="medium"
              onClick={() => dispatch(messagingActions.nextMessagesPage())}
              isLoading={loadingMessages}
            >
              Load More
            </Button>
          </div>
        )}
        {loadingMessages && (
          <div className="tw-relative tw-min-h-[50px]">
            <Loading />
          </div>
        )}
        {selectedMessages.map((message, index) => (
          <ConversationMessage
            key={message.uuid}
            conversation={focusedConversation}
            message={message}
            onClickImage={openMmsMediaModal}
            showTimeAgo={
              index === 0 ||
              moment(message.dateCreated).valueOf() -
                moment(selectedMessages[index - 1]?.dateCreated).valueOf() >
                7200000
            }
          />
        ))}

        {isOptedOut && (
          <div className="tw-my-[16px]">
            <Alert
              variant="error"
              text={
                <div className="tw-flex tw-items-center tw-gap-[8px]">
                  <TriangleExclamationSolidV6 size="l" />
                  <span>This number has opted out of text communication and cannot be texted.</span>
                </div>
              }
            />
          </div>
        )}

        {conflictConversation && (
          <div className="tw-my-[16px]">
            <Alert
              variant="warning"
              title="This conversation already exists"
              text={
                <div className="tw-flex tw-justify-between tw-items-end">
                  <span>
                    {originalPeople} shares number(s) with contact(s) {conflictPeople}, with which you already
                    have an open conversation.
                  </span>
                  <TextLink
                    className="!tw-text-inherit tw-font-semibold tw-uppercase tw-whitespace-nowrap"
                    onClick={() => {
                      dispatch(messagingActions.selectNewConversation(conflictConversation));
                      dispatch(messagingActions.getMessages({ uuid: conflictConversation.uuid }));
                    }}
                  >
                    Go To Conversation
                  </TextLink>
                </div>
              }
            />
          </div>
        )}
        <EmptySmsHistory
          containerClassName="tw-h-full"
          show={!conflictConversation && focusedConversation.uuid === "temp" && selectedMessages.length === 0}
        />
        <div ref={messagesEndRef} />
      </div>

      {featureFlags.gabbiAi && focusedConversation.isAiControlled && (
        <AiSummary
          variant="inline"
          allowAddAI={false}
          leadPersonUuid={conversationParticipant.personUuid}
          phoneStatus={isOptedOut ? "hard-opt-out" : "valid"}
        />
      )}

      <div className="tw-px-[24px] tw-flex tw-flex-col tw-gap-[16px]">
        {!newSmsMediaFile && (
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.gif"
            onChange={(e) => onMediaFileChange(e)}
            id="file"
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        )}

        {newSmsMediaFile && (
          <div className="tw-relative tw-self-start">
            <img src={newSmsMediaPreview} className="tw-rounded-10px tw-max-h-[50px]" alt="new sms media" />
            <IconButton
              className="tw-absolute tw-top-[-10px] tw-right-[-10px]"
              schema="tertiary"
              size="small"
              onClick={() => dispatch(messagingActions.setMedia(null))}
            >
              <XmarkSolidV6 size="l" />
            </IconButton>
          </div>
        )}

        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px] tw-flex-wrap tw-whitespace-nowrap">
          <div className="tw-flex tw-gap-[8px]">
            <Dropdown
              components={{
                Control: PlaceholderControl,
                DropdownIndicator: null,
              }}
              styles={{
                menu: (base) => ({ ...base, minWidth: "200px" }),
                placeholder: (base) => ({ ...base, color: "inherit", fontSize: "inherit" }),
                valueContainer: (base) => ({ ...base, fontSize: "inherit", padding: 0 }),
              }}
              onChange={(opt) => insertPlaceholder(opt)}
              placeholder="Insert placeholder"
              data-testid="text-message-placeholder-select"
              onClose={() => textMessageInputRef.current.focus()}
              isDisabled={disableInputs}
              options={placeholderOptions()}
              value={null}
            />

            {!newSmsMediaFile && (
              <IconButton
                schema="secondary"
                size="small"
                onClick={() => fileInputRef.current.click()}
                disabled={disableInputs}
              >
                <ImageSolidV6 />
              </IconButton>
            )}
          </div>

          {!disableInputs && (
            <AiAssistant
              textareaId="text-message-textarea"
              messageType="SMS"
              contactUuid={
                focusedConversation.conversationUsers?.find((u) => !u.isConversationOwner)?.personUuid
              }
              onInsertClick={(text) => dispatch(messagingActions.updateDraft(text))}
            />
          )}
        </div>

        <TextInput
          name="newTextMessage"
          className="tw-resize-none"
          ref={textMessageInputRef}
          id="text-message-textarea"
          data-testid="text-message-textarea"
          onDrop={handleDragDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onChange={(e) => dispatch(messagingActions.updateDraft(e.target.value))}
          onKeyDown={(e) => e.keyCode === 13 && onSendMessage()}
          value={draftMessage || ""}
          disabled={disableInputs}
          maxLength={1600}
          rows="1"
          multiline
          error={newSmsMediaError}
          placeholder="Text Message"
        />

        {unsubscribedRecipient || emptyNumberRecipient || focusedConversation.isArchived || isOptedOut ? (
          <Tooltip
            className="tw-self-end"
            innerClassName="tw-max-w-[300px]"
            placement="left"
            trigger={
              <Button size="medium" disabled>
                Send
              </Button>
            }
            content={getTooltipMessage(focusedConversation, isOptedOut)}
          />
        ) : (
          <Button
            className="tw-self-end"
            size="medium"
            disabled={
              disableInputs ||
              (focusedConversation.uuid === "temp" && recipients.length === 0) ||
              (!draftMessage && !newSmsMediaFile) ||
              newSmsMediaError
            }
            onClick={() => onSendMessage()}
          >
            Send
          </Button>
        )}
      </div>

      <MmsMediaModal
        onClose={closeMmsMediaModal}
        message={mediaModal.message}
        mediaIndex={mediaModal.index}
      />
    </div>
  );
};

export default ConversationMessages;
