import Tooltip from "@shared/v2/Tooltip";
import React from "react";

const PhoneCallIcon = (props) => {
  return props.personPhoneNumber ? (
    <Tooltip
      placement="right"
      trigger={
        <i
          style={props.style}
          className={`fa fa-phone phone ${props.iconClass}`}
          onClick={props.handleClick}
        />
      }
      content={props.personPhoneNumber}
    />
  ) : (
    <i style={props.style} className={`fa fa-phone phone ${props.iconClass}`} onClick={props.handleClick}></i>
  );
};
export default PhoneCallIcon;
