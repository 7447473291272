import React from "react";
import axios from "axios";
import _ from "lodash";
import capitalize from "capitalize";
import TaskForm from "./TaskForm";
import * as TaskHelpers from "./TaskIndexModalHelpers";
import buttons from "../../PersonDetail/components/button.module.css";
const defaultTask = {
  name: "",
  id: null,
  priority: 9,
  visibility: "",
  instructions: "",
  account_id: "",
  note: "",
  assigned_to_user_id: "",
  contingency_rule: {},
  contingency_event_and_type: [],
  taskable_id: "",
  taskable_type: "",
  taskable_name: "",
  appointment_type: "",
  outcome: "",
  location: "",
  externally_visible: false,
  contingent: false,
  recurring: false,
  showInstructions: false,
  showNotes: false,
  completed: false,
  user_id: "",
};

class PersonTaskForm extends React.Component {
  constructor(props) {
    super(props);
    const task = props.task || {};
    const taskable = props.taskable || {};
    defaultTask.taskable_id = taskable.uuid || "";
    defaultTask.taskable_type = taskable.taskable_type || "";
    defaultTask.taskable_name = taskable.task_name || "";
    defaultTask.appointment_type = taskable.appointment_type || null;
    defaultTask.showInstructions = task.instructions;
    defaultTask.showNotes = task.note;
    defaultTask.contingent = task.contingent || false;
    defaultTask.recurring = task.recurring || false;
    defaultTask.scheduled_time = moment().add(1, "hours").startOf("hour").unix();
    defaultTask.due_on = moment().format("YYYY-MM-DD");
    defaultTask.location = taskable.location || "";
    defaultTask.user_id = taskable.user_id || "";
    const task_prop = props.task;
    const default_priority = (task_prop && task_prop.priority) || 9;
    const assignedToUserId =
      task_prop.assigned_to_user_id !== undefined ? task_prop.assigned_to_user_id : this.props.user.uuid;
    this.state = {
      task: {
        ...defaultTask,
        ...task_prop,
        priority: default_priority,
        account_id: props.account_uuid,
        assigned_to_user_id: assignedToUserId,
      },
      showRecurring: defaultTask.recurring,
      showContingency: defaultTask.contingent,
      showDue: !task.contingent,
      disabledButton: false,
    };

    this.toggleInstructions = this.toggleInstructions.bind(this);
    this.toggleNotes = this.toggleNotes.bind(this);
    this.toggleContingent = this.toggleContingent.bind(this);
    this.toggleRecurring = this.toggleRecurring.bind(this);
    this.focusTextInput = this.focusTextInput.bind(this);

    this.handleTaskChange = TaskHelpers.handleTaskChange.bind(this);
    this.handleRecurrence = TaskHelpers.handleRecurrence.bind(this);
    this.handleMultiSelect = TaskHelpers.handleMultiSelect.bind(this);
    this.completeTask = TaskHelpers.completeTask.bind(this);
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this);
    this.visibilityOptions = this.visibilityOptions.bind(this);
  }

  handleContingencyRuleChange = (e) => {
    let { contingency_rule } = this.state.task;
    contingency_rule[e.target.name] = e.target.value;
    this.setState({
      ...this.state,
      ...{ task: { ...this.state.task, ...contingency_rule } },
    });
  };

  toggleInstructions() {
    var task = { ...this.state.task };
    task["showInstructions"] = !task["showInstructions"];
    task.instructions = null;
    this.setState({ task });
  }

  toggleNotes() {
    var task = { ...this.state.task };
    task["showNotes"] = !task["showNotes"];
    task.note = null;
    this.setState({ task });
  }

  toggleContingent = () => {
    this.setState(
      (prevState) => ({
        showContingency: !prevState.showContingency,
        showDue: !prevState.showDue,
      }),
      () => this.makeTaskContingent(),
    );
  };

  toggleRecurring = () => {
    this.setState(
      (prevState) => ({ showRecurring: !prevState.showRecurring }),
      () => this.makeTaskRecurring(),
    );
  };

  makeTaskContingent = () => {
    var task = { ...this.state.task };
    task["contingent"] = this.state.showContingency;
    this.setState({ task });
  };

  makeTaskRecurring = () => {
    var task = { ...this.state.task };
    task["recurring"] = this.state.showRecurring;
    this.setState({ task });
  };

  focusTextInput() {
    this.textInput.focus();
  }

  taskableOptions = (taskable_type) => {
    let task_options_for_taskable = this.taskableOptionsHash()[taskable_type];
    if (
      this.state.task.contingency_event_and_type.length == 0 ||
      this.state.task.contingency_event_and_type[0] == "milestone"
    ) {
      return task_options_for_taskable;
    } else {
      let current_contingent_event = [
        {
          value: this.state.task.contingency_event_and_type.join("-"),
          label: _.startCase(
            this.state.task.parent_task_name || this.state.task.contingency_event_and_type[1],
          ),
        },
      ];

      return current_contingent_event.concat(task_options_for_taskable);
    }
  };

  taskableOptionsHash = () => {
    return {
      Person: this.milestonableMilestoneOptions(this.props.accountMilestoneNames["Person"]),
      Listing: this.milestonableMilestoneOptions(this.props.accountMilestoneNames["Listing"]),
      Project: this.milestonableMilestoneOptions(this.props.accountMilestoneNames["Project"]),
    };
  };

  milestonableMilestoneOptions = (milestones) => {
    let array = [];

    milestones.forEach(function (milestone) {
      array.push({
        value: `milestone-${milestone}`,
        label: milestone
          .split("_")
          .map((element) => {
            return capitalize(element);
          })
          .join(" "),
      });
    });

    return array;
  };

  taskTypeOptions() {
    const taskTypes = ["To-Do", "Call", "Text", "Email", "Appointment", "Mail", "Social Media", "Door Knock"];
    return taskTypes.map(function (taskType) {
      return { value: taskType, label: taskType };
    });
  }

  visibilityOptions() {
    return [
      {
        value: false,
        label: (
          <div>
            <i className="fa fa-eye-slash p-r-10" />
            <span className="text-base">Hidden from Viewers</span>
          </div>
        ),
      },
      {
        value: true,
        label: (
          <div>
            <i className="fa fa-eye p-r-10" />
            <span className="text-base">Visible to Viewers</span>
          </div>
        ),
      },
    ];
  }

  taskForWatcher = (result) => {
    this.setState({
      task: {
        ...this.state.task,
        taskable_type: result.object_type,
        taskable_id: result.uuid,
      },
    });
  };

  priorityselectionclass = (priority, value) => {
    if (priority == value) {
      return "fa fa-dot-circle-o brivity-blue";
    } else {
      return "brivity-grey-text fa fa-circle-thin";
    }
  };

  taskPriorityWatcher = (priority) => {
    this.setState({ task: { ...this.state.task, priority: priority } });
  };

  showContingencyButton = () => {
    let text = "";
    let classes = "";
    if (this.state.showContingency) {
      text = "Select Specific Date";
      classes = "fa fa-calendar";
    } else {
      text = "Make Contingent";
      classes = "fa fa-magnet";
    }

    return (
      <a onClick={this.toggleContingent} className={`${buttons.btn} ${buttons.btnSm} ${buttons.btnBlue}`}>
        <span>{text}</span>
        <i className={`${classes} m-l-5`} style={{ top: "0" }} />
      </a>
    );
  };

  showRecurringButton = () => {
    let text = "";
    let classes = "";
    if (this.state.showRecurring) {
      text = "Stop Recurrence";
      classes = "fa fa-refresh";
    } else {
      text = "Make Recurring";
      classes = "fa fa-refresh";
    }

    return (
      <a
        style={{ marginLeft: "10px" }}
        onClick={this.toggleRecurring}
        className={`${buttons.btn} ${buttons.btnSm} ${buttons.btnBlue}`}
      >
        <span>{text}</span>
        <i className={`${classes} m-l-5`} style={{ top: "0" }} />
      </a>
    );
  };

  notesOrInstructionsClass = () => {
    const task = this.state.task;
    return task.showInstructions || task.showNotes ? "" : "form-group";
  };

  submitButton = () => (this.state.task.id ? "SAVE" : "CREATE");

  render() {
    const { task } = this.state;
    const { contactEmail } = this.props;
    return (
      <TaskForm
        agents={this.props.agents}
        accountUuid={this.props.account_uuid}
        task={task}
        taskable={this.state.taskable}
        accountMilestoneNames={this.props.accountMilestoneNames}
        taskChange={this.props.taskChange}
        onCloseAction={this.props.closeModal}
        closeModal={this.props.closeModal}
        deleteButton={false}
        showErrors={this.props.showErrors}
        errors={this.props.errors}
        editToDelete={this.swapForDelete}
        submitButton={this.submitButton}
        handleTaskChange={this.handleTaskChange}
        newTask={this.props.newTask}
        personTaskForm={true}
        handleDueOnChange={this.handleDueOnChange}
        handleTimeOnChange={this.handleTimeOnChange}
        isAppointment={this.props.isAppointment}
        isEditing={this.props.isEditing}
        updateTask={this.props.updateTask}
        getCardTasks={this.props.getCardTasks}
        user={this.props.user}
        contactEmail={contactEmail}
      />
    );
  }
}

export default PersonTaskForm;
