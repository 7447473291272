// Simple example of a React "smart" component

import { connect } from 'react-redux';
import QuickSearch from '../components/QuickSearch';
import * as actions from '../actions/quickSearchActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({...state.quickSearch });

// Don't forget to actually use connect!
// Note that we don't export QuickSearch, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, actions)(QuickSearch);
