import React from "react";
import { Close as CloseIcon } from "../../Icomoon";
import CustomMultiValueRemove from "../custom/MultiValueRemove";
import { customDefaultProps, customPropTypes } from "../utils";

const MultiValueRemove = ({ componentProps }) => (
  <CustomMultiValueRemove
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    preserveStyle
  >
    <CloseIcon size="m" />
  </CustomMultiValueRemove>
);

MultiValueRemove.propTypes = customPropTypes;

MultiValueRemove.defaultProps = customDefaultProps;

export default MultiValueRemove;
