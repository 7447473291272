import React, { useState, useRef } from "react";

import TextInput from "@shared/v2/TextInput";
import { Search as SearchIcon } from "@shared/v2/Icomoon";
import ListingsAutocompleteResults from "@shared/ListingsAutocomplete/ListingsAutocompleteResults";

const Search = ({ setLocation, searchBarRef }) => {
  const timeoutRef = useRef(null);
  const tokenRef = useRef(null);
  const [term, setTerm] = useState("");
  const [results, setResults] = useState([]);
  const [isSearchSelected, setIsSearchSelected] = useState(false);
  const [childPosition, setChildPosition] = useState(0);

  const onSelectAutocompleteResult = (location) => {
    // Clean up results and tracking for them. Only show saved searches in the future
    setChildPosition(0);
    setTerm("");
    setResults([]);

    const place = new window.google.maps.places.PlacesService(document.createElement("div"));

    // The session token is only supposed to be used for a single loop of (get suggestions, pick sugggestion)
    const token = tokenRef.current;
    tokenRef.current = null;

    const query = {
      placeId: location.id,
      fields: ["formatted_address", "geometry"],
      sessionToken: token,
    };

    place.getDetails(query, (result, status) => {
      if (status === "OK") {
        setLocation({
          isRadius: true,
          name: result.formatted_address,
          location: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          },
        });
      }
    });
  };

  const onInput = (e) => {
    const newTerm = e.target?.value || "";
    setTerm(newTerm);

    if (newTerm.length < 2 || newTerm.length > 100) {
      // cats always type 101 chars
      setResults([]);
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (!tokenRef.current) {
        tokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
      }
      const request = {
        input: newTerm,
        componentRestrictions: {
          country: ["us"],
        },
        types: GoogleTypes,
        sessionToken: tokenRef.current,
      };

      const autocomplete = new window.google.maps.places.AutocompleteService();

      autocomplete.getPlacePredictions(request, (predictions, status) => {
        if (status === "OK") {
          setResults(mapPredictions(predictions));
        } else {
          setResults([]);
        }
      });
    }, 150);
  };

  const handleKeypress = (event) => {
    if (event.defaultPrevented) return;

    switch (event.key) {
      case "ArrowUp":
        setChildPosition(childPosition > 0 ? childPosition - 1 : 0);
        break;
      case "ArrowDown":
        setChildPosition(results.length - 1 > childPosition ? childPosition + 1 : childPosition);
        break;
      case "Enter":
        onSelectAutocompleteResult(results[childPosition]);
        break;
      default:
        return;
    }

    event.preventDefault();
  };

  return (
    <div className="tw-relative">
      <div className="tw-flex tw-flex-row">
        <TextInput
          ref={searchBarRef}
          containerClassName="tw-w-full"
          placeholder="Search by address, city, neighborhood, county, or ZIP code"
          onChange={onInput}
          value={term}
          onBlur={() => setIsSearchSelected(false)}
          onFocus={() => setIsSearchSelected(true)}
          onKeyDown={handleKeypress}
        />
        <SearchIcon className="tw-absolute tw-right-[10px] tw-top-[calc(50%-8px)] tw-text-gray-30" size="l" />
      </div>
      {isSearchSelected && (
        <ListingsAutocompleteResults
          searchResults={results}
          selectResult={onSelectAutocompleteResult}
          childPosition={childPosition}
          setChildPosition={setChildPosition}
          deleteSearch={() => {}}
        />
      )}
    </div>
  );
};

export default Search;

const mapPredictions = (predictions) => {
  const types = Object.keys(GoogleTypesToReadable);

  return predictions
    .map((p) => ({
      value: p.description,
      id: p.place_id,
      category: GoogleTypesToReadable[p.types.filter((t) => types.includes(t))[0]],
    }))
    .sort((a, b) => {
      // Results from google aren't organized by type
      const aOrder = LocationsOrder[a.category];
      const bOrder = LocationsOrder[b.category];

      if (aOrder > bOrder) {
        return 1;
      } else if (aOrder < bOrder) {
        return -1;
      }

      return 0;
    });
};

const GoogleTypesToReadable = {
  street_address: "Street Address",
  neighborhood: "Neighborhood",
  locality: "City",
  postal_code: "ZIP Code",
  administrative_area_level_2: "County",
};

const GoogleTypes = Object.keys(GoogleTypesToReadable);

const LocationsOrder = {
  "Street Address": 1,
  City: 2,
  Neighborhood: 3,
  County: 4,
  "ZIP Code": 5,
};
