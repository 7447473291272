import axios from "axios";
import moment from "moment/moment";
import { differenceInDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { getPostJsonHeaders } from "../../shared/axiosHelpers";
import { addParametersToImageUrl, ExistingFeatureFields, formatPrice } from "./helpers";
import SearchModel from "./SearchModel";

export const getUserInfoForSend = async () => {
  const deets = await axios.get("/search_listings", getPostJsonHeaders());

  return deets.data;
};

export const getListings = async (searchModel, teamIds, includeCount = false) => {
  const formatted = formatForSearch(searchModel, teamIds);
  const promises = [
    axios.post(`/listings_api/search`, { search_request: formatted }, getPostJsonHeaders()),
  ];

  if (includeCount) {
    promises.push(
      axios.post(`/listings_api/count`, { search_request: formatted }, getPostJsonHeaders()),
    );
  }

  const [listingsResponse, countResponse] = await Promise.all(promises);

  return [formatListings(listingsResponse.data || []), countResponse?.data];
};

export const getAgentListings = async (mlsId, agentId) => {
  const formatted = {
    mlsIds: [mlsId],
    agentIds: [agentId],
    statuses: [1, 2, 3, 4, 5],
    queryOptions: {
      limit: 1000,
      sort: [{
        field: "listDate",
        order: "desc"
      }]
    }
  };

  const listingsResponse = await axios.post(`/listings_api/search`, { search_request: formatted }, getPostJsonHeaders());

  return formatListings(listingsResponse.data || []);
};

export const getPropertyTypes = async (mlsIds, userId) => {
  const response = await axios.get(
    `/people/${userId}/listing_alert_property_types.json?mls_ids=${mlsIds.join(",")}`,
    getPostJsonHeaders(),
  );

  return response.data?.property_types || [];
};

export const getFeatureFields = async (mlsIds, userId) => {
  const response = await axios.get(
    `/people/${userId}/listing_alert_filter_options.json?mls_ids=${mlsIds.join(",")}`,
    getPostJsonHeaders(),
  );

  if (!response.data?.filter_options) {
    return {};
  }

  const ignoredCategories = ["Stories"];
  return Object.entries(response.data.filter_options).reduce((acc, [key, val]) => {
    if (ignoredCategories.includes(key)) {
      return acc;
    }

    const filtered = val.sort((a, b) => a.label.localeCompare(b.label))
      .filter(f => ExistingFeatureFields.includes(f.search_category));

    if (!filtered.length) {
      return acc;
    }

    acc[key] = filtered;

    return acc;
  }, {});
};

export const getBuyerMatches = async (city, state, price, blossorId, matchesToKeep = 10) => {
  const response = await axios.get(`/search_listings/buyer_matches?city=${city}&state=${state}&price=${price}&blossor_id=${blossorId}`);
  if (!response.data?.buyer_matches?.length) {
    return {};
  }

  const matches = []; // this is to get the correct count but avoid 100 matches in JS when we don't show more than 10 (maybe a few more on the tooltip on the table view)
  const maxMatchesToKeep = Math.min(response.data.buyer_matches.length, matchesToKeep);
  for (let i = 0; i < maxMatchesToKeep; i++) {
    const {id, full_name, initials, email} = response.data.buyer_matches[i];
    matches.push({
      personId: `${id}`,
      fullName: full_name,
      initials,
      email
    });
  }

  return {
    blossorId,
    matches,
    count: response.data.buyer_matches.length,
    countText: response.data.buyer_matches.length >= 99 ? "99+" : response.data.buyer_matches.length,
    linkToIndex: response.data.link_to_index
  };
};

export const getSearchUrl = async () => {
  const response = await axios.get("/search_listings/idx_search_url");
  return response.data?.search_url;
};

export const getSearchUrlWithLogin = async (personId) => {
  const response = await axios.get(`/search_listings/idx_search_url_with_login?person_id=${personId}`);
  return response.data?.search_url;
};

export const getDetailsUrlsWithLogin = async (personId, blossorIds) => {
  const response = await axios.get(`/search_listings/idx_details_urls_with_login?person_id=${personId}&blossor_ids=${blossorIds.join('|')}`);
  return response.data?.details_urls;
};

export const getDetailsUrls = async (blossorIds) => {
  const response = await axios.get(`/search_listings/idx_details_urls?blossor_ids=${blossorIds.join('|')}`);
  return response.data?.details_urls;
};

// This logic is duplicated in the backend:
// app/services/interactions/email_all/fetch_data.rb
export const formatListing = (listing) => {
  if (!listing) {
    return null;
  }

  const photos = listing.photos?.length
    ? listing.photos.map((photo) => ({
      url: addParametersToImageUrl(photo.url, 194, 320, 80),
      largeUrl: addParametersToImageUrl(photo.url, 353, 498, 80),
      emailUrl: addParametersToImageUrl(photo.url, 374, 528, 80),
    }))
    : [{ url: MissingImageUrl, largeUrl: MissingImageUrl }];

  const listDate = new Date(listing.listDate);

  return {
    ...listing,
    daysOnSite: differenceInDays(new Date(), listDate),
    formattedPrice: formatPrice(listing.price),
    formattedListDate: formatInTimeZone(listDate, "Greenwich", "P"),
    photos,
  };
};

export const loadListingSearches = async (userId) => {
  const response = await axios.get(`/users/${userId}/search_listings_saved_searches`);

  return response.data?.saved_searches;
};

export const saveListingSearch = async (userId, name, search) => {
  const data = { saved_search: { name, search: formatForSave(search) } };

  await axios.post(`/users/${userId}/search_listings_saved_searches`, data, getPostJsonHeaders());
};

export const deleteListingSearch = async (userId, searchId) => {
  await axios.delete(`/users/${userId}/search_listings_saved_searches/${searchId}`, getPostJsonHeaders());
};

export const saveCurrentSearch = search => saveObj("listing-search", search);

export const saveViewSelections = view => saveObj("listing-view", view);

export const loadMostRecentSearch = () => {
  const search = loadObj("listing-search") || {};
  return new SearchModel(search);
};

export const loadListingView = () => {
  const savedView = loadObj("listing-view");
  return savedView || { map: false, grid: false };
};

const formatForSave = (searchModel) => {
  return Object.entries(searchModel).reduce((acc, [key, val]) => {
    if (val === "" || (Array.isArray(val) && !val.length)) {
      return acc;
    }

    acc[key] = val;
    return acc;
  }, {});
};

const formatForSearch = (searchModel, teamIds) => {
  const cleaned = Object.entries(searchModel).reduce((acc, [key, val]) => {
    if (!!val && !RemoveWhenSearching.has(key)) {
      acc[key] = val;
    }

    return acc;
  }, {});

  if (cleaned.storiesMin) {
    cleaned.storiesMin = parseInt(cleaned.storiesMin); // The listings API looks for numbers
  }

  if (searchModel.showOnlyNew) {
    // This is to deal with some situations where the .diff used for daysOnSite seems to round down and then listings that were shown as `New` dont show up when searching for only new
    const from = moment().utc().subtract(7, "days").startOf("day").subtract(1, "minute");
    cleaned["listDate"] = { from };
  }

  // currently assuming the team IDs would include the current agents ID because why wouldnt they
  if (searchModel.showTeamListings) {
    cleaned["agentIds"] = teamIds;
  }

  if (cleaned.officeIds) {
    cleaned.officeIds = cleaned.officeIds.reduce((acc, cur) => {
      acc.push(...cur.split("|"));
      return acc;
    }, []);
  }

  return cleaned;
};

const RemoveWhenSearching = new Set([
  "showOnlyNew",
  "showTeamListings",
  "namesByIds"
]);

const formatListings = (listings) => listings?.map(formatListing);

const MissingImageUrl = "https://blossor.s3.amazonaws.com/images/default/defaultMissingImage.jpg?v=0";

const saveObj = (key, obj) => {
  const s = JSON.stringify(obj);
  window.localStorage.setItem(key, s);
};

const loadObj = key => {
  const s = window.localStorage.getItem(key);
  return JSON.parse(s);
};
