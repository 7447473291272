import React, { useEffect } from "react";
import css from "./person-detail-styles.module.css";
import EditAddRelationship from "./EditAddRelationship";
import AddRelationshipModal from "./modals/AddRelationshipModal";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions/personDetailActionCreators";
import CollapsibleElement from "./CollapsibleElement";
import Loading from "../../Goals/components/Loader";

const RELATIONSHIP_OPTIONS = [
  { label: "Brother", value: "brother" },
  { label: "Child In Law", value: "child-in-law" },
  { label: "Child", value: "child" },
  { label: "Coworker", value: "coworker" },
  { label: "Daughter", value: "daughter" },
  { label: "Father", value: "father" },
  { label: "Friend", value: "friend" },
  { label: "Grandchild", value: "grandchild" },
  { label: "Grandparent", value: "grandparent" },
  { label: "Husband", value: "husband" },
  { label: "Landlord", value: "landlord" },
  { label: "Mother", value: "mother" },
  { label: "Other", value: "other" },
  { label: "Parent In Law", value: "parent-in-law" },
  { label: "Parent", value: "parent" },
  { label: "Partner", value: "partner" },
  { label: "Referee", value: "referee" },
  { label: "Referrer", value: "referrer" },
  { label: "Sibling", value: "sibling" },
  { label: "Sister", value: "sister" },
  { label: "Son", value: "son" },
  { label: "Spouse", value: "spouse" },
  { label: "Tenant", value: "tenant" },
  { label: "Wife", value: "wife" },
];

const Relationships = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const relationships = useSelector((state) => state.personDetail.relationships);
  const addRelationshipModalOpen = useSelector((state) => state.personDetail.addRelationshipModalOpen);

  useEffect(() => {
    if (!person.data?.id) return;
    dispatch(actions.fetchRelationships(person.data));
  }, [person.data?.id]);

  return (
    <CollapsibleElement
      title="Relationships"
      buttonText="+ ADD"
      buttonClickFunction={() => dispatch(actions.openAddRelationShipModal())}
    >
      {!person.data && <Loading />}
      <div id="person-relationships-card" className="tw-mx-[-20px]">
        {/*TODO: need to look at what happens when person isn't readable... see link_to_or_text in relationship_decorator.rb */}
        {relationships.length === 0 && (
          <div
            className={css.personDetailEmptyStateText}
            style={{ paddingLeft: "20px", paddingRight: "14px" }}
          >
            Use relationships to link contacts and show how contacts know each other.
          </div>
        )}
        {relationships.map((relationship, index) => (
          <EditAddRelationship
            key={index}
            relationship={relationship}
            relationshipOptions={RELATIONSHIP_OPTIONS}
            saveRelationship={(...args) => dispatch(actions.saveRelationship(...args))}
            deleteRelationship={(...args) => dispatch(actions.deleteRelationship(...args))}
            person={person}
          />
        ))}
        {addRelationshipModalOpen && (
          <AddRelationshipModal
            closeAddRelationShipModal={() => dispatch(actions.closeAddRelationShipModal())}
            addRelationship={(...args) => dispatch(actions.addRelationship(...args))}
            person={person}
            relationshipOptions={RELATIONSHIP_OPTIONS}
            saveRelationship={(...args) => dispatch(actions.saveRelationship(...args))}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default Relationships;
