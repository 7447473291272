import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";
import InlineEditingInput from "../InlineEditingInput";
import Toggle from "./Toggle";
import {
  extractNumeric,
  formatNumber,
  isValidPercentage,
  isValidValue,
  removeLeadingZeroes,
} from "../../inputFormatUtils";

const FlatPercent = ({
  id,
  value,
  placeholder,
  onChange,
  flatOrPercent,
  onFlatSelected,
  onPercentSelected,
  withCancelConfirm,
  className,
  ...otherProps
}) => {
  const inlineEditingInputRef = useRef();
  const [initialFlatOrPercent, setInitialFlatOrPercent] = useState(flatOrPercent);

  const focusInput = () => {
    if (!inlineEditingInputRef.current) {
      return;
    }
    const input = inlineEditingInputRef.current;
    input.focus();
  };

  const flatSelected = () => {
    if (onFlatSelected) {
      onFlatSelected();
    }
    focusInput();
  };

  const percentSelected = () => {
    if (onPercentSelected) {
      onPercentSelected();
    }
    focusInput();
  };

  const handleOnCancelEditing = () => {
    // Revert flatOrPercent if it has changed
    if (flatOrPercent === initialFlatOrPercent) {
      return;
    }
    switch (flatOrPercent) {
      case "flat":
        if (onPercentSelected) {
          onPercentSelected();
        }
        break;
      case "percent":
        if (onFlatSelected) {
          onFlatSelected();
        }
        break;
      default:
        break;
    }
  };

  const handleOnConfirmEditing = () => {
    // Update the initial value to keep the consistency
    setInitialFlatOrPercent(flatOrPercent);
  };

  const isFlat = flatOrPercent === "flat";
  const isPercent = flatOrPercent === "percent";

  const additionalTrailing = (
    <>
      {isPercent ? <span className="tw-w-[12px]">%</span> : <span className="tw-mr-[4px]" />}
      <Toggle selected={flatOrPercent} onFlatSelected={flatSelected} onPercentSelected={percentSelected} />
    </>
  );

  const validateInput = (inputValue) => {
    if (!inputValue) {
      return { isValid: true, newValue: "" };
    }

    const newValue = removeLeadingZeroes(extractNumeric(inputValue)) || "0";
    const isValid = isValidValue(newValue) && (!isPercent || isValidPercentage(newValue));
    return { isValid, newValue };
  };

  const formatInput = (inputValue) => formatNumber(inputValue, true);

  return (
    <InlineEditingInput
      inputRef={inlineEditingInputRef}
      id={id}
      value={value}
      prefix={isFlat ? "$" : ""}
      suffix={isPercent ? "%" : ""}
      placeholder={placeholder}
      onChange={onChange}
      onCancelEditing={handleOnCancelEditing}
      onConfirmEditing={handleOnConfirmEditing}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(isFlat ? { leading: <span className="tw-w-[8px]">$</span> } : {})}
      additionalTrailing={additionalTrailing}
      withCancelConfirm={withCancelConfirm}
      spacing={0}
      className={className}
      size={placeholder?.length || InlineEditingInput.numericDefaultSize}
      validateInput={validateInput}
      formatInput={formatInput}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

FlatPercent.propTypes = {
  id: PropTypes.string,
  value: nullable(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  flatOrPercent: PropTypes.oneOf(["flat", "percent"]).isRequired,
  onFlatSelected: PropTypes.func,
  onPercentSelected: PropTypes.func,
  withCancelConfirm: PropTypes.bool,
  className: PropTypes.string,
};

FlatPercent.defaultProps = {
  id: null,
  placeholder: "",
  onChange: null,
  onFlatSelected: null,
  onPercentSelected: null,
  withCancelConfirm: true,
  className: "",
};

export default FlatPercent;
