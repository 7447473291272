import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { CheckCircleRegularV3, CircleExclamationRegularV6 } from "@shared/v2/Icomoon/Icons";

const TextToolTip = ({ showMultipleReason, reasons }) => {
  if (showMultipleReason) {
    return (
      <p className="tw-text-left">
        <div className="tw-pb-[4px]">This message was not delivered to the following recipients:</div>
        <br></br>
        {reasons?.map((reason) => (
          <div className="tw-flex tw-justify-between">
            <div>{reason.recipient}:</div>
            <div>{reason.reason}</div>
          </div>
        ))}
      </p>
    );
  } else {
    return (
      <div className="tw-text-left">
        This message was not delivered. <br></br> Reason:
        {` ${reasons[0]?.reason}`}
      </div>
    );
  }
};

const SmsStatus = ({ deliveryStatus, nonDeliveryReasons }) => {
  const getStatusColor = () => {
    switch (deliveryStatus) {
      case "Undelivered":
      case "Undelivered to all":
        return "tw-text-semantic-red-110";
      case "Undelivered to some":
        return "tw-text-[#E5941B]";
      default:
        return "tw-text-gray-50";
    }
  };

  const getStatusIcon = () => {
    if (deliveryStatus === "Delivered" || deliveryStatus === "Delivered to all") {
      return <CheckCircleRegularV3 className="tw-align-middle tw-text-gray-50" />;
    } else if (
      deliveryStatus === "Undelivered" ||
      deliveryStatus === "Undelivered to all" ||
      deliveryStatus === "Undelivered to some"
    ) {
      return nonDeliveryReasons.length > 0 ? (
        <Tooltip
          innerClassName="tw-max-w-[238px]"
          placement="top"
          trigger={<CircleExclamationRegularV6 className={`tw-align-middle ${getStatusColor()}`} />}
          content={
            <TextToolTip
              reasons={nonDeliveryReasons}
              showMultipleReason={
                deliveryStatus === "Undelivered to some" || deliveryStatus === "Undelivered to all"
              }
            />
          }
        />
      ) : (
        <CircleExclamationRegularV6 className={`tw-align-middle ${getStatusColor()}`} />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {deliveryStatus && (
        <>
          <span className={`tw-align-middle tw-text-12px ${getStatusColor()}`}>{deliveryStatus}</span>
          <span className="tw-pl-[6px]">{getStatusIcon()}</span>
        </>
      )}
    </>
  );
};

SmsStatus.propTypes = {
  deliveryStatus: PropTypes.string,
  nonDeliveryReasons: PropTypes.arrayOf(
    PropTypes.shape({
      recipient: PropTypes.string,
      reason: PropTypes.string,
    }),
  ),
};

SmsStatus.defaultProps = {
  deliveryStatus: "",
  nonDeliveryReasons: [],
};

export default SmsStatus;
