import React, { useEffect, useState } from "react";

import {
  ChevronRightSolidV6,
  ChevronUpSolidV6,
  Close,
  HouseCircleXmarkSolidV6,
  MapSolidV6,
  ThumbsUpSolidV6,
} from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import Modal from "@shared/v2/Modal";
import Dnc from "./Dnc";
import Button from "@shared/v2/Button";
import Tooltip from "@shared/v2/Tooltip";

const PropertyDetails = ({ property, closeModal, addProspectAsContact }) => {
  const end = `${property.streetAddress}, ${property.city}, ${property.state} ${property.postalCode}`;
  const streetViewLink = `https://www.google.com/maps/place/${end}`;
  const zillowLink = `https://www.zillow.com/homes/${end.replace(" ", "-")}`;

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-w-full tw-h-full !tw-p-0"
      contentClassName="tw-m-auto !tw-p-0 tw-h-[100vh] tw-flex tw-flex-col tw-w-[825px] tw-right-0 !tw-absolute !tw-rounded-none"
      onHide={closeModal}
      show
    >
      <Modal.Header className="tw-mx-48px tw-mt-48px tw-mb-16px">
        <div className="tw-flex tw-flex-row tw-justify-between !tw-mt-0">
          <div className="tw-flex tw-flex-col tw-gap-4px">
            <span className="tw-text-12px tw-text-gray-50">MLS ID: {property.mlsId}</span>
            <div className="tw-flex tw-flex-row tw-gap-6px">
              <span className="tw-text-18px tw-text-gray-75">
                {property.streetAddress}, {property.city} {property.state} {property.postalCode}{" "}
              </span>
              <IconButton
                onClick={() => window.open(streetViewLink, "_blank")}
                schema="tertiary"
                size="small"
              >
                <MapSolidV6 size="m" />
              </IconButton>
              <IconButton onClick={() => window.open(zillowLink, "_blank")} schema="tertiary" size="small">
                <Zillow />
              </IconButton>
            </div>
          </div>
          <IconButton onClick={closeModal} aria-label="Close" schema="tertiary">
            <Close />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Body className="tw-flex tw-flex-col tw-overflow-y-auto tw-px-48px tw-relative tw-gap-32px tw-mb-48px">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col tw-gap-12px">
            <span className="tw-text-32px tw-font-bold tw-text-gray-75">{property.listPriceFormatted}</span>
            <div className="tw-flex tw-flex-row tw-items-center">
              <HouseCircleXmarkSolidV6 size="l" className="tw-mr-8px" />
              <span className="tw-text-gray-50 tw-font-semibold">Expired: {property.statusDateDisplay}</span>
              <div className="tw-border-0 tw-border-l tw-border-solid tw-border-gray-10 tw-h-20px tw-mx-12px"></div>
              <span className="tw-mr-8px tw-text-gray-50">Days on Market</span>
              <span className="tw-font-semibold tw-text-gray-75">{property.daysOnMarket}</span>
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-gap-32px tw-self-end">
            <PropertySpecifics name="Beds" value={property.beds} />
            <PropertySpecifics name="Baths" value={property.baths} />
            <PropertySpecifics name="Sq Ft" value={property.sqft} />
          </div>
        </div>
        <div>
          <Location lat={property.lat} lng={property.lng} address={property.streetAddress} />
        </div>
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Prospects for this Property" />
          {property.prospects?.length > 0 ? (
            <div className="tw-flex tw-flex-col tw-gap-16px">
              {property.prospects.map((prospect, i) => (
                <Prospect
                  prospect={prospect}
                  key={`prospect-${i}`}
                  addProspectAsContact={() => addProspectAsContact(property, prospect)}
                />
              ))}
            </div>
          ) : (
            <span className="tw-text-gray-50">No prospects found for this property</span>
          )}
        </div>
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Call History" />
          {property.callHistory?.length > 0 ? (
            <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-max-h-[300px] tw-pr-20px">
              {property.callHistory.map((call, i) => (
                <CallInfo key={`call-${i}`} call={call} includeBorder={i < property.callHistory.length - 1} />
              ))}
            </div>
          ) : (
            <span className="tw-text-gray-50">No calls logged for this property</span>
          )}
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Owner Information" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Name" value={property.owner?.name} />
              <DetailItem field="Address" value={property.owner?.address} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Email" value={property.owner?.email} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Listing Facts" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Property Type" value={property.propertyType} />
              <DetailItem field="Year Built" value={property.yearBuilt} />
              <DetailItem field="SQFT" value={property.sqft} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Lot Size (Acres)" value={property.lotSize} />
              <DetailItem field="County" value={property.postalCode} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="MLS Information" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="MLS ID" value={property.mlsId} />
              <DetailItem field="Status" value={property.status} />
              <DetailItem field="Expired Date" value={property.statusDateDisplay} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Agent" value={property.agent?.name} />
              <DetailItem field="Phone" value={property.agent?.phone} />
              <DetailItem field="Company" value={property.agent?.company} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Tax Information" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Owner Name" value={property.tax?.name} />
              <DetailItem field="Phone Number" value={property.tax?.phone} />
              <DetailItem field="Mailing Address" value={property.tax?.address} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Tax ID" value={property.tax?.taxId} />
              <DetailItem field="Owner Occupied" value={property.tax?.ownerOccupied} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Agent Remarks" />
          <p className="!tw-m-0">{valueOrDash(property.agentRemarks)}</p>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Showing Instructions" />
          <p className="!tw-m-0">{valueOrDash(property.showingInstructions)}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PropertyDetails;

const SectionTitle = ({ text }) => {
  return <span className="tw-text-18px tw-font-bold tw-text-gray-55 tw-mb-16px">{text}</span>;
};

const DetailItem = ({ field, value }) => {
  return (
    <div className={`tw-flex tw-justify-end`}>
      <span className="tw-flex tw-mr-auto tw-text-gray-50">{field}</span>
      <span className="tw-text-gray-75 tw-w-1/2 tw-ml-auto tw-text-right">{valueOrDash(value)}</span>
    </div>
  );
};

const PropertySpecifics = ({ name, value }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-6px">
      <span className="tw-text-18px tw-font-bold tw-text-gray-75">{valueOrDash(value)}</span>
      <span className="tw-text-12px tw-font-normal tw-text-gray-50">{name}</span>
    </div>
  );
};

const Location = ({ lat, lng, address }) => {
  useEffect(() => {
    const latLng = { lat, lng };
    const mapSettings = {
      zoom: 16,
      center: latLng,
      mapTypeId: "roadmap",
      panControl: false,
      disableDefaultUI: true,
    };

    const init = async () => {
      const { Map } = await google.maps.importLibrary("maps");
      const { Marker } = await google.maps.importLibrary("marker");

      const map = new Map(document.getElementById("listing-map"), mapSettings);
      new Marker({
        position: latLng,
        map,
        title: address,
      });
    };

    init();
  }, []);

  return <div id="listing-map" className="tw-h-[398px] tw-w-full"></div>;
};

const Prospect = ({ prospect, addProspectAsContact }) => {
  return (
    <div className="tw-flex tw-flex-row tw-justify-between">
      <div className="tw-flex tw-flex-col tw-gap-2px">
        <div className="tw-flex tw-flex-row tw-gap-4px tw-items-center">
          <span
            className={`tw-text-14px tw-max-w-[250px] tw-font-semibold tw-overflow-hidden tw-overflow-ellipsis tw-text-gray-75 ${prospect.name ? "" : "tw-italic"}`}
          >
            {prospect.name || "No Name"}
          </span>
          {prospect.isRecommended && (
            <Tooltip
              trigger={<ThumbsUpSolidV6 />}
              content={<span>Recommended Prospect</span>}
              placement="right"
            />
          )}
        </div>
        <div className="tw-flex tw-flex-row tw-gap-4px">
          <span className="tw-text-12px tw-text-gray-75">{prospect.phoneFormatted || "--"}</span>
          <Dnc show={prospect.isDnc} />
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-gap-8px tw-items-center">
        <Button schema="primary" size="small" onClick={() => {}} disabled={!prospect.phone}>
          Call
        </Button>
        <Button schema="secondary" size="small" onClick={addProspectAsContact}>
          Add as Lead
        </Button>
      </div>
    </div>
  );
};

const CallInfo = ({ call, includeBorder }) => {
  const [areNotesVisible, setAreNotesVisible] = useState(false);

  const outcome = call.outcome === "talked_to" ? "Talked" : "Missed Call";
  const preposition = call.outcome === "talked_to" ? "with" : "from";

  return (
    <div className="tw-flex tw-flex-col tw-gap-16px">
      <div className="tw-flex tw-flex-row tw-gap-12px tw-h-[68px] tw-items-center">
        <div className="tw-cursor-pointer" onClick={() => setAreNotesVisible(!areNotesVisible)}>
          {areNotesVisible ? <ChevronUpSolidV6 /> : <ChevronRightSolidV6 />}
        </div>
        <div className="tw-flex tw-flex-col">
          <span className={`tw-text-14px tw-text-gray-75 ${call.name ? "" : "tw-italic"}`}>
            {call.name || "No Name"}
          </span>
          <span className="tw-text-12px tw-text-gray-75">{call.phoneDisplay}</span>
        </div>
        <div className="tw-flex tw-flex-col tw-ml-auto tw-items-end">
          <div className="tw-text-14px">
            <span className="tw-font-semibold tw-text-gray-75">{outcome}</span>{" "}
            <span className="tw-font-normal tw-text-gray-50">{preposition}</span>{" "}
            <span className="tw-font-semibold tw-text-theme-primary">{call.agent}</span>
          </div>
          <span className="tw-text-12px tw-text-gray-75">
            {call.durationDisplay} on {call.timestampDisplay}
          </span>
        </div>
      </div>
      {areNotesVisible && (
        <div className="tw-flex tw-flex-col tw-text-12px tw-ml-20px tw-gap-6px">
          <span className="tw-font-semibold">Notes:</span>
          <p className="!tw-m-0 tw-whitespace-pre-line">{valueOrDash(call.notes)}</p>
        </div>
      )}
      {includeBorder && <hr className="tw-w-full !tw-m-0" />}
    </div>
  );
};

const valueOrDash = (value) => value || "\u2014";

const Zillow = () => {
  return (
    <svg
      className="!tw-overflow-visible"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="currentColor"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.93785 3.80145C7.99313 3.78918 8.0177 3.80758 8.05124 3.84437C8.24164 4.05571 8.85015 4.79776 9.01551 5.00014C9.04622 5.03693 9.02496 5.07703 9.00039 5.09826C7.77203 6.05495 6.40523 7.41026 5.64365 8.37922C5.62853 8.40092 5.64081 8.40092 5.6531 8.39761C6.97973 7.82728 10.0965 6.91635 11.5001 6.66208V4.88692L6.00649 0.547852L0.503418 4.88362V6.82436C2.20754 5.81295 6.15059 4.24583 7.93785 3.80145Z" />
      <path d="M3.4665 11.259C3.42067 11.2835 3.38335 11.2774 3.3465 11.2406L2.32081 10.0202C2.29341 9.98624 2.2901 9.96784 2.32696 9.91595C3.11972 8.75688 4.73786 6.95059 5.76968 6.18731C5.78811 6.17505 5.78197 6.15948 5.76023 6.16892C4.68541 6.51847 1.63009 7.83086 0.5 8.46865V12.6138H11.4972V8.62197C9.99856 8.87623 5.50889 10.2103 3.4665 11.259Z" />
    </svg>
  );
};
