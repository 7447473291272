import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import cards from "../cards.module.css";
import colors from "../colors.module.css";
import CardAppointment from "./CardAppointment";
import TaskModal from "./TaskModal";
import css from "../person-detail-styles.module.css";
import Details from "./Details";
import ViewAllAppointmentsModal from "../modals/ViewAllAppointmentsModal";
import CollapsibleElement from "../CollapsibleElement";
import * as actions from "../../actions/personDetailActionCreators";
import Loading from "../../../Goals/components/Loader";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const CardAppointments = ({ allAgents, accountMilestoneNames }) => {
  const dispatch = useDispatch();
  const [state, _setState] = useState({
    viewAlltasks: false,
    viewDetails: false,
    selectedTask: undefined,
    viewAppointmentModal: false,
    editAppointment: false,
  });
  const setState = (newState) => _setState((prevState) => ({ ...prevState, ...newState }));

  const currentUser = useCurrentUser();
  const person = useSelector((state) => state.personDetail.person);
  const incompleteAppointments = useSelector((state) => state.personDetail.appointments);
  const allAppointmentCount = useSelector((state) => state.personDetail.allAppointmentCount);
  const appointmentModalOpen = useSelector((state) => state.personDetail.appointmentModalOpen);

  const getAppointments = (personUuid) => dispatch(actions.getAppointments(personUuid));
  useEffect(() => {
    if (person.data?.attributes.uuid) getAppointments(person.data.attributes.uuid);
  }, [person.data?.attributes.uuid]);

  const openAppointmentModal = () => {
    setState({ viewAppointmentModal: true });
  };
  const closeAppointmentModal = () => {
    setState({ viewAppointmentModal: false });
  };
  const showTaskDetails = (task) => setState({ viewDetails: true, selectedTask: task });
  const editTask = (task) => setState({ viewDetails: false, selectedTask: task, editAppointment: true });
  const closeTaskDetails = () =>
    setState({ viewDetails: false, selectedTask: undefined, editAppointment: false });
  const updateQuickViewTask = (taskToUpdate) => dispatch(actions.updateCardTasks(taskToUpdate));
  const closeTaskModal = () => dispatch(actions.closeAppointmentModal());
  const editAndComplete = (task) => {
    task["state"] = "completed";
    setState({ viewDetails: false, selectedTask: task, task });
    dispatch(actions.openAppointmentModal());
  };
  const openAddAppointmentModal = () => dispatch(actions.openAppointmentModal());

  const emptyState = (incompleteAppointments, allAppointmentCount) => {
    if (incompleteAppointments.length == 0 && allAppointmentCount == 0) {
      return (
        <div className={css.personDetailEmptyStateText}>
          Create an appointment for this contact and assign it to yourself or a teammate.
        </div>
      );
    } else if (incompleteAppointments.length == 0 && allAppointmentCount > 0) {
      return <div className={css.personDetailEmptyStateText}>No upcoming tasks.</div>;
    }
  };

  const taskable = {
    task_name: `${person.data?.attributes.first_name} ${person.data?.attributes.last_name}`.trim(),
    uuid: person.data?.attributes.uuid,
    taskable_type: "Person",
  };

  return (
    <CollapsibleElement title="Appointments" buttonText="+ ADD" buttonClickFunction={openAddAppointmentModal}>
      {!person.data && <Loading />}
      <div id="person-tasks-card" className="tw-relative">
        {emptyState(incompleteAppointments, allAppointmentCount)}
        <Row>
          {incompleteAppointments.map((task) => (
            <CardAppointment key={task.id} task={task} showTaskDetails={showTaskDetails} />
          ))}
        </Row>
        {allAppointmentCount > 2 && (
          <Row>
            <Col xs={12} className="m-t-10">
              <a onClick={openAppointmentModal} className={`${cards.viewAll} ${colors.brivityBlue}`}>
                VIEW ALL
              </a>
            </Col>
          </Row>
        )}
        {state.viewAppointmentModal && (
          <ViewAllAppointmentsModal
            person={person}
            onClose={closeAppointmentModal}
            closeTaskModal={closeTaskDetails}
            agents={allAgents}
            account_uuid={currentUser.account.uuid}
            user={currentUser}
            taskable={taskable}
            accountMilestoneNames={accountMilestoneNames}
            task={state.selectedTask}
            updateTask={updateQuickViewTask}
          />
        )}
        {state.viewDetails && (
          <Details
            task={state.selectedTask}
            closeTaskDetails={closeTaskDetails}
            editTask={editTask}
            editAndComplete={editAndComplete}
            accountMilestoneNames={accountMilestoneNames}
          />
        )}
        {state.editAppointment && (
          <TaskModal
            closeTaskModal={closeTaskDetails}
            agents={allAgents}
            account_uuid={currentUser.account.uuid}
            user={currentUser}
            taskable={taskable}
            accountMilestoneNames={accountMilestoneNames}
            task={state.selectedTask}
            updateTask={updateQuickViewTask}
          />
        )}

        {appointmentModalOpen && (
          <TaskModal
            agents={allAgents}
            account_uuid={currentUser.account.uuid}
            user={currentUser}
            taskable={taskable}
            accountMilestoneNames={accountMilestoneNames}
            task={state.selectedTask}
            updateTask={updateQuickViewTask}
            getCardTasks={getAppointments}
            isAppointment
            closeTaskModal={closeTaskModal}
            contactEmail={person.data?.attributes.main_email}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default CardAppointments;
