import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import TextLink from "@shared/v2/TextLink";
import { deleteInteractionTemplate, setDeleteTemplate } from "../reducers/interactionTemplatesReducer";

const DeleteInteractionTemplateModal = () => {
  const dispatch = useDispatch();
  const deleteTemplate = useSelector((state) => state.interactionTemplates.deleteTemplate);
  const loading = useSelector((state) => state.interactionTemplates.interactionTemplates.loading);

  return (
    <Modal
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[720px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      show={Boolean(deleteTemplate)}
      onHide={() => dispatch(setDeleteTemplate(null))}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <Modal.Header title="Are you sure?" closeButton />
      <Modal.Body className="tw-flex tw-flex-col tw-gap-[16px] tw-overflow-auto">
        <p>
          Are you sure you want to delete this template?{" "}
          {deleteTemplate?.actionPlans?.length > 0 && (
            <>
              This email Template is being used in {deleteTemplate.actionPlans.length} People Action Plans.
              Before deleting it we recommend you update them.
            </>
          )}
        </p>
        <ul>
          {deleteTemplate?.actionPlans?.map(({ name, href }) => (
            <li key={href}>
              <TextLink href={href} target="_blank">
                {name}
              </TextLink>
            </li>
          ))}
        </ul>
        {deleteTemplate?.futureInteractions !== 0 && (
          <p>
            Would you still like to send any pre-scheduled emails that came from this template? There are{" "}
            {deleteTemplate?.futureInteractions} pre-scheduled emails to be sent.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-flex-wrap-reverse tw-gap-[8px] tw-justify-between">
          <Button
            isLoading={loading}
            schema="secondary"
            size="medium"
            onClick={() => dispatch(setDeleteTemplate(null))}
          >
            Cancel
          </Button>
          <div className="tw-flex tw-gap-[8px]">
            {deleteTemplate?.futureInteractions !== 0 && (
              <Button
                onClick={() =>
                  dispatch(deleteInteractionTemplate({ template: deleteTemplate, archive: true }))
                }
                size="medium"
                schema="tertiary"
              >
                Send & Delete
              </Button>
            )}
            <Button
              isLoading={loading}
              size="medium"
              schema="warning"
              onClick={() => dispatch(deleteInteractionTemplate({ template: deleteTemplate }))}
            >
              Yes, Delete
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteInteractionTemplateModal;
