import React, { Component } from "react";
import { produce } from "immer";
import cards from "./cards.module.css";
import css from "./person-detail-styles.module.css";
import icons from "./icons.module.css";
import button from "./button.module.css";
import { Row, Col, Alert } from "react-bootstrap";
import form from "./form.module.css";
import { Dropdown } from "../../shared/v1";
import DatePicker from "../../shared/DatePicker";
import { updatePersonPromise } from "../actions/updatePerson";

class EditableElement extends Component {
  state = {
    editingElement: false,
    editablePerson: JSON.parse(JSON.stringify(this.props.person)),
    errors: null,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  onElementEdit = () => {
    this.setState({ editingElement: true });
  };

  onElementSave = () => {
    updatePersonPromise(this.state.editablePerson)
      .then((r) => {
        this.props.updatePersonData(r.data);
        this.setState({ editingElement: false, errors: null });
      })
      .catch((err) => {
        this.setState({ errors: err.data.errors });
      });
  };

  onDismissElementEdit = () => {
    this.setState({
      editingElement: false,
      editablePerson: JSON.parse(JSON.stringify(this.props.person)),
      errors: null,
    });
  };

  handleEditableValueChange = (value, key) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes[key] = value;
      }),
    }));
  };

  handleCustomFieldChange = (key, value) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes.custom_fields[key] = value;
      }),
    }));
  };

  handleDetailableValueChange = (value, key) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes[key].value = value;
      }),
    }));
  };

  formatSelectOptions = (options) => {
    return options.map((opt) => {
      return { label: opt[0], value: opt[1] };
    });
  };

  handleMilestoneableDateChange = (value, key) => {
    this.setState(({ editablePerson }) => ({
      editablePerson: produce(editablePerson, (draft) => {
        draft.data.attributes[key].date = value;
      }),
    }));
  };

  editMultilineInput = () => {
    return (
      <textarea
        style={{ width: "100%" }}
        value={this.state.editablePerson.data.attributes[this.props.elementType]}
        onChange={(e) => this.handleEditableValueChange(e.target.value, this.props.elementType)}
        rows={5}
        className={form.formControl}
      />
    );
  };

  editSingleLineInputInput = () => {
    return (
      <input
        type="text"
        style={{ width: "100%" }}
        value={this.state.editablePerson.data.attributes[this.props.elementType]}
        onChange={(e) => this.handleEditableValueChange(e.target.value, this.props.elementType)}
        className={form.formControl}
      />
    );
  };

  editCustomFieldInput = () => {
    return (
      <input
        type="text"
        style={{ width: "100%" }}
        value={this.state.editablePerson.data.attributes.custom_fields[this.props.fieldName]}
        onChange={(e) => this.handleCustomFieldChange(this.props.fieldName, e.target.value)}
        className={form.formControl}
      />
    );
  };

  editDetailableSingleLineInputInput = () => {
    return (
      <input
        type="text"
        style={{ width: "100%" }}
        value={this.state.editablePerson.data.attributes[this.props.elementType].value}
        onChange={(e) => this.handleDetailableValueChange(e.target.value, this.props.elementType)}
        className={form.formControl}
      />
    );
  };

  editDropdownInput = () => {
    return (
      <Dropdown
        isSearchable={false}
        isClearable={false}
        value={this.state.editablePerson.data.attributes[this.props.elementType].value}
        onChange={(e) => this.handleDetailableValueChange(e.value, this.props.elementType)}
        placeholder="Select..."
        options={this.formatSelectOptions(this.state.editablePerson.meta[this.props.dropdownOptions])}
        variant="flatBlueishGray"
      />
    );
  };

  editDateInput = () => {
    return (
      <DatePicker
        timeFormat={false}
        dateFormat="YYYY-MM-DD"
        name={this.props.elementType}
        value={this.state.editablePerson.data.attributes[this.props.elementType].date}
        onChange={(value) => this.handleMilestoneableDateChange(value, this.props.elementType)}
      />
    );
  };

  renderEditFieldsByType = () => {
    switch (this.props.elementType) {
      case "description":
        return this.editMultilineInput();
      case "letter_salutation":
        return this.editSingleLineInputInput();
      case "envelope_salutation":
        return this.editSingleLineInputInput();
      case "marital_status":
        return this.editDropdownInput();
      case "kids":
        return this.editDetailableSingleLineInputInput();
      case "pets":
        return this.editDetailableSingleLineInputInput();
      case "timezone":
        return this.editDropdownInput();
      case "company":
        return this.editDetailableSingleLineInputInput();
      case "job_title":
        return this.editDetailableSingleLineInputInput();
      case "custom_field":
        return this.editCustomFieldInput();
      default:
        return undefined;
    }
  };

  renderEditFields = () => {
    return (
      <Row>
        {this.state.errors && (
          <Alert bsStyle="danger">
            {this.state.errors.map((error) => (
              <span>
                <strong>Error: </strong> {error}
                <br />
              </span>
            ))}
          </Alert>
        )}
        <Col xs={8} className="p-t-5">
          {this.renderEditFieldsByType()}
        </Col>
        <Col xs={4} className="p-t-5">
          <div className={`${icons.edit} d-flex`} style={{ justifyContent: "flex-end" }}>
            <div>
              <a
                className={`${button.btn} ${button.btnSmNarrow} ${button.btnBlue}`}
                onClick={this.onElementSave}
              >
                SAVE
              </a>
            </div>
            <div style={{ paddingLeft: "6px" }}>
              <a className={`${icons.gray} ${icons.button} pull-left`} onClick={this.onDismissElementEdit}>
                <i className={`${icons.smIcon} fa fa-times`} />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  renderFieldValues = () => {
    switch (this.props.elementType) {
      case "description":
        return (
          <div
            className={css.brivityPersonDetailsText}
            dangerouslySetInnerHTML={{ __html: this.props.person.data?.attributes.description }}
          />
        );
      case "envelope_salutation":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.envelope_salutation}
          </div>
        );
      case "letter_salutation":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.letter_salutation}
          </div>
        );
      case "marital_status":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.marital_status?.value}
          </div>
        );
      case "kids":
        return (
          <div className={css.brivityPersonDetailsText}>{this.props.person.data?.attributes.kids?.value}</div>
        );
      case "pets":
        return (
          <div className={css.brivityPersonDetailsText}>{this.props.person.data?.attributes.pets?.value}</div>
        );
      case "timezone":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.timezone?.value}
          </div>
        );
      case "company":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.company?.value}
          </div>
        );
      case "job_title":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.job_title?.value}
          </div>
        );
      case "custom_field":
        return (
          <div className={css.brivityPersonDetailsText}>
            {this.props.person.data?.attributes.custom_fields[this.props.fieldName]}
          </div>
        );
      default:
        return;
    }
  };

  render() {
    return (
      <Row
        className={`${icons.inlineEditable} ${cards.fullRowHover}`}
        style={{ paddingTop: "9px", paddingBottom: "9px" }}
      >
        <Col xs={6}>
          <div className={css.brivityPersonDetailsLabel}>{this.props.fieldName}</div>
        </Col>
        {!this.state.editingElement && (
          <Col xs={6} className={`${icons.edit}`}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <a className={`${icons.blue} ${icons.button} ${icons.hidden}`}>
                  <i className={`${icons.smIcon} fa fa-pencil`} onClick={this.onElementEdit} />
                </a>
              </div>
            </div>
          </Col>
        )}
        <Col xs={12}>
          <div>{this.state.editingElement ? this.renderEditFields() : this.renderFieldValues()}</div>
        </Col>
      </Row>
    );
  }
}

export default EditableElement;
