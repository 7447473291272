import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewAllDocumentsModal from "./modals/ViewAllDocumentsModal";
import icons from "./icons.module.css";
import cards from "./cards.module.css";
import colors from "./colors.module.css";
import css from "./person-detail-styles.module.css";
import {
  fetchDocuments,
  handleDocumentDelete,
  handleDocumentUpload,
} from "../actions/personDetailActionCreators";
import CollapsibleElement from "./CollapsibleElement";
import Loading from "../../Goals/components/Loader";

const FadeOut = () => (
  <div className="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-w-[48px] tw-bg-gradient-to-r tw-from-transparent tw-to-white" />
);

const DocumentCard = ({
  document: { id, date_added, file_name, image_icon, uploaded_by_name, uploaded_by_url, expiring_url },
  index,
  onDelete,
  person,
}) => (
  <div
    key={id}
    className="tw-relative tw-shadow-tdp-card tw-overflow-hidden tw-flex tw-flex-col tw-gap-[12px] tw-rounded-[7px] tw-p-[12px]"
  >
    <div className="tw-flex tw-items-center tw-gap-[12px] tw-overflow-hidden">
      <div className="tw-flex tw-items-center tw-gap-[12px] tw-flex-1 tw-min-w-0">
        <i className={`fa ${image_icon} ${icons.faLg} ${colors.brivityBlue}`} />
        <div className="tw-flex-1 tw-relative tw-overflow-hidden">
          <a
            className="tw-text-14d tw-text-neutral-gray-75 tw-font-semibold tw-whitespace-nowrap"
            href={expiring_url}
            target="_blank"
            title={file_name}
          >
            {file_name}
            <FadeOut />
          </a>
        </div>
      </div>
      <div className={`${icons.downloadAndDelete} tw-flex tw-gap-[4px]`}>
        <a className={`${icons.button} ${icons.gray}`} href={expiring_url} target="_blank" download={true}>
          <i className={`${icons.smIcon} fa fa-download`} />
        </a>
        <a className={`${icons.button} ${icons.gray}`}>
          <i
            onClick={() => onDelete(person.data.attributes, id, index)}
            className={`${icons.smIcon} fa fa-trash`}
          />
        </a>
      </div>
    </div>
    <div className="tw-flex tw-whitespace-nowrap">
      <div className="tw-flex-1 tw-relative tw-overflow-hidden tw-min-w-0">
        <span>
          Uploaded by{" "}
          <a href={uploaded_by_url} title={uploaded_by_name}>
            {uploaded_by_name}
          </a>
        </span>
        <div className="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-w-[48px] tw-bg-gradient-to-r tw-from-transparent tw-to-white" />
        <FadeOut />
      </div>
      <span>{date_added}</span>
    </div>
  </div>
);

const Documents = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const documents = useSelector((state) => state.personDetail.documents);
  const uploadingDocument = useSelector((state) => state.personDetail.uploadingDocument);
  const hiddenUploadInput = useRef(null);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);

  useEffect(() => {
    if (person.data?.id) dispatch(fetchDocuments(person.data));
  }, [person.data?.id]);

  return (
    <CollapsibleElement
      title="Documents"
      buttonText="UPLOAD"
      buttonClickFunction={() => hiddenUploadInput.current?.click()}
    >
      {!person.data && <Loading />}
      <input
        id="documentUploadInput"
        type="file"
        ref={hiddenUploadInput}
        onChange={(event) => dispatch(handleDocumentUpload(event, person.data.attributes, false))}
        style={{ display: "none" }}
      />
      <div id="person-documents-card" className="tw-flex tw-flex-col tw-gap-[12px]">
        <div id="progress" className="tw-relative tw-h-[12px] tw-my-[-12px]">
          <div className={`progress-bar tw-bg-teal ${uploadingDocument ? "tw-w-full" : "tw-w-0"}`} />
        </div>

        {documents.length === 0 && (
          <div className={css.personDetailEmptyStateText}>
            Upload documents associated with this contact here (images, PDFs, .doc, etc.).
          </div>
        )}

        {documents.slice(0, 2).map((document, index) => (
          <DocumentCard
            key={document.id}
            document={document}
            index={index}
            onDelete={(...args) => dispatch(handleDocumentDelete(...args))}
            person={person}
          />
        ))}

        {documents.length > 2 && (
          <a onClick={() => setShowDocumentsModal(true)} className={`${cards.viewAll} ${colors.brivityBlue}`}>
            VIEW ALL
          </a>
        )}

        {showDocumentsModal && (
          <ViewAllDocumentsModal
            documents={documents}
            onClose={() => setShowDocumentsModal(false)}
            handleDocumentDelete={(...args) => dispatch(handleDocumentDelete(...args))}
            person={person}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default Documents;
