import React from "react";
import moment from "moment-timezone";
import Proptypes from "prop-types";

const MilestoneDate = ({ milestoneDate, short }) => (
  <>
    {milestoneDate ? (
      <h5>{moment(milestoneDate).format(short ? "MM-DD" : "MM-DD-YYYY")}</h5>
    ) : (
      <span className="tw-text-gray-50">—</span>
    )}
  </>
);

MilestoneDate.propTypes = {
  milestoneDate: Proptypes.string,
  short: Proptypes.bool,
};
MilestoneDate.defaultProps = {
  milestoneDate: null,
  short: false,
};

export default MilestoneDate;
