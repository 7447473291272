import React from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { Dropdown } from '../../shared/v1';

const optionsType = [
    { value:'other',label:'Other' },
    { value:'pop_by',label:'Pop By' },
    { value:'mail',label:'Mail' },
    { value:'social_media',label:'Social Media' },
]

const OtherInteractDetails = (props) => {
  const { interaction, updateValues } = props;
  return(
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <label>Date</label>
              <Datetime
                timeFormat={false}
                dateFormat='MM-DD-YYYY'
                value={moment(interaction.interacted_at)}
                onChange={(e) =>updateValues('interacted_at', moment(e).format())}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <label>Type</label>
              <Dropdown
                isSearchable={false}
                isClearable={false}
                onChange={(e) =>updateValues('interaction_type', e.value)}
                value={interaction.interaction_type}
                options={optionsType}
              />
          </Col>
        </Row>
      </Col>
      <Col xs={12} style={{marginTop: '10px'}}>
        <label>Notes</label>
        <textarea
          onChange={(e) => props.updateValues('comment', e.target.value)}
          value={interaction.comment || ''}
          name='comment'
          style={{resize: 'none'}}
          className='form-control notes'
          rows='3'>
        </textarea>
      </Col>
    </Row>
  )
}

export default OtherInteractDetails;
