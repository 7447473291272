import React from "react";
import { Col, Dropdown as DropdownV1, Label } from "../../shared/v1";
import Dropdown from "../../shared/v2/Dropdown";

const OUTCOMES = {
  COMPLETE: { label: "Appointment Complete", value: "Appointment Complete" },
  CANCELED: { label: "Appointment Canceled", value: "Appointment Cancelled" },
  NO_SHOW: { label: "No Show", value: "No Show" },
  SIGNED_BUYER: {
    label: "Signed - Buyer Agreement",
    value: "Signed - Buyer Agreement",
  },
  SIGNED_LISTING: {
    label: "Signed - Listing Agreement",
    value: "Signed - Listing Agreement",
  },
  SIGNED_BOTH: {
    label: "Signed - Both Buyer/Listing",
    value: "Signed - Both Buyer/Listing",
  },
  SIGNED_RECRUIT: {
    label: "Signed - Recruit",
    value: "Signed - Recruit",
  },
  NOT_A_FIT: {
    label: "Not a Fit",
    value: "Not a Fit",
  },
};

const OutcomeSelection = (props) => {
  const outcomeOptions = () => {
    const appointmentType = props.appointmentType;
    let outcomes = [OUTCOMES.COMPLETE, OUTCOMES.CANCELED, OUTCOMES.NO_SHOW];

    switch (appointmentType) {
      case "Buyer Consultation":
        outcomes.splice(1, 0, OUTCOMES.SIGNED_BUYER, OUTCOMES.SIGNED_BOTH);
        break;
      case "Listing Consultation":
        outcomes.splice(1, 0, OUTCOMES.SIGNED_LISTING, OUTCOMES.SIGNED_BOTH);
        break;
      case "Buyer/Listing Consultation":
        outcomes.splice(1, 0, OUTCOMES.SIGNED_BUYER, OUTCOMES.SIGNED_BOTH, OUTCOMES.SIGNED_LISTING);
        break;
      case "Recruiting Appointment":
        outcomes.splice(0, 0, OUTCOMES.SIGNED_RECRUIT);
        outcomes.splice(2, 0);
        break;
      case "Signing Appointment":
        outcomes.splice(0, 0, OUTCOMES.SIGNED_RECRUIT);
        break;
      case "Meet & Greet":
      case "Screening":
      case "Recruiting Follow Up":
        outcomes.splice(1, 0, OUTCOMES.NOT_A_FIT);
        break;
      default:
        outcomes;
    }

    return outcomes;
  };
  const { task, handleOutcomeOnChange } = props;
  return (
    <Col size={6} className={task.completed && task.type ? "tw-block" : "tw-hidden"}>
      <Dropdown
        label="Outcome"
        isRequired
        options={outcomeOptions()}
        value={outcomeOptions().find((opt) => opt.value === task.outcome)}
        onChange={(option) => handleOutcomeOnChange(option.value)}
        isDisabled={task.completed && task.type == "Appointment" ? false : true}
      />
    </Col>
  );
};

export default OutcomeSelection;
