/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { extractData } from "../utils";

const Control = ({ children, ...componentProps }) => {
  const Component = componentProps.selectProps?.custom?.Control;

  if (!Component) {
    return null;
  }

  const [ValueContainer, IndicatorsContainer] = children;

  return (
    <Component componentProps={componentProps} data={extractData(componentProps)}>
      {ValueContainer}

      {componentProps.selectProps?.inputTrailing && (
        <div
          className={`
            ${componentProps.isDisabled ? "tw-text-neutral-gray-30" : "tw-text-neutral-gray-50"}
            tw-text-14d tw-font-normal
          `}
        >
          {componentProps.selectProps.inputTrailing}
        </div>
      )}

      {IndicatorsContainer}
    </Component>
  );
};

Control.propTypes = {
  selectProps: PropTypes.shape({
    custom: PropTypes.shape({
      Control: PropTypes.func,
    }),
  }),
  children: PropTypes.node,
};

Control.defaultProps = {
  selectProps: {
    custom: {
      Control: null,
    },
  },
  children: null,
};

export default Control;
