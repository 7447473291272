import React, { Component } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import capitalize from "capitalize";
import Pdf from "./Pdf";

export default class TimelineEvent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_modal: false,
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;
    this.event = this.props.event;

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show_modal: true });
  };

  hideModal = () => {
    this.setState({ show_modal: false });
  };

  details = () => {
    let description = [];
    let fields = this.event.timeline_fields;
    for (var key in fields) {
      const titleized_key = key
        .split("_")
        .map((element) => {
          return capitalize(element);
        })
        .join(" ");
      description.push(titleized_key + ": " + fields[key]);
    }
    let to_value = this.event.timeline_fields.to;
    let from_value = this.event.timeline_fields.from;
    let name_value = this.event.timeline_fields.name;
    let file_name = this.event.timeline_fields.file_name;
    let file_uri = this.event.timeline_fields.file_uri;
    let newDescription = [`To: ${to_value}`, `From: ${from_value}`, `Name: ${name_value}`];
    if (this.event.event_type == "modified_listing_alert_frequency") {
      return newDescription.map((newD) => {
        return (
          <div key={newD} className="row details-row">
            {newD}
          </div>
        );
      });
    } else if (this.event.event_type == "disabled_monthly_listing_alert") {
      return (
        <div key={description} className="row details-row">
          Name: {name_value}
        </div>
      );
    } else if (this.event.event_type == "unsubscribed_listing_alerts") {
      return (
        <p>
          Listing Alert emails have been deactivated. To protect your email deliverability reputation, the
          status can not be changed. Please reach out to this contact via an alternate method.
        </p>
      );
    } else if (this.event.event_type == "recruiter_upload") {
      return (
        <>
          <div className="row tw-break-words details-row">
            File Name:{" "}
            <a
              href={file_uri}
              target="_blank"
              className="tw-text-brivity-blue-100 hover:tw-text-brivity-blue-100 hover:tw-underline"
            >
              {file_name}
            </a>
          </div>
          <div className={`${!this.state.show_modal && "tw-w-[145%]"}`}>
            <Pdf file={file_uri} />
          </div>
        </>
      );
    } else {
      return description.map((description) => {
        return (
          <div key={description} className="row tw-break-words details-row">
            {description}
          </div>
        );
      });
    }
  };

  row = () => {
    const { event_type, titleized_type } = this.event;
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i
              className="fa fa-bell-o timeline-icon-circle"
              style={{
                color:
                  event_type == "disabled_monthly_listing_alert" ||
                  event_type == "unsubscribed_listing_alerts"
                    ? "#F2714D"
                    : null,
              }}
            />
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">Event: </span>
            {event_type == "modified_listing_alert_frequency"
              ? "System modified Listing Alert frequency due to inactivity"
              : event_type == "disabled_monthly_listing_alert"
              ? "System paused Listing Alert due to extended inactivity"
              : event_type == "unsubscribed_listing_alerts"
              ? "Contact unsubscribed from Listing Alerts"
              : titleized_type}
            <div className="timeline-description-text">{this.details()}</div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    );
  };

  modal = () => {
    return (
      this.state.show_modal && (
        <Modal bsSize="sm" show={this.state.show_modal} onHide={this.hideModal}>
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="tw-ml-[-10px]">
                <span className="bold-detail">Event:</span>
                {` ${this.event.titleized_type}`}
              </div>
              <div className="row bold-detail details-row details-header">Details:</div>
              {this.details()}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </div>
        </Modal>
      )
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
