import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import styles from "./Toggle.styles";

const { dotSchema, trackSchema } = styles;

const Dot = ({ className, size }) => <div className={`${dotSchema[size]} ${className}`} data-role="button" />;

Dot.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]).isRequired,
};

Dot.defaultProps = {
  className: "",
};

const Track = ({ className, size }) => (
  <div className={`${trackSchema[size]} ${className}`} data-role="track" />
);

Track.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]).isRequired,
};

Track.defaultProps = {
  className: "",
};

const Toggle = ({
  id: idFromProps,
  checked,
  disabled,
  labelClassName,
  onChange,
  size,
  dotClassName,
  trackClassName,
  text,
  labelPlacement,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("toggle", idFromProps);
  const textContent = text ? <span>{text}</span> : null;

  return (
    <label
      htmlFor={id}
      className={`tw-flex tw-items-center tw-gap-[8px] tw-cursor-pointer tw-text-gray-75 tw-text-14d tw-leading-[20px] ${labelClassName}`}
    >
      {labelPlacement === "start" && textContent}
      <div className="tw-relative" data-role="toggle">
        <input
          type="checkbox"
          id={id}
          className="tw-sr-only tw-peer"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...otherProps}
        />
        <Track size={size} className={trackClassName} />
        <Dot size={size} className={dotClassName} />
      </div>
      {labelPlacement === "end" && textContent}
    </label>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["small", "large"]),
  dotClassName: PropTypes.string,
  trackClassName: PropTypes.string,
  text: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["start", "end"]),
};

Toggle.defaultProps = {
  id: null,
  disabled: false,
  labelClassName: "",
  onChange: null,
  size: "small",
  dotClassName: "",
  trackClassName: "",
  text: "",
  labelPlacement: "end",
};

export default Toggle;
