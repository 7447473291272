import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Overlay } from "react-bootstrap";
import axios from "axios";
import Tooltip from "@shared/v2/Tooltip";
import { homeAppTxt } from "@shared/Utilities";
import popovers from "../../PersonDetail/components/popovers.module.css";
import moment from "moment/moment";
import "./HomeAppLink.scss";

export default class HomeAppLink extends Component {
  state = {
    showPopover: false,
    show: false,
    selectedDay: null,
    loadingUrl: false,
    invite_sent: this.props.invite_sent,
    appUrl: "",
  };

  homeLinkStatus() {
    const { last_login } = this.props;

    return this.state.invite_sent == null && last_login == null ? "empty" : "sent";
  }

  formattedLabel() {
    const { last_login } = this.props;

    if (last_login != null) {
      return moment(last_login).fromNow();
    } else if (this.state.invite_sent != null) {
      return "Sent";
    }
  }

  interactionSuccess = (data) => {
    const { person_id } = this.props;

    axios
      .patch(`/api/v4/person_detail/${person_id}/touch_home_app_invite_sent`, {
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        this.setState({ invite_sent: res.data.data.attributes.home_app_invite_sent });
      });
  };

  handleSelectedType = (type) => {
    const sharedUrlContent = "Download my Brivity Home search app to search for homes here: ";
    const { person_id, recipientFirstName, recipientEmailAddress, teamName, currentUserFullName } = this.props;

    if (type === "email") {
      const emailBody = `${sharedUrlContent} <a href="${this.state.appUrl}" target="_blank">${this.state.appUrl}</a>.<br/><br/>`;
      Brivity.EmailInteraction.showModal(person_id, null, null, null, emailBody, this.interactionSuccess);
    } else if (type === "text") {
      const textBody = `${sharedUrlContent} ${this.state.appUrl}.`;
      const event = new CustomEvent("homeAppSmsClick", {
        detail: {
          personId: person_id, text: homeAppTxt({
            primaryAgentName: currentUserFullName,
            brivityAppLink: this.state.appUrl,
            recipientFirstName,
            recipientEmailAddress,
            teamName,
          })
        }
      });
      window.dispatchEvent(event);
    } else if (type === "copy") {
      navigator.clipboard.writeText(this.state.appUrl).catch((error) => {
        console.log(error);
      });
    }
  };

  fetchBrivityHomeURL = (type) => {
    this.setState({ loadingUrl: true });

    axios
      .get(`/people/${this.props.slug}/home_app`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({ appUrl: response.data.url, loadingUrl: false }, () =>
          this.handleSelectedType(type),
        );
      })
      .catch((error) => {
        this.setState({ loadingUrl: false });
      });
  };

  toggle = () => {
    if (!this.props.email_active) return;
    this.setState({ showPopover: !this.state.showPopover });
  };

  showMenuitem = (key, itemType, caption, iconClass) => {
    return (
      <div
        key={key}
        className={"menuitem overflow"}
        onMouseDown={() => {
          this.fetchBrivityHomeURL(itemType);
        }}
      >
        {caption} <i className={`tw-font-[12px] tw-w-[24px]  tw-m-0 tw-p-0 fa ${iconClass}`} aria-hidden="true" />
      </div>
    );
  };

  render() {
    const label = this.formattedLabel();
    const status = this.homeLinkStatus();

    return (
      <div className="home-app-column" onClick={this.toggle}>
        {!this.props.email_active ?
          <Tooltip
            multiline
            placement="left"
            tooltipClassName='tw-ml-[90px]'
            content={<div className="tw-w-[180px]">An email address is required to log in to Brivity Home. Please add an email address to the contact before sending an invite.</div>}
            trigger={
              <div className="flex-row d-flex space-evenly flex-justify-center pseudo-link tw-cursor-auto">
                <i className={`fa6 fa6-mobile-screen tw-text-[20px] ${status} !tw-text-gray-30`} />
              </div>
            }
          /> :
          <div className="flex-row d-flex space-evenly flex-justify-center pseudo-link">
            <i className={`fa6 fa6-mobile-screen tw-text-[20px] ${status} !tw-text-gray-50`} />
          </div>
        }
        <h6 className={label && "lower-row"}>
          <span>{label}</span>
        </h6>
        {this.renderOverlayMenu()}
      </div>
    );
  }

  renderOverlayMenu() {
    const { email_active, sms_active } = this.props;
    return (
      <Overlay
        show={this.state.showPopover}
        onHide={() => this.setState({ showPopover: false })}
        placement={"bottom"}
        rootClose={true}
        rootCloseEvent={"mousedown"}
        container={this}
        target={() => ReactDOM.findDOMNode(this.target)}
      >
        <div
          className="overlayPosition"
          onBlur={() => this.setState({ showPopover: false })}
        >
          <div className=" tw-mt-[15px] tw-w-[154px] tw-p-[0px] tw-font-semibold	
          tw-leading-5 tw-text-[14px] tw-shadow-bootstrap-18 tw-shrink-0	tw-bg-[#ffffff] 
          tw-px-0 tw-absolute tw-ml-[-0.7%] tw-rounded-[8px] tw-z-1 tw-text-left	tw-text-[#666666]">
            {sms_active && this.showMenuitem(0, "text", "Text", "fa-commenting-o")}
            {email_active && this.showMenuitem(1, "email", "Email", "fa-envelope-o")}
            {this.showMenuitem(2, "copy", "Copy Link", "fa-link")}
          </div>
        </div>
      </Overlay>
    );
  }
}
