import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Cluster from "../../../../shared/v2/Cluster";
import { EditIcon, ArchiveIcon, EllipsisIcon, TrashIcon } from "../../icons";
import Listing from "../../../models/Listing";
import useOutsideClick from "../../../utils/useOnClickOutside";
// eslint-disable-next-line import/no-cycle
import { isAddressoOrTitle } from "../../Transaction";

const DeleteButton = ({ onDeletion, listing }) => (
  <button
    type="button"
    className="tw-text-[12px] tw-w-full tw-border-0 tw-bg-transparent tw-px-0"
    onClick={() => onDeletion(listing)}
  >
    <Cluster justify="start" space="md" align="center">
      <span className="tw-text-[12px] tw-text-red-500">
        <TrashIcon />
      </span>
      <span className="tw-text-[12px]">Delete</span>
    </Cluster>
  </button>
);

const ArchiveButton = ({ onArchive, listing }) => (
  <button
    type="button"
    className="tw-text-[12px] tw-w-full tw-border-0 tw-bg-transparent tw-px-0"
    onClick={() => onArchive(listing)}
  >
    <Cluster justify="start" space="md" align="center">
      <span className="tw-text-[12px]">
        <ArchiveIcon />
      </span>
      <span className="tw-text-[12px]">Archive</span>
    </Cluster>
  </button>
);

const ListingOptions = ({ listing, onArchive, onDeletion, permissions }) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setOpened(false));
  const canDelete = listing.isDeletable && permissions.listings.delete;
  const canArchive = !listing.isDeletable;

  const deleteButton = <DeleteButton listing={listing} onDeletion={onDeletion} />;
  const archiveButton = <ArchiveButton listing={listing} onArchive={onArchive} />;

  return (
    <div ref={ref} className="tw-relative tw-z-30">
      <button
        aria-label="listing options"
        type="button"
        className="tw-invisible group-hover:tw-visible tw-flex tw-justify-center tw-items-center tw-rounded-full tw-p-[8px] tw-text-12d tw-bg-neutral-gray-10 tw-border-0 tw-text-neutral-gray-75"
        onClick={() => setOpened(!opened)}
      >
        <EllipsisIcon />
      </button>
      {opened && (
        <div
          className="
              tw-absolute tw-bottom-100px tw-right-0
              tw-rounded-[5px]
              tw-bg-white
              tw-max-h-[600px] tw-w-max
              tw-overflow-y-auto
              tw-border-none
              tw-p-[20px]
              tw-space-y-[16px]
              tw-shadow-md
            "
        >
          <Cluster justify="start" space="md" align="center">
            <span className="tw-text-[12px]">
              <EditIcon />
            </span>
            <a className="tw-text-[12px] tw-text-neutral-gray-75" href={listing.editURL}>
              Edit
            </a>
          </Cluster>
          {canDelete && deleteButton}
          {canArchive && archiveButton}
        </div>
      )}
    </div>
  );
};
const ListingCardHeader = ({ listing, onArchive, onDeletion, permissions }) => (
  <div data-cy="transaction-item-addressTitle">
    <Cluster justify="between" align="center">
      <Cluster justify="start" align="center" space="xs">
        <a
          href={listing.showURL}
          className="tw-text-12d tw-uppercase tw-font-semibold tw-text-neutral-gray-75"
        >
          {isAddressoOrTitle(listing.type, listing.status, listing.useCustomTitle)
            ? listing.title
            : listing.address.firstLine}
        </a>
      </Cluster>
      <div>
        <ListingOptions
          listing={listing}
          onDeletion={onDeletion}
          onArchive={onArchive}
          permissions={permissions}
        />
      </div>
    </Cluster>

    {listing.type !== "referral" && (
      <div className="tw-text-12d tw-font-normal tw-text-neutral-gray-50">{listing.address.secondLine}</div>
    )}
  </div>
);

DeleteButton.propTypes = {
  listing: PropTypes.instanceOf(Listing),
  onDeletion: PropTypes.func,
};

DeleteButton.defaultProps = {
  listing: null,
  onDeletion: null,
};

ArchiveButton.propTypes = {
  listing: PropTypes.instanceOf(Listing),
  onArchive: PropTypes.func,
};

ArchiveButton.defaultProps = {
  listing: null,
  onArchive: null,
};

ListingCardHeader.propTypes = {
  listing: PropTypes.instanceOf(Listing),
  onArchive: PropTypes.func,
  onDeletion: PropTypes.func,
  permissions: PropTypes.shape({}),
};

ListingCardHeader.defaultProps = {
  listing: null,
  onArchive: null,
  onDeletion: null,
  permissions: null,
};

ListingOptions.propTypes = {
  listing: PropTypes.instanceOf(Listing),
  onArchive: PropTypes.func,
  onDeletion: PropTypes.func,
  permissions: PropTypes.shape({ listings: PropTypes.shape({ delete: PropTypes.bool }) }),
};

ListingOptions.defaultProps = {
  listing: null,
  onArchive: null,
  onDeletion: null,
  permissions: null,
};

export default ListingCardHeader;
