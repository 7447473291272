import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "../../shared/v2/IconButton/IconButton";
import HoverButton from "./HoverButton";
import Menu from "./Menu";
import AppsMenu from "./AppsMenu";
import PanelIcon, { PanelIconCaret } from "./PanelIcon";
import useMobileMenu from "./hooks/useMobileMenu";
import MobileSidebar from "./MobileSidebar";
import ICON_MAP from "./helpers/icons";
import ACTION_MAP from "./ActionModals";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const Sidebar = () => {
  const {
    data: { isMobile },
  } = useMobileMenu();
  const currentUser = useCurrentUser();
  const featureFlags = useSelector(({ layout }) => layout.featureFlags);
  const sidebar = useSelector(({ layout }) => layout.sidebar);
  const theme = useSelector(({ layout }) => layout.theme);
  const mounted = useRef(false);
  const [isOpen, setIsOpen] = useState(localStorage.getItem("nav:closedManually") !== "true");
  const [selected, setSelected] = useState({ name: null });
  const preventMenuOpen =
    [
      "Dashboard",
      "Messages",
      "Projects",
      "Calendar",
      "Marketer",
      "Phone System",
      "Recruiter",
      "Opportunities",
    ].includes(selected.name) ||
    (["Search"].includes(selected.name) && !(featureFlags.partnerSearch && theme.name === "place-theme"));
  const Action = ACTION_MAP[selected.name];

  useEffect(() => {
    const menuItem = sidebar.menu.find((m) => m.name === selected.name);
    if (menuItem) setSelected(menuItem);
  }, [sidebar]);

  useEffect(() => {
    let newSelection = "Settings";
    const { pathname, search } = window.location;
    const fullPath = `${pathname}${search}`;
    if (/^\/dashboard/.test(fullPath)) newSelection = "Dashboard";
    else if (/^\/messages/.test(fullPath)) newSelection = "Messages";
    else if (/^\/phone_system/.test(fullPath)) newSelection = "Phone System";
    else if (
      /^\/people\/(?:leads|brivity_valuations|brivity_leads|brivity_idx|kwkly|zillow|all_people.+selected_lead_ponds)/.test(
        fullPath,
      )
    )
      newSelection = "Leads";
    else if (new RegExp(`^/people/(?!${currentUser.person.slug})`).test(fullPath)) newSelection = "People";
    else if (/^\/(?:search_listings|partner_searches)/.test(fullPath)) newSelection = "Search";
    else if (/^\/(?:listings|transactions)/.test(fullPath)) newSelection = "Transactions";
    else if (/^\/projects/.test(fullPath)) newSelection = "Projects";
    else if (/^\/agent_stats/.test(fullPath)) newSelection = "Reporting";
    else if (/^\/marketing/.test(fullPath)) newSelection = "Marketing";
    else if (/^\/events/.test(fullPath)) newSelection = "Calendar";
    else if (/^\/tasks/.test(fullPath)) newSelection = "Tasks";
    else if (/^\/opportunities/.test(fullPath)) newSelection = "Opportunities";

    setSelected(sidebar.menu.find((m) => m.name === newSelection));
  }, []);

  useEffect(() => {
    if (preventMenuOpen) {
      setIsOpen(false);
    } else if (mounted.current) {
      setIsOpen(true);
    }
    if (selected.name && !mounted.current) mounted.current = true;
  }, [selected.name, preventMenuOpen]);

  if (isMobile) return <MobileSidebar />;

  return (
    <nav
      className={`tw-transition-transform tw-flex tw-max-w-[260px] tw-h-full ${
        theme.name === "place-theme" ? "tw-bg-[#121212]" : "tw-bg-[#f9fbfb]"
      }`}
      data-cy="side-navbar"
      id="sidebar"
    >
      <div className="tw-relative tw-flex tw-flex-col tw-gap-[4px] tw-w-[52px] tw-h-full">
        <div className="nav-scrollbar tw-z-2 tw-flex tw-flex-col tw-gap-[6px] tw-h-full tw-overflow-x-hidden tw-overflow-y-auto">
          {sidebar.menu.map((menuItem) => {
            const { enabled, name, href, target } = menuItem;
            if (!enabled) return null;
            const Icon = ICON_MAP[name];
            return (
              <HoverButton
                key={name}
                id={`${name.toLowerCase()}-nav-menu`}
                tooltip={name}
                isSelected={name === selected.name}
                onClick={() => {
                  if (menuItem.name === selected.name && !preventMenuOpen) setIsOpen((o) => !o);
                  setSelected(menuItem);
                  if (href && target) {
                    window.open(href, target);
                    return;
                  }
                  if (href) window.location.assign(href);
                }}
              >
                <Icon size="l" />
              </HoverButton>
            );
          })}
        </div>

        <div className="tw-flex-0 tw-flex tw-justify-center tw-pl-[8px] tw-pb-[16px]">
          <IconButton
            size="custom"
            className="tw-relative tw-w-[32px] tw-h-[32px] tw-p-[4px] !tw-rounded-[4px] tw-border-0 hover:tw-bg-gray/10 disabled:tw-hidden active:tw-bg-gray/20"
            schema="transparent"
            disabled={preventMenuOpen}
            onClick={() =>
              setIsOpen((o) => {
                const open = !o;
                window.localStorage.setItem("nav:closedManually", !open);
                return open;
              })
            }
          >
            <PanelIcon />
            <PanelIconCaret className={isOpen ? "tw-rotate-180" : ""} />
          </IconButton>
        </div>
      </div>

      <div
        className={`tw-transition-all tw-ease-in-out tw-duration-300 tw-mr-[8px] tw-overflow-x-hidden ${
          theme.name === "place-theme" ? "tw-text-white" : ""
        } ${isOpen ? "tw-w-[200px]" : "tw-w-0 tw-opacity-0"}`}
      >
        {selected.name && selected.name !== "Apps" && (
          <Menu
            title={selected.name}
            options={selected.options?.filter((o) => typeof o.enabled === "undefined" || o.enabled)}
            action={Action ? <Action /> : null}
          />
        )}
        {selected.name === "Apps" && <AppsMenu />}
      </div>
    </nav>
  );
};

export default Sidebar;
