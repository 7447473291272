/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import RadioButton from "../../shared/v2/RadioButton";

const OptionSectionComponent = ({ label, copy, required, options, value, onChange, action }) => (
  <div className="tw-bg-[var(--semantic-blue-5)] tw-p-[16px] tw-rounded-[4px] tw-border tw-border-solid tw-border-[var(--semantic-blue-120)]">
    <div className="tw-flex tw-mb-[12px] tw-gap-[4px]">
      {label && (
        <div className="tw-text-14px tw-font-semibold tw-text-[var(--semantic-blue-120)] tw-whitespace-nowrap">
          {label}
        </div>
      )}
      {required && <div className="tw-text-14px tw-font-bold tw-text-brivity-coral-100">*</div>}
    </div>
    {copy && <p className="tw-text-[var(--semantic-blue-120)]">{copy}</p>}
    {options?.map((o, i) =>
      o.value !== undefined ? (
        <div key={i} className="tw-flex tw-items-center tw-mb-[8px]">
          <RadioButton
            className="tw-cursor-pointer"
            labelClassName="tw-cursor-pointer !tw-text-[#2454B2]"
            label={o.name}
            value={o.value}
            checked={o.value === value}
            onChange={() => onChange(o.value)}
          />
        </div>
      ) : (
        <div
          key={i}
          className="tw-flex tw-items-center tw-mb-[8px] tw-text-[#2454B2] tw-text-12d tw-pl-[24px]"
        >
          {o.name}
        </div>
      ),
    )}
    {action}
  </div>
);

OptionSectionComponent.propTypes = {
  label: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

OptionSectionComponent.defaultProps = {
  action: null,
};

export default OptionSectionComponent;
