import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as creators from "../actions/creators";
import * as api from "../actions/api";
import Card from "./Card";
import DisplayRow from "./DisplayRow";
import TextInputWrap from "./TextInputWrap";
import LinkInput from "./UrlInputWrap";
import Toggle from "../../shared/v2/Toggle";
import Dropdown from "../../shared/v2/Dropdown";

const DATE_FORMAT = "MM/DD/yyyy";
const TeamCard = ({ setValue, model, isPlace, timezones, saveValue }) => {
  const SECTIONS = [
    { title: "Team Name", key: "team_name", editable: true, comp: TextInputWrap },
    {
      title: "Brokerage Name",
      key: "broker_name",
      editable: true,
      comp: TextInputWrap,
    },
    { title: "Team Type", key: "team_type", editable: false, placeOnly: true, value: "PLACE team" },
    {
      title: "Website",
      key: "website",
      editable: true,
      comp: LinkInput,
      placeholder: "Paste website URL",
    },
    {
      title: "Compliance License #",
      key: "compliance_license_number",
      editable: true,
      comp: TextInputWrap,
    },
    {
      title: "Partnership Effective Date",
      key: "partnership_effective",
      editable: false,
      comp: TextInputWrap,
      placeOnly: true,
      displayValue: (value) => (value > "" ? moment(value).format(DATE_FORMAT) : "—"),
    },
    {
      title: "Current Contract Effective Date",
      key: "current_contract_effective",
      editable: false,
      placeOnly: true,
      displayValue: (value) => (value > "" ? moment(value).format(DATE_FORMAT) : "—"),
    },
    {
      title: "Go-Live Date",
      tooltip: "Denotes the date where the team’s revenue and expenses become shared with PLACE.",
      key: "go_live",
      editable: false,
      placeOnly: true,
      displayValue: (value) => (value > "" ? moment(value).format(DATE_FORMAT) : "—"),
    },
    {
      title: "Visible in Partner Search",
      tooltip:
        "If disabled, your team won't appear in search results for the PLACE network's Partner search feature",
      key: "partner_search_enabled",
      editable: true,
      comp: ({ value, onChange }) => (
        <Toggle size="small" checked={value} onChange={(e) => onChange(e.target.checked)} />
      ),
      placeOnly: true,
      alwaysEdit: true,
    },
    {
      title: "Account Time Zone",
      key: "time_zone",
      editable: true,
      isDD: true,
      comp: ({ value, onChange, onBlur, onKeyPress }) => (
        <Dropdown
          isSearchable
          defaultMenuIsOpen
          autoFocus
          containerClassName="tw-w-full tw-flex-1"
          placeholder="Select a timezone"
          value={value ? timezones.find((el) => el.value === value) : null}
          onBlur={onBlur}
          options={timezones}
          onKeyDown={onKeyPress}
          onChange={(e) => onChange(e?.value)}
        />
      ),
    },
    { title: "API Key", key: "api_key", editable: false },
  ];
  return (
    <Card title="Team">
      {SECTIONS.map((sec) =>
        !isPlace && sec.placeOnly ? null : (
          <DisplayRow
            key={sec.key}
            title={sec.title}
            tooltip={sec.tooltip}
            editable={sec.editable}
            alwaysEdit={sec.alwaysEdit}
            displayValue={sec.displayValue}
            value={sec.value ? sec.value : model[sec.key]}
            onChange={(val) => setValue(sec.key, val)}
            onSave={(val) => saveValue(sec.key, val)}
            Comp={sec.comp}
            isDD={sec.isDD}
          />
        ),
      )}
    </Card>
  );
};

TeamCard.propTypes = {
  model: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  saveValue: PropTypes.func.isRequired,
  isPlace: PropTypes.bool.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  model: state.businessSettings.model,
  isPlace: state.businessSettings.isPlace,
  timezones: state.businessSettings.timezones,
});
export default connect(mapStateToProps, { ...creators, ...api })(TeamCard);
