import { tryCatchHandlr } from "../../../../shared/Utilities";
import {
  postOtherInteraction,
  getEmailTemplates,
  parseInteractionTemplate,
  getInteractableEmails,
  postSmsInteraction,
  getSmsHistory,
  postPhoneCallV1,
  getOrCreateConversation,
} from "../api/apiWrappers";
import {
  closeModal,
  errorHandler,
  sendEmailError,
  emailTemplates,
  parsedTemplate,
  interactableEmails,
  getSMSHistory,
  sendingMessage,
  fetchingMessages,
  conversationLoaded,
  moreSmsToLoad,
  setConversation,
} from "./creators";
import {
  startingCall,
  setInteraction,
} from "../../../../Interactions/PhoneCall/actions/phoneCallActionCreators";
import { adaptError, smsHistoryAdapter } from "../api/apiAdapters";

export const postOtherInteractionAsThunk = (interaction, uuid) => async (dispatch) => {
  const req = postOtherInteraction(interaction, uuid);
  const [, err] = await tryCatchHandlr(req);

  if (err) {
    const error = adaptError(err.response.data.errors.person);
    dispatch(errorHandler(error));
  }
  dispatch(closeModal(true));
  // TODO: Re-write memory leak?
  setTimeout(() => {
    dispatch(closeModal(false));
  }, 1000);
};

export const getEmailTemplatesAsThunk = () => async (dispatch) => {
  const req = getEmailTemplates();
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(sendEmailError(err.response.data));
  }
  dispatch(emailTemplates(res.data));
};

export const parseInteractionTemplateAsThunk = (id, listingId, recipientId, senderId) => async (dispatch) => {
  const req = parseInteractionTemplate(id, listingId, recipientId, senderId);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(sendEmailError(err.response.data));
  }
  dispatch(parsedTemplate({ ...res.data, id }));
};

export const getInteractableEmailsAsThunk = (searchTerm) => async (dispatch) => {
  const req = getInteractableEmails(searchTerm);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(sendEmailError(err.response.data));
  }
  dispatch(interactableEmails(res.data));
};

export const getOrCreateConversationAsThunk = (payload) => async (dispatch, getState) => {
  const { messagingServiceUrl } = getState().tdpDetailsReducer;
  getOrCreateConversation({ ...payload, messagingServiceUrl })
    .catch((err) => {
      if (err.response.status === 409) return { data: { ...err.response.data, isConflict: true } };
      throw err;
    })
    .then(({ data }) => {
      dispatch(setConversation(data));
    })
    .catch((err) => {
      const error = err.response?.data.errors ? err.response.data.errors : [err.response?.data];
      dispatch(errorHandler(error));
    });
};

export const getSmsHistoryAsThunk = (payload) => async (dispatch) => {
  getSmsHistory(payload)
    .then(({ data }) => {
      const details = smsHistoryAdapter(data);
      dispatch(getSMSHistory(details));
      dispatch(sendingMessage(false));
      dispatch(fetchingMessages(false));
      dispatch(conversationLoaded(true));
      dispatch(moreSmsToLoad(data.length === 10));
    })
    .catch((err) => {
      const error = adaptError(err.response.data.errors);
      dispatch(errorHandler(error));
    });
};

export const postSmsInteractionAsThunk =
  ({ message, personUuid, personPhoneNumber }) =>
  async (dispatch, getState) => {
    const {
      tdpDetailsReducer: {
        messagingServiceUrl,
        headerDetails: { listing_id },
      },
      tdpInteractionsReducer: { conversation },
    } = getState();
    dispatch(sendingMessage(true));
    const req = postSmsInteraction({ conversation, message, messagingServiceUrl, listingId: listing_id });
    const [, err] = await tryCatchHandlr(req);

    if (err) {
      const error = adaptError(err.response.data.errors);
      dispatch(errorHandler(error));
    }

    dispatch(sendingMessage(false));
    dispatch(
      getSmsHistoryAsThunk({
        personId: message.person_to_text,
        maxId: null,
        personUuid,
        personPhoneNumber,
        messagingServiceUrl,
      }),
    );
  };

export const resetMessageHistory = () => (dispatch) => {
  dispatch(errorHandler([]));
  dispatch(setConversation(null));
  dispatch(getSMSHistory([]));
  dispatch(fetchingMessages(true));
  dispatch(conversationLoaded(false));
  dispatch(moreSmsToLoad(true));
  dispatch(sendingMessage(false));
};

// eslint-disable-next-line consistent-return
export const startCallWrapper = (listingId) => (dispatch) => async (personId, phoneDetailId) => {
  dispatch(startingCall());
  const req = postPhoneCallV1(personId, phoneDetailId, listingId);
  const [res, err] = await tryCatchHandlr(req);

  // TODO need better error handler
  if (err) return err;

  dispatch(setInteraction(res.data));
};
