export const saveMarketReport = (data) => ({ type: "SAVE_MARKET_REPORT", ...data });
export const saveMarketReportSuccess = (data) => ({ type: "SAVE_MARKET_REPORT_SUCCESS", ...data });
export const saveMarketReportError = (data) => ({ type: "SAVE_MARKET_REPORT_ERROR", ...data });

export const sendMarketReport = (data) => ({ type: "SEND_MARKET_REPORT", ...data });
export const sendMarketReportSuccess = (data) => ({ type: "SEND_MARKET_REPORT_SUCCESS", ...data });
export const sendMarketReportError = (data) => ({ type: "SEND_MARKET_REPORT_ERROR", ...data });

export const getMarketReport = (data) => ({ type: "GET_MARKET_REPORT", ...data });
export const getMarketReportSuccess = (data) => ({ type: "GET_MARKET_REPORT_SUCCESS", ...data });
export const getMarketReportError = (data) => ({ type: "GET_MARKET_REPORT_ERROR", ...data });

export const updateMarketReport = (data) => ({ type: "UPDATE_MARKET_REPORT", ...data });
export const updateMarketReportSuccess = (data) => ({ type: "UPDATE_MARKET_REPORT_SUCCESS", ...data });
export const updateMarketReportError = (data) => ({ type: "UPDATE_MARKET_REPORT_ERROR", ...data });

export const setTab = (data) => ({ type: "SET_TAB", data });
export const setDrawing = (data) => ({ type: "SET_DRAW", data });
export const setFormData = (data) => ({ type: "SET_FORM1", data });
export const setContactData = (data) => ({ type: "SET_CONTACT", data });
export const setForm2Data = (data) => ({ type: "SET_FORM2", data });
export const setFormError = (data) => ({ type: "SET_FORM_ERROR", data });

export const setLatLon = (data) => ({ type: "SET_COORDS", data });
export const setBrowserLatLon = (data) => ({ type: "SET_B_COORDS", data });

export const clearMessage = (data) => ({ type: "CLEAR_MSG", data });
export const setMessage = (data) => ({ type: "SET_MSG", data });
export const resetForms = () => ({ type: "CLEAR_FORMS" });

export const setLocationData = (data) => ({ type: "SET_LOCATION_DATA", data });
export const setPerson = (data) => ({ type: "SET_PERSON", data });
export const setSearching = (data) => ({ type: "SET_SEARCHING", data });
export const setMLSPropertyTypes = (data) => ({ type: "SET_MLS_PROP_TYPES", data });
