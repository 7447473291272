import React from "react";
import { Modal, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import Avatar from "@shared/v2/Avatar";
import MarketReport from "../shared/MarketReport";
import Button from "../shared/v2/Button";
import IconButton from "../shared/v2/IconButton";
import { getStatusClass } from "@shared/StatusDropdown/StatusDropdown";

class MarketReportPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      marketReports: [],
    };
  }
  componentDidMount = () => {
    if (this.props.modalIsOpen) {
      this.fetchReportsAndOpenModal();
    }
  };
  fetchReportsAndOpenModal = () => {
    this.openMarketReportModal();
  };

  fetchMarketReports = () => {
    axios
      .get(`/market_reports?uuid=${this.props.person.uuid}`)
      .then((res) => this.setState({ marketReports: res.data }));
  };

  openMarketReportModal = () => {
    this.setState({ modalIsOpen: true }, this.fetchMarketReports);
  };

  deleteReportFunc = (id) => {
    if (this.props.deleteReportFn(id)) {
      this.setState({
        marketReports: this.state.marketReports?.filter((r) => r.id !== id),
      });
    } else {
      alert("Something went wrong...");
    }
  };

  closeMarketReportModal = () => {
    this.setState({ modalIsOpen: false, marketReports: [] });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderMarketReportModal = () => {
    const { modalIsOpen, marketReports } = this.state;
    const { person, person_status, person_lead_type, person_address, person_avatar } = this.props;

    return (
      <Modal show={modalIsOpen} onHide={this.closeMarketReportModal}>
        <div className="tw-flex tw-mt-[32px] tw-mx-[32px]">
          <h2 className="tw-flex-grow m-0 tw-text-[28px] tw-text-neutral-gray-75">Applied Market Reports</h2>
          <IconButton schema="tertiary" onClick={this.closeMarketReportModal}>
            <i className="fa fa-close" />
          </IconButton>
        </div>
        <Modal.Body id="idx-modal-body">
          <div className="tw-flex tw-items-center">
            <Avatar
              src={person_avatar}
              alt={!person?.first_name && !person?.last_name
                ? "No Name"
                : `${person?.first_name} ${person?.last_name}`.trim()}
              size="large"
            />
            <div className="tw-flex-1 tw-ml-[12px]">
              <div className="tw-flex tw-items-center tw-space-x-[12px]">
                <span className="tw-text-[14px] tw-text-neutral-gray-75 tw-font-semibold">
                  {!person?.first_name && !person?.last_name
                    ? "No Name"
                    : `${person?.first_name} ${person?.last_name}`}
                </span>
                {person_lead_type &&
                  person_lead_type.split("/").map((t) => {
                    return (
                      <div key={t} className={`intent-box ${t.toLowerCase()}`}>
                        {t}
                      </div>
                    );
                  })}
                <button
                  className={`status btn btn-sm ${getStatusClass(person_status?.toLowerCase())} tw-text-white tw-px-[23px] tw-py-[4px] tw-leading-4 tw-rounded-[12px] tw-text-[11px] !tw-min-w-[50px] !tw-w-auto`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {person_status}
                </button>
              </div>
              {person_address && (
                <div className="tw-text-[14px] tw-text-neutral-gray-75">{person_address}</div>
              )}
            </div>
          </div>
          <div className="tw-flex tw-mt-[32px]">
            <span className="tw-flex-1 tw-font-[12px] tw-text-neutral-gray-75">{`Reports Created (${marketReports.length})`}</span>
            <Button
              small="small"
              schema="secondary"
              onClick={() => {
                this.props.openMarketReport();
                this.closeMarketReportModal();
              }}
            >
              + Add market report
            </Button>
          </div>
          <div className="tw-h-[1px] tw-w-full tw-my-[16px] tw-bg-neutral-gray-10" />
          <Row id="listing-grid">
            {marketReports &&
              marketReports.map((report) => (
                <Col key={report.id} xs={6} className="m-b-20">
                  <MarketReport
                    {...report}
                    editReportFn={(id) => {
                      this.props.editReportFn(id);
                      this.closeMarketReportModal();
                    }}
                    deleteReportFn={(id) => this.deleteReportFunc(id)}
                    openMarketReportModal={() => {
                      this.props.openMarketReportModal();
                      this.closeMarketReportModal();
                    }}
                  />
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  tooltipDescription = () => {
    if (this.props.person?.active_market_report_count > 0) return "Market Report(s) Sending";
    if (this.props.person?.market_report_count > 0) return "No Active Market Report Drips";
    return "No Market Reports";
  };

  getColorStyle = () => {
    return this.props.person?.active_market_report_count
      ? "active"
      : this.props.person?.market_report_count
        ? "inactive"
        : "zero";
  };

  getReportCount = () => {
    return this.props.person?.market_report_count || "0";
  };

  render() {
    return (
      <div className="flex-row d-flex space-evenly flex-justify-center ">
        {!this.props.modalOnly && (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={<Tooltip id="market-report-tooltip-wrap">{this.tooltipDescription()}</Tooltip>}
          >
            <h6
              className={`tw-cursor-pointer ${this.getColorStyle()}`}
              onClick={this.fetchReportsAndOpenModal}
            >
              {this.getReportCount()}
            </h6>
          </OverlayTrigger>
        )}
        {this.renderMarketReportModal()}
      </div>
    );
  }
}

export default MarketReportPreview;
