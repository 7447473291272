import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "../../../shared/v2/Modal";
import Button from "../../../SearchListings/components/Button";
import Error from "../../../Reports/components/DashboardShared/Error";
import { buyTrackingNumber, updateVoicemailGreeting } from "./services";
import { LeadAssignInfo } from "./shared";

const ConfirmTrackingNumber = ({
  agentId,
  name,
  agents,
  number,
  routingMethod,
  sourceId,
  shouldCreateLeads,
  noAnswerSetting,
  voicemailGreetingFile,
  voicemailGreetingFilename,
  onConfirmBuy,
  onClose,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");

  const agentInfo = agents.map((a) => a.name).join(", ");

  const buyNumber = async () => {
    setIsFetching(true);

    const { error: buyError, trackingNumberId } = await buyTrackingNumber(
      agentId,
      name,
      number.value,
      routingMethod,
      agents,
      sourceId,
      shouldCreateLeads,
      noAnswerSetting,
      voicemailGreetingFilename,
    );

    if (voicemailGreetingFile !== null) {
      await updateVoicemailGreeting(trackingNumberId, voicemailGreetingFile, voicemailGreetingFilename);
    }

    if (buyError) {
      setError(buyError);
      setIsFetching(false);
    } else {
      onConfirmBuy();
    }
  };

  return (
    <Modal
      show
      onHide={onClose}
      dialogClassName="tw-overflow-y-auto tw-max-h-full"
      closeOnClickOutside={false}
      contentSize="medium"
      contentClassName="tw-m-auto"
    >
      <Modal.Header title="Confirm tracking number" className="tw-mb-12px" closeButton>
        <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
      </Modal.Header>
      <Modal.Body className="tw-mb-32px">
        <div className="tw-flex tw-flex-col tw-text-gray-75 tw-gap-8px tw-mb-12px">
          <div className="tw-mb-14px">Here&apos;s the details about your new tracking number:</div>
          <ItemSummary title="Tracking Number Name" info={name} />
          <ItemSummary title="Number" info={number.label} />
          <ItemSummary title="Route To" info={agentInfo} />
          <ItemSummary title="Routing Method" info={LeadAssignInfo[routingMethod].pretty} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button color="blue" size="large" disabled={!isFetching ? "" : "disabled"} onClick={buyNumber}>
            DONE
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmTrackingNumber.propTypes = {
  agentId: PropTypes.string.isRequired,
  number: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  routingMethod: PropTypes.string.isRequired,
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shouldCreateLeads: PropTypes.bool.isRequired,
  sourceId: PropTypes.string.isRequired,
  onConfirmBuy: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmTrackingNumber;

const ItemSummary = ({ title, info }) => (
  <span>
    <strong>{title}: </strong>
    {info}
  </span>
);

ItemSummary.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};
