import React, { Component } from "react";
import { Dropdown } from "../../../shared/v1";

class AgentDropdown extends Component {
  render() {
    return (
      <div data-cy="agents-dropdown-wrapper" style={{ paddingBottom: "30px" }}>
        <label
          style={{
            letterSpacing: "0.2px",
            fontSize: "14px",
            fontFamily: "Open Sans",
          }}
        >
          <b>AGENT</b>
        </label>
        <Dropdown
          components={{ Option: this.props.option }}
          placeholder={this.props.selectedAgents.length > 1 ? "Multiple Users" : this.props.selectedAgents[0]}
          value={this.props.selectedAgents.length == 1 ? this.props.selectedAgents[0] : null}
          closeMenuOnSelect={false}
          isClearable={false}
          options={this.props.formatOptions(this.props.agents)}
          variant="flatGray"
        />
      </div>
    );
  }
}

export default AgentDropdown;
