import { useEffect, useState } from "react";

const useTimer = () => {
  const [intervalId, setIntervalId] = useState();
  const [timerCount, setTimerCount] = useState(0);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return {
    timerCount,
    startTimer: () => setIntervalId(setInterval(() => setTimerCount((i) => i + 1), 1000)),
    stopTimer: () => clearInterval(intervalId),
  };
};

export default useTimer;
