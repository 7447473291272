import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PlatformCTA = (props) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={
        <Tooltip id="platform-cta">
          <span>
            Filtering by property view/favorite criteria is a Brivity Platform feature. Contact
            sales@brivity.com to upgrade.
          </span>
        </Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default PlatformCTA;
