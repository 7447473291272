import { responseDataToCamelCase } from "@shared/v2/caseTransformingAxios";
import { isEmpty, startCase } from "lodash";

const IS_REQUIRED = "is a required field.";

export const validateLeadForm = (person) => {
  const detail = {};
  if (!(person.firstName || "").trim()) detail["First Name"] = IS_REQUIRED;
  if (!(person.lastName || "").trim()) detail["Last Name"] = IS_REQUIRED;
  if (!person.sourceId) detail.Source = IS_REQUIRED;

  return { errors: isEmpty(detail) ? [] : [{ detail }] };
};

export const validateUserForm = (person) => {
  const detail = {};
  const isOperator = person.displayedPosition === "Operator";
  if (!(person.firstName || "").trim()) detail["First Name"] = IS_REQUIRED;
  if (!(person.lastName || "").trim()) detail["Last Name"] = IS_REQUIRED;
  if (!(person.emailDetailsAttributes?.find((e) => e.position === 1)?.value || "").trim()) {
    detail.Email = IS_REQUIRED;
  }
  if (!person.position && !isOperator) detail.Position = IS_REQUIRED;
  if (!person.agentStatus) detail["Production Status"] = IS_REQUIRED;
  if (!person.id && typeof person.loginUserAttributes?.inviteToOkta === "undefined") {
    detail["Invite User selection"] = IS_REQUIRED;
  }
  if (person.isRealEstateLlc && !person.individualRealEstateLlcName) {
    detail["Real Estate LLC Name"] = IS_REQUIRED;
  }

  person.loginUserAttributes?.customLinksAttributes?.forEach((customLink) => {
    if (!customLink.title) detail["Custom Link Title"] = "is missing";
    if (!customLink.url) detail["Custom Link URL"] = "is missing";
  });

  if (person.agentStatus && person.agentStatus !== "Unlicensed") {
    person.loginUserAttributes?.usersMlsesAttributes?.forEach((mls) => {
      if (!mls.mlsId) detail["Licensed Agent "] = "is missing MLS ID";
      if (!mls.mlsAgentId) detail["Licensed Agent"] = "is missing MLS Agent ID";
    });
  }

  return { errors: isEmpty(detail) ? [] : [{ detail }] };
};

export const transformFormOptions = (formOptions) => ({
  ...formOptions,
  brokerageOptions: formOptions.brokerageOptions?.map((option) => ({
    label: option,
    value: option,
  })),
  militaryStatusOptions: formOptions.militaryStatusOptions?.map((option) => ({
    label: option,
    value: option,
  })),
  positionOptions: formOptions.positionOptions?.map((option) => ({
    label: option,
    value: option,
  })),
  mlsOptions: formOptions.mlsOptions?.map((option) => ({
    label: option.name,
    value: option.id,
    description: option.description,
  })),
  maritalStatusOptions: formOptions.maritalStatusOptions.map((option) => ({
    label: option.displayName || option,
    value: option.value || option,
  })),
  militaryStatusOptions: formOptions.militaryStatusOptions?.map((option) => ({
    label: option,
    value: option,
  })),
  tagsOptions: formOptions.tagsOptions.map((option) => ({
    label: option.name,
    value: option.name,
  })),
  timezoneOptions: formOptions.timezoneOptions.map((option) => ({
    label: option.displayName,
    value: option.value,
  })),
  stateOptions: Object.entries(formOptions.stateOptions).map(([category, options]) => ({
    category: startCase(category),
    options: options.map(({ name, code }) => ({
      label: `${name} (${code})`,
      value: code,
    })),
  })),
  primaryAgentOptions: formOptions.primaryAgentOptions?.map((option) => ({
    label: option.fullName,
    value: option.id,
  })),
  statusOptions: formOptions.statusOptions?.map((option) => ({
    label: option.displayName,
    value: option.value,
  })),
  customDateOptions: formOptions.milestonesOptions.reduce((result, item) => {
    if (!item.default) {
      return result
        ? [...result, { displayName: item.displayName, name: item.name }]
        : [{ displayName: item.displayName, name: item.name }];
    }
    return result;
  }, []),
  leadStageOptions: [
    {
      label: "Lead Stages",
      value: "Lead Stages",
      header: "Lead Stages",
    },
  ].concat(
    formOptions.stageOptions?.map((option) => ({
      label: option,
      value: option,
      type: "lead",
    })),
  ),
  candidateStageOptions: [
    {
      label: "Candidate Stages",
      value: "Candidate Stages",
      header: "Candidate Stages",
    },
  ].concat(
    formOptions.candidateStageOptions?.map((option) => ({
      label: option,
      value: option,
      type: "candidate",
    })),
  ),
  leadTypeOptions: formOptions.leadTypeOptions?.map((option) => ({
    label: option.displayName,
    value: option.value,
  })),
  typeNameOptions: formOptions.typeNameOptions?.map((option) => ({
    label: option.displayName,
    value: option.value,
  })),
});

export const transformSources = (sources) =>
  sources.map((source) => ({
    label: source.text,
    value: source.id,
  }));

const transformLoginUser = (loginUser) => {
  if (!loginUser) return null;
  const { customLinks, usersMlses, ...loginUserAttributes } = loginUser;
  return {
    customLinksAttributes: customLinks,
    usersMlsesAttributes: usersMlses,
    ...loginUserAttributes,
  };
};

export const transformPerson = (rawPerson) => {
  if (!rawPerson) return null;
  const { milestones } = rawPerson;
  const { addresses, emailDetails, loginUser, phoneDetails, ...person } = responseDataToCamelCase(rawPerson);
  return {
    addressesAttributes: addresses,
    emailDetailsAttributes: emailDetails,
    loginUserAttributes: transformLoginUser(loginUser),
    phoneDetailsAttributes: phoneDetails,
    ...person,
    milestones,
  };
};
