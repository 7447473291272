/* eslint-disable react/jsx-filename-extension */

import React from "react";
import { Alert } from "react-bootstrap";

export const tryCatchHandlr = async (promise) => {
  try {
    const data = await promise;
    return [data, null];
  } catch (err) {
    return [null, err];
  }
};
export const errorGenerator = (errorMessage, onClose) => (
  <Alert bsStyle="danger" onDismiss={onClose}>
    <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
  </Alert>
);
