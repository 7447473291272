import React from "react";
import PropTypes from "prop-types";

const PersonResult = ({ result, index, onHover, onClickHandle }) => {
  return (
    <div className="tw-h-[32px] tw-px-[12px] tw-cursor-pointer tw-py-[6px] hover:tw-bg-gray-lighter hover:tw-text-gray-darker">
      <div
        data-slug={result.slug}
        data-item-id={result.id}
        onMouseOver={(e) => onHover(index, e)}
        onMouseDown={(e) => onClickHandle(index, e)}
      >
        {result.label}
      </div>
    </div>
  );
};

PersonResult.propTypes = {
  result: PropTypes.shape({}),
  onHover: PropTypes.func,
  onClickHandle: PropTypes.func,
  index: PropTypes.number.isRequired,
};
PersonResult.defaultProps = {
  result: {},
  onHover: () => {},
  onClickHandle: () => {},
};

export default PersonResult;
