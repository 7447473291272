import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import alerts from "./listing_alerts.module.css";
import css from "../person-detail-styles.module.css";
import cards from "../cards.module.css";
import colors from "../colors.module.css";
import icons from "../icons.module.css";
import ViewAllListingAlertsModal from "../modals/ViewAllListingAlertsModal";
import AddListingAlertModal from "../modals/AddListingAlertModal";
import EditListingAlertModal from "../modals/EditListingAlertModal";
import {
  formattedPriceRange,
  formattedRange,
  formatLocationsForDisplay,
} from "../../../shared/ListingAlertHelpers";
import { capitalize } from "lodash";
import AlertStatus from "./AlertStatus";
import * as listingAlertService from "./service";
import DeleteListingAlertModal from "../modals/DeleteListingAlertModal";
import CollapsibleElement from "../CollapsibleElement";
import * as actions from "../../actions/personDetailActionCreators";
import Loading from "../../../Goals/components/Loader";

const ListingAlertCard = ({ openPlatformCTA }) => {
  const dispatch = useDispatch();
  const [
    {
      editListingAlertModalOpen,
      deleteListingAlertModalOpen,
      deleteAlertPending,
      deleteAlertIndex,
      indexOfAlertToEdit,
      listingAlerts,
      listingAlertsModalOpen,
      mlsIds,
      websiteUrl,
    },
    _setState,
  ] = useState({
    editListingAlertModalOpen: false,
    deleteListingAlertModalOpen: false,
    deleteAlertPending: false,
    indexOfAlertToEdit: 0,
    listingAlerts: [],
    listingAlertsModalOpen: false,
    mlsIds: [],
    websiteUrl: "",
  });
  const setState = (newState) => _setState((prevState) => ({ ...prevState, ...newState }));
  const featureFlags = useSelector((state) => state.layout.featureFlags);
  const basePerson = useSelector((state) => state.personDetail.person);
  const person = basePerson.data?.attributes;
  const geoCodeAccountAddress = basePerson.meta?.geo_code_account_address;
  const addListingAlertModalOpen = useSelector((state) => state.personDetail.addListingAlertModalOpen);

  const openAddListingAlertModal = () => dispatch(actions.openAddListingAlertModal());

  const listingAlertButtonText = featureFlags.platformAccount ? "+ ADD" : "LEARN MORE";
  const listingAlertButtonClick = featureFlags.platformAccount ? openAddListingAlertModal : openPlatformCTA;

  useEffect(() => {
    if (!person) return;
    getAlerts();
  }, [person?.main_email]);

  const getAlerts = async () => {
    try {
      const response = await listingAlertService.getAlerts(person.slug);
      setState({
        listingAlerts: response.data.alerts,
        websiteUrl: response.data.website_url,
        mlsIds: response.data.mls_ids,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const renderLaCTA = () => {
    return (
      <div className={css.personDetailEmptyStateText}>
        Use Listing Alert emails to regularly send listings that your buyers might be interested in. When a
        contact saves a search on your website, it automatically creates a Listing Alert.
      </div>
    );
  };

  const renderListingAlert = (alert, index) => {
    return (
      <Row key={alert.alertId}>
        <div className={`${css.materialDesignBox} ${alerts.card}`}>
          <Row>
            <Col xs={12} style={{ marginBottom: "12px" }}>
              <div className={`${alerts.searchName} pull-left overflow`}>
                <i className="fa fa-bell-o" />
                <span>
                  <a href={`https://${websiteUrl}/${alert.searchUrl}`} target="_blank">
                    {alert.searchName}
                  </a>
                </span>
                <div className="fade-out" />
              </div>
              <div className={`${icons.editAndDelete} pull-right`}>
                <a
                  className={`${icons.button} ${icons.gray}`}
                  onClick={() => openEditListingAlertModal(index)}
                >
                  <i className={`${icons.smIcon} fa fa-pencil`} />
                </a>
                <a
                  className={`${icons.button} ${icons.gray}`}
                  onClick={() => openDeleteListingAlertModal(index)}
                >
                  <i className={`${icons.smIcon} fa fa-trash`} />
                </a>
              </div>
            </Col>
            <div className={alerts.content}>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>CREATED:</span>
                <span className={css.inlineLabelValue}>{moment(alert.createdAt).format("MM/D/YYYY")}</span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <AlertStatus alert={alert} />
              </Col>
              {alert.active && (
                <Col xs={12} className={css.inlineDetail}>
                  <span className={css.brivityPersonDetailsLabel}>FREQUENCY:</span>
                  <span className={css.inlineLabelValue}>{capitalize(alert.frequency)}</span>
                </Col>
              )}
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>SEARCH TERM:</span>
                <span className={css.inlineLabelValue}>{formatLocationsForDisplay(alert)}</span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>Price:</span>
                <span className={css.inlineLabelValue}>
                  {formattedPriceRange(alert.priceMin, alert.priceMax)}
                </span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>BED:</span>
                <span className={css.inlineLabelValue}>{formattedRange(alert.bedsMin, alert.bedsMax)}</span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>BATH:</span>
                <span className={css.inlineLabelValue}>{formattedRange(alert.bathsMin, alert.bathsMax)}</span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>SQUARE FEET:</span>
                <span className={css.inlineLabelValue}>{formattedRange(alert.sqFtMin, alert.sqFtMax)}</span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>LOT:</span>
                <span className={css.inlineLabelValue}>
                  {formattedRange(alert.lotSizeMin, alert.lotSizeMax)}
                </span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>GARAGE:</span>
                <span className={css.inlineLabelValue}>
                  {formattedRange(alert.garageMin, alert.garageMax)}
                </span>
              </Col>
              <Col xs={12} className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>YEAR:</span>
                <span className={css.inlineLabelValue}>{formattedRange(alert.yearMin, alert.yearMax)}</span>
              </Col>
            </div>
          </Row>
        </div>
      </Row>
    );
  };

  const renderNoEmail = () => {
    return (
      <div className={css.personDetailEmptyStateText}>
        Enter an email address for this contact in order to send Listing Alerts.
      </div>
    );
  };

  const openListingAlertModal = () => {
    setState({ listingAlertsModalOpen: true });
  };

  const closeListingAlertModal = () => {
    setState({ listingAlertsModalOpen: false });
  };

  const openEditListingAlertModal = (alertIndex) => {
    setState({ editListingAlertModalOpen: true, indexOfAlertToEdit: alertIndex });
  };

  const closeEditListingAlertModal = (status) => {
    setState({ editListingAlertModalOpen: false, indexOfAlertToEdit: 0 });
    if (status === "success") {
      getAlerts();
    }
  };

  const openDeleteListingAlertModal = (deleteAlertIndex) => {
    setState({ deleteListingAlertModalOpen: true, deleteAlertIndex });
  };

  const closeDeleteListingAlertModal = () => {
    setState({ deleteListingAlertModalOpen: false, deleteAlertIndex: null });
  };

  const emptyStateOrAlert = (listingAlerts) => {
    if (person?.main_email === null && listingAlerts.length === 0) {
      return renderNoEmail();
    }

    if (listingAlerts.length === 0) {
      return renderLaCTA();
    }

    return listingAlerts.slice(0, 2).map((alert, alertIndex) => renderListingAlert(alert, alertIndex));
  };

  const deleteAlert = (alertIndex) => {
    const alertId = listingAlerts[alertIndex].alertId;
    const url = `/people/${person?.id}/listing_alerts/${alertId}`;

    setState({ deleteAlertPending: true });

    axios
      .delete(url, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
      .then(() => {
        setState({
          listingAlerts: listingAlerts.filter((alert) => alert.alertId !== alertId),
          deleteAlertIndex: null,
          deleteListingAlertModalOpen: false,
          deleteAlertPending: false,
        });
      })
      .catch((response) => console.log(response))
      .finally(() => setState({ deleteAlertPending: false }));
  };

  const openEditFromViewAll = (alertIndex) => {
    setState({
      indexOfAlertToEdit: alertIndex,
      editListingAlertModalOpen: true,
      listingAlertsModalOpen: false,
    });
  };

  const openAddFromViewAll = () => {
    setState({ listingAlertsModalOpen: false });
    dispatch(actions.openAddListingAlertModal());
  };

  const closeAddListingAlertModal = (status) => {
    dispatch(actions.closeAddListingAlertModal());

    if (status === "success") {
      getAlerts();
    }
  };

  return (
    <CollapsibleElement
      title="Listing Alerts"
      buttonText={listingAlertButtonText}
      buttonClickFunction={listingAlertButtonClick}
      disabled={person?.main_email === null}
    >
      {!basePerson.data && <Loading />}
      <div id="person-listing-alerts-card">
        {emptyStateOrAlert(listingAlerts)}
        {listingAlerts.length > 2 && (
          <Row>
            <Col xs={12} className="m-t-10">
              <a onClick={openListingAlertModal} className={`${cards.viewAll} ${colors.brivityBlue}`}>
                VIEW ALL
              </a>
            </Col>
          </Row>
        )}
        {listingAlertsModalOpen && (
          <ViewAllListingAlertsModal
            onClose={closeListingAlertModal}
            listingAlerts={listingAlerts}
            openEditModal={openEditFromViewAll}
            handleDelete={deleteAlert}
            mlsIds={mlsIds}
            websiteUrl={websiteUrl}
            openAddModal={openAddFromViewAll}
          />
        )}
        {addListingAlertModalOpen && (
          <AddListingAlertModal
            person={person}
            closeListingAlertModal={(status) => closeAddListingAlertModal(status)}
            mlsIds={mlsIds}
            geoCodeAccountAddress={geoCodeAccountAddress}
          />
        )}
        {editListingAlertModalOpen && (
          <EditListingAlertModal
            person={person}
            closeListingAlertModal={closeEditListingAlertModal}
            alert={listingAlerts[indexOfAlertToEdit]}
            mlsIds={mlsIds}
            geoCodeAccountAddress={geoCodeAccountAddress}
          />
        )}
        <DeleteListingAlertModal
          loading={deleteAlertPending}
          open={deleteListingAlertModalOpen}
          onClose={closeDeleteListingAlertModal}
          onDelete={() => deleteAlert(deleteAlertIndex)}
        />
      </div>
    </CollapsibleElement>
  );
};

export default ListingAlertCard;
