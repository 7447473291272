import React, { Component } from 'react';
import axios from 'axios';
import SharedAddAutoPlanModal from '../../../shared/AddAutoPlanModal';

class AddAutoPlanModal extends Component {
  state = {
    autoPlans: [],
    selectedPlan: null
  }

  componentDidMount = () => {
    this.fetchAutoPlans()
  }

  updateAutoPlan = (planId) => {
    const plan = this.state.autoPlans.find(p => p.id == planId)
    this.setState({selectedPlan: plan})
  }

  fetchAutoPlans = () => {
    const autoPlansPath = `/auto_plans/auto_plans_no_actions`
    const path = `${autoPlansPath}.json?plan_type=undefined?page=null`;

    axios.get(path)
      .then(results => {
        this.setState({autoPlans: results.data})
      }).catch(console.log)
  }

  formatOptions = () => {
    const plans = this.state.autoPlans
    return plans.map(({id, name}) => ({ value: id, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  render() {
    const { assignableId, closeAddAutoPlanModal, isModalSubmitting, showPreviewPlanModal, submitApplyPlan, show } = this.props
    const { selectedPlan } = this.state

    return (
      <SharedAddAutoPlanModal
        show={show}
        onCancel={closeAddAutoPlanModal}
        onApply={() => submitApplyPlan(selectedPlan, assignableId)}
        isApplying={isModalSubmitting}
        onPreview={() => showPreviewPlanModal(selectedPlan.id)}
        disableInteractionButtons={!selectedPlan}
        availablePlansAsOptions={this.formatOptions()}
        selectedAutoPlan={selectedPlan?.id && {label: selectedPlan.name, value: selectedPlan.id}}
        onSelectAutoPlan={(opt) => this.updateAutoPlan(opt.value)}
        />
    );
  }
}

export default AddAutoPlanModal;
