import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropdown from "@shared/v2/Dropdown";
import * as creators from "../../actions/creators";
import useGetMLSData from "./hookHelpers";

const MLSInput = ({ setFormError, value, onChange, primaryAgent, setMLSPropertyTypes }) => {
  const [options, setOptions] = useState();
  const [isLoading, setLoading] = useState(true);
  useGetMLSData({ setOptions, setLoading, primaryAgent, setMLSPropertyTypes });
  useEffect(() => {
    if (options?.length > 0 && !value) {
      onChange({ id: options[0].value, country: options[0].country });
      return;
    }
    setFormError({ mls_data_source_id: false });
  }, [options]);
  const hasError = !value;
  return (
    <div data-cy="mls-wrapper" className="tw-mb-[24px]">
      <Dropdown
        label="MLS"
        error={hasError}
        options={options}
        isClearable={false}
        isLoading={isLoading}
        isSearchable
        placeholder="Select a MLS"
        name="mls_data_source_id"
        value={options?.find((o) => o.value === value)}
        onChange={(opt) => {
          if (!opt) return;
          const id = opt.value;
          onChange({ id, country: options.filter((o) => o.value === id)[0]?.country });
          setFormError({ mls_data_source_id: false });
        }}
      />
    </div>
  );
};

MLSInput.propTypes = {
  setFormError: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  primaryAgent: PropTypes.string,
  setMLSPropertyTypes: PropTypes.func,
};

MLSInput.defaultProps = {
  setFormError: null,
  value: null,
  onChange: null,
  primaryAgent: null,
  setMLSPropertyTypes: null,
};

export default connect(null, creators)(MLSInput);
