/* eslint-disable class-methods-use-this */
import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import UnAssignModal from "./UnAssignModal";

export default class TwilioManageNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subaccountSid: props.subaccountSid,
      loading: true,
      twilioNumbers: [],
      unassignedUsers: [],
      trackingNumbers: [],
      successAlerts: [],
      errors: [],
      assignModalVisible: false,
      numberToAssign: null,
      assignTo: "user",
      userToAssign: "",
      trackingNumberToAssign: "",
      moveAccountModalVisible: false,
      moveAccountUserEmail: "",
      numberToMove: null,
      userToMoveTo: null,
      moveNumberErrors: [],
      setUnAssignModalShow: false,
      unAssignedModalCb: () => {},
    };
  }

  componentDidMount() {
    const { subaccountSid } = this.state;
    const { developerRole } = this.props;
    if (subaccountSid && developerRole) {
      axios.get("/support/twilio/numbers").then((res) => {
        this.setState({
          loading: false,
          twilioNumbers: res.data.twilio_numbers,
          trackingNumbers: res.data.tracking_numbers,
          unassignedUsers: res.data.unassigned_users,
        });
      });
    }
  }

  assignedNumbers = () => {
    const { twilioNumbers, trackingNumbers } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <br />
        <h4>
          <strong>Assigned Twilio Numbers</strong>
        </h4>
        <table className="table">
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>SID</th>
              <th>Name</th>
              <th>Email</th>
              <th style={{ textAlign: "end" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {twilioNumbers
              .filter((n) => n.user_id !== null)
              .map((n) => (
                <tr key={n.sid}>
                  <td width="150px">{n.phone_number}</td>
                  <td width="325px">{n.sid}</td>
                  <td>{n.name}</td>
                  <td>{n.email}</td>
                  <td style={{ textAlign: "end" }}>
                    {n.toll_free ? (
                      <span className="tw-text-[FF0000]">Toll-Free</span>
                    ) : (
                      <button
                        className="btn btn-xs tw-text-[12px]"
                        type="button"
                        onClick={(e) => {
                          this.setState({
                            setUnAssignModalShow: true,
                            unAssignedModalCb: () => this.unassignNumber(e, n.user_id),
                          });
                        }}
                      >
                        Un-assign
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            {trackingNumbers.map((n) => (
              <tr key={n.id}>
                <td width="150px">{n.number}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td width="325px" />
                <td>{n.name}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  unassignedNumbers = () => {
    const { twilioNumbers } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <h4>
          <strong>Unassigned Twilio Numbers</strong>
        </h4>
        <table className="table">
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>SID</th>
              <th>Unassigned on</th>
              <th style={{ textAlign: "end" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {twilioNumbers
              .filter((n) => n.user_id === null)
              .map((n) => (
                <tr key={n.sid}>
                  <td width="150px">{n.phone_number}</td>
                  <td width="325px">{n.sid}</td>
                  <td width="200px">{n.unassigned_at}</td>
                  <td style={{ textAlign: "end" }}>
                    {n.toll_free ? (
                      <span className="tw-text-[FF0000]">Toll-Free</span>
                    ) : (
                      <>
                        <button
                          className="btn btn-xs tw-text-[12px]"
                          type="button"
                          onClick={() => {
                            this.showAssignModal(n);
                          }}
                        >
                          Assign
                        </button>{" "}
                        <button
                          className="btn btn-xs tw-text-[12px]"
                          type="button"
                          onClick={() => this.showMoveAccountModal(n)}
                        >
                          Move Account
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  successAlerts = () => {
    const { successAlerts } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        {successAlerts.map((e) => (
          <div key={e} className="alert alert-success" role="alert">
            {e}
          </div>
        ))}
      </div>
    );
  };

  errorAlerts = () => {
    const { errors } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        {errors.map((e) => (
          <div key={e} className="alert alert-danger" role="alert">
            {e}
          </div>
        ))}
      </div>
    );
  };

  loadingState = () => (
    <div className="row">
      <div className="col text-center">
        <h3>
          <i className="fa fa-pulse fa-spinner" /> Loading Twilio Numbers...
        </h3>
      </div>
    </div>
  );

  unassignNumber = (e, userId) => {
    const { successAlerts, errors } = this.state;
    e.preventDefault();
    this.setState({ loading: true }, () => {
      axios
        .post("/support/twilio/unassign_number", {
          user_id: userId,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            loading: false,
            twilioNumbers: res.data.twilio_numbers,
            unassignedUsers: res.data.unassigned_users,
            successAlerts: [...successAlerts, res.data.success],
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            errors: [...errors, "There was an error un-assigning the number."],
          });
        });
    });
  };

  showAssignModal = (number) => {
    this.setState({ assignModalVisible: true, numberToAssign: number });
  };

  selectUser = () => {
    const { unassignedUsers, numberToAssign, userToAssign } = this.state;
    return (
      <>
        <strong>Select a user</strong>
        <br />
        <select
          value={userToAssign}
          id="userToAssignSelect"
          className="form-control"
          placeholder="Select a user"
          onChange={(e) => {
            this.setState({ userToAssign: e.target.value });
          }}
        >
          <option key="null_user" value="" disabled>
            Select a User
          </option>
          {unassignedUsers.map((u) => (
            <option key={u.id} value={u.id}>
              {u.name} - {u.email}
            </option>
          ))}
        </select>
        <br />
        <br />
        <button
          className="btn btn-sm btn-info"
          type="button"
          disabled={userToAssign === ""}
          onClick={() => {
            this.assignNumber(numberToAssign, userToAssign);
          }}
        >
          Assign Number
        </button>
      </>
    );
  };

  selectTrackingNumber = () => {
    const { trackingNumbers, numberToAssign, trackingNumberToAssign } = this.state;
    return (
      <>
        <strong>Select a tracking number</strong>
        <br />
        <select
          value={trackingNumberToAssign}
          id="trackingNumberToAssignSelect"
          className="form-control"
          placeholder="Select a tracking number"
          onChange={(e) => {
            this.setState({ trackingNumberToAssign: e.target.value });
          }}
        >
          <option key="null_user" value="" disabled>
            Select a Tracking Number
          </option>
          {trackingNumbers.map((u) => (
            <option key={u.id} value={u.id}>
              {u.name}
            </option>
          ))}
        </select>
        <br />
        <br />
        <button
          className="btn btn-sm btn-info"
          type="button"
          disabled={trackingNumberToAssign === ""}
          onClick={() => {
            this.assignNumberToTrackingNumber(numberToAssign, trackingNumberToAssign);
          }}
        >
          Assign Number
        </button>
      </>
    );
  };

  assignModal = () => {
    const { assignModalVisible, assignTo, numberToAssign } = this.state;
    return (
      <div>
        <Modal
          show={assignModalVisible}
          onHide={() => this.setState({ assignModalVisible: false, numberToAssign: null, userToAssign: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex">Assign Twilio Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <strong>Number to assign: </strong>
              {numberToAssign && numberToAssign.phone_number}
              <br />
              <strong>Assign to: </strong>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="assignTo"
                  id="assignToUser"
                  value="user"
                  checked={assignTo === "user"}
                  onChange={(e) => {
                    this.setState({ assignTo: e.target.value });
                  }}
                />
                User
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="assignTo"
                  id="assignToTrackingNumber"
                  value="trackingNumber"
                  checked={assignTo === "trackingNumber"}
                  onChange={(e) => {
                    this.setState({ assignTo: e.target.value });
                  }}
                />
                Tracking Number
              </label>
              <br />
              {assignTo === "user" ? this.selectUser() : this.selectTrackingNumber()}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  assignNumber = (number, userId) => {
    const { errors, successAlerts } = this.state;
    this.setState({ loading: true, assignModalVisible: false }, () => {
      axios
        .post("/support/twilio/assign_number", {
          phone_number: number.phone_number,
          user_id: userId,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            loading: false,
            twilioNumbers: res.data.twilio_numbers,
            trackingNumbers: res.data.tracking_numbers,
            unassignedUsers: res.data.unassigned_users,
            successAlerts: [...successAlerts, res.data.success],
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errors: [...errors, err.response.data.errors || "There was an error assigning the number"],
          });
        });
    });
  };

  assignNumberToTrackingNumber = (number, trackingNumberId) => {
    const { errors, successAlerts } = this.state;
    this.setState({ loading: true, assignModalVisible: false }, () => {
      axios
        .post("/support/twilio/assign_number_to_tracking_number", {
          phone_number: number.phone_number,
          tracking_number_id: trackingNumberId,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            loading: false,
            twilioNumbers: res.data.twilio_numbers,
            trackingNumbers: res.data.tracking_numbers,
            unassignedUsers: res.data.unassigned_users,
            successAlerts: [...successAlerts, res.data.success],
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errors: [...errors, err.response.data.errors || "There was an error assigning the number"],
          });
        });
    });
  };

  showMoveAccountModal = (number) => {
    this.setState({ moveAccountModalVisible: true, numberToMove: number });
  };

  moveAccountModal = () => {
    const { moveAccountModalVisible, moveAccountUserEmail, numberToMove, userToMoveTo, moveNumberErrors } =
      this.state;
    return (
      <div>
        <Modal
          show={moveAccountModalVisible}
          onHide={() =>
            this.setState({ moveAccountModalVisible: false, numberToMove: null, userToMoveTo: null })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex">Move Twilio Number Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {moveNumberErrors.map((e) => (
                <div key={e} className="alert alert-danger" role="alert">
                  {e}
                </div>
              ))}
              <strong>Number to move: </strong>
              {numberToMove && numberToMove.phone_number}
              <br />
              <p>The destination user cannot have a currently assigned Twilio number.</p>
              <br />
              {!userToMoveTo && (
                <div>
                  <strong>Enter the email address for the destination user</strong>
                  <br />
                  <input
                    type="text"
                    className="form-control"
                    value={moveAccountUserEmail}
                    onChange={(e) => {
                      this.setState({ moveAccountUserEmail: e.target.value });
                    }}
                  />
                </div>
              )}
              {userToMoveTo && (
                <div>
                  <p>
                    <strong>Confirm Destination User:</strong>
                  </p>
                  <p>
                    <strong>Name: </strong>
                    {userToMoveTo.user_name}
                  </p>
                  <p>
                    <strong>Email: </strong>
                    {userToMoveTo.user_email}
                  </p>
                  <p>
                    <strong>Account: </strong>
                    {userToMoveTo.account_name}
                  </p>
                  <p>
                    <strong>Account Id: </strong>
                    {userToMoveTo.account_id}
                  </p>
                  <p>
                    <strong>Subaccount Sid: </strong>
                    {userToMoveTo.subaccount_sid}
                  </p>
                </div>
              )}
              <br />
              <br />
              {!userToMoveTo && (
                <button
                  className="btn btn-sm btn-info"
                  type="button"
                  onClick={() => {
                    this.moveNumber(false);
                  }}
                >
                  Check User
                </button>
              )}
              {userToMoveTo && (
                <button
                  className="btn btn-sm btn-info"
                  type="button"
                  onClick={() => {
                    this.moveNumber(true);
                  }}
                >
                  Confirm - Move Number
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  moveNumber = (confirm) => {
    const { numberToMove, successAlerts, moveAccountUserEmail, errors } = this.state;
    if (confirm) {
      this.setState({ loading: true, moveAccountModalVisible: false }, () => {
        axios
          .post("/support/twilio/move_number_account", {
            phone_number: numberToMove.phone_number,
            user_email: moveAccountUserEmail,
            confirm: "true",
            // eslint-disable-next-line no-undef
            authenticity_token: ReactOnRails.authenticityToken(),
          })
          .then((res) => {
            this.setState({
              loading: false,
              twilioNumbers: res.data.twilio_numbers,
              trackingNumbers: res.data.tracking_numbers,
              unassignedUsers: res.data.unassigned_users,
              successAlerts: [...successAlerts, res.data.success],
              moveAccountUserEmail: "",
              numberToMove: null,
              userToMoveTo: null,
              moveNumberErrors: [],
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              errors: [...errors, err.response?.data?.errors || "There was an error moving the number"],
            });
          });
      });
    } else {
      axios
        .post("/support/twilio/move_number_account", {
          phone_number: numberToMove.phone_number,
          user_email: moveAccountUserEmail,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            userToMoveTo: res.data,
            moveNumberErrors: [],
          });
        })
        .catch((err) => {
          this.setState({ moveNumberErrors: err.response?.data?.errors || ["An error occured"] });
        });
    }
  };

  noSubaccountMessage = () => (
    <div className="row">
      <div className="col text-center">
        <h4>Provision a subaccount before managing numbers for this account</h4>
      </div>
    </div>
  );

  developerOnlyMessage = () => (
    <div className="row">
      <div className="col text-center">
        <h4>The Twilio support tool is limited to superusers with developer access</h4>
      </div>
    </div>
  );

  render() {
    const { loading, subaccountSid, setUnAssignModalShow, unAssignedModalCb } = this.state;
    const { developerRole } = this.props;
    return (
      <>
        <UnAssignModal
          show={setUnAssignModalShow}
          onHideHandlr={() => this.setState({ setUnAssignModalShow: false })}
          onSubmitHandlr={() => {
            unAssignedModalCb();
            this.setState({ setUnAssignModalShow: false });
          }}
        />
        {!developerRole && this.developerOnlyMessage()}
        {!subaccountSid && this.noSubaccountMessage()}
        {developerRole && subaccountSid && loading && this.loadingState()}
        {developerRole && subaccountSid && !loading && this.successAlerts()}
        {developerRole && subaccountSid && !loading && this.errorAlerts()}
        {developerRole && subaccountSid && !loading && this.assignedNumbers()}
        {developerRole && subaccountSid && !loading && <hr />}
        {developerRole && subaccountSid && !loading && this.unassignedNumbers()}
        {developerRole && subaccountSid && this.assignModal()}
        {developerRole && subaccountSid && this.moveAccountModal()}
      </>
    );
  }
}

TwilioManageNumbers.propTypes = {
  subaccountSid: PropTypes.string,
  developerRole: PropTypes.bool,
};

TwilioManageNumbers.defaultProps = {
  subaccountSid: "",
  developerRole: false,
};
