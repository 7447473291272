import React from "react";
import { Modal } from "react-bootstrap";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import * as actionCreators from "../actions/thunks";
import { Col, Row } from "../../../../shared/v1";
import HeaderTitle from "../HeaderTitle";
import Button from "../../../../shared/v2/Button";

const DeleteAdPlan = ({ modalOpen, deleteAdvertisingPlan, isLoading, uuid, planId, closeModal }) => {
  const destroyPlan = () => {
    deleteAdvertisingPlan(uuid, planId, closeModal);
  };
  return (
    <>
      <Modal className="modal" backdrop="static" show={modalOpen} onHide={closeModal}>
        <Modal.Header className="tw-pb-0 tw-px-[32px] tw-pt-[32px]">
          <Modal.Title className="tw-pb-[12px]  tw-border-0">
            <HeaderTitle title="Delete Advertising Plan?" onClose={closeModal} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
          <span className="tw-text-[14px] tw-font-normal tw-leading-[20px] tw-text-gray-75">
            Deleting this plan will not un-share any sites shared with the client.
          </span>
          <Row className="tw-pt-[32px] tw-m-0 tw-p-0 tw-flex">
            <Col xs={8} className="tw-m-0 tw-p-0">
              <Button
                onClick={closeModal}
                disabled={isLoading}
                className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
                schema="tertiary"
                size="medium"
              >
                CANCEL
              </Button>
            </Col>
            <Col xs={4} className="tw-m-0 tw-p-0 tw-flex tw-justify-end">
              <Button
                disabled={isLoading}
                onClick={destroyPlan}
                className="tw-flex tw-items-center"
                schema="warning"
                size="medium"
              >
                YES, DELETE
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

DeleteAdPlan.propTypes = {
  uuid: Proptypes.string.isRequired,
  modalOpen: Proptypes.bool,
  isLoading: Proptypes.bool,
  closeModal: Proptypes.func,
  deleteAdvertisingPlan: Proptypes.func.isRequired,
  planId: Proptypes.number,
};
DeleteAdPlan.defaultProps = {
  modalOpen: false,
  isLoading: false,
  closeModal: null,
  planId: null,
};
const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAdvertisingPlan: (uuid, planId, closeModal) =>
    dispatch(actionCreators.deleteAdvertisingPlan(uuid, planId, closeModal)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAdPlan);
