import React from "react";
import PropTypes from "prop-types";
import Results from "./Results";
import css from "./QuickSearch.module.css";
import debounce from "lodash/debounce";
import { Search } from "../../shared/v2/Icomoon";

class QuickSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      cursor: 0,
    };
  }

  saveRecentSearch = (result) => {
    const recentSearches = JSON.parse(localStorage.getItem("recentSearches") || "[]");
    for (let i = 0; i < recentSearches.length; i++) {
      if (recentSearches[i].path === result.path) {
        recentSearches.splice(i, 1);
        break;
      }
    }
    recentSearches.unshift(result);
    const limitedRecentSearches = recentSearches.slice(0, 5);
    localStorage.setItem("recentSearches", JSON.stringify(limitedRecentSearches));
  };

  toggleActive() {
    this.setState({ isActive: !this.state.isActive });
  }

  handleKeyDown = (e) => {
    const { cursor } = this.state;
    const { searchResults, searchValid } = this.props;
    const recentSearches = JSON.parse(localStorage.getItem("recentSearches") || "[]");

    // when pressing the enter key on a selection goes to that result's page
    if (e.keyCode === 13) {
      e.preventDefault();
      let selected = undefined;

      if (searchValid) {
        selected = searchResults[cursor];
      } else {
        if (cursor <= recentSearches.length - 1) {
          selected = recentSearches[cursor];
        }
      }
      selected && this.saveRecentSearch(selected);
      selected && window.location.replace(selected.path);
    // arrow up/down button should select next/previous list element
    } else if (e.keyCode === 38 && cursor > 0) {
      this.setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40 && (searchValid && cursor < searchResults.length - 1 || !searchValid && cursor < recentSearches.length - 1)) {
      this.setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
    }
  };

  handleHover = (index) => {
    this.setState((prevState) => ({
      cursor: index,
    }));
  };

  debounceFetchResults = debounce((value) => {
    this.props.fetchResults(value);
  }, 150);

  render() {
    const { isActive, cursor } = this.state;
    const { searchTerm, searchResults, hasResults, isFetching, searchValid, inputLength } = this.props;
    return (
      <div>
        <form role="search">
          <div className={css.searchDropdown}>
            <div className={css.searchInputContainer} id="searchInputContainer">
              <input
                className={`${css.formControl} !tw-bg-gray/10 !tw-text-inherit`}
                type="text"
                onFocus={() => this.toggleActive()}
                onBlur={() => this.toggleActive()}
                maxLength={inputLength}
                placeholder="Search by name, address, phone or MLS#"
                defaultValue={searchTerm}
                onChange={(e) => this.debounceFetchResults(e.target.value)}
                onKeyDown={this.handleKeyDown}
              />
              <Search className="tw-absolute tw-right-[6px] tw-top-[calc(50%-8px)]" size="l" />
            </div>
            {isActive && (
              <Results
                searchResults={searchResults}
                hasResults={hasResults}
                onHover={this.handleHover}
                searchTerm={searchTerm}
                cursor={cursor}
                searchTermLength={searchTerm.length}
                isFetching={isFetching}
                searchValid={searchValid}
                saveRecentSearch={this.saveRecentSearch}
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}
QuickSearch.defaultProps = {
  searchTerm: "",
  searchResults: [],
  inputLength: 50, // TODO: ENV['VAR'] ?
};

QuickSearch.propTypes = {
  searchTerm: PropTypes.string,
  fetchResults: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  inputLength: PropTypes.number,
};

export default QuickSearch;
