import React from "react";
import Proptypes from "prop-types";
import { Col, Row } from "../../shared/v1";
import IconButton from "../../shared/v2/IconButton";
import { Close } from "../../shared/v2/Icomoon";

const HeaderTitle = ({ title, onClose }) => (
  <Row className="tw-flex tw-p-0 tw-m-0 tw-mx-[0px] tw-items-center">
    <Col
      size={10}
      className="tw-py-[0px] tw-px-[0px] tw-p-0 tw-m-0 tw-mx-[0px] tw-text-[28px] tw-font-normal	tw-leading-[36px] tw-text-gray-75"
    >
      {title}
    </Col>
    <Col size={2} className="tw-p-0 tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px]">
      <IconButton onClick={onClose} className="tw-float-right" size="medium" schema="tertiary">
        <Close size="s" />
      </IconButton>
    </Col>
  </Row>
);

HeaderTitle.propTypes = {
  title: Proptypes.string,
  onClose: Proptypes.func,
};
HeaderTitle.defaultProps = {
  title: "",
  onClose: () => {},
};

export default HeaderTitle;
