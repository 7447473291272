import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import TextComboInput from "@shared/v2/TextComboInput";
import { headline2 } from "@shared/styles";
import { isStrInputLen } from "./helpers";
import Button from "../../shared/v2/Button";
import DeactivateUserModal from "../../Settings/Users/DeactivateUserModal";
import CannotReActivateUserModal from "../../Settings/Users/CannotReActivateUserModal";
import ActivateUserModal from "../../Settings/Users/ActivateUserModal";
import OptionSectionComponent from "./OptionSectionComponent";
import Checkbox from "../../shared/v2/Checkbox";
import { containerView } from "./styles";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import { useContactPerson } from "../../reducers/contactsReducer/selectors";

function AccountSettingsView() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const person = useContactPerson();
  const isSeatsAvailable = currentUser.account.seats > 0;
  const primaryEmail = useMemo(
    () => person.emailDetailsAttributes?.find((email) => email.position === 1)?.value || "",
    [person.emailDetailsAttributes],
  );
  const [isDeactivatingUser, setIsDeactivatingUser] = useState(false);
  const [isActivatingUser, setIsActivatingUser] = useState(false);
  const [isCannotReActivateUser, setIsCannotReActivateUser] = useState(false);
  const inviteUserOptions = useMemo(
    () =>
      currentUser.account.defaultCrmAccess
        ? [
            { name: "Yes, invite this user to log in", value: true },
            { name: "No, I'll do this later", value: false },
          ]
        : [
            { name: "Yes, invite this user to log in", value: true },
            {
              name: "Note: This user will not have access to the Brivity CRM but will have access to all other apps associated with their account. Contact support by calling 855-427-4848 if you want this user to have access to Brivity.",
              value: undefined,
            },
            { name: "No, I'll do this later", value: false },
          ],
    [currentUser.account.defaultCrmAccess],
  );

  useEffect(() => {
    dispatch(setPersonValue({ key: "loginUserAttributes.email", value: primaryEmail }));
  }, [primaryEmail]);

  if (!person.loginUserAttributes) return null;

  return (
    <div className={containerView}>
      <div className={`tw-text-neutral-gray-75 ${headline2}`}>Account Settings</div>
      <TextComboInput
        label="Brivity Login Email"
        isRequired
        value={person.loginUserAttributes.email || ""}
        containerClassName="tw-flex-1"
        error={isStrInputLen(person.loginUserAttributes.email)}
        onChange={(e) =>
          dispatch(setPersonValue({ key: "loginUserAttributes.email", value: e.target.value }))
        }
        data-cy="brivity-login-meail"
      />
      {person.id && currentUser.person.id !== person.id && (
        <OptionSectionComponent
          label="Resend User Invitation"
          copy="This user has already been invited to log in through their Brivity login email address. Would you like to resend this email?"
          action={
            <span className="tw-flex tw-items-center">
              <Checkbox
                className="tw-cursor-pointer"
                id="reinvite"
                checked={person.loginUserAttributes.inviteToOkta === true}
                onChange={() =>
                  dispatch(
                    setPersonValue({
                      key: "loginUserAttributes.inviteToOkta",
                      value: !person.loginUserAttributes.inviteToOkta,
                    }),
                  )
                }
              />
              {person.loginUserAttributes.inviteToOkta}
              <label
                htmlFor="reinvite"
                className="tw-mb-0 tw-ml-[8px] tw-cursor-pointer tw-select-none tw-text-14d tw-text-[var(--semantic-blue-120)]"
              >
                Yes, resend email invitation
              </label>
            </span>
          }
        />
      )}
      {!person.id && (
        <OptionSectionComponent
          label="Invite User"
          required
          copy="Do you want to invite this user to log in? They will get an email at their primary email address."
          options={inviteUserOptions}
          value={person.loginUserAttributes.inviteToOkta}
          onChange={(value) => dispatch(setPersonValue({ key: "loginUserAttributes.inviteToOkta", value }))}
        />
      )}
      {person.id && (
        <div>
          {!person.loginUserAttributes.active ? (
            <Button
              schema="secondary"
              size="medium"
              onClick={() => (isSeatsAvailable ? setIsActivatingUser(true) : setIsCannotReActivateUser(true))}
            >
              Activate User
            </Button>
          ) : (
            <Button
              className={
                person.agentType === "Account Owner" || person.id === currentUser.person.id ? "tw-hidden" : ""
              }
              schema="warning"
              size="medium"
              onClick={() => setIsDeactivatingUser(true)}
            >
              Archive User
            </Button>
          )}
        </div>
      )}
      <DeactivateUserModal
        show={isDeactivatingUser}
        onClose={(data) => {
          setIsDeactivatingUser(false);
          if (data) dispatch(setPersonValue({ key: "loginUserAttributes.active", value: false }));
        }}
        userId={person.loginUserAttributes.id}
      />
      {isCannotReActivateUser && (
        <CannotReActivateUserModal
          show={isCannotReActivateUser}
          onClose={() => setIsCannotReActivateUser(false)}
        />
      )}
      {isActivatingUser && (
        <ActivateUserModal
          show={isActivatingUser}
          onClose={(data) => {
            setIsActivatingUser(false);
            if (data) dispatch(setPersonValue({ key: "loginUserAttributes.active", value: true }));
          }}
          userId={person.loginUserAttributes.id}
        />
      )}
    </div>
  );
}

export default AccountSettingsView;
