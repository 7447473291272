import React from "react";
import { Modal } from "react-bootstrap";
import Proptypes from "prop-types";
import HeaderTitle from "../HeaderTitle";
import Planbody from "./Planbody";

const AdApplyPlan = ({ modalOpen, closeModal }) => (
  <Modal className="modal" backdrop="static" show={modalOpen} onHide={closeModal}>
    <Modal.Header className="tw-pb-0 tw-px-[32px] tw-pt-[32px]">
      <Modal.Title className="tw-pb-[12px]  tw-border-0">
        <HeaderTitle title="Apply Advertising Plan" onClose={closeModal} />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
      <Planbody closeModal={closeModal} />
    </Modal.Body>
  </Modal>
);

AdApplyPlan.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
};
AdApplyPlan.defaultProps = {
  modalOpen: false,
  closeModal: null,
};
export default AdApplyPlan;
