import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactSelect, { createFilter } from "react-select";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import * as customComponentsWrappers from "./custom-wrappers";
import * as customComponents from "./custom";

import DefaultControl from "./default/Control";
import DefaultMultiValueRemove from "./default/MultiValueRemove";
import DefaultSearchableInput from "./default/SearchableInput";
import DefaultDropdownIndicator from "./default/DropdownIndicator";

import FieldLabel from "../FieldLabel";
import FieldHelper from "../FieldHelper";
import FieldError from "../FieldError";

import DropdownMultiValues from "./DropdownMultiValues";

import styles from "./Dropdown.styles";
import { option } from "./utils";

export const CLASS_NAME_PREFIX = "react-select";

const Dropdown = forwardRef((props, ref) => {
  if (props.isSearchable) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SearchableDropdown {...props} ref={ref} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BasicDropdown {...props} ref={ref} />;
});

const DropdownBase = forwardRef(
  (
    {
      id: idFromProps,
      className,
      containerClassName,
      label,
      loadingMessage: initialLoadingMessage,
      noOptionsMessage: initialNoOptionsMessage,
      isRequired,
      multiShouldRenderBelowSelect,
      menuShouldComeToFront,
      maxVisibleOptions,
      helperText,
      error,
      leading,
      labelClassName,
      trailing,
      defaultComponents,
      components: initialComponents,
      toolTipInfoContent,
      toolTipPlacement,
      dropdownMultiValueClassName,
      removablePrefix,
      "data-cy": dataCy,
      ...otherProps
    },
    ref,
  ) => {
    const id = useGivenOrGeneratedId("dropdown", idFromProps);

    const internalRef = ref || useRef(null);

    // We need to re-render the component once the ref is populated,
    // so we need to keep a separate state for it
    const [selectRef, setSelectRef] = useState({ current: null });

    const loadingMessage = useCallback(() => initialLoadingMessage, [initialLoadingMessage]);

    const noOptionsMessage = useCallback(() => initialNoOptionsMessage, [initialNoOptionsMessage]);

    const closeMenuOnScroll = useCallback(
      (e) =>
        // Do not close the menu if it's already closed,
        // or if we're scrolling inside of it
        selectRef.current?.props?.menuIsOpen &&
        e.target?.classList &&
        !e.target.classList.contains(`${CLASS_NAME_PREFIX}__menu-list`),
      [selectRef.current],
    );

    const custom = useMemo(
      () => ({
        ...defaultComponents,
        ...initialComponents,
      }),
      [defaultComponents, initialComponents],
    );

    const components = useMemo(
      () =>
        Object.fromEntries(
          Object.entries(custom).map(([componentName, Component]) => {
            if (componentName === "ClearIndicator" && otherProps.isMulti && multiShouldRenderBelowSelect) {
              // When the options should be rendered below the select we must remove the
              // ClearIndicator component from the components object to render it inside the
              // DropdownMultiValues component.
              return [componentName, null];
            }

            return [componentName, Component && customComponentsWrappers[componentName]];
          }),
        ),
      [custom],
    );

    const selectStyles = () => styles(otherProps.isPipeline);

    useEffect(() => {
      setSelectRef(internalRef);
    }, [internalRef.current]);

    return (
      <div className={`tw-flex tw-flex-col ${containerClassName}`}>
        {label && (
          <FieldLabel
            className={`tw-mb-8px ${labelClassName}`}
            htmlFor={id}
            label={label}
            isRequired={isRequired}
            isDisabled={otherProps.isDisabled}
            toolTipInfoContent={toolTipInfoContent}
            toolTipPlacement={toolTipPlacement}
          />
        )}
        <div className="tw-flex tw-flex-1 tw-gap-[4px]">
          {leading}
          <div
            className={`
              tw-flex-1
              ${otherProps.isDisabled ? "tw-cursor-not-allowed" : "tw-cursor-default"}
            `}
            data-cy={dataCy}
          >
            <ReactSelect
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...otherProps}
              ref={internalRef}
              classNamePrefix={CLASS_NAME_PREFIX}
              className={`
                ${CLASS_NAME_PREFIX}__container
                ${className}
              `}
              id={id}
              loadingMessage={loadingMessage}
              noOptionsMessage={noOptionsMessage}
              // Searchable dropdowns should be clearable,
              // so that `backspaceRemovesValue` can work properly
              isClearable={otherProps.isSearchable || false}
              // If multiple values are enabled and they should render below the select,
              // we need to force the control not to render them
              controlShouldRenderValue={
                otherProps.isMulti && multiShouldRenderBelowSelect
                  ? false
                  : otherProps.controlShouldRenderValue
              }
              // Each option has 32px height
              maxMenuHeight={maxVisibleOptions ? maxVisibleOptions * 32 : null}
              // If we're bringing the menu to the front,
              // we want to close it when scrolling to prevent it from staying fixed
              closeMenuOnScroll={menuShouldComeToFront ? closeMenuOnScroll : otherProps.closeMenuOnScroll}
              // If multiple values are disabled, we need to force the selected options not to be hidden
              hideSelectedOptions={otherProps.isMulti ? otherProps.hideSelectedOptions : false}
              error={!!error}
              styles={{ ...selectStyles(), ...otherProps.styles }}
              components={components}
              custom={custom}
              // If we're bringing the menu to the front,
              // this is needed for it to be visible
              menuPortalTarget={menuShouldComeToFront ? document.body : null}
            />
          </div>
          {trailing}
        </div>
        {helperText && (
          <FieldHelper className="tw-mt-4px" helperText={helperText} isDisabled={otherProps.isDisabled} />
        )}
        {error && <FieldError className="tw-mt-4px" error={error} />}
        {otherProps.isMulti && multiShouldRenderBelowSelect && selectRef.current?.state?.selectValue && (
          <DropdownMultiValues
            selectRef={selectRef}
            dropdownMultiValueClassName={dropdownMultiValueClassName}
            removablePrefix={removablePrefix}
          />
        )}
      </div>
    );
  },
);

const BasicDropdown = forwardRef((props, ref) => (
  <DropdownBase
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    ref={ref}
    isSearchable={false}
    defaultComponents={{
      Control: DefaultControl,
      MultiValueRemove: DefaultMultiValueRemove,
      IndicatorSeparator: null,
      LoadingIndicator: null,
      ClearIndicator: null,
      DropdownIndicator: DefaultDropdownIndicator,
    }}
  />
));

const SearchableDropdown = forwardRef((props, ref) => {
  const selectRef = ref || useRef(null);
  const wasSearchTextSelected = useRef(false);
  const hasInputValueChanged = useRef(false);
  const wasOptionSelected = useRef(false);

  const [inputValue, setInputValue] = useState("");
  useEffect(() => setInputValue(props.inputValue), [props.inputValue]);

  const filter = useMemo(() => props.customFilter || createFilter(), []);

  const filterOption = useCallback(
    (currentOption, currentInputValue) =>
      // If the user opened the menu and hasn't changed the input value,
      // we should not filter the options so that all options appear at first
      props.shouldFilterOptions && hasInputValueChanged.current
        ? filter(currentOption, currentInputValue)
        : true,
    [filter, props.shouldFilterOptions],
  );

  const handleInputChange = (newValue, event) => {
    switch (event.action) {
      case "input-change":
        props.onInputChange?.(newValue, event);
        setInputValue(newValue);

        if (props.emptyInputRemovesValue && !newValue && !props.isMulti) {
          // Clear the selected option if multiple values are disabled and the input value is empty
          props.onChange(null, {
            action: "clear",
            removedValues: selectRef.current?.state?.selectValue ?? [],
          });
        }

        hasInputValueChanged.current = true;
        break;

      case "set-value":
        // Selecting an option triggers an input change with `set-value` action,
        // so we need to keep track of this to prevent reverting the value on blur
        wasOptionSelected.current = true;
        break;

      case "input-blur":
        if (!wasOptionSelected.current) {
          if (props.isMulti) {
            // If multiple values are enabled, clear the input value
            setInputValue("");
          } else if (props.selectInputOnMenuOpen && newValue !== props.value?.label) {
            // If no option was selected and the input value is different than the previously selected option,
            // revert the input value back to the previously selection option
            setInputValue(props.value?.label || "");
          }
        }

        hasInputValueChanged.current = false;
        wasOptionSelected.current = false;
        break;

      default:
        break;
    }
  };

  const isSearchTextSelected = (input) => Math.abs(input.selectionStart - input.selectionEnd) > 0;

  const selectSearchText = () => {
    if (!props.isMulti && selectRef.current?.inputRef) {
      selectRef.current.inputRef.select();

      // We need to keep track of when the search text was selected
      // to make sure that the selection is preserved if the user clicked on the input to open the menu
      wasSearchTextSelected.current = true;

      // We give it just enough time to be used by `SearchableInput`,
      // because any subsequent clicks shouldn't be prevented
      window.setTimeout(() => {
        wasSearchTextSelected.current = false;
      }, 100);
    }
  };

  const unselectSearchText = () => {
    if (!props.isMulti && selectRef.current?.inputRef && isSearchTextSelected(selectRef.current.inputRef)) {
      selectRef.current.inputRef.setSelectionRange(0, 0);
    }
  };

  const handleMenuOpen = () => {
    selectSearchText();

    if (props.onMenuOpen) {
      props.onMenuOpen();
    }
  };

  const handleMenuClose = () => {
    unselectSearchText();

    if (props.onMenuClose) {
      props.onMenuClose();
    }
  };

  useEffect(() => {
    // Update the input value to match the selected option
    if (props.preventOptionUpdate) return;
    setInputValue((props.selectInputOnMenuOpen && !props.isMulti && props.value?.label) || "");
  }, [props.selectInputOnMenuOpen, props.isMulti, props.value]);

  return (
    <DropdownBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={selectRef}
      inputValue={inputValue}
      isSearchable
      filterOption={filterOption}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      defaultComponents={{
        Control: DefaultControl,
        MultiValueRemove: DefaultMultiValueRemove,
        Input: DefaultSearchableInput,
        IndicatorSeparator: null,
        LoadingIndicator: null,
        ClearIndicator: null,
        DropdownIndicator: DefaultDropdownIndicator,
      }}
      wasSearchTextSelected={wasSearchTextSelected}
    />
  );
});

const commonPropTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.node,
  loadingMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  options: PropTypes.arrayOf(option).isRequired,
  value: PropTypes.oneOfType([option, PropTypes.arrayOf(option)]),
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  multiShouldRenderBelowSelect: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  selectInputOnMenuOpen: PropTypes.bool,
  menuShouldComeToFront: PropTypes.bool,
  menuShouldScrollIntoView: PropTypes.bool,
  maxVisibleOptions: PropTypes.number,
  blurInputOnSelect: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  closeMenuOnScroll: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  emptyInputRemovesValue: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.string,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  leading: PropTypes.node,
  trailing: PropTypes.node,
  inputTrailing: PropTypes.node,
  toolTipInfoContent: PropTypes.node,
  toolTipPlacement: PropTypes.string,
  dropdownMultiValueClassName: PropTypes.string,
  removablePrefix: PropTypes.string,
  preventOptionUpdate: PropTypes.bool,
  components: PropTypes.shape(
    Object.fromEntries(Object.keys(customComponents).map((componentName) => [componentName, PropTypes.func])),
  ),
};

Dropdown.propTypes = {
  ...commonPropTypes,
  isSearchable: PropTypes.bool,
};
DropdownBase.propTypes = {
  ...Dropdown.propTypes,
  defaultComponents: Dropdown.propTypes.components,
};
BasicDropdown.propTypes = commonPropTypes;
SearchableDropdown.propTypes = { ...commonPropTypes, shouldFilterOptions: PropTypes.bool };

const commonDefaultProps = {
  className: "",
  containerClassName: "",
  id: null,
  label: null,
  labelClassName: "",
  placeholder: "Select",
  loadingMessage: "Loading...",
  noOptionsMessage: "No options",
  value: null,
  isMulti: false,
  isLoading: false,
  isRequired: false,
  isDisabled: false,
  autoFocus: false,
  controlShouldRenderValue: true,
  multiShouldRenderBelowSelect: true,
  openMenuOnFocus: false,
  selectInputOnMenuOpen: true,
  menuShouldComeToFront: false,
  menuShouldScrollIntoView: false,
  maxVisibleOptions: 8,
  blurInputOnSelect: true,
  closeMenuOnSelect: true,
  closeMenuOnScroll: false,
  hideSelectedOptions: true,
  emptyInputRemovesValue: true,
  backspaceRemovesValue: true,
  helperText: null,
  error: null,
  onKeyDown: null,
  onInputChange: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onMenuOpen: null,
  onMenuClose: null,
  leading: null,
  trailing: null,
  inputTrailing: null,
  toolTipInfoContent: null,
  toolTipPlacement: null,
  dropdownMultiValueClassName: null,
  removablePrefix: "",
  preventOptionUpdate: false,
  components: {},
};

Dropdown.defaultProps = {
  ...commonDefaultProps,
  isSearchable: false,
};
DropdownBase.defaultProps = {
  ...Dropdown.defaultProps,
  defaultComponents: {},
};
BasicDropdown.defaultProps = commonDefaultProps;
SearchableDropdown.defaultProps = { ...commonDefaultProps, shouldFilterOptions: true };

Dropdown.Basic = BasicDropdown;
Dropdown.Searchable = SearchableDropdown;

Object.entries(customComponents).forEach(([componentName, Component]) => {
  Dropdown[componentName] = Component;
});

export default Dropdown;
