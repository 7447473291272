import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import configureStore from "../store/configureStore";
import View from "./View";
import { defaultState as businessSettingsState } from "../reducers/businessSettingsReducer";

const Main = ({ timezones, territories, states, isPlace, awsDirectPost }) => {
  const store = useMemo(
    () =>
      configureStore({
        businessSettings: {
          ...businessSettingsState,
          timezones,
          states,
          territories,
          isPlace,
          awsDirectPost,
        },
      }),
    [],
  );

  return (
    <Provider store={store}>
      <View />
    </Provider>
  );
};

export default Main;

Main.propTypes = {
  timezones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  territories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isPlace: PropTypes.bool.isRequired,
  awsDirectPost: PropTypes.shape({}).isRequired,
};
