import axios from "axios";
import { updateHomeAppInviteSent } from "../actions/default";

export const touchHomeAppInviteSent = (personId) => (dispatch) => {
  axios
    .patch(`/api/v4/person_detail/${personId}/touch_home_app_invite_sent`, {
      authenticity_token: window.ReactOnRails.authenticityToken(),
    })
    .then((res) => {
      dispatch(updateHomeAppInviteSent(res.data.data.attributes.home_app_invite_sent));
    });
};
export const updateIntent = (personId, newIntent, setIntent) => () => {
  const params = {
    person: {
      lead_type: newIntent,
    },
  };
  axios
    .patch(`/people/${personId}/update_inline`, params, {
      headers: {
        "X-CSRF-Token": window.ReactOnRails.authenticityToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(() => {
      setIntent(newIntent);
    });
};
