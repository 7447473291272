import React, { Component } from "react";

class ModalFooter extends Component {
  render() {
    return (
      <div className="modal-footer" style={{ padding: "10em 0 13px 0" }}>
        <div className="pull-left">
          <button
            style={{
              borderRadius: "30px",
              color: "#9F9F9F",
            }}
            className="btn"
            data-dismiss="modal"
            type="cancel"
            onClick={this.props.handleFilter}
          >
            Cancel
          </button>
        </div>
        <div className="pull-right">
          <button
            className="btn"
            type="submit"
            style={{
              borderRadius: "30px",
              backgroundColor: "#59C4C4",
              color: "white",
            }}
          >
            Filter
          </button>
        </div>
      </div>
    );
  }
}

export default ModalFooter;
