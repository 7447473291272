import React, { useState, useEffect, useRef } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { Overlay } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../shared/v2/IconButton";
import * as actionCreators from "../actions/thunks";
import TextButton from "../../../../shared/v2/TextButton";
import TextComboInput from "../../../../shared/v2/TextComboInput";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import CustomDates from "../CustomDates/CustomDates";
import { getSearchableDate } from "../actions/helper";
import { Search } from "../../../../shared/v2/Icomoon";
import colors from "../../../../Reports/components/DashboardShared/helpers/colors";

const DateHeaderOptions = ({ dates, showCustomDateLists, isOpen }) => {
  const [search, setSearch] = useState(null);
  const [options, setOptions] = useState(dates?.available_dates || []);
  const [target, setTarget] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    if (search) {
      setOptions(getSearchableDate(dates?.available_dates || [], search));
    } else {
      setOptions(dates?.available_dates || []);
    }
  }, [dates.available_dates, search]);

  const handleToggle = (event) => {
    showCustomDateLists(!isOpen);
    setTarget(event.target);
  };

  const onHideLists = () => {
    showCustomDateLists(false);
  };

  return (
    <>
      <div className="tw-flex tw-ml-auto">
        <IconButton onClick={handleToggle} size="small" schema="tertiary">
          <Plus size="s" />
        </IconButton>
      </div>
      <Overlay
        show={isOpen}
        target={target}
        rootClose
        placement="bottom"
        onHide={onHideLists}
        onEntered={() => inputRef.current?.focus()}
      >
        <div className="tw-z-[99] tw-w-[226px] tw-max-h-[328px] tw-p-0 tw-font-normal tw-leading-[20px] tw-text-[14px] tw-shadow-bootstrap-18 tw-shrink-0 tw-bg-white tw-px-[0px] tw-absolute tw-ml-[-2.7%] tw-mt-[15px] tw-rounded-[2px] tw-z-1 tw-text-left tw-text-gray-75">
          <div className="tw-h-[56px]">
            <div className="tw-px-[12px] tw-pb-[8px] tw-pt-[12px] tw-h-[56px]">
              <TextComboInput
                inputRef={inputRef}
                value={search}
                size="3"
                trailing={
                  <Search size="xl" className="tw-align-middle" style={{ color: colors.color1.default }} />
                }
                placeholder="Search dates"
                onChange={(e) => setSearch(e.target.value)}
                className="tw-w-full"
              />
            </div>
          </div>
          <div id="date-portal" />
          <div className="tw-max-h-[232px] tw-overflow-y-auto">
            {options.map((option) => (
              <CustomDates key={option.id} option={option} />
            ))}
          </div>
          <div className="tw-h-[40px]">
            <div className="tw-shadow-20 tw-p-[12px] tw-h-[40px]">
              <TextButton size="small" onClick={() => window.location.assign("/new_milestones")}>
                <FontAwesomeIcon icon={faCog} className="tw-text-[12px] tw-mr-[4px]" />
                CUSTOM DATES SETTINGS
              </TextButton>
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

DateHeaderOptions.propTypes = {
  showCustomDateLists: Proptypes.func.isRequired,
  isOpen: Proptypes.bool,
  dates: Proptypes.shape({
    available_dates: Proptypes.arrayOf(Proptypes.string),
  }),
};

DateHeaderOptions.defaultProps = {
  dates: {
    available_dates: [],
  },
  isOpen: false,
};
const mapStateToProps = (state) => ({
  dates: state.tdpDatesReducer.dates,
  isOpen: state.tdpDatesReducer.isOpen,
});
const mapDispatchToProps = (dispatch) => ({
  showCustomDateLists: (show) => dispatch(actionCreators.showCustomDateLists(show)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DateHeaderOptions);
