import React from "react";
import { Modal } from "react-bootstrap";
import Checkbox from "../../../Transactions/components/shared/Checkbox";

import ListingDetails from "./ListingDetails";
import "../details.scss";

const ListingDetailsModal = ({
  listing,
  buyerMatches,
  isSelected,
  numberSelected,
  selectedLimit,
  onToggleShare,
  sendToLead,
  maxListingsSelected,
  setSharedListing,
  onClose,
  openAgentDetailsModal,
  agentDetailsEnabled,
}) => {
  return (
    <Modal
      id="listing-details-modal"
      className="listing-details-modal"
      dialogClassName="tw-w-[760px] tw-m-0 tw-fixed tw-h-full tw-right-0"
      show={true}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="tw-flex tw-flex-row tw-w-full tw-items-center">
            <Checkbox checked={isSelected} onChange={() => onToggleShare(listing)} disabled={maxListingsSelected && !isSelected} />
            <span className="tw-text-gray-75 tw-font-normal tw-text-14px tw-ml-8px">{` ${numberSelected}/${selectedLimit} selected`}</span>
            <div className="tw-flex tw-items-center tw-justify-center tw-h-32px tw-w-32px tw-ml-auto tw-bg-color-ebebeb tw-bg-opacity-90 tw-rounded-112px tw-cursor-pointer">
              <i aria-hidden="true" className="fa fa-remove tw-text-gray-75 tw-text-18px" onClick={onClose} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListingDetails
          listing={listing}
          setSharedListing={setSharedListing}
          buyerMatches={buyerMatches}
          sendToLead={sendToLead}
          onShowAgentDetails={openAgentDetailsModal}
          agentDetailsEnabled={agentDetailsEnabled}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ListingDetailsModal;
