import { useEffect } from "react";
import { getAgentMLSOptions } from "../../services";

export default ({ setOptions, setLoading, primaryAgent, setMLSPropertyTypes }) =>
  useEffect(() => {
    getAgentMLSOptions({
      data: { primary_agent_uuid: primaryAgent },
      successCb: (response) => {
        if (Array.isArray(response?.data)) {
          setMLSPropertyTypes(response.data);
          setOptions(response.data);
        }
        setLoading(false);
      },
      errorCb: () => {
        setLoading(false);
      },
    });
  }, []);
