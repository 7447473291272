/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import IconButton from "../../shared/v2/IconButton/IconButton";
import { XmarkRegularV6 } from "../../shared/v2/Icomoon";
import useMobileMenu from "./hooks/useMobileMenu";
import QuickSearchContainer from "../../QuickSearch/containers/QuickSearchContainer";
import MenuOption from "./MenuOption";
import AppsMenu from "./AppsMenu";
import Menu from "./Menu";
import BackButton from "./BackButton";
import ACTION_MAP from "./ActionModals";

const MobileSidebar = () => {
  const {
    actions,
    data: { isMobileMenuOpen, isSubmenuOpen },
  } = useMobileMenu();
  const theme = useSelector(({ layout }) => layout.theme);
  const sidebar = useSelector(({ layout }) => layout.sidebar);
  const [selected, setSelected] = useState({ name: null });
  const Action = ACTION_MAP[selected.name];

  useEffect(() => {
    const menuItem = sidebar.menu.find((m) => m.name === selected.name);
    if (menuItem) setSelected(menuItem);
  }, [sidebar]);

  return createPortal(
    <>
      {isMobileMenuOpen && (
        <div
          className="tw-z-3000 tw-absolute tw-inset-0 tw-flex tw-justify-start tw-bg-black/15"
          onClick={actions.toggleMobileMenu}
        />
      )}
      <nav
        className={`tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-z-3000 tw-max-w-[404px] tw-w-full tw-pr-[24px] tw-flex tw-transition-transform tw-ease-in-out tw-duration-300 ${
          isMobileMenuOpen ? "tw-translate-x-0" : "-tw-translate-x-full"
        }`}
        onClick={actions.toggleMobileMenu}
        data-cy="side-navbar"
        id="sidebar"
      >
        <div
          className={`tw-flex tw-flex-col tw-gap-[12px] tw-p-[12px] tw-flex-1 tw-w-full tw-rounded-r-[16px] tw-overflow-y-auto tw-shadow-nav ${
            theme.name === "place-theme" ? "tw-bg-[#121212]" : "tw-bg-[#f9fbfb]"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="tw-flex tw-justify-end">
            <IconButton schema="transparent" onClick={actions.toggleMobileMenu}>
              <XmarkRegularV6 size="xl" />
            </IconButton>
          </div>
          <QuickSearchContainer />
          <div className="tw-relative tw-w-full tw-h-full tw-overflow-hidden">
            <div
              className={`tw-absolute tw-transition-left tw-duration-300 tw-top-0 tw-bottom-0 ${
                selected.name ? "tw-left-[calc(-100%-12px)]" : "tw-left-0"
              } tw-w-full tw-overflow-y-auto`}
            >
              {sidebar.menu.map((m) => (
                <MenuOption
                  key={m.name}
                  className="tw-font-bold"
                  isMobile
                  submenu={selected.name}
                  option={m}
                  onClick={() => setSelected(m)}
                />
              ))}
            </div>
            <div
              className={`tw-flex tw-flex-col tw-absolute tw-transition-left tw-duration-300 tw-top-0 tw-bottom-0 ${
                selected.name ? "tw-left-0" : "tw-left-[calc(100%+12px)]"
              } tw-w-full tw-overflow-hidden`}
            >
              {!isSubmenuOpen && (
                <div>
                  <BackButton onClick={() => setSelected({ name: null })}>Back</BackButton>
                </div>
              )}
              {selected.name && selected.name !== "Apps" && (
                <Menu
                  title={selected.name}
                  options={selected.options?.filter((o) => typeof o.enabled === "undefined" || o.enabled)}
                  action={Action ? <Action /> : null}
                />
              )}
              {selected.name === "Apps" && <AppsMenu />}
            </div>
          </div>
        </div>
      </nav>
    </>,
    document.body,
  );
};

export default MobileSidebar;
