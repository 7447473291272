import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import Button from "@shared/v2/Button";
import { PlusSolidV6 } from "@shared/v2/Icomoon";
import Tag from "@shared/v2/Tag";
import Avatar from "@shared/v2/Avatar";
import PhoneValue from "../../PersonDetail/components/PhoneValue";

const PlaceholderControl = ({ children, componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.Control {...componentProps}>
    <Button schema="secondary" disabled={componentProps.isDisabled}>
      <span className="tw-flex tw-w-full tw-gap-[4px] tw-items-center">
        <PlusSolidV6 size="s" /> {children}
      </span>
    </Button>
  </Dropdown.Control>
);

PlaceholderControl.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

const RecipientSearchOption = ({ componentProps }) => {
  const { label, meta } = componentProps.data;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dropdown.Option {...componentProps}>
      <div
        className={`tw-flex tw-gap-[8px] tw-text-neutral-gray-75 tw-whitespace-nowrap tw-px-[12px] tw-py-[6px] hover:tw-bg-gray-5 ${
          componentProps.isDisabled ? "tw-opacity-50" : ""
        }`}
      >
        <Avatar src={meta.avatar} alt={label} /> <span>{label}</span>{" "}
        <span className="tw-overflow-hidden tw-text-ellipsis">{meta.emailAddress}</span>{" "}
        {meta.phoneNumber && <span>{meta.phoneNumber}</span>}
      </div>
    </Dropdown.Option>
  );
};

RecipientSearchOption.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

const RecipientNumberControl = ({ children, componentProps }) => {
  const { smsStatus, smsUnsubscribed, smsIcon } = componentProps.selectProps.selectedNumber?.meta || {};
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dropdown.Control {...componentProps} preserveStyle={false}>
      <Tag
        disableMediaQuery
        removable
        onRemove={componentProps.selectProps.onRemove}
        className={
          smsUnsubscribed
            ? "tw-bg-semantic-red-5 tw-text-semantic-red-120"
            : `${smsStatus === "invalid" ? "tw-bg-semantic-yellow-5 tw-text-semantic-yellow-120" : ""}`
        }
      >
        {smsIcon && (smsStatus !== "valid" || smsUnsubscribed) && (
          <span className="tw-mr-[4px]">
            <smsIcon.icon />
          </span>
        )}
        {children}
      </Tag>
    </Dropdown.Control>
  );
};

RecipientNumberControl.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

const RecipientNumberOption = ({ componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.Option {...componentProps}>
    <div
      className={`tw-px-[12px] tw-py-[6px] hover:tw-bg-gray-5 ${
        componentProps.isDisabled ? "tw-opacity-50" : ""
      }`}
    >
      <PhoneValue
        hideCallStatus
        showPrimaryStatus
        phone={componentProps.data.meta}
        isDnc={componentProps.data.meta.isDnc}
      />
    </div>
  </Dropdown.Option>
);

RecipientNumberOption.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

export { PlaceholderControl, RecipientSearchOption, RecipientNumberControl, RecipientNumberOption };
