import React from "react";
import { Col, Row, FormGroup } from "react-bootstrap";
import { Input } from "../../shared";
import { Dropdown } from "../../shared/v1";
import axios from "axios";
import "./Filters.sass";
import LocationBubble from "./LocationBubble";
import LocationResults from "./LocationResults";
import debounce from "lodash/debounce";

class WebsiteSearchHistoryFilter extends React.Component {
  constructor(props) {
    super(props);
    const {
      selectedSearchHistory: {
        min_price,
        max_price,
        min_bedrooms,
        max_bedrooms,
        min_bathrooms,
        max_bathrooms,
        city,
        zip,
        views_and_favorites,
        views_only,
        favorites_only,
      },
    } = this.props;

    this.state = {
      minPrice: min_price || null,
      maxPrice: max_price || null,
      minBedrooms: min_bedrooms || null,
      maxBedrooms: max_bedrooms || null,
      minBathrooms: min_bathrooms || null,
      maxBathrooms: max_bathrooms || null,
      city: city || [],
      zip: zip || [],
      favoritesAndViews: views_and_favorites === undefined ? true : views_and_favorites === "true",
      viewsOnly: views_only === "true",
      favoritesOnly: favorites_only === "true",
      cityStateActive: false,
      cursor: 0,
      cursorCategory: "",
      searchResults: {},
      mlsIds: [],
      locationValue: "",
    };
  }

  componentDidMount() {
    axios
      .get(`/users/${this.props.currentUserId}/mls_ids`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({ mlsIds: response.data.ids });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleMinPriceChange = (e) => {
    Brivity.ClearAllFiltersButton.toggle();
    this.setState({ minPrice: this.formatPrice(e.target.value) });
  };

  handleMaxPriceChange = (e) => {
    Brivity.ClearAllFiltersButton.toggle();
    this.setState({ maxPrice: this.formatPrice(e.target.value) });
  };

  formatPrice = (value) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return parsedValue.toLocaleString();
  };

  handleMinBedroomsChange = (option) => {
    this.setState({ minBedrooms: option ? option.value : "" });
  };

  handleMaxBedroomsChange = (option) => {
    this.setState({ maxBedrooms: option ? option.value : "" });
  };

  handleMinBathroomsChange = (option) => {
    this.setState({ minBathrooms: option ? option.value : "" });
  };

  handleMaxBathroomsChange = (option) => {
    this.setState({ maxBathrooms: option ? option.value : "" });
  };

  removeLocation = (type, locationToRemove) => {
    if (type == "city") {
      const newCities = this.state.city.filter((location) => location != locationToRemove);
      this.setState({ city: newCities });
    } else {
      const newZips = this.state.zip.filter((location) => location != locationToRemove);
      this.setState({ zip: newZips });
    }
  };

  radioButtonClass = (isSelected) =>
    isSelected ? "fa fa-dot-circle-o brivity-blue" : "brivity-grey-text fa fa-circle-o";

  toggleButtons = (button) => {
    if (button === "views_and_favorites") {
      this.setState({ favoritesAndViews: true, viewsOnly: false, favoritesOnly: false });
    } else if (button === "views") {
      this.setState({ favoritesAndViews: false, viewsOnly: true, favoritesOnly: false });
    } else {
      this.setState({ favoritesAndViews: false, viewsOnly: false, favoritesOnly: true });
    }
  };

  toggleActive = () => {
    this.setState((prevState) => ({ cityStateActive: !prevState.cityStateActive }));
  };

  fetchResults = (term) => {
    // Update state on every keypress, but debounce before searching
    this.setState({ locationValue: term }, () => this.debounceFetchResults(term));
  };

  debounceFetchResults = debounce((term) => {
    this.doFetchResults(term);
  }, 150);

  doFetchResults = (term) => {
    if (!term.length) {
      return;
    }

    const idsArrayQuery = this.state.mlsIds.map((id) => `mlsIds[]=${id}`).join("&");

    axios
      .get(`/lead_filters/location_autocomplete.json?${idsArrayQuery}&term=${term}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (this.state.locationValue !== term) {
          return;
        }

        const cityAndZips = response.data.reduce((cityAndZips, obj) => {
          let category = obj["category"];
          if (
            !cityAndZips.hasOwnProperty(category) &&
            (category === "Zip Code" || category === "CityState")
          ) {
            cityAndZips[category] = [];
          }
          if (category === "Zip Code" || category === "CityState") {
            cityAndZips[category].push(obj.value);
          }
          return cityAndZips;
        }, {});
        this.setState({ searchResults: cityAndZips });
        if (cityAndZips.hasOwnProperty("CityState")) {
          this.setState({ cursorCategory: "CityState" });
        } else if (cityAndZips.hasOwnProperty("Zip Code")) {
          this.setState({ cursorCategory: "Zip Code" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  handleHover = (cursor, cursorCategory) => {
    this.setState({ cursor, cursorCategory });
  };

  handleSelect = (cursor, cursorCategory) => {
    if (cursorCategory === "CityState") {
      this.setState((prevState) => ({
        city: [...prevState.city, this.state.searchResults["CityState"][cursor]],
        locationValue: "",
        cursor: 0,
        cursorCategory: "",
        searchResults: {},
      }));
    } else if (cursorCategory === "Zip Code") {
      this.setState((prevState) => ({
        zip: [...prevState.zip, this.state.searchResults["Zip Code"][cursor]],
        locationValue: "",
        cursor: 0,
        cursorCategory: "",
        searchResults: {},
      }));
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      //Enter has been pressed
      e.preventDefault();
      if (this.state.cursorCategory === "CityState") {
        this.setState((prevState) => ({
          city: [...prevState.city, this.state.searchResults["CityState"][this.state.cursor]],
        }));
      } else if (this.state.cursorCategory === "Zip Code") {
        this.setState((prevState) => ({
          zip: [...prevState.zip, this.state.searchResults["Zip Code"][this.state.cursor]],
        }));
      }
    }

    if (e.keyCode === 38) {
      //navigate up
      if (this.state.cursorCategory === "CityState") {
        if (this.state.cursor > 0) {
          this.setState((prevState) => ({ cursor: prevState.cursor - 1 }));
        }
      } else if (this.state.cursorCategory === "Zip Code") {
        if (this.state.cursor > 0) {
          this.setState((prevState) => ({ cursor: prevState.cursor - 1 }));
        } else if (this.state.cursor === 0 && this.state.searchResults.hasOwnProperty("CityState")) {
          this.setState({
            cursor: this.state.searchResults["CityState"].length - 1,
            cursorCategory: "CityState",
          });
        }
      }
    } else if (e.keyCode === 40) {
      //navigate down
      if (this.state.cursorCategory === "CityState") {
        if (this.state.cursor < this.state.searchResults["CityState"].length - 1) {
          this.setState((prevState) => ({ cursor: prevState.cursor + 1 }));
        } else if (
          this.state.cursor === this.state.searchResults["CityState"].length - 1 &&
          this.state.searchResults.hasOwnProperty("Zip Code")
        ) {
          this.setState({ cursor: 0, cursorCategory: "Zip Code" });
        }
      } else if (this.state.cursorCategory === "Zip Code") {
        if (this.state.cursor < this.state.searchResults["Zip Code"].length - 1) {
          this.setState((prevState) => ({ cursor: prevState.cursor + 1 }));
        }
      }
    }
    if (e.keyCode === 8 && this.state.locationValue.length === 1) {
      this.setState({ locationValue: "", searchResults: {} });
    }
  };

  render() {
    const {
      minPrice,
      maxPrice,
      minBedrooms,
      maxBedrooms,
      minBathrooms,
      maxBathrooms,
      city,
      zip,
      favoritesAndViews,
      viewsOnly,
      favoritesOnly,
      cityStateActive,
      cursor,
      cursorCategory,
      locationValue,
      searchResults,
    } = this.state;
    const dropDownMinMax = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ];

    return (
      <div>
        <Col xs={12}>
          <FormGroup id="view-and-favorites-selector">
            <Input
              id="leads_filter_selected_website_history_filters_view_and_favorites"
              name="leads_filter[selected_website_history_filters][views_and_favorites]"
              onClick={() => this.toggleButtons("views_and_favorites")}
              value={favoritesAndViews}
            />
            <label
              htmlFor="leads_filter_selected_website_history_filters_view_and_favorites"
              className={this.radioButtonClass(favoritesAndViews)}
            ></label>
            <span style={{ marginRight: "16px" }}>Property Views & Favorites</span>
            <Input
              id="leads_filter_selected_website_history_filters_views_only"
              name="leads_filter[selected_website_history_filters][views_only]"
              onClick={() => this.toggleButtons("views")}
              value={viewsOnly}
            />
            <label
              htmlFor="leads_filter_selected_website_history_filters_views_only"
              className={this.radioButtonClass(viewsOnly)}
            ></label>
            <span style={{ marginRight: "16px" }}>Views only</span>
            <Input
              id="leads_filter_selected_website_history_filters_favorites_only"
              name="leads_filter[selected_website_history_filters][favorites_only]"
              onClick={() => this.toggleButtons("favorites")}
              value={favoritesOnly}
            />
            <label
              htmlFor="leads_filter_selected_website_history_filters_favorites_only"
              className={this.radioButtonClass(favoritesOnly)}
            ></label>
            <span>Favorites only</span>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <label>Min Price</label>
            <Input
              name="leads_filter[selected_website_history_filters][min_price]"
              value={minPrice}
              onChange={this.handleMinPriceChange}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <label>Max Price</label>
            <Input
              name="leads_filter[selected_website_history_filters][max_price]"
              value={maxPrice}
              onChange={this.handleMaxPriceChange}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <label>Bedrooms</label>
              </Col>
              <Col xs={12}>
                <div className="range-dropdowns">
                  <Dropdown
                    name="leads_filter[selected_website_history_filters][min_bedrooms]"
                    options={dropDownMinMax}
                    placeholder="No Min"
                    value={minBedrooms}
                    onUpdate={this.handleMinBedroomsChange}
                    isClearable={true}
                  />
                  <div className="range-separator"></div>
                  <Dropdown
                    name="leads_filter[selected_website_history_filters][max_bedrooms]"
                    options={dropDownMinMax}
                    placeholder="No Max"
                    value={maxBedrooms}
                    onUpdate={this.handleMaxBedroomsChange}
                    isClearable={true}
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <label>Bathrooms</label>
              </Col>
              <Col xs={12}>
                <div className="range-dropdowns">
                  <Dropdown
                    name="leads_filter[selected_website_history_filters][min_bathrooms]"
                    options={dropDownMinMax}
                    placeholder="No Min"
                    value={minBathrooms}
                    onUpdate={this.handleMinBathroomsChange}
                    isClearable={true}
                  />
                  <div className="range-separator"></div>
                  <Dropdown
                    name="leads_filter[selected_website_history_filters][max_bathrooms]"
                    options={dropDownMinMax}
                    placeholder="No Max"
                    value={maxBathrooms}
                    onUpdate={this.handleMaxBathroomsChange}
                    isClearable={true}
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <div>
          <Col xs={12}>
            <FormGroup style={{ position: "relative" }}>
              <label>Location</label>
              <Input
                autoComplete="no"
                name="leads_filter[selected_website_history_filters][location][]"
                placeholder="Search by City or Zip"
                onFocus={this.toggleActive}
                onBlur={this.toggleActive}
                onChange={(e) => this.fetchResults(e.target.value)}
                onKeyDown={this.handleKeyDown}
                value={locationValue}
              />
              {cityStateActive && (
                <div id="city-zip-results">
                  <div className="result-children">
                    {searchResults.hasOwnProperty("CityState") && (
                      <LocationResults
                        locationType="CityState"
                        cursorCategory={cursorCategory}
                        searchResults={searchResults}
                        cursor={cursor}
                        handleHover={this.handleHover}
                        handleSelect={this.handleSelect}
                      />
                    )}
                    {searchResults.hasOwnProperty("Zip Code") && (
                      <LocationResults
                        locationType="Zip Code"
                        cursorCategory={cursorCategory}
                        searchResults={searchResults}
                        cursor={cursor}
                        handleHover={this.handleHover}
                        handleSelect={this.handleSelect}
                      />
                    )}
                  </div>
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12}>
            <div className="filter-checkboxes">
              {city.map((city) => (
                <LocationBubble key={city} type="city" location={city} removeLocation={this.removeLocation} />
              ))}
              {zip.map((zip) => (
                <LocationBubble key={zip} type="zip" location={zip} removeLocation={this.removeLocation} />
              ))}
            </div>
          </Col>
        </div>
        <Col xs={12}>
          <hr />
        </Col>
      </div>
    );
  }
}
export default WebsiteSearchHistoryFilter;
