import { OWNER_BRIVITY_RESOURCES, OWNER_PLACE_RESOURCES, AGENT_RESOURCES } from "./constants";

export const isPlace = (theme) => theme === "place-theme";

export const setResources = ({ theme, isAdmin, isOwner }) => {
  if ((isAdmin || isOwner) && isPlace(theme)) return OWNER_PLACE_RESOURCES;
  if ((isAdmin || isOwner) && !isPlace(theme)) return OWNER_BRIVITY_RESOURCES;

  return AGENT_RESOURCES;
};

export const textColor = (theme) => (isPlace(theme) ? "tw-text-black" : "tw-text-gray-75");
