import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@shared/v2/Tooltip/Tooltip";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import { CaretDownSolidV6 } from "@shared/v2/Icomoon";

const IconDropdown = ({ icon, tooltip, editable, children }) => {
  const [open, setOpen] = React.useState(false);
  const lines = tooltip?.split("\n");
  const isMultiline = lines?.length > 1;
  const wrappedTooltips = isMultiline ? (
    <div className="tw-max-w-[184px]">
      {lines.map((x, i) => (
        <p className="tw-text-12d" key={i}>
          {x}
        </p>
      ))}
    </div>
  ) : (
    <div>{tooltip}</div>
  );

  const popoverCaret = (
    <span className="tw-pl-[4px]">
      <CaretDownSolidV6 className={editable ? "" : "tw-opacity-60"} />
    </span>
  );

  return (
    <>
      <Tooltip
        placement="top"
        multiline={isMultiline}
        content={wrappedTooltips}
        trigger={icon}
        innerClassName="tw-max-w-[150px]"
      />
      {!editable && popoverCaret}
      {editable && (
        <Popover open={open} onOpenChange={setOpen} placement="bottom-start" offset={0}>
          <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => setOpen(false)}
          >
            <PopoverTrigger>{popoverCaret}</PopoverTrigger>
            <PopoverContent>{children}</PopoverContent>
          </div>
        </Popover>
      )}
    </>
  );
};

IconDropdown.propTypes = {
  editable: PropTypes.bool,
  children: PropTypes.func,
  tooltip: PropTypes.string,
  icon: PropTypes.func,
};

IconDropdown.defaultValue = {
  editable: true,
  children: null,
  tooltip: null,
  icon: null,
};

export default IconDropdown;
