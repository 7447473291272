import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";

import { range } from "../../Utilities";
import { useGivenOrGeneratedId } from "../../hookHelpers";
import { CancelConfirm, Display, DisplayEditorToggleBase } from "../InlineEditor";
import TextComboInput from "../TextComboInput";
import TextButton from "../TextButton";
import { resizeInputBasedOnValue } from "../utils";

const InlineEditingInput = ({
  id: idFromProps,
  value: valueFromProps,
  prefix,
  suffix,
  disabled,
  error,
  placeholder,
  externalEditing,
  onInteract,
  onChange,
  onCancelEditing,
  onConfirmEditing,
  leading,
  additionalTrailing,
  withCancelConfirm,
  inputRef,
  spacing,
  className,
  displayClassName,
  editorClassName,
  displayComponent,
  size,
  validateInput,
  formatInput,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("inline-editing-input", idFromProps);

  const value = useMemo(
    () => (typeof valueFromProps === "number" ? valueFromProps.toString() : valueFromProps) || "",
    [valueFromProps],
  );

  const ignoreExternalEdition = [null, undefined].includes(externalEditing);
  const [editing, setEditing] = useState(externalEditing ?? false);
  const [editingValue, setEditingValue] = useState(value);
  const textComboInputRef = inputRef || useRef();

  // We need to keep track of when the user clicked outside, to prevent calling `confirmEditing` twice
  const [clickedOutside, setClickedOutside] = useState(false);

  // Update the editing flag when `externalEditing` changes
  useEffect(() => setEditing(externalEditing ?? false), [externalEditing]);

  // Update the editing value when `value` changes
  useEffect(() => setEditingValue(value), [value]);

  const handleInteract = () => {
    if (onInteract) {
      onInteract();
    }
    if (ignoreExternalEdition) {
      setEditing(true);
    }
  };

  const cancelEditing = () => {
    if (ignoreExternalEdition) {
      setEditing(false);
    }
    setEditingValue(value); // Revert changes made on editing mode
    if (onCancelEditing) {
      onCancelEditing();
    }
  };

  const confirmEditing = () => {
    if (onChange) {
      onChange(formatInput ? formatInput(editingValue) : editingValue);
    }
    if (ignoreExternalEdition) {
      setEditing(false);
    }
    if (onConfirmEditing) {
      onConfirmEditing();
    }
  };

  const focusInput = () => {
    if (!textComboInputRef.current) {
      return;
    }
    const input = textComboInputRef.current;
    input.select();
    input.focus();
  };

  // Auto-focus when switching to editing mode
  useEffect(() => {
    if (!editing) {
      return;
    }
    focusInput();
  }, [editing]);

  // Update input styles when switching to editing mode and/or
  // after the user changes the editing value
  useEffect(() => {
    if (!editing) {
      return;
    }
    resizeInputBasedOnValue(textComboInputRef.current, editingValue || placeholder);
  }, [editing, editingValue, placeholder]);

  const handleKeyDown = (e) => {
    // In case the input is inside of a modal, this prevents closing the modal before closing the input
    e.stopPropagation();

    switch (e.key) {
      case "Esc":
      case "Escape":
        cancelEditing();
        break;
      case "Enter":
        confirmEditing();
        break;
      default:
        break;
    }
  };

  const handleFocusOut = () => {
    if (clickedOutside) {
      // Do not call `confirmEditing` a second time if it was already called from `handleClickOutside`
      setClickedOutside(false);
      return;
    }

    confirmEditing();
  };

  const handleClickOutside = () => {
    setClickedOutside(true);

    confirmEditing();
  };

  const trailing = () => {
    if (!withCancelConfirm) {
      return additionalTrailing || null;
    }

    const cancelConfirm = <CancelConfirm onCancelEditing={cancelEditing} onConfirmEditing={confirmEditing} />;

    return additionalTrailing ? (
      <div className="tw-flex tw-flex-row tw-items-center tw-space-x-[12px]">
        {additionalTrailing}
        {cancelConfirm}
      </div>
    ) : (
      cancelConfirm
    );
  };

  const displayWhenNoValueIsProvided = displayComponent ? (
    displayComponent(handleInteract)
  ) : (
    <TextButton
      data-cy="inline-editor-display-button"
      className={`tw-p-0 ${displayClassName}`}
      onClick={handleInteract}
    >
      add
    </TextButton>
  );

  const display = value ? (
    <Display
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      id={id}
      label={`${prefix}${value}${suffix}`.trim()}
      value={value}
      disabled={disabled}
      onInteract={handleInteract}
      className={displayClassName}
    />
  ) : (
    displayWhenNoValueIsProvided
  );

  const sizeOrPlaceholderLength = () => {
    if (size > 0) {
      return size;
    }
    if (placeholder) {
      return placeholder.length;
    }
    // Invalid size values make the input's width be the default width based on the user agent
    // See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/size
    return -1;
  };

  const handleOnChange = (event) => {
    if (!validateInput) {
      setEditingValue(event.target.value);
      return;
    }

    const { isValid, newValue } = validateInput(event.target.value);

    if (isValid) {
      setEditingValue(newValue);
    }
  };

  const editor = (
    <TextComboInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      inputRef={textComboInputRef}
      id={id}
      value={editingValue}
      leading={leading}
      trailing={trailing()}
      disabled={disabled}
      error={error}
      spacing={spacing}
      placeholder={placeholder}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      onFocusOut={handleFocusOut}
      size={sizeOrPlaceholderLength()}
      className={`tw-shadow-dropdown selection:tw-bg-theme-inline-editing-input ${editorClassName}`}
      data-cy="inline-editor-input"
    />
  );

  return (
    <DisplayEditorToggleBase
      mode={editing ? "editor" : "display"}
      display={display}
      editor={editor}
      onClickedOutside={handleClickOutside}
      className={className}
    />
  );
};

InlineEditingInput.propTypes = {
  id: PropTypes.string,
  value: nullable(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  // To allow changing the `editing` state from the outside,
  // useful for when we don't want the input to close after confirming/canceling (e.g. need to make an external request)
  externalEditing: PropTypes.bool,
  onInteract: PropTypes.func,
  onChange: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onConfirmEditing: PropTypes.func,
  leading: PropTypes.node,
  additionalTrailing: PropTypes.node,
  withCancelConfirm: PropTypes.bool,
  // See https://github.com/facebook/prop-types/issues/240#issue-384666636
  inputRef: PropTypes.oneOfType([
    PropTypes.func, // for legacy refs
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  spacing: PropTypes.oneOf(range(0, 14)),
  className: PropTypes.string,
  displayClassName: PropTypes.string,
  editorClassName: PropTypes.string,
  displayComponent: PropTypes.func,
  size: PropTypes.number,

  // Hooks that allow client code to validate and format text input
  validateInput: PropTypes.func,
  formatInput: PropTypes.func,
};

InlineEditingInput.defaultProps = {
  id: null,
  prefix: "",
  suffix: "",
  disabled: false,
  error: null,
  placeholder: "",
  externalEditing: null,
  onInteract: null,
  onChange: null,
  onCancelEditing: null,
  onConfirmEditing: null,
  leading: null,
  additionalTrailing: null,
  withCancelConfirm: true,
  inputRef: null,
  spacing: 14,
  className: "",
  displayClassName: "",
  editorClassName: "",
  displayComponent: null,
  // Invalid size values make the input's width be the default width based on the user agent
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/size
  size: -1,
  validateInput: null,
  formatInput: null,
};

// Default size for numeric components like Flat, FlatPercent, and Percent
InlineEditingInput.numericDefaultSize = 5;

export default InlineEditingInput;
