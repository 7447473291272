import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import { camelCase } from "lodash";
import format from "@shared/formatters";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Alert from "@shared/v2/Alert";
import Flyout from "@shared/v2/Flyout";
import Avatar from "@shared/v2/Avatar";
import Typography from "@shared/v2/Typography";
import Tag from "@shared/v2/Tag";
import OverviewCard from "./OverviewCard";
import { Table, TBody, TH, THead, TR } from "../BusinessDashboard/Table/Table";
import useOpenHouseDetails from "./hooks/useOpenHouseDetails";
import Loading from "../../../Goals/components/Loader";
import LeadRow from "./LeadRow";

const useOpenHouseLeads = ({ leadPersonUuids }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!leadPersonUuids?.length) {
      setData([]);
      return;
    }
    const abortController = new AbortController();
    setData([]);
    setLoading(true);
    caseTransformingAxios
      .post(
        "/api/v4/reporting/open_house_dashboard/leads",
        { leadPersonUuids },
        {
          signal: abortController.signal,
        },
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setLoading(false);
        setError(true);
      });
  }, [leadPersonUuids]);

  return { data, error, loading };
};

const OpenHouseFlyout = ({ openHouseUuid, onClose, status }) => {
  const {
    data: {
      address,
      attendees,
      hostName,
      instructions,
      leads,
      leadPersonUuids,
      listing,
      startDate,
      startTime,
      endTime,
      tags,
      workingWithAgent,
    },
    error,
    loading,
  } = useOpenHouseDetails({ openHouseUuid });
  const { data: leadList, loading: leadListLoading } = useOpenHouseLeads({ leadPersonUuids });

  return (
    <Flyout show={Boolean(openHouseUuid)} onHide={onClose}>
      <Flyout.Body className="tw-relative tw-bg-white tw-max-w-[908px] tw-w-[100vw] !tw-p-[24px]">
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="tw-flex tw-flex-wrap tw-justify-between tw-align-bottom tw-gap-[24px]">
            <Typography variant="headline3">
              {address
                ? `${address.streetAddress} ${address.city}, ${address.state} ${address.postalCode}`
                : ""}
            </Typography>
            <div className="tw-mr-[32px] tw-text-14d tw-flex tw-gap-[8px] tw-items-end">
              <span
                className={`${status === "completed" ? "tw-text-semantic-green-110" : "tw-text-[#e5941b]"} tw-uppercase tw-font-bold`}
              >
                {status}:
              </span>
              <div className="tw-flex tw-gap-[4px]">
                <Typography variant="subtitle-m-bold" className="!tw-text-gray-50">
                  {moment(startDate).format("ddd. MM/DD/YYYY")}
                </Typography>
                <span className="tw-text-gray-10">|</span>
                <Typography variant="subtitle-m-bold" className="!tw-text-gray-50">
                  {`${moment(startTime, "HH:mm:ss").format("hh:mm a")} - ${moment(endTime, "HH:mm:ss").format("hh:mm a")}`}
                </Typography>
              </div>
            </div>
          </div>
          <div className="tw-relative tw-flex tw-flex-col tw-gap-[16px]">
            <div className="tw-flex tw-gap-[24px]">
              <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
                <div className="tw-flex tw-gap-[8px]">
                  <Avatar size="medium" alt={hostName} />
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-text-14d tw-font-bold">Host</span>
                    <span className="tw-text-12d tw-text-gray-50">{hostName}</span>
                  </div>
                </div>
                <div className="tw-flex tw-gap-[32px]">
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">{listing?.price ? format.currency(listing?.price) : "--"}</Typography>
                    <Typography variant="body-m">Listing Price</Typography>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">{listing?.bedrooms || "--"}</Typography>
                    <Typography variant="body-m">Beds</Typography>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">{listing?.bathsTotalDecimal || "--"}</Typography>
                    <Typography variant="body-m">Baths</Typography>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">{listing?.squareFeet ? listing?.squareFeet.toLocaleString() : "--"}</Typography>
                    <Typography variant="body-m">Sq Ft</Typography>
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-[4px]">
                  <Typography variant="subtitle-s" className="!tw-text-gray-50">
                    Tags
                  </Typography>
                  <div className="tw-flex tw-gap-[8px]">
                    {tags?.map((tag) => (
                      <Tag removable={false}>{tag}</Tag>
                    ))}
                  </div>
                </div>
              </div>

              <div className="tw-flex-1 tw-flex tw-items-stretch tw-gap-[16px]">
                {status === "completed" && (
                  <>
                    <div className="tw-flex-1 tw-border-solid tw-border-[1px] tw-border-gray-10 tw-rounded-[8px] tw-p-[8px]">
                      <OverviewCard
                        description="Total number of open house visitors"
                        title="Attendees"
                        value={attendees || 0}
                      />
                    </div>
                    <div className="tw-flex-1 tw-border-solid tw-border-[1px] tw-border-gray-10 tw-rounded-[8px] tw-p-[8px]">
                      <OverviewCard
                        description="Attendees who signed-in with the app"
                        title="Leads"
                        value={leads || 0}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {instructions && (
              <div className="tw-flex tw-flex-col">
                <Typography variant="subtitle-s" className="!tw-text-gray-50">
                  Notes
                </Typography>
                <Typography variant="body-s">{instructions}</Typography>
              </div>
            )}

            {loading && <Loading />}
          </div>

          {status === "completed" && (
            <div className="tw-relative tw-min-h-[150px]">
              <Table>
                <THead>
                  <TR className="tw-border-x-0">
                    <TH className="tw-border-l-0 tw-py-[8px] tw-font-semibold">Name</TH>
                    <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Intent</TH>
                    <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Agent</TH>
                    <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Status</TH>
                    <TH className="tw-py-[8px] tw-font-semibold">Last Interaction</TH>
                    <TH className="tw-py-[8px]" />
                  </TR>
                </THead>
                <TBody>
                  {leadList.map((lead) => (
                    <LeadRow
                      key={lead.uuid}
                      lead={lead}
                      hasAgent={workingWithAgent?.[camelCase(lead.uuid)]}
                    />
                  ))}
                </TBody>
              </Table>

              {leadListLoading && <Loading />}
            </div>
          )}

          {error && (
            <Alert variant="error" text="Whoops something went wrong loading the details, try again later." />
          )}
        </div>
      </Flyout.Body>
    </Flyout>
  );
};

OpenHouseFlyout.propTypes = {
  openHouseUuid: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

OpenHouseFlyout.defaultProps = {
  openHouseUuid: null,
};

export default OpenHouseFlyout;
