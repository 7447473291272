/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const CHAR_COUNT = 1; // constant for minimum required characters to fetch search results.

const search = () => {
  return {
    type: 'FETCH_RESULTS',
    loading: true,
  }
}

const setSearchTerm = (term) => {
  return {
    type: 'UPDATE_SEARCH_TERM',
    searchValid: term.length >= CHAR_COUNT,
    searchTerm: term
  }
}

const searchSuccess = (results) => {
  const hasResults = results.length >= 1;
  return {
    type: 'SEARCH_SUCCESS',
    results,
    loading: false,
    hasResults
  }
}

export const fetchResults = (term) => {
  return (dispatch, getState) => {
    dispatch(setSearchTerm(term))
    if (term) {
      dispatch(search())
      let encodedTerm = term.replace('+', '%2B')
      axios(`/accounts/search?search=${encodedTerm}`)
        .then(response => {
          const { searchTerm } = getState().quickSearch
          if (searchTerm == term) {
            dispatch(searchSuccess(response.data))
          }
        })
    }
  }
}

export const fetchPeopleResults = (term) => {
  return dispatch => {
    dispatch(setSearchTerm(term))
    if (term) {
      dispatch(search())
      let encodedTerm = term.replace('+', '%2B')
      axios(`/search/account_people?search=${encodedTerm}`)
        .then(response => dispatch(searchSuccess(response.data)))
    }
  }
}
