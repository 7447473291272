import React from "react";
import capitalize from "capitalize";

const LocationBubble = (props) => {
  const { location, type, removeLocation } = props;

  return (
    <div className="location-bubble">
      <input
        className="filter-bubble-checkbox"
        type="checkbox"
        name={`leads_filter[selected_website_history_filters][${type}][]`}
        id={`leads_filter_selected_website_history_filters_${type}_${location}`}
        value={location}
        checked="checked"
      />
      <div className="removable-bubble">
        <span>{capitalize(type)}: </span>
        <label
          className="info-label"
          htmlFor={`leads_filter_selected_website_history_filters_${type}_${location}`}
        >
          {location}
        </label>
        <i onClick={() => removeLocation(type, location)} className="fa fa-remove"></i>
      </div>
    </div>
  );
};

export default LocationBubble;
