import React, { useEffect, useState } from "react";
import { shape, string, bool, func, number, oneOfType, arrayOf } from "prop-types";

import { patchNoteAsThunk } from "../actions/thunks";
import { setEditNoteModal } from "../actions/creators";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import MentionablePersonTextArea from "../../../../shared/mentions/MentionablePersonTextArea";
import ErrorBanner from "../../../../shared/v2/ErrorBanner";
import { tdpDetails, tdpNotes } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID, NOTABLEID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { EDITNOTE, ERRORS, ISEDITNOTEMODALOPEN, NOTES } from "../../../../shared/reduxRoutes/tdpNotesRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

const EditNoteModal = ({
  note,
  onPatchNoteAsThunk,
  uuid,
  errors,
  notableId,
  isEditNoteModalOpen,
  onSetEditNoteModal,
}) => {
  const [noteContent, setNoteContent] = useState("");

  useEffect(() => {
    setNoteContent(note?.markdown_content);
  }, [note]);

  return (
    <div>
      <Modal
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[640px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
        show={isEditNoteModalOpen}
        onHide={() => onSetEditNoteModal(false)}
        closeOnClickOutside={false}
      >
        <Modal.Header closeButton className="tw-mb-[32px]">
          <div className="!tw-mt-[-28px] tw-text-[28px] tw-font-normal tw-leading-9 tw-tracking-normal tw-text-left tw-text-gray-75">
            Edit Note
          </div>
        </Modal.Header>
        <Modal.Body className="tw-flex tw-flex-col tw-overflow-hidden">
          {errors && <ErrorBanner errors={errors} />}
          <div className="tw-flex-1 tw-overflow-auto">
            <MentionablePersonTextArea
              value={noteContent}
              mentionable_id={notableId}
              mentionable_type="Listing"
              onChange={setNoteContent}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="tw-mt-[16px] tw-flex tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={() => onSetEditNoteModal(false)}>
            Cancel
          </Button>
          <Button
            size="medium"
            schema="primary"
            onClick={() => onPatchNoteAsThunk({ ...note, content: noteContent }, uuid)}
            disabled={!noteContent?.length}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const withTdpDetails = tdpDetails({
  WrappedComponent: EditNoteModal,
  states: [LISTING_UUID, NOTABLEID],
});

const withTdpNotes = tdpNotes({
  WrappedComponent: withTdpDetails,
  states: [EDITNOTE, NOTES, ERRORS, ISEDITNOTEMODALOPEN],
  aliases: ["note"],
});

export default withDispatch({
  WrappedComponent: withTdpNotes,
  actions: [patchNoteAsThunk, setEditNoteModal],
  aliases: ["onPatchNoteAsThunk", "onSetEditNoteModal"],
});

EditNoteModal.propTypes = {
  uuid: string.isRequired,
  note: shape({
    id: number,
  }),
  isEditNoteModalOpen: bool,
  onPatchNoteAsThunk: func.isRequired,
  errors: oneOfType([arrayOf(string), string]),
  notableId: number,
  onSetEditNoteModal: func,
};

EditNoteModal.defaultProps = {
  note: {
    id: "",
  },
  isEditNoteModalOpen: false,
  errors: "",
  notableId: "",
  onSetEditNoteModal: () => {},
};
