import React from 'react';
import axios from 'axios';
import { Modal, Row, Col } from 'react-bootstrap';
import OtherInteractIcon from './OtherInteractIcon';
import OtherInteractForm from './OtherInteractForm';
import HeaderTitle from '../../PersonDetail/components/modals/HeaderTitle';
class OtherInteract extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      interaction: this.props.otherInteract.interaction,
      hasError: false,
      errors: []
    }
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  saveOther = () => {
    const { interaction } = this.state;
    const path = `/people/${interaction.person_id}/interactions`;
    axios
      .post(path, { interaction: { ...interaction }, authenticity_token: ReactOnRails.authenticityToken() })
      .then(() => window.location.reload())
      .catch(err => this.setState({ hasError: true, errors: err.response.data.content })
      )
  }

  updateValues = (name, value) => {
    this.setState({
      interaction: { ...this.state.interaction, [name]: value }
    })

  }

  renderOtherInteractModal = () => {
    const { interaction, hasError, errors } = this.state;
    const { person, personAvatar } = this.props.otherInteract

    return <Modal
      id="other-interaction-modal"
      className='modal'
      backdrop='static'
      show={this.openModal}
      onHide={this.closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          <HeaderTitle title="Interaction" onClose={this.closeModal} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OtherInteractForm
          person={person}
          updateValues={this.updateValues}
          interaction={interaction}
          personAvatar={personAvatar}
          errors={hasError && errors}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className='modal-cancel pull-left'
          onClick={this.closeModal}>CANCEL</button>
        <button
          className='modal-submit'
          onClick={this.saveOther}>SAVE</button>
      </Modal.Footer>
    </Modal>
  }
  render() {
    return (
      <div className='modal-container'>
        <OtherInteractIcon
          handleClick={() => this.openModal()} />
        {this.state.modalOpen && this.renderOtherInteractModal()}
      </div>
    )
  }
}

export default OtherInteract;
