import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TrashSolidV6, ChevronRightSolidV6 } from "../../shared/v2/Icomoon";
import IconButton from "../../shared/v2/IconButton/IconButton";
import { isSmartFilters } from "../helpers/menu";
import ICON_MAP from "./helpers/icons";
import ACTION_MAP from "./ActionModals";
import useSimpleNavigation from "../../shared/v2/useSimpleNavigation";
import { toggleMobileMenu } from "../../reducers/layoutReducer";

const BUTTON_CLASSES = {
  active: {
    "brivity-theme": "tw-text-brivity-blue-dark tw-bg-[rgba(89,196,196,0.15)] tw-border-none",
    "place-theme":
      "tw-bg-white/10 tw-border-solid tw-border-[1px] tw-border-white/25 hover:tw-border-solid hover:tw-border-[1px]",
  },
  base: {
    "brivity-theme":
      "hover:tw-bg-black/5 hover:tw-text-brivity-blue-dark focus:tw-bg-black/5 focus:tw-text-brivity-blue-dark active:tw-bg-black/10 active:tw-text-brivity-blue-dark",
    "place-theme":
      "tw-text-white hover:tw-text-semantic-blue-25 focus:tw-text-semantic-blue-25 hover:tw-bg-white/10 focus:tw-bg-white/10 active:tw-bg-white/15",
  },
};

const MenuOption = ({
  className,
  counts,
  option,
  Menu,
  title,
  isEditing,
  isMobile,
  submenu,
  onBack,
  onClick,
  onDelete,
}) => {
  const simpleNav = useSimpleNavigation();
  const dispatch = useDispatch();
  const theme = useSelector(({ layout }) => layout.theme);
  const { active, name, href, target, countIdentifier, options, deletePath, push } = option;
  // TODO: this is a stop-gap, use react-router when we have a SPA
  const isPushable = href?.startsWith(simpleNav.pathname);
  const element = { type: "a" };
  const Icon = ICON_MAP[name];
  const Action = ACTION_MAP[name];
  if (push || options) element.type = "div";

  useEffect(() => {
    if (!isMobile && !isSmartFilters(title) && options?.some((o) => o.active)) onClick?.();
  }, [options, title]);

  if (!options && !href) return null;

  return (
    <Fragment key={name}>
      <element.type
        href={href}
        tabIndex={submenu ? -1 : undefined}
        {...(element.type === "div" && { role: "button", tabIndex: 0 })}
        className={`tw-transition-colors tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-[4px] tw-px-[8px] tw-py-[6px] ${
          isMobile
            ? "tw-min-h-[48px] tw-leading-[24px] tw-text-18d tw-text-neutral-gray-75"
            : "tw-min-h-[32px] tw-leading-[18px] tw-text-14d"
        } tw-flex-[1_0_auto] tw-rounded-[8px] tw-outline-none hover:tw-no-underline focus:tw-no-underline ${
          isEditing ? "tw-items-center" : ""
        } ${BUTTON_CLASSES.base[theme.name]} ${
          (!push && active) || href?.endsWith(simpleNav.fullPath)
            ? BUTTON_CLASSES.active[theme.name]
            : "tw-border-none tw-bg-transparent"
        } ${className}`}
        target={target}
        onClick={(e) => {
          if (isEditing) e.preventDefault();
          if (push) {
            if (isPushable) simpleNav.push(href);
            else window.location.assign(href);
          }
          if (options) onClick?.();
        }}
      >
        <span className="tw-flex tw-items-center tw-gap-[12px]">
          {isMobile && className && Icon && <Icon size="xl" />}
          {name}
          {isMobile && options && <ChevronRightSolidV6 size="l" />}
        </span>
        <span>
          {countIdentifier ? counts[countIdentifier] : ""}
          {!isMobile && options && <ChevronRightSolidV6 size="s" />}
          {isEditing && deletePath && (
            <IconButton
              size="small"
              schema="tertiary"
              className="tw-mr-[-8px]"
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
            >
              <TrashSolidV6 />
            </IconButton>
          )}
          {isMobile && className && Action && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              onClick={(e) => {
                // prevent modal action from triggering button/link
                e.stopPropagation();
                dispatch(toggleMobileMenu(false));
              }}
            >
              <Action />
            </div>
          )}
        </span>
      </element.type>

      {options && Menu && (
        <div
          className={`tw-absolute tw-w-full tw-left-[calc(100%+12px)] tw-h-full tw-top-0 ${
            submenu === name ? "" : "tw-invisible"
          }`}
        >
          <Menu
            title={name}
            backTitle={title}
            onBack={() => onBack?.()}
            options={options}
            counts={counts}
            theme={theme}
          />
        </div>
      )}
    </Fragment>
  );
};

MenuOption.propTypes = {
  className: PropTypes.string,
  counts: PropTypes.shape(),
  option: PropTypes.shape().isRequired,
  Menu: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isEditing: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  title: PropTypes.string,
  submenu: PropTypes.string,
  onBack: PropTypes.func,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

MenuOption.defaultProps = {
  className: "",
  counts: {},
  Menu: null,
  onDelete: undefined,
  isEditing: false,
  submenu: null,
  onBack: null,
  onClick: null,
  title: null,
};

export default MenuOption;
