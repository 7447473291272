import React from "react";

const OverviewCard = ({ centered, description, title, value }) => (
  <div
    className={`tw-flex-1 tw-flex tw-flex-col ${centered ? "tw-items-center" : ""} tw-gap-[12px] tw-px-[8px]`}
  >
    <div className="tw-text-28d tw-font-semibold tw-text-theme-primary-alternative">{value}</div>
    <div className="tw-text-14d tw-font-bold">{title}</div>
    <hr className="tw-m-0 tw-w-full tw-border-solid" />
    <div className="tw-text-12d tw-font-semibold tw-text-gray-50">{description}</div>
  </div>
);

export default OverviewCard;
