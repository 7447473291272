import { useEffect, useState } from "react";

import { getCallLogs, getLogCount } from "./services";
import { getTrackingNumberIds } from "./helpers";
import formatPhoneNumber from "../../shared/FormatPhoneNumber";
import { format, parseISO } from "date-fns";

export default ({ trackingNumbers, selectedTrackingNumbers, minDate, maxDate, offset, logFilters, sort }) => {
  const [callLogs, setCallLogs] = useState([]);
  const [logsCount, setLogsCount] = useState(0);

  const limit = 25;

  useEffect(() => {
    const load = async () => {
      const results = await getCallLogs(
        getTrackingNumberIds(selectedTrackingNumbers, trackingNumbers),
        minDate,
        maxDate,
        sort[0],
        sort[1],
        offset,
        limit,
        logFilters,
      );

      setCallLogs(mapCallLogs(results));
    };

    load();
  }, [sort, selectedTrackingNumbers, minDate, maxDate, offset, logFilters]);

  useEffect(() => {
    const load = async () => {
      const logCount = await getLogCount(
        getTrackingNumberIds(selectedTrackingNumbers, trackingNumbers),
        minDate,
        maxDate,
        logFilters,
      );
      setLogsCount(logCount);
    };

    load();
  }, [selectedTrackingNumbers, minDate, maxDate, logFilters]);

  return { callLogs, logsCount };
};

const mapCallLogs = (callLogs) => {
  return callLogs?.map((log) => {
    // This avoids time zone conversions to the wrong date
    const createdAtWithoutTime = log.created_at.substring(0, 10);
    let outcome = "Missed";

    if (log.voicemail_url !== null) {
      outcome = "Voicemail";
    } else if (log.outcome === "completed") {
      outcome = "Talked";
    }

    return {
      callLogId: log.id,
      answererFullName: log.answerer_full_name,
      answererAvatarUrl: log.answerer_avatar_url?.length > 3 ? log.answerer_avatar_url : null,
      callerPhone: formatPhoneNumber(log.caller_phone),
      callerFullName: log.caller_full_name,
      callerUrl: log.caller_url,
      outcome: outcome,
      duration: log.duration,
      createdAt: format(parseISO(createdAtWithoutTime), "MM/dd/yyyy"),
      trackingNumberName: log.tracking_number.name,
      isNumberDeleted: !!log.tracking_number.deleted_at,
      voicemailUrl: log.voicemail_url,
    };
  });
};
