import React from "react";
import Tooltip from "../../shared/v2/Tooltip/Tooltip";
import IconButton from "@shared/v2/IconButton";
import { EllipsisSolidV6 } from "@shared/v2/Icomoon";

const OtherInteractIcon = (props) => {
  return (
    <Tooltip
      placement="right"
      trigger={
        <IconButton aria-label="other interaction" onClick={props.handleClick}>
          <EllipsisSolidV6 />
        </IconButton>
      }
      content="Other"
    />
  );
};

export default OtherInteractIcon;
