import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sortListByKey } from ".";
import { getServiceAreaOptionsByInput } from "../api";
import { setCrmAccessModal } from "../actions/default";

// UserWithoutFeatureFlag > Users > helper for isFilterActiveUsers
export const useIsFilterActiveUsers = (isFilterActiveUsers, setAgents, activeAgents, deactiveAgents) => {
  useEffect(() => {
    if (isFilterActiveUsers) {
      setAgents(activeAgents);
    } else {
      setAgents(deactiveAgents);
    }
  }, [isFilterActiveUsers]);
};
// UserWithoutFeatureFlag > Users > helper for filterSearchText
export const useFilterSearchText = (
  filterSearchText,
  setAgents,
  isFilterActiveUsers,
  activeAgents,
  deactiveAgents,
) => {
  useEffect(() => {
    if (filterSearchText === "") {
      setAgents(isFilterActiveUsers ? activeAgents : deactiveAgents);
    } else {
      const lowerCaseSearchText = filterSearchText.toLowerCase();
      setAgents(() => {
        const curAgents = isFilterActiveUsers ? activeAgents : deactiveAgents;
        return curAgents.filter(
          (agent) =>
            agent.fullName.toLowerCase().includes(lowerCaseSearchText) ||
            agent.email.includes(lowerCaseSearchText),
        );
      });
    }
  }, [filterSearchText]);
};
// for user Table sort column
export const useCurrentSortListByKey = (currentSort, setAgents) => {
  useEffect(() => {
    sortListByKey(currentSort.key, setAgents, currentSort.isAsc);
  }, [currentSort]);
};

// use for serviceArea
export const useGetServiceAreaOptions = (serviceAreaInput, setServiceAreaOptions) => {
  useEffect(() => {
    // Update debounced function after 500 ms
    const handler = setTimeout(() => {
      if (serviceAreaInput.length > 0) {
        getServiceAreaOptionsByInput(serviceAreaInput).then((res) =>
          setServiceAreaOptions(res.data ? res.data : []),
        );
      } else {
        setServiceAreaOptions([]);
      }
    }, 500);
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [serviceAreaInput]);
};

// use for Address, email, and Phone category options
export const useFilterListOptions = (typeOptions, setOptions, triggerList, matchKeyName) => {
  useEffect(() => {
    setOptions([
      ...typeOptions.map((option) => {
        const newOption = {};
        if (
          triggerList.find((item, index) => {
            // eslint-disable-next-line no-underscore-dangle
            if (item._destroy) return false;
            if (item[matchKeyName] === option.value) {
              newOption.index = index;
              newOption.isTaken = true;
            }
            return item[matchKeyName] === option.value;
          })
        ) {
          return { ...option, ...newOption };
        }
        return option;
      }),
      { label: "Other", value: "other", isTaken: false, index: null },
    ]);
  }, [triggerList]);
};

export const useSetCrmModal = () => {
  const dispatch = useDispatch();

  return (bool, userId = null, personId = null) => dispatch(setCrmAccessModal(bool, userId, personId));
};
