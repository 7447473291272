import React, { Component } from "react";
import axios from "axios";
import Proptypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import debounce from "lodash/debounce";
import Input from "../shared/Input";
import formCss from "../PersonDetail/components/form.module.css";
import modalCss from "../PersonDetail/components/modal.module.css";
import transactionCss from "../PersonDetail/components/transactions.module.css";
import ListingPreviewCard from "./ListingPreviewCard";

class TransactionAutocomplete extends Component {
  debounceSearchAddress = debounce(async (term) => {
    await this.searchAddress(term);
  }, 150);

  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      currentChildPosition: 0,
      currentTerm: "",
    };
  }

  handleHover = (currentChildPosition) => {
    this.setState({ currentChildPosition });
  };

  selectListing = async (term) => {
    this.setState({ searchResults: [], currentChildPosition: 0 });

    const { selectListing } = this.props;
    await selectListing(term);
  };

  searchAddress = async (term) => {
    if (!term.length) {
      return;
    }
    const { mlsIds } = this.props;
    const idsArrayQuery = mlsIds.map((id) => `mlsIds[]=${id}`).join("&");
    const SearchAddress = this.doSearchAddress(idsArrayQuery, term);
    this.setState({ currentTerm: term }, async () => SearchAddress);
  };

  doSearchAddress = async (idsArrayQuery, term) => {
    const response = await axios.get(`/autocomplete?${idsArrayQuery}&term=${term}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const { currentTerm } = this.state;
    // This happens if a previous query returned later than a later query
    if (currentTerm !== term) {
      return;
    }

    const searchResults = response.data.map((d) => ({ key: d.selected_fields.id, value: d.value }));

    this.setState({ searchResults });
  };

  handleKeyDown = async (e) => {
    const { searchResults, currentChildPosition, currentTerm } = this.state;

    // Enter key
    if (e.keyCode === 13) {
      e.preventDefault();
      await this.selectListing(searchResults[currentChildPosition]);
    } else if (e.keyCode === 38) {
      // Navigate up
      if (currentChildPosition > 0) {
        this.setState((prevState) => ({ currentChildPosition: prevState.currentChildPosition - 1 }));
      }
    } else if (e.keyCode === 40) {
      // Navigate down
      if (currentChildPosition < searchResults.length - 1) {
        this.setState((prevState) => ({ currentChildPosition: prevState.currentChildPosition + 1 }));
      }
    } else if (e.keyCode === 8 && currentTerm.length === 1) {
      // Removing last character with backspace
      this.setState({
        currentChildPosition: 0,
        searchResults: [],
      });
    }
  };

  render() {
    const { selectedBlossorId, listingData, clearListing } = this.props;
    const { searchResults, currentChildPosition } = this.state;

    return (
      <div>
        <Row>
          <Col xs={12} className={modalCss.mB24}>
            <p>Import a listing or fill out the information below manually.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="formControl" className={formCss.formLabel2}>
              IMPORT LISTING DETAILS
            </label>
            {!selectedBlossorId && (
              <Input
                autoComplete="no"
                type="text"
                className={formCss.formControl}
                name="address"
                onChange={(e) => this.debounceSearchAddress(e.target.value)}
                onKeyDown={this.handleKeyDown}
                placeholder="Search for a listing address to auto-populate the information below"
              />
            )}
            {selectedBlossorId && (
              <ListingPreviewCard listingData={listingData} clearListing={clearListing} />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-group">
            {searchResults.length > 0 && (
              <div className={transactionCss.searchResults}>
                <div className={transactionCss.resultChildren}>
                  {searchResults.map((result, index) => (
                    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                    <div
                      key={result.key}
                      className={
                        currentChildPosition === index
                          ? `${transactionCss.resultItem} ${transactionCss.highlight}`
                          : transactionCss.resultItem
                      }
                      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                      onMouseOver={() => this.handleHover(index)}
                      // eslint-disable-next-line  no-return-await
                      onMouseDown={async () => await this.selectListing(result)}
                      role="button"
                      tabIndex={0}
                    >
                      {result.value}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

TransactionAutocomplete.propTypes = {
  selectedBlossorId: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  mlsIds: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  listingData: Proptypes.shape({}),
  clearListing: Proptypes.func,
  selectListing: Proptypes.func,
};

TransactionAutocomplete.defaultProps = {
  selectedBlossorId: null,
  listingData: null,
  clearListing: null,
  mlsIds: null,
  selectListing: null,
};

export default TransactionAutocomplete;
