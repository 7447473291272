import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import AiAssistant from "@shared/AiAssistant";
import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import { getPostJsonHeaders } from "../../shared/axiosHelpers";
import EmailAutocomplete from "./EmailAutocomplete";
import EmailAutocompleteResults from "./EmailAutocompleteResults";
import RemovableBubble from "../../shared/RemovableBubble";
import EmailBodyProperties from "./EmailTemplates/EmailBodyProperties";
import emailBody from "./EmailTemplates/emailBody";
import { getDetailsUrls, getDetailsUrlsWithLogin, getSearchUrl, getSearchUrlWithLogin } from "./service";

const EmailModal = (props) => {
  const defaultSubject = () => {
    const plural = props.listings.length !== 1;
    return `${props.listings.length} new ${plural ? "properties" : "property"} you may be interested in`;
  };

  const defaultEmailMessage = () => {
    const plural = props.listings.length !== 1;
    return `Hi {{recipient_first_name}}, here ${
      plural ? "are some listings" : "is a listing"
    } that I think you'll like. If you want to schedule a showing or have questions, please call or text me at ${
      props.sender.phoneNumber
    }.`;
  };

  const [subject, setSubject] = useState(defaultSubject());
  const [recipients, setRecipients] = useState(props.selectedRecipients || []);
  const [term, setTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [childPosition, setChildPosition] = useState(0);
  const [message, setMessage] = useState(defaultEmailMessage());
  const [sendMeACopy, setSendMeACopy] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [previewListings, setPreviewListings] = useState([]);
  const [searchUrl, setSearchUrl] = useState("");

  useEffect(() => {
    const get = async () => {
      const url = await getSearchUrl();
      setSearchUrl(url);
    };

    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      const blossorIds = props.listings.map((l) => l.blossorId);
      const detailsUrls = await getDetailsUrls(blossorIds);

      const listingsWithUrls = listingsWithDetailsUrl(props.listings, detailsUrls);
      setPreviewListings(listingsWithUrls);
    };

    get();
  }, [props.listings]);

  const selectResult = (result) => {
    setSearchResults([]);
    setChildPosition(0);

    setTerm("");

    if (!recipients.some((r) => r.email === result.email)) {
      setRecipients([...recipients, result]);
    }
  };

  const sendEmail = async () => {
    setIsSending(true);

    // TODO: Manuel will add support for a bulk lead + bulk blossorId route
    // which will let us replace this loop too
    // This is particularly important for the person index which calls this for up to 50 people
    for (let i = 0; i < recipients.length; i++) {
      const personId = recipients[i].personId;
      const sendCopy = sendMeACopy && i === 0;
      const blossorIds = props.listings.map((l) => l.blossorId);

      const [detailsUrls, searchUrlWithLogin] = await Promise.all([
        getDetailsUrlsWithLogin(personId, blossorIds),
        getSearchUrlWithLogin(personId),
      ]);

      const emailBodyPropertiesHtml = ReactDOMServer.renderToStaticMarkup(
        <EmailBodyProperties
          listings={listingsWithDetailsUrl(props.listings, detailsUrls)}
          idxDomain={searchUrlWithLogin}
          message={message}
          sender={props.sender}
          brokerImage={props.brokerImage}
        />,
      );

      const body = emailBody(emailBodyPropertiesHtml);
      await doSendEmail(body, personId, sendCopy);
    }

    setIsSending(false);
    props.onClose();
  };

  const doSendEmail = async (body, personId, sendCopy) => {
    const url = "/interactions/email_all";
    const data = {
      message: {
        // Controller wants this as a comma-delimited string
        recipient_ids: personId,
        send_me_a_copy: sendCopy === true ? "1" : "0",
        user_submitted: "true",
        body: body,
        subject: subject,
      },
    };

    await axios.post(url, data, getPostJsonHeaders());
  };

  return (
    <Modal
      id="listing-search-email-modal"
      className="search-listing-email-modal tw-flex tw-justify-center tw-items-center"
      show={true}
      onHide={props.onClose}
    >
      <Modal.Header closeButton title="Interaction: Email" />
      <Modal.Body className="tw-max-h-[436px] tw-overflow-y-auto tw-my-[32px]">
        <div className="tw-flex tw-flex-col tw-gap-[32px]">
          <div>
            <label className="tw-text-14d tw-text-gray-50 tw-font-semibold tw-mb-[8px]">Send To</label>
            <EmailAutocomplete
              selectResult={selectResult}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              childPosition={childPosition}
              setChildPosition={setChildPosition}
              term={term}
              setTerm={setTerm}
              placeholder={"Search by name or email address"}
            />
            <EmailAutocompleteResults
              searchResults={searchResults}
              selectResult={selectResult}
              setSearchResults={setSearchResults}
              childPosition={childPosition}
              setChildPosition={setChildPosition}
            />
            <div>
              <div className="tw-flex tw-flex-wrap">
                {recipients.map((recipient) => (
                  <RemovableBubble
                    value={recipient.fullName}
                    key={recipient.email}
                    remove={() => setRecipients(recipients.filter((r) => r.email !== recipient.email))}
                  />
                ))}
              </div>
              {recipients.length >= 2 && (
                <p className="tw-text-gray-50 tw-text-12d">
                  Your mass email will be sent to each contact as an individual conversation.
                </p>
              )}
            </div>
          </div>
          <TextInput
            label="Subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className=""
            placeholder={defaultSubject()}
          />
          <div>
            <div className="tw-flex tw-gap-[8px] tw-justify-between tw-items-center tw-mb-[8px]">
              <label className="tw-text-14d tw-text-gray-50 tw-font-semibold tw-mb-0">Message</label>
              <AiAssistant textareaId="share-listing-message" onInsertClick={(text) => setMessage(text)} />
            </div>
            <textarea
              id="share-listing-message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className="tw-w-full tw-h-[118px] tw-block tw-border tw-border-solid tw-border-gray-15 tw-rounded-2px tw-px-12px tw-py-8px tw-bg-tinted-gray-50"
            />
          </div>
          <EmailBodyProperties
            listings={previewListings}
            idxDomain={searchUrl}
            message={message}
            sender={props.sender}
            brokerImage={props.brokerImage}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-gap-[8px] tw-justify-between">
          <Button onClick={props.onClose} size="medium" schema="tertiary" disabled={isSending}>
            Cancel
          </Button>

          <Button
            onClick={() => sendEmail()}
            disabled={!recipients.length}
            size="medium"
            isLoading={isSending}
          >
            Send Email
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailModal;

const listingsWithDetailsUrl = (listings, detailsUrls) => {
  return listings.map((listing) => {
    const listingUrl = detailsUrls.find((d) => d.blossorId === listing.blossorId)?.url;
    return {
      ...listing,
      listingUrl,
    };
  });
};
