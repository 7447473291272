import React, { Component } from "react";
import { Dropdown } from "../../../shared/v1";

class DateRangeDropdown extends Component {
  render() {
    return (
      <div>
        <hr
          style={{
            height: "2px",
            color: "#F3F3F3",
            backgroundColor: "#F3F3F3",
            margin: "0.8em 0",
          }}
        />
        <label
          style={{
            letterSpacing: "0.2px",
            fontSize: "14px",
            fontFamily: "Open Sans",
          }}
        >
          <b>CLOSE DATE RANGE</b>
        </label>
        <Dropdown
          options={this.props.dateOptions}
          onChange={(date) => this.props.handleDateSelect(date)}
          value={this.props.selectedDate}
          isClearable={false}
          variant="flatGray"
        />
      </div>
    );
  }
}

export default DateRangeDropdown;
