import React, { Component } from "react";
import { Dropdown } from "../../shared/v1";
import button from "./button.module.css";
import Button from "@shared/v2/Button";

class AddTag extends Component {
  state = {
    addingTag: false,
    newTagId: null,
    newTagText: null,
    tagOptions: JSON.parse(JSON.stringify(this.props.person)).meta?.tag_options || [],
  };

  // TODO: refactor this in PersonDetail, maybe everywhere
  // https://hackernoon.com/replacing-componentwillreceiveprops-with-getderivedstatefromprops-c3956f7ce607
  componentWillReceiveProps(nextProps) {
    if (this.props.person !== nextProps.person) {
      this.setState({ tagOptions: JSON.parse(JSON.stringify(nextProps.person)).meta.tag_options });
    }
  }

  showNewTagInput = () => {
    this.setState({ addingTag: true });
  };

  onRemoveTagInput = () => {
    this.setState({ addingTag: false });
  };

  onAddNewTag = (id, text) => {
    if (this.state.tagOptions.filter((t) => (t.value === id.length) === 0)) {
      this.setState({ tagOptions: [...this.state.tagOptions, { id: id, name: text }] });
    }
    this.setState({ newTagId: id, newTagText: text });
  };

  onElementSave = () => {
    this.props.addTagToPerson(this.state.newTagText, this.props.person);
    this.setState({ addingTag: false, newTagId: null, newTagText: null });
  };

  onDismissElementEdit = () => {
    this.setState({ addingTag: false });
  };

  formatSelectOptions = (options) => {
    return options
      .map(({ id, name }) => ({ value: id, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  render() {
    return (
      <div style={{ marginTop: "8px" }}>
        {this.state.addingTag && (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div
              className="tw-text-14px"
              style={{ position: "relative", width: "80%", top: "7px", display: "inline-block" }}
            >
              <Dropdown.Creatable
                onChange={(opt) => this.onAddNewTag(opt.value, opt.label)}
                isSearchable
                isClearable={false}
                options={this.formatSelectOptions(this.state.tagOptions)}
                placeholder="Search tags..."
                value={this.state.newTagId}
                variant="flatBlueishGray"
              />
            </div>
            <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <a
                className={`${button.btn} ${button.btnSmNarrow} ${button.btnBlue}`}
                onClick={this.onElementSave}
              >
                SAVE
              </a>
            </div>
            <div className="p-l-5" style={{ paddingTop: "10px" }}>
              <a
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(184, 184, 184, 0.2)",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={this.onDismissElementEdit}
              >
                <i className="fa fa-times" style={{ fontSize: "10px", color: "#979797" }} />
              </a>
            </div>
          </div>
        )}
        {!this.state.addingTag && <Button onClick={this.showNewTagInput}>+ ADD</Button>}
      </div>
    );
  }
}

export default AddTag;
