import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";
import { Edit } from "../Icomoon";

const Label = ({ label, timeOnNewLine }) => {
  const showOnNewLine = timeOnNewLine && label?.includes(" ");
  if (showOnNewLine) {
    const iSpace = label.indexOf(" ");
    const date = label.substring(0, iSpace);
    const time = label.substring(iSpace);
    return (
      <div className="tw-flex tw-flex-col tw-items-end">
        <span>{date}</span>
        <span className="tw-text-neutral-gray-50">{time}</span>
      </div>
    );
  }
  return <>{label}</>;
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  timeOnNewLine: PropTypes.bool,
};

Label.defaultProps = {
  timeOnNewLine: false,
};

const Display = ({
  id: idFromProps,
  label,
  value,
  disabled,
  icon,
  iconAlwaysVisible,
  onInteract,
  className,
  showLineBreak,
  timeOnNewLine,
  showRightAlign,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("inline-editor-display", idFromProps);

  const callOnInteractWhenNotDisabled = () => {
    if (disabled) {
      return;
    }
    onInteract();
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        callOnInteractWhenNotDisabled();
        break;
      default:
        break;
    }
  };

  const iconClasses = `group-hover:peer-enabled:tw-cursor-pointer group-hover:peer-disabled:tw-cursor-default peer-disabled:tw-text-neutral-gray-30${
    iconAlwaysVisible ? "" : " tw-invisible group-hover:tw-visible"
  }`;

  return (
    <>
      <button
        className={`
          tw-group tw-flex tw-flex-row tw-space-x-[9px] tw-text-neutral-gray-75
          tw-p-0 tw-border-0 tw-bg-transparent
          ${disabled ? "" : "active:tw-text-theme-display-active"}
          ${showLineBreak ? "tw-items-start" : "tw-h-[34px] tw-items-center"}
          ${showRightAlign ? "tw-items-center" : ""}
          ${className}
        `}
        disabled={disabled}
        type="button"
        onClick={callOnInteractWhenNotDisabled}
        tabIndex={iconAlwaysVisible ? 0 : -1}
        onKeyDown={iconAlwaysVisible ? handleKeyDown : undefined}
        data-cy="inline-editor-display"
      >
        <span
          className={`
              tw-peer tw-text-14d
              group-hover:enabled:tw-cursor-pointer
              tw-select-none disabled:tw-select-auto
              ${showRightAlign ? "tw-text-right" : ""}
              ${showLineBreak ? "tw-text-left" : "tw-truncate"}
            `}
          role={iconAlwaysVisible ? undefined : "button"}
          tabIndex={iconAlwaysVisible ? undefined : 0}
          onKeyDown={iconAlwaysVisible ? undefined : handleKeyDown}
          data-cy="inline-editor-display-button"
        >
          <Label label={label} timeOnNewLine={timeOnNewLine} />
        </span>
        {React.cloneElement(icon, {
          className: [icon.props?.className, iconClasses].filter(Boolean).join(" "),
        })}
      </button>
      <input type="hidden" id={id} value={value} disabled={disabled} {...otherProps} />
    </>
  );
};

Display.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  iconAlwaysVisible: PropTypes.bool,
  onInteract: PropTypes.func.isRequired,
  className: PropTypes.string,
  showLineBreak: PropTypes.bool,
  showRightAlign: PropTypes.bool,
  timeOnNewLine: PropTypes.bool,
};

Display.defaultProps = {
  id: null,
  disabled: false,
  icon: <Edit size="custom" className="tw-w-[10px] tw-h-[10px]" />,
  iconAlwaysVisible: false,
  className: "",
  showLineBreak: false,
  showRightAlign: false,
  timeOnNewLine: false,
};

export default Display;
