import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { PhoneCallIcon, PhoneCallForm } from "./";
import Button from "@shared/v2/Button";
import HeaderTitle from "../../../PersonDetail/components/modals/HeaderTitle";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";

// DO NOT use this component unless all props are mapped out, including side-effects from PhoneCallApp. Use PhoneCallApp component instead.
class PhoneCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = { callable: true };
  }

  adjustCallable = (viewPhone) => this.setState({ callable: !viewPhone.callUnsubscribed });

  saveInteraction = () => {
    const { submitPhoneInteraction, clickedHangup, enableCallAfterLogged, setStatus } = this.props;
    if (this.props.interaction?.outcome == null)
      this.props.setError("<dl><dd>Outcome</dd><dt>Can't be blank</dt></dl>");
    else if (this.props.renderWithoutModal === true) {
      submitPhoneInteraction(this.props.interaction, true);
      this.clearForm();
      if (clickedHangup) {
        enableCallAfterLogged();
        setStatus(null, null);
      }
    } else {
      this.props.updateInteraction(this.props.interaction);
    }
  };

  clearForm = () => {
    const { updateValues } = this.props;
    updateValues("outcome", null);
    updateValues("comment", "");
  };

  renderCallModal = () => {
    const {
      isCallModalOpen,
      isSubmitting,
      userNumber,
      platformAccount,
      callInProgress,
      closeCallModal,
      setStatus,
      duration,
      startCall,
      managedNumber,
      platformIsRequired,
      updateValues,
      endCall,
      showPlatformRequiredMessage,
      person,
      clickedHangup,
      interaction,
      hasError,
      errorMessage,
      dialablePhoneNumbers,
    } = this.props;

    const { callable } = this.state;

    return (
      <Modal
        id="call-interaction-modal"
        className="modal"
        backdrop="static"
        show={isCallModalOpen}
        onHide={closeCallModal}
        enforceFocus={false}
      >
        <Modal.Header className="tw-p-32px tw-pb-0">
          <Modal.Title>
            <HeaderTitle title="Interaction: Call" onClose={closeCallModal} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-p-32px tw-pb-0">
          {isCallModalOpen && (
            <PhoneCallForm
              dialablePhoneNumbers={dialablePhoneNumbers}
              callInProgress={callInProgress}
              person={person}
              platformAccount={platformAccount}
              platformIsRequired={platformIsRequired}
              showPlatformRequiredMessage={showPlatformRequiredMessage}
              clickedHangup={clickedHangup}
              userNumber={userNumber}
              managedNumber={managedNumber}
              interaction={interaction}
              updateValues={updateValues}
              duration={duration}
              handleEndCall={endCall}
              handleStartCall={startCall}
              setStatus={setStatus}
              hasError={hasError}
              errorMessage={errorMessage}
              linkToContact={true}
              onPhoneChange={this.adjustCallable}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="tw-p-32px">
          {!callInProgress && interaction?.status !== "completed" && callable && (
            <div className="pull-left">
              <Button schema="tertiary" onClick={closeCallModal} size="medium">
                Clear
              </Button>
            </div>
          )}
          <Button
            schema="primary"
            disabled={isSubmitting || callInProgress || !callable}
            onClick={(e) => this.saveInteraction()}
            size="medium"
          >
            Save and Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderPhoneCallIcon = () => {
    const { openCallModal, dialablePhoneNumbers } = this.props;
    const iconClass = "active";
    const primaryPhone = (dialablePhoneNumbers || []).map(viewPhoneFromFlat)[0] || {};
    const primaryPhoneNumber = primaryPhone.formattedNumber || null;

    return (
      <div className="modal-container">
        <PhoneCallIcon
          personPhoneNumber={primaryPhoneNumber}
          handleClick={openCallModal}
          iconClass={iconClass}
        />
        {this.renderCallModal()}
      </div>
    );
  };

  renderPhoneCallForm = () => {
    const {
      dialablePhoneNumbers,
      isSubmitting,
      userNumber,
      platformAccount,
      callInProgress,
      setStatus,
      duration,
      startCall,
      managedNumber,
      platformIsRequired,
      updateValues,
      endCall,
      showPlatformRequiredMessage,
      person,
      clickedHangup,
      personPhoneNumber,
      interaction,
      hasError,
      errorMessage,
      clickedPhoneNo,
    } = this.props;

    const { callable } = this.state;
    return (
      <div className={"p-b-20"}>
        <PhoneCallForm
          dialablePhoneNumbers={dialablePhoneNumbers}
          callInProgress={callInProgress}
          person={person}
          platformAccount={platformAccount}
          platformIsRequired={platformIsRequired}
          showPlatformRequiredMessage={showPlatformRequiredMessage}
          clickedHangup={clickedHangup}
          userNumber={userNumber}
          managedNumber={managedNumber}
          interaction={interaction}
          updateValues={updateValues}
          duration={duration}
          handleEndCall={endCall}
          personPhoneNumber={personPhoneNumber}
          handleStartCall={startCall}
          setStatus={setStatus}
          hasError={hasError}
          errorMessage={errorMessage}
          onPhoneChange={this.adjustCallable}
          clickedPhoneNo={clickedPhoneNo}
        />
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-justify-space-between m-t-20">
              <Button
                schema="tertiary"
                disabled={isSubmitting || !callable}
                onClick={(e) => this.clearForm()}
                size="medium"
              >
                Clear
              </Button>
              <Button
                schema="primary"
                disabled={isSubmitting || callInProgress || !callable}
                onClick={(e) => this.saveInteraction()}
                size="medium"
              >
                Save & Log Call
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { renderWithoutModal } = this.props;
    if (renderWithoutModal === true) {
      return this.renderPhoneCallForm();
    } else {
      return this.renderPhoneCallIcon();
    }
  }
}
export default PhoneCall;
