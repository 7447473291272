import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import AddAutoPlanModal from "./AddAutoPlanModal";
import DeleteAssignedPlanModal from "./DeleteAssignedPlanModal";
import PreviewAutoPlan from "./PreviewAutoPlan";
import PlanDetails from "./PlanDetails";
import auto_plans from "./auto_plans.module.css";
import css from "../person-detail-styles.module.css";
import icons from "../icons.module.css";
import DeleteAction from "./DeleteAction";
import * as actions from "../../actions/personDetailActionCreators";
import CollapsibleElement from "../CollapsibleElement";
import Loading from "../../../Goals/components/Loader";

const AutoPlans = ({ openPlatformCTA }) => {
  const dispatch = useDispatch();
  const featureFlags = useSelector((state) => state.layout.featureFlags);
  const person = useSelector((state) => state.personDetail.person);
  const activeAutoPlanActionId = useSelector((state) => state.personDetail.activeActionId);
  const assignedPlan = useSelector((state) => state.personDetail.assignedPlan);
  const assignedPlans = useSelector((state) => state.personDetail.assignedPlans);
  const addAutoPlanModalOpen = useSelector((state) => state.personDetail.addAutoPlanModalOpen);
  const actionErrorMessage = useSelector((state) => state.personDetail.actionErrorMessage);
  const currentAction = useSelector((state) => state.personDetail.currentAction);
  const deleteAssignedPlanModalOpen = useSelector((state) => state.personDetail.deleteAssignedPlanModalOpen);
  const deleteAssignedActionModalOpen = useSelector(
    (state) => state.personDetail.deleteAssignedActionModalOpen,
  );
  const planToDelete = useSelector((state) => state.personDetail.planToDelete);
  const activeAssignedActionId = useSelector((state) => state.personDetail.assignedActionId);
  const autoPlanPreviewModalOpen = useSelector((state) => state.personDetail.autoPlanPreviewModalOpen);
  const autoPlanPreview = useSelector((state) => state.personDetail.planPreview);
  const autoPlanDetailsOpen = useSelector((state) => state.personDetail.autoPlanDetailsOpen);
  const hasActionError = useSelector((state) => state.personDetail.hasActionError);
  const isActionUpdating = useSelector((state) => state.personDetail.isActionUpdating);

  const deleteAction = (...args) => dispatch(actions.deleteAction(...args));
  const updateAction = (...args) => dispatch(actions.updateAction(...args));
  const closeAddAutoPlanModal = () => dispatch(actions.closeAddAutoPlanModal());
  const submitApplyPlan = (...args) => dispatch(actions.submitApplyPlan(...args));
  const updateAssignedPlan = (...args) => dispatch(actions.updateAssignedPlan(...args));
  const openDeleteAssignedPlanModal = (plan) => dispatch(actions.openDeleteAssignedPlanModal(plan));
  const closeDeleteAssignedPlanModal = () => dispatch(actions.closeDeleteAssignedPlanModal());
  const showAutoPlanPreview = (planId) => dispatch(actions.showAutoPlanPreview(planId));
  const previewAutoPlanAction = (actionId) => dispatch(actions.previewAction(actionId));
  const closeAutoPlanPreview = () => dispatch(actions.closeAutoPlanPreview());
  const showPlanDetails = (planId) => dispatch(actions.showPlanDetails(planId));
  const closePlanDetails = () => dispatch(actions.closePlanDetails());
  const previewAssignedAction = (assignedAction) => dispatch(actions.assignedActionPreview(assignedAction));
  const showAssignedActionDeleteModal = (action) => dispatch(actions.showAssignedActionDeleteModal(action));
  const closeAssignedActionDeleteModal = () => dispatch(actions.closeAssignedActionDeleteModal());
  const showDeleteAssignedPlanModal = (plan) => dispatch(actions.openDeleteCloseActionsModal(plan));
  const openAddAutoPlanModal = () => dispatch(actions.openAddAutoPlanModal());

  const autoPlanButtonText = featureFlags.platformAccount ? "+ ADD" : "LEARN MORE";
  const autoPlanButtonClick = featureFlags.platformAccount ? openAddAutoPlanModal : openPlatformCTA;

  useEffect(() => {
    if (person.data?.attributes.uuid) dispatch(actions.getAssignedPlans(person.data.attributes.uuid));
  }, [person.data?.attributes.uuid]);

  const showPreviewPlanModal = (planId) => {
    closeAddAutoPlanModal();
    showAutoPlanPreview(planId);
  };

  const updatePlanState = (id, state) => {
    const form = { state: state };
    updateAssignedPlan(`/assigned_plans/${id}`, form);
  };

  const deletePlan = () => {
    updatePlanState(planToDelete.id, "deleted");
    closeDeleteAssignedPlanModal();
  };

  const autoPlan = (plan) => {
    return (
      <Row key={plan.id}>
        <div className={css.materialDesignBox}>
          <Row>
            <Col xs={12} className="m-b-10">
              <div
                className={`pull-left overflow ${auto_plans.planName}`}
                onClick={() => showPlanDetails(plan.id)}
              >
                <i className="fa fa-bolt m-r-10" />
                <span>{plan.name}</span>
              </div>
              <div className="pull-right">
                {plan.state != "completed" && (
                  <div className={icons.editAndDelete}>
                    <a className={`${icons.button} ${icons.gray}`}>
                      {plan.state == "paused" && (
                        <i
                          onClick={() => updatePlanState(plan.id, "started")}
                          className={`${icons.smIcon} fa fa-play`}
                        />
                      )}
                      {plan.state == "started" && (
                        <i
                          onClick={() => updatePlanState(plan.id, "paused")}
                          className={`${icons.smIcon} fa fa-pause`}
                        />
                      )}
                    </a>
                    <a className={`${icons.button} ${icons.gray}`}>
                      {plan.state != "deleted" && plan.state != "completed" && (
                        <i
                          onClick={() => openDeleteAssignedPlanModal(plan)}
                          className={`${icons.smIcon} fa fa-trash`}
                        />
                      )}
                    </a>
                  </div>
                )}
              </div>
            </Col>

            <Col xs={12} className="m-b-10">
              <div className="pull-left">
                <span className={`${css.brivityPersonDetailsLabel} m-r-5`}>Progress:</span>
                <span style={{ fontSize: "12px" }}>
                  {plan.total_completed_actions} / {plan.assigned_actions_count}{" "}
                </span>
              </div>
              <div className="pull-right">
                {plan.state === "paused" && (
                  <span className={`text-danger ${auto_plans.cardState}`}>PAUSED</span>
                )}
                {plan.state === "started" && (
                  <span className={`text-info ${auto_plans.cardState}`}>RUNNING</span>
                )}
                {plan.state === "completed" && (
                  <span className={`text-success ${auto_plans.cardState}`}>COMPLETED</span>
                )}
              </div>
            </Col>
            {plan.state != "completed" && (
              <div>
                <Col xs={12} className="m-b-5">
                  <span className={css.brivityPersonDetailsLabel}>NEXT STEP</span>
                </Col>
                <Col xs={12}>
                  <span style={{ fontSize: "13px" }}>{plan.next_action_description}</span>
                </Col>
              </div>
            )}
          </Row>
        </div>
      </Row>
    );
  };

  return (
    <CollapsibleElement
      title="Auto Plans"
      buttonText={autoPlanButtonText}
      buttonClickFunction={autoPlanButtonClick}
    >
      {!person.data && <Loading />}
      <div id="person-auto-plans-card">
        {(assignedPlans.length === 0 || assignedPlans.every((plan) => plan.state === "deleted")) && (
          <div className={css.personDetailEmptyStateText}>
            Use Auto Plans to automate email, text, and tasks to save time.
          </div>
        )}
        {assignedPlans.map((a) => {
          return ["started", "paused", "completed"].includes(a.state) && autoPlan(a);
        })}
        <AddAutoPlanModal
          assignableId={person.data?.attributes.uuid}
          closeAddAutoPlanModal={closeAddAutoPlanModal}
          show={addAutoPlanModalOpen}
          submitApplyPlan={submitApplyPlan}
          showPreviewPlanModal={showPreviewPlanModal}
        />
        {deleteAssignedPlanModalOpen && (
          <DeleteAssignedPlanModal closeModal={closeDeleteAssignedPlanModal} deletePlan={deletePlan} />
        )}
        {autoPlanPreviewModalOpen && (
          <PreviewAutoPlan
            autoPlanPreview={autoPlanPreview}
            activeAutoPlanActionId={activeAutoPlanActionId}
            previewAutoPlanAction={previewAutoPlanAction}
            closeAutoPlanPreview={closeAutoPlanPreview}
          />
        )}
        {autoPlanDetailsOpen && (
          <PlanDetails
            activeAssignedActionId={activeAssignedActionId}
            assignedPlan={assignedPlan}
            closeModal={closePlanDetails}
            previewAssignedAction={previewAssignedAction}
            deleteAssignedActionModalOpen={deleteAssignedActionModalOpen}
            showAssignedActionDeleteModal={showAssignedActionDeleteModal}
            updateAction={updateAction}
            isActionUpdating={isActionUpdating}
            updateAssignedPlan={updateAssignedPlan}
            showDeleteAssignedPlanModal={showDeleteAssignedPlanModal}
          />
        )}
        {deleteAssignedActionModalOpen && (
          <DeleteAction
            action={currentAction}
            actionErrorMessage={actionErrorMessage}
            closeModal={closeAssignedActionDeleteModal}
            hasActionError={hasActionError}
            assignedPlanName={assignedPlan.name}
            deleteAssignedActionModalOpen={deleteAssignedActionModalOpen}
            onActionDelete={deleteAction}
            showPlanDetails={showPlanDetails}
            assignedPlan={assignedPlan}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default AutoPlans;
