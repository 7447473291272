import React from "react";
import { Alert } from "react-bootstrap";
import isString from "lodash/isString";

const Error = ({ errorMessage }) => {
  const messages = isString(errorMessage) ? errorMessage.split() : errorMessage;

  function createMarkup(err) {
    return {__html: err}
  }

  return (
    <div>
      {messages.map((err, i) => <Alert key={i} bsStyle="danger"><p dangerouslySetInnerHTML={createMarkup(err)}></p></Alert>)}
    </div>
    )
};

Error.defaultProps = {
  errorMessage: ""
};
export default Error;