import React, { useState } from "react";
import SmsStatus from "./SmsStatus";
import MmsMediaModal from "@shared/MmsMediaModal";
import { CommentDotsRegularV6 } from "@shared/v2/Icomoon";

const TimelineGroupSmsEvent = ({ event }) => {
  const { sender, recipients, message, attachments } = event.timeline_fields || {};
  const [state, setState] = useState({
    selectedMessage: null,
    mediaIndex: null,
  });
  return (
    <div className="tw-p-[24px] tw-bg-white tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-10 tw-flex tw-items-start tw-gap-[24px]">
      <div className="tw-flex tw-flex-none tw-items-center tw-justify-center tw-border-solid tw-border-[2px] tw-border-neutral-gray-75 tw-rounded-full tw-p-[10px]">
        <CommentDotsRegularV6 size="l" />
      </div>
      <div className="tw-text-14d tw-flex tw-flex-col tw-gap-[4px] tw-flex-1">
        <div className="tw-flex tw-justify-between tw-gap-[8px]">
          <div>
            <span className="tw-font-semibold">{sender?.full_name}</span> texted{" "}
            <span className="tw-font-semibold">{recipients?.map((r) => r.full_name).join(", ")}</span>
          </div>
          <span className="tw-text-12d">{event.interacted_at_absolute}</span>
        </div>

        <div className="tw-flex tw-justify-between">
          <div>{message}</div>
          <div>
            <SmsStatus
              deliveryStatus={event.timeline_fields.delivery_status}
              nonDeliveryReasons={event.timeline_fields.non_delivery_reasons}
            />
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap tw-gap-[8px]">
          {attachments?.map(({ url }, i) => (
            <button
              className="tw-border-0 tw-p-0 tw-m-0 tw-bg-transparent"
              aria-label="preview image"
              onClick={() =>
                setState({
                  selectedMessage: {
                    createdByAlias: sender?.full_name,
                    dateCreated: moment(event.interacted_at_absolute, "MM/DD/YY [at] h:mm a z"),
                    attachments: attachments.map(({ url }) => ({ fileUrl: url })),
                  },
                  mediaIndex: i,
                })
              }
            >
              <img src={url} className="tw-rounded-lg tw-max-h-96 tw-max-w-96 tw-h-auto tw-w-auto" />
            </button>
          ))}
        </div>
      </div>
      <MmsMediaModal
        message={state.selectedMessage}
        mediaIndex={state.mediaIndex}
        onClose={() => setState({ selectedMessage: null, mediaIndex: null })}
      />
    </div>
  );
};

export default TimelineGroupSmsEvent;
