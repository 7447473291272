import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import Button from "../../../../shared/v2/Button";
import { Col, Row } from "../../../../shared/v1";
import Dropdown from "../../../../shared/v2/Dropdown";
import * as actionCreators from "../actions/thunks";
import errorShape from "../model/error";
import Error from "../Error";
import TextButton from "../../../../shared/v2/TextButton";
import LoadingOverlay from "../../../../shared/v2/LoadingOverlay";

const Planbody = ({
  getAvailablePlanAsThunk,
  submitAvailablePlanAsThunk,
  uuid,
  isLoading,
  getAvailablePlanError,
  availablePlan,
  closeModal,
}) => {
  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    getAvailablePlanAsThunk(uuid);
  }, []);
  const handleApplyPlan = () => {
    if (selectedPlan?.value) {
      submitAvailablePlanAsThunk(uuid, selectedPlan.value, closeModal);
    }
  };
  const plansDropdown = availablePlan.map((result) => ({
    value: result.id.toString(),
    label: result.name,
  }));
  return (
    <LoadingOverlay isLoading={isLoading}>
      {getAvailablePlanError && <Error message={getAvailablePlanError.message} />}
      <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-pt-[32px]">
        <Col size={12} className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0">
          <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
            Advertising Plan
          </div>
          <Dropdown
            disabled={isLoading}
            onChange={setSelectedPlan}
            placeholder="Select plan"
            name="plan"
            options={plansDropdown}
            value={selectedPlan || ""}
            className="tw-bg-gray-10"
          />
          <TextButton size="medium" onClick={() => window.location.assign("/action_plans")}>
            Set up a new advertising plan
          </TextButton>
        </Col>
      </Row>
      <Row className="tw-pt-[32px] tw-m-0 tw-p-0 tw-flex ">
        <Col xs={8} className="tw-m-0 tw-p-0">
          <Button
            disabled={isLoading}
            onClick={closeModal}
            className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
            schema="tertiary"
            size="medium"
          >
            CANCEL
          </Button>
        </Col>
        <Col xs={4} className="tw-m-0 tw-p-0 tw-flex tw-justify-end">
          <Button
            disabled={isLoading}
            onClick={handleApplyPlan}
            className="tw-flex tw-items-center"
            schema="primary"
            size="medium"
          >
            APPLY
          </Button>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

Planbody.propTypes = {
  getAvailablePlanAsThunk: Proptypes.func.isRequired,
  submitAvailablePlanAsThunk: Proptypes.func.isRequired,
  uuid: Proptypes.string.isRequired,
  isLoading: Proptypes.bool,
  availablePlan: Proptypes.arrayOf(Proptypes.shape({})),
  getAvailablePlanError: errorShape,
  closeModal: Proptypes.func,
};
Planbody.defaultProps = {
  availablePlan: [],
  getAvailablePlanError: null,
  isLoading: false,
  closeModal: null,
};
const mapStateToProps = (state) => ({
  availablePlan: state.tdpMarketingReducer.availablePlan,
  uuid: state.tdpMarketingReducer.uuid,
  getAvailablePlanError: state.tdpMarketingReducer.getAvailablePlanError,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAvailablePlanAsThunk: (uuid) => dispatch(actionCreators.getAvailablePlanAsThunk(uuid)),
  submitAvailablePlanAsThunk: (uuid, planId, closeModal) =>
    dispatch(actionCreators.submitAvailablePlanAsThunk(uuid, planId, closeModal)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Planbody);
