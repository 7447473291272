import React from "react";
import { connect } from "react-redux";
import { string, func, bool } from "prop-types";
import Toggle from "../../../../shared/v2/Toggle/Toggle";
import { putDetailAsThunk } from "../../actions/thunks";

const EllipsisDropdown = ({
  uuid,
  setRename,
  status,
  published,
  DOM,
  onPutDetailAsThunk,
  deleteListing,
  setDeleteListing,
}) => (
  <div className="tw-absolute tw-right-0 tw-top-full tw-shadow-modal tw-w-[153px] tw-flex tw-flex-col tw-items-start tw-pt-[10px] tw-bg-white tw-rounded tw-cursor-default">
    <div className="tw-h-[30px] tw-ml-12px">
      <button
        type="button"
        className="tw-flex tw-border-none tw-bg-transparent"
        onClick={() => setRename((prev) => !prev)}
        data-cy="header-dropdown-rename-button"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.1833 3.19859L13.6889 5.70415C13.7944 5.8097 13.7944 5.98193 13.6889 6.08748L7.62222 12.1542L5.04445 12.4403C4.7 12.4791 4.40833 12.1875 4.44722 11.843L4.73333 9.26526L10.8 3.19859C10.9056 3.09304 11.0778 3.09304 11.1833 3.19859ZM15.6833 2.56248L14.3278 1.20693C13.9056 0.784703 13.2194 0.784703 12.7944 1.20693L11.8111 2.19026C11.7056 2.29581 11.7056 2.46804 11.8111 2.57359L14.3167 5.07915C14.4222 5.1847 14.5944 5.1847 14.7 5.07915L15.6833 4.09581C16.1056 3.67081 16.1056 2.9847 15.6833 2.56248ZM10.6667 10.5041V13.3319H1.77778V4.44304H8.16111C8.25 4.44304 8.33333 4.40693 8.39722 4.34581L9.50833 3.2347C9.71945 3.02359 9.56944 2.66526 9.27222 2.66526H1.33333C0.597222 2.66526 0 3.26248 0 3.99859V13.7764C0 14.5125 0.597222 15.1097 1.33333 15.1097H11.1111C11.8472 15.1097 12.4444 14.5125 12.4444 13.7764V9.39304C12.4444 9.09582 12.0861 8.94859 11.875 9.15693L10.7639 10.268C10.7028 10.3319 10.6667 10.4153 10.6667 10.5041Z"
            fill="#666666"
          />
        </svg>

        <div className="tw-ml-10px tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal">Rename</div>
      </button>
    </div>
    {DOM && DOM !== "n/a" ? (
      <>
        <div className="tw-h-[25px] tw-ml-12px">
          <button
            type="button"
            className="tw-flex tw-border-none tw-bg-transparent"
            onClick={
              status === "archived"
                ? () => setDeleteListing(!deleteListing)
                : () =>
                    onPutDetailAsThunk({
                      uuid,
                      key: "status",
                      value: "archived",
                      group: "transactionDetails",
                    })
            }
            data-cy={
              status === "archived" ? "header-dropdown-delete-button" : "header-dropdown-archive-button"
            }
          >
            {status === "archived" ? (
              <svg
                className="tw-mr-[5px]"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.75 1.96875V1.3125C0.75 1.00078 1.00078 0.75 1.3125 0.75H3.9375L4.15781 0.311719C4.25156 0.119531 4.44609 0 4.65938 0H7.33828C7.55156 0 7.74609 0.119531 7.84219 0.311719L8.0625 0.75H10.6875C10.9992 0.75 11.25 1.00078 11.25 1.3125V1.96875C11.25 2.12344 11.1234 2.25 10.9688 2.25H1.03125C0.876563 2.25 0.75 2.12344 0.75 1.96875ZM10.5 3.28125V10.875C10.5 11.4961 9.99609 12 9.375 12H2.625C2.00391 12 1.5 11.4961 1.5 10.875V3.28125C1.5 3.12656 1.62656 3 1.78125 3H10.2188C10.3734 3 10.5 3.12656 10.5 3.28125ZM4.125 4.875C4.125 4.66875 3.95625 4.5 3.75 4.5C3.54375 4.5 3.375 4.66875 3.375 4.875V10.125C3.375 10.3313 3.54375 10.5 3.75 10.5C3.95625 10.5 4.125 10.3313 4.125 10.125V4.875ZM6.375 4.875C6.375 4.66875 6.20625 4.5 6 4.5C5.79375 4.5 5.625 4.66875 5.625 4.875V10.125C5.625 10.3313 5.79375 10.5 6 10.5C6.20625 10.5 6.375 10.3313 6.375 10.125V4.875ZM8.625 4.875C8.625 4.66875 8.45625 4.5 8.25 4.5C8.04375 4.5 7.875 4.66875 7.875 4.875V10.125C7.875 10.3313 8.04375 10.5 8.25 10.5C8.45625 10.5 8.625 10.3313 8.625 10.125V4.875Z"
                  fill="#666666"
                />
              </svg>
            ) : (
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.75 11.5C1.75 11.9148 2.08516 12.25 2.5 12.25H11.5C11.9148 12.25 12.25 11.9148 12.25 11.5V4.75H1.75V11.5ZM5.5 6.53125C5.5 6.37656 5.62656 6.25 5.78125 6.25H8.21875C8.37344 6.25 8.5 6.37656 8.5 6.53125V6.71875C8.5 6.87344 8.37344 7 8.21875 7H5.78125C5.62656 7 5.5 6.87344 5.5 6.71875V6.53125ZM12.25 1.75H1.75C1.33516 1.75 1 2.08516 1 2.5V3.625C1 3.83125 1.16875 4 1.375 4H12.625C12.8313 4 13 3.83125 13 3.625V2.5C13 2.08516 12.6648 1.75 12.25 1.75Z"
                  fill="#666666"
                />
              </svg>
            )}

            <div
              className={`tw-ml-10px tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal ${
                status === "archived" && "tw-text-red-700"
              }`}
            >
              {status === "archived" ? "Delete" : "Archive"}
            </div>
          </button>
        </div>

        <div className="tw-border-solid tw-border-[1px] tw-w-[153px] tw-border-gray-10" />
        <div className="tw-h-[25px] tw-ml-[12px] tw-mr-[6px] tw-mt-[10px] ">
          <button
            onClick={() =>
              onPutDetailAsThunk({
                uuid,
                key: "published",
                value: !published,
                group: "headerDetails",
              })
            }
            type="button"
            className="tw-flex tw-border-none tw-bg-transparent"
            data-cy="header-dropdown-publish-button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22 22" fill="none">
              <path
                d="M18.5 0.5H3.5C1.84297 0.5 0.5 1.84344 0.5 3.5V18.5C0.5 20.1566 1.84297 21.5 3.5 21.5H18.5C20.157 21.5 21.5 20.1566 21.5 18.5V3.5C21.5 1.84344 20.1547 0.5 18.5 0.5ZM16.625 13.625C16.625 14.4542 15.9542 15.125 15.125 15.125C14.2958 15.125 13.625 14.4542 13.625 13.625V10.4984L7.93438 16.1891C7.64375 16.4797 7.25937 16.625 6.875 16.625C6.49063 16.625 6.10719 16.4785 5.81469 16.1855C5.22875 15.5996 5.22875 14.6504 5.81469 14.0645L11.5063 8.375H8.375C7.54578 8.375 6.875 7.70422 6.875 6.875C6.875 6.04578 7.54578 5.375 8.375 5.375H15.125C15.9542 5.375 16.625 6.04578 16.625 6.875V13.625Z"
                fill="#666666"
              />
            </svg>

            <div className="tw-flex tw-ml-[12px] tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal">
              Publish
              <div className="tw-ml-[12px] tw-mt-[-2px]">
                <Toggle
                  id="publish"
                  checked={published}
                  onChange={() =>
                    onPutDetailAsThunk({
                      uuid,
                      key: "published",
                      value: !published,
                      group: "headerDetails",
                    })
                  }
                />
              </div>
            </div>
          </button>
        </div>
      </>
    ) : null}
  </div>
);

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  status: state.tdpDetailsReducer.transactionDetails.status,
  published: state.tdpDetailsReducer.headerDetails?.published,
  DOM: state.tdpDetailsReducer.headerDetails.days_on_market,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (uuid) => dispatch(putDetailAsThunk(uuid)),
});
EllipsisDropdown.propTypes = {
  uuid: string.isRequired,
  setRename: func.isRequired,
  deleteListing: func.isRequired,
  setDeleteListing: func.isRequired,
  status: string.isRequired,
  published: bool.isRequired,
  DOM: string,
  onPutDetailAsThunk: func.isRequired,
};

EllipsisDropdown.defaultProps = {
  DOM: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EllipsisDropdown);
