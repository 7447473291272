export const defaultState = {
  interaction: {
    status: null,
  },
  dialablePhoneNumbers: [],
  clickedPhoneNo: "",
};

const phoneCall = (state = {}, action) => {
  switch (action.type) {
    case "STARTING_PHONE_CALL":
      return {
        ...state,
        callInProgress: true,
      };

    case "SHOW_PLATFORM_REQUIRED_MESSAGE":
      return {
        ...state,
        platformIsRequired: true,
      };
    case "SET_INTERACTION":
      return {
        ...state,
        interaction: action.interaction,
      };
    case "STOPPING_PHONE_CALL":
      return {
        ...state,
        callInProgress: false,
      };
    case "OPEN_CALL_MODAL":
      return {
        ...state,
        isCallModalOpen: true,
        callInProgress: false,
      };
    case "IS_SUBMITTING":
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    case "CLOSE_CALL_MODAL":
      return {
        ...state,
        isCallModalOpen: false,
        callInProgress: false,
      };
    case "SET_STATUS":
      return {
        ...state,
        callInProgress: action.callInProgress,
        interaction: { ...state.interaction, status: action.callStatus, duration: action.duration },
      };
    case "UPDATE_PHONE_CALL_VALUES":
      return {
        ...state,
        interaction: { ...state.interaction, [action.name]: action.value },
      };
    case "IS_HANGUP_ALREADY_CLICKED":
      return {
        ...state,
        clickedHangup: action.clickedHangup,
      };
    case "SET_ERROR":
      return {
        ...state,
        hasError: !!action.error,
        errorMessage: action.error,
      };
    case "PHONE_CALL_INIT_REDUCER_FROM_TDP":
      return {
        ...state,
        ...action.initCallData,
        isCallModalOpen: true,
        callInProgress: false,
      };
    case "PHONE_CALL_RESET_REDUCER_FROM_TDP":
      return state;

    default:
      return state;
  }
};
export default phoneCall;
