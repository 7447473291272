import axios from "axios";

export const startingCall = () => {
  return {
    type: "STARTING_PHONE_CALL",
  };
};

export const openCallModalTDP = (initCallData) => (dispatch) => {
  dispatch({ type: "PHONE_CALL_INIT_REDUCER_FROM_TDP", initCallData });
};

export const closeCallModalTDP = () =>
  function (dispatch) {
    dispatch({ type: "PHONE_CALL_RESET_REDUCER_FROM_TDP" });
  };

export const showPlatformRequiredMessage = () => {
  return (dispatch) => {
    dispatch({ type: "SHOW_PLATFORM_REQUIRED_MESSAGE" });
  };
};

export const openCallModal = () => {
  return (dispatch) => {
    dispatch({ type: "OPEN_CALL_MODAL" });
  };
};

export const closeCallModal = () => {
  return (dispatch) => {
    dispatch({
      type: "CLOSE_CALL_MODAL",
    });
  };
};

export const updateValues = (name, value) => {
  return (dispatch) => {
    dispatch(setError(null));
    dispatch({ type: "UPDATE_PHONE_CALL_VALUES", name, value });
  };
};

export const setStatus = (callStatus, duration = null) => {
  const callInProgress = callStatus !== null && callStatus !== "completed";
  return (dispatch) => {
    dispatch({ type: "SET_STATUS", callStatus, callInProgress, duration });
  };
};

export const setInteraction = (interaction) => {
  return (dispatch) => {
    dispatch({ type: "SET_INTERACTION", interaction });
  };
};

export const setError = (error) => {
  return (dispatch) => {
    dispatch({ type: "SET_ERROR", error });
  };
};

export const enableCallAfterLogged = () => {
  const clickedHangup = false;
  return (dispatch) => {
    dispatch({ type: "IS_HANGUP_ALREADY_CLICKED", clickedHangup });
  };
};

export const startCall = (personId, phoneDetailId) => {
  const path = `/people/${personId}/interactions/phone_call`;
  return (dispatch, getState) => {
    const { comment } = getState().phoneCall.interaction;

    dispatch(startingCall());
    axios
      .post(path, { phone_detail_id: phoneDetailId, authenticity_token: ReactOnRails.authenticityToken() })
      .then((res) => dispatch(setInteraction({ ...res.data, comment })))
      .catch((err) => console.log("ERROR", err));
  };
};

export const updateInteraction = (interaction) => {
  let route = "";
  if (interaction.id) {
    const path = `/people/${interaction.person_id}/interactions/${interaction.id}.json`;
    route = axios.patch(path, {
      interaction: interaction,
      authenticity_token: ReactOnRails.authenticityToken(),
    });
  } else {
    const path = `/people/${interaction.person_id}/interactions`;
    route = axios.post(path, {
      interaction: interaction,
      authenticity_token: ReactOnRails.authenticityToken(),
    });
  }
  return (dispatch) => {
    route
      .then((res) => {
        dispatch({ type: "IS_SUBMITTING", isSubmitting: true });
        dispatch(closeCallModal());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(setError(err.response.data.content));
      });
  };
};

export const endCall = (interaction) => {
  const path = `/people/${interaction.person_id}/interactions/hangup/${interaction.id}.json`;
  const clickedHangup = true;

  return (dispatch) => {
    axios
      .post(path, { authenticity_token: ReactOnRails.authenticityToken() })
      .then((res) => console.log("HANGUP", res.data))
      .catch((err) => console.log("ERROR ON HANGUP", err));
    dispatch({ type: "IS_HANGUP_ALREADY_CLICKED", clickedHangup });
  };
};
