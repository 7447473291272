import { intervalToDuration } from "date-fns";

export const getTrackingNumberIds = (selectedTrackingNumbers, trackingNumbers) => {
  return selectedTrackingNumbers.length === trackingNumbers.length
    ? []
    : selectedTrackingNumbers.filter((tn) => tn !== null).map((tn) => tn.value);
};

export const durationDisplay = (durationSeconds) =>
  new Date(durationSeconds * 1000).toISOString().substring(11, 19);

export const formatCallDuration = (durationInSeconds) => {
  if (!durationInSeconds) {
    return;
  }

  if (durationInSeconds === Infinity) {
    return "--:--:--";
  }

  const padWithZeroes = (num) => String(num).padStart(2, "0");

  let { days, hours, minutes, seconds } = intervalToDuration({ start: 0, end: durationInSeconds * 1000 });
  if (days) {
    hours += days * 24;
  }

  return [hours, minutes, seconds].map(padWithZeroes).join(":");
};
