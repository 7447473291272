import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Loader from "../../../../../Goals/components/Loader";

import Button from "../../../../../shared/v2/Button";
import Modal from "../../../../../shared/v2/Modal";

import { MODAL_TITLE_CLASSES } from "../../../../../shared/v2/Modal/Modal.styles";

import { PlayOrPauseButton, TrashIcon } from "../AssignedPlanIconButtons";
import AssignedPlanStateLabel from "../AssignedPlanStateLabel";
import { shouldShowPlayOrPauseAndDeleteButtons } from "./ActionDetails/helpers";
import PlanDetails from "./PlanDetails";

const AssignedPlanDetailsModal = ({
  plan,
  meta: { error, isLoading },
  isStateUpdateLoading,
  show,
  onClose,
  onPlayOrPauseButtonClicked,
  onTrashButtonClicked,
}) => {
  const modalTitle = isLoading ? "Loading Auto Plan..." : `Auto Plan: ${plan?.name}`;

  // There's no error state design for this modal, so for now we just kick the
  // user back out so he retries.
  if (show && error) {
    onClose();
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
      contentClassName="tw-w-[983px] tw-space-y-32px tw-m-auto"
    >
      <div className="tw-flex tw-justify-between tw-h-36px">
        <h1
          // This whole <div> (see above ^) is a mimicry of the v2 Modal.Header.
          // It was manually kinda-sorta reimplemented here because the design
          // is a one-off due to the buttons and plan state on the right-hand
          // side, which is why I've opted to depend on its styles here.
          className={MODAL_TITLE_CLASSES.medium}
        >
          {modalTitle}
        </h1>
        {!isLoading && (
          <span>
            {shouldShowPlayOrPauseAndDeleteButtons(plan.state) && (
              <div className="tw-w-[54px] tw-ml-auto tw-flex tw-justify-between tw-pb-[5px]">
                <PlayOrPauseButton
                  isLoading={isLoading || isStateUpdateLoading}
                  isPaused={plan.state === "paused"}
                  onClick={() => {
                    const desiredState = plan.state === "paused" ? "started" : "paused";
                    onPlayOrPauseButtonClicked(plan.id, desiredState);
                  }}
                />
                <TrashIcon
                  isLoading={isLoading || isStateUpdateLoading}
                  onClick={() => onTrashButtonClicked(plan.id)}
                />
              </div>
            )}

            <AssignedPlanStateLabel state={plan.state} />
          </span>
        )}
      </div>

      <Modal.Body className="tw-h-[60vh] tw-flex tw-border-solid tw-border-0 tw-border-t tw-border-b tw-border-neutral-gray-10">
        {isLoading ? (
          <div className="tw-relative tw-w-full">
            <Loader />
          </div>
        ) : (
          <PlanDetails plan={plan} />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <div className="tw-flex tw-flex-row tw-space-x-12px">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AssignedPlanDetailsModal.propTypes = {
  // Validation of this prop will happen further down the component tree.
  // eslint-disable-next-line react/forbid-prop-types
  plan: PropTypes.any,
  meta: PropTypes.shape({
    error: PropTypes.string,
    isLoading: PropTypes.bool,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isStateUpdateLoading: PropTypes.bool.isRequired,
  onPlayOrPauseButtonClicked: PropTypes.func.isRequired,
  onTrashButtonClicked: PropTypes.func.isRequired,
};

AssignedPlanDetailsModal.defaultProps = {
  plan: null,
};

const mapStateToProps = (state) => ({
  plan: state.tdpAutoPlansReducer.planDetails.plan,
  meta: {
    error: state.tdpAutoPlansReducer.planDetails.meta.error,
    isLoading: state.tdpAutoPlansReducer.planDetails.meta.isLoading,
  },
});

export default connect(mapStateToProps)(AssignedPlanDetailsModal);
