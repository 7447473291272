import React from 'react';
import OtherInteractDetails from './OtherInteractDetails';
import { FormGroup, Row, Col } from 'react-bootstrap';
import Error from '../../shared/Error';

class OtherInteractForm extends React.Component{

    render(){
      const { person, updateValues, interaction, personAvatar, errors} = this.props;
      return(
        <FormGroup>
          <Row style={{marginBottom: '10px'}}>
            <Col xs={8}>
              <div className='form-group contact-card'>
                <span className="tooltipper circled user-image default-avatar">
                  {personAvatar}
                </span>
                <p style={{fontSize: '16px', fontWeight: '600', color: '#666666' }}>
                    {person.first_name} { person.last_name}
                </p>
                <div
                  style={{fontSize: '14px',color: '#B3B3B3'}}
                  className='overflow'>
                  {person.main_email}
                </div>
              </div>
            </Col>
          </Row>
          {errors && <Error errorMessage={errors}/>}
          <OtherInteractDetails
            updateValues={updateValues}
            interaction={interaction}/>
        </FormGroup>
    )
  }
}

export default OtherInteractForm;
