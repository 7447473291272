import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import interact_css from "./person_interactions.module.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { viewPhoneFromFastJsonApi } from "../../shared/models/Communications/viewPhone";
import PersonInteractionEmailForm from "./PersonInteractionEmailForm";
import SmsConversation from "../../SmsConversation/components/SmsConversation";
import PersonOtherInteractionForm from "./PersonOtherInteractionForm";
import PersonInteractionsNoteForm from "./PersonInteractionsNoteForm";
import TaskForm from "../../Tasks/components/TaskForm";
import * as actions from "../actions/personDetailActionCreators";
import Loading from "../../Goals/components/Loader";
import { useMessagingServiceUrl } from "../../reducers/messagingReducer/selectors";
import { useCurrentUser, useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import SingleDialer from "../../Dialer/SingleDialer";
import Tooltip from "@shared/v2/Tooltip";

const TAB_PANEL_CLASS = "tw-rounded-b-[4px] tw-hidden tw-bg-white tw-p-[20px]";

const PersonInteractions = ({
  allAgents,
  gmailUnsynced,
  emailTemplates,
  appointmentOutcomes,
  accountMilestoneNames,
}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const person = useSelector((state) => state.personDetail.person);
  const gmailReplyThreadId = useSelector((state) => state.personDetail.gmailReplyThreadId);
  const gmailReplyThreadSubject = useSelector((state) => state.personDetail.gmailReplyThreadSubject);
  const additionalRecipientsVal = useSelector((state) => state.personDetail.additionalRecipientsVal);
  const CCval = useSelector((state) => state.personDetail.CCval);
  const BCCval = useSelector((state) => state.personDetail.BCCval);
  const sendEmailError = useSelector((state) => state.personDetail.sendEmailError);
  const isEmailSent = useSelector((state) => state.personDetail.isEmailSent);
  const selectedInteractionTab = useSelector((state) => state.personDetail.selectedInteractionTab);
  const defaultHomeAppTabContent = useSelector((state) => state.personDetail.defaultHomeAppTabContent);
  const task = useSelector((state) => state.personDetail.task);
  const messagingServiceUrl = useMessagingServiceUrl();
  const [appointmentErrors, setAppointmentErrors] = useState(null);

  const updatePersonData = (data) => dispatch(actions.updatePersonData(data));
  const touchHomeAppInviteSent = (id) => dispatch(actions.touchHomeAppInviteSent(id));
  const submitNote = (...args) => dispatch(actions.submitNote(...args));
  const submitEmail = (...args) => dispatch(actions.submitEmail(...args));
  const handleSubmitEmailError = (...args) => dispatch(actions.handleSubmitEmailError(...args));
  const addSmsToTimeline = (...args) => dispatch(actions.addSmsToTimeline(...args));
  const submitAppointment = (...args) => dispatch(actions.submitAppointment(...args));
  const closeAddAppointmentModal = () => dispatch(actions.closeAddAppointmentModal());
  const submitOtherInteraction = (...args) => dispatch(actions.submitOtherInteraction(...args));

  const showAppointmentErrors = (messages) => {
    setAppointmentErrors(messages);
  };

  const detailsObjects = (type) => {
    return person.included?.filter((a) => a.type === type).map((a) => a.attributes) || [];
  };

  const callTitle = (platform, disabled) => {
    if (!disabled) {
      return undefined;
    }
    if (!platform) {
      return "The dialer is a Brivity Platform feature - call (866) 295-3322 or email sales@brivity.com to upgrade!";
    }
    return "No Phone Number";
  };

  const textTitle = (platform, disabled) => {
    if (!disabled) {
      return undefined;
    }
    if (!platform) {
      return "Texting is a Brivity Platform feature - call (866) 295-3322 or email sales@brivity.com to upgrade!";
    }
    return "No Phone Number";
  };

  const selectInteractionTab = (tabIndex) => {
    if (tabIndex === 1) {
      dispatch(actions.setGmailReplyThread(null, null, [], [], []));
    }
    dispatch(actions.setInteractionTab(tabIndex));
    dispatch(actions.clearInteractionTabContent());
  };

  const emailDisabledMessage = () => {
    if (detailsObjects("email_detail").length < 1) {
      return "No Email Address";
    }
  };

  const setAgentsForModal = () => {
    const agents = [];
    for (const obj in allAgents) {
      const agent = allAgents[obj];
      agents.push({ label: agent.full_name, value: agent.uuid, email: agent.email });
    }
    return agents;
  };

  //TODO: if using defaultHomeAppTabContent for setting default tab content from somewhere other than Home App card, then we should rename this prop
  //See comments in PersonInteractionEmailForm and SmsConversation
  const emailTabDisabled = detailsObjects("email_detail").length < 1;
  const callTabDisabled =
    !featureFlags.platformAccount || person.data?.attributes.primary_phone_number.length < 1;
  const smsTabDisabled =
    !featureFlags.platformAccount || person.data?.attributes.primary_phone_number.length < 1;
  const default_agents = [{ label: "Anyone", value: "anyone" }];

  const phones =
    person.included?.filter((detail) => detail.type === "phone_detail").map(viewPhoneFromFastJsonApi) || [];
  const primaryPhone =
    phones.find((phone) => phone.isPrimary) ||
    // fallback logic, as some phone have null position and some collections have numbers, but not 0
    phones.sort((a, b) => a.position - b.position)[0];

  useEffect(() => {
    if (!person.data?.id) return;
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (tab) selectInteractionTab(parseInt(tab, 10));
  }, [person.data?.id]);

  return (
    <div className="tw-shadow-tdp-card tw-relative">
      {!person.data && <Loading />}
      <Tabs
        className={interact_css.interactTabsWrapper}
        selectedTabClassName={interact_css.activeTab}
        selectedIndex={selectedInteractionTab}
        onSelect={(index) => selectInteractionTab(index)}
      >
        <TabList className={interact_css.interactTabsList}>
          <Tab className={interact_css.interactTab}>Note</Tab>
          <Tab
            className={interact_css.interactTab}
            disabledClassName={interact_css.disabledTab}
            disabled={emailTabDisabled}
          >
            {emailTabDisabled ? (
              <Tooltip placement="bottom" trigger="Email" content={emailDisabledMessage()} />
            ) : (
              "Email"
            )}
          </Tab>
          <Tab
            className={interact_css.interactTab}
            disabledClassName={interact_css.disabledTab}
            disabled={callTabDisabled}
          >
            {callTabDisabled ? (
              <Tooltip
                placement="bottom"
                trigger="Call"
                content={callTitle(featureFlags.platformAccount, callTabDisabled)}
              />
            ) : (
              "Call"
            )}
          </Tab>
          <Tab
            className={interact_css.interactTab}
            disabledClassName={interact_css.disabledTab}
            disabled={smsTabDisabled}
          >
            {smsTabDisabled ? (
              <Tooltip
                placement="bottom"
                trigger="Text"
                content={textTitle(featureFlags.platformAccount, smsTabDisabled)}
              />
            ) : (
              "Text"
            )}
          </Tab>
          <Tab className={interact_css.interactTab}>Appointment</Tab>
          <Tab className={interact_css.interactTab}>Other</Tab>
        </TabList>

        <TabPanel className={TAB_PANEL_CLASS} selectedClassName="!tw-block">
          <div className="tw-flex tw-flex-col tw-max-h-[400px]">
            <PersonInteractionsNoteForm
              hideCancel
              person={person}
              submitNote={submitNote}
              notableId={person.data?.id}
              notableType="Person"
            />
          </div>
        </TabPanel>
        <TabPanel className={TAB_PANEL_CLASS} selectedClassName="!tw-block">
          <PersonInteractionEmailForm
            person={person}
            updatePersonData={updatePersonData}
            submitEmail={submitEmail}
            handleSubmitEmailError={handleSubmitEmailError}
            send_email_url={person.send_email_url}
            current_user_id={currentUser.id}
            email_templates={emailTemplates}
            gmailReplyThreadId={gmailReplyThreadId}
            additionalRecipientsVal={additionalRecipientsVal}
            CCval={CCval}
            BCCval={BCCval}
            gmailReplyThreadSubject={gmailReplyThreadSubject}
            sendEmailError={sendEmailError}
            isEmailSent={isEmailSent}
            defaultHomeAppTabContent={defaultHomeAppTabContent}
            gmailUnsynced={gmailUnsynced}
          />
        </TabPanel>
        <TabPanel className={TAB_PANEL_CLASS} selectedClassName="!tw-block">
          <SingleDialer leadUuid={person?.data?.attributes?.uuid} />
        </TabPanel>
        <TabPanel className={TAB_PANEL_CLASS} selectedClassName="!tw-block">
          <SmsConversation
            messagingServiceUrl={messagingServiceUrl}
            personUuid={person.data?.attributes.uuid}
            personId={person.data?.attributes.id}
            personName={`${person.data?.attributes.first_name} ${person.data?.attributes.last_name}`}
            personPhoneNumber={person.data?.attributes.primary_phone_number}
            addSmsToTimeline={addSmsToTimeline}
            defaultHomeAppTabContent={defaultHomeAppTabContent}
            touchHomeAppInviteSent={touchHomeAppInviteSent}
            primaryPhone={primaryPhone}
          />
        </TabPanel>
        <TabPanel className={`${TAB_PANEL_CLASS} interact-tab`} selectedClassName="!tw-block">
          <div className="tw-m-[-15px]">
            <TaskForm
              agents={default_agents.concat(setAgentsForModal())}
              accountUuid={currentUser.account.uuid}
              current_user_id={currentUser.id}
              showErrors={showAppointmentErrors}
              errors={appointmentErrors}
              person={person}
              appointment_outcomes={appointmentOutcomes}
              submitAppointment={submitAppointment}
              closeModal={closeAddAppointmentModal}
              isCreatingAppointment={true}
              task={task}
              accountMilestoneNames={accountMilestoneNames}
              user={currentUser}
              contactEmail={person.data?.attributes.main_email}
              hideCancel
            />
          </div>
        </TabPanel>
        <TabPanel className={`${TAB_PANEL_CLASS} interact-tab`} selectedClassName="!tw-block">
          <PersonOtherInteractionForm
            person={person}
            current_user_id={currentUser.id}
            submitOtherInteraction={submitOtherInteraction}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PersonInteractions;
