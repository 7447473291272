import React from "react";

const LocationResults = (props) => {
  const { locationType, searchResults, cursor, cursorCategory } = props;

  return (
    <div>
      <div className="result-label">{locationType === "CityState" ? "City" : "Zip"}</div>
      {searchResults[locationType].map((location, position) => {
        const positionClass = cursorCategory === locationType && cursor === position ? "highlight" : "";
        const prefix = locationType === "CityState" ? "city" : "zip";

        return (
          <div
            key={`${prefix}-${position}`}
            className={`result-item ${positionClass}`}
            onMouseOver={() => {
              props.handleHover(position, locationType);
            }}
            onMouseDown={() => {
              props.handleSelect(position, locationType);
            }}
          >
            {location}
          </div>
        );
      })}
    </div>
  );
};

export default LocationResults;
