import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { bool, func, object } from "prop-types";
import { chain, map, find, keys, isNil, isString, trim } from "lodash";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import TextComboInput from "@shared/v2/TextComboInput";
import FieldLabel from "@shared/v2/FieldLabel";
import RadioButton from "@shared/v2/RadioButton";
import TextInput from "@shared/v2/TextInput";
import { postEnvoyReferralApi } from "../../shared/api";
import { usePerson, useLenderModalOpen, useLastReferredDateTime } from "../../../reducers/personalDetailReducer/personDetailReducer";
import { setLastRefferedDateTime, toggleLenderModal, toggleSuccessReferLoanToast } from "../../actions/personDetailActionCreators";
import FilterStatesModal from "./FilterStatesModal";
import { produce } from "immer";
import Banner from "@shared/v2/Banner";
import { CircleInfoSolidV6, TriangleExclamationSolidV6 } from "@shared/v2/Icomoon";
import { useGetEnvoyReferralData } from "./hooks";
import { timeStampFormat } from "../Mortgage/Mortgage";
import Loading from "../../../Goals/components/Loader";

const STYLES = {
  hr: 'tw-border-t-gray-10 tw-my-24px',
  header: 'tw-text-14d tw-font-semibold tw-color-gray-75 tw-mb-8px',
  subHeader: 'tw-text-14d',
  formBody: "tw-flex tw-flex-col tw-gap-[24px] tw-mt-[24px]",
  flexGroup: "tw-flex tw-gap-[24px]",
}

const DEFAULT_FORM_DATA = {
  agent_email: null,
  agent_name: null,
  agent_phone: null,
  team_name: null,
  brokerage: null,
  best_time_to_call: 'Mornings',
  notes: null,
  budget: null,
  email: null,
  first_name: null,
  last_name: null,
  hub_spot_owner_id: 'First Available',
  loan_type: 'Buy',
  phone: null,
  source_name: null,
  state: null,
  timeframe: 'ASAP'
}

function validateEnvoyReferralProperties(payload) {
  const referral = payload;
  const IGNORE_KEYS = ['notes', 'budget', 'lastReferredDate'];

  const invalidProperties = keys(referral).filter(key => {
    if (IGNORE_KEYS.includes(key)) {
      return false;
    }
    const value = referral[key];

    return isNil(value) || (isString(value) && trim(value) === '');
  });


  return invalidProperties;
}

const ReferLenderModal = ({ show, onSubmit, onHide, personId, initFormData, isSubmitting, hasError, initFormOptions, isLoading }) => {
  const [showErrors, setErrors] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [isFilteredStatesModalOpen, setIsFilteredStatesModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    envoy_referral: { ...DEFAULT_FORM_DATA }
  });
  const lastRefferedDateTime = useLastReferredDateTime();

  useEffect(() => {
    setFormData({ envoy_referral: { ...DEFAULT_FORM_DATA, ...initFormData } });
  }, [initFormData])

  useEffect(() => {
    if (personId === null || !show) {
      setFormData({ envoy_referral: { ...DEFAULT_FORM_DATA, ...initFormData } });
      setErrors([]);
      return;
    }
  }, [personId, show]);

  const setFormDataValue = (key, value) => {
    setFormData(produce(formData, (draft) => {
      draft.envoy_referral[key] = value;
    }));
  }

  const onSubmitHandlr = () => {
    const invalidProperties = validateEnvoyReferralProperties(formData.envoy_referral)
    if (invalidProperties.length) return setErrors(invalidProperties);

    onSubmit(formData);
  }

  const loanOfficersOptions = useMemo(() => {
    if (filteredStates.length) {
      return chain(filteredStates)
        .flatMap(({ abbreviation }) =>
          map(initFormOptions?.loanOfficersByState[abbreviation], index => initFormOptions.originalLOList[index])
        )
        .uniqBy('email')
        .map(officer => ({
          label: officer.name,
          value: officer.id
        }))
        .value();
    }

    return [{ label: 'First Available', value: 'First Available' }, ...initFormOptions?.originalLOList?.map(({ name, id }) => ({ label: name, value: id }))]
  }, [filteredStates, initFormOptions.originalLOList]);

  const selectedLoanOfficer = useMemo(() => {
    if (formData.envoy_referral.hub_spot_owner_id === 'First Available') return { name: 'First Available', id: 'First Available' };

    return find(initFormOptions.originalLOList, { id: formData.envoy_referral.hub_spot_owner_id })
  }, [formData.envoy_referral.hub_spot_owner_id])

  const removeErrorHandlr = (error) => {
    setErrors(showErrors.filter(err => err !== error));
  }

  const showErrorHandlr = (errName) => showErrors.includes(errName) && 'This field is required'

  return (
    <>
      <FilterStatesModal
        show={isFilteredStatesModalOpen}
        onHide={() => setIsFilteredStatesModalOpen(false)}
        onSubmit={(arrayOfStates) => {
          setFilteredStates(arrayOfStates)
          setFormDataValue('hub_spot_owner_id', null)
        }}
        filteredStates={filteredStates} />
      <Modal
        show={show}
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-w-[616px] tw-max-h-[700px]"
        onHide={onHide}
        closeButton
      >
        <Modal.Header title="Refer Lead to Envoy Mortgage" className="tw-mb-32px" closeButton />
        <Banner containerClassName='tw-mb-[32px]' show={(hasError || Boolean(showErrors.length))} hasClose>
          <div className="tw-text-14d tw-flex tw-items-center tw-gap-[16px]">
            <TriangleExclamationSolidV6 size='l' className='tw-text-[#E5342E]' />
            <span className="tw-text-[#A81E19]">
              There was a problem submitting the referral. Please try again.
            </span>
          </div>
        </Banner>
        <Banner containerClassName='tw-mb-[32px] !tw-bg-gray-5 !tw-border-gray-10' closeIconClassName='!tw-text-gray-75' show={Boolean(lastRefferedDateTime)} hasClose>
          <div className="tw-text-14d tw-flex tw-items-center tw-gap-[16px]">
            <CircleInfoSolidV6 size='l' className='tw-text-gray-50' />
            <span className="tw-text-gray-75">
              Last referral submitted on <b>{timeStampFormat(lastRefferedDateTime)}</b>
            </span>
          </div>
        </Banner>
        <Modal.Body className="tw-overflow-y-auto tw-h-[345px]">
          {isLoading ? <Loading /> :
            <>
              <div className="tw-flex tw-gap-[8px]">
                <Dropdown
                  label='Select Loan Officer'
                  isSearchable
                  options={loanOfficersOptions}
                  containerClassName="tw-flex-1"
                  backspaceRemovesValue={false}
                  value={selectedLoanOfficer && {
                    label: selectedLoanOfficer?.name,
                    value: selectedLoanOfficer?.id
                  }}
                  onChange={(e) => {
                    removeErrorHandlr('hub_spot_owner_id');
                    setFormDataValue('hub_spot_owner_id', e.value);
                  }}
                  error={showErrorHandlr('hub_spot_owner_id')}
                  isRequired />
                <Button
                  schema={filteredStates.length ? 'primary' : 'secondary'}
                  size='medium'
                  className='tw-mt-[28px]'
                  onClick={() => setIsFilteredStatesModalOpen(true)}>
                  {`Filter${filteredStates.length ? 'ed' : ''}`}
                </Button>
              </div>
              <hr className={STYLES.hr} />
              <div className={STYLES.header}>Lead Information</div>
              <div className={STYLES.subHeader}>Enter the contact information of the person you are referring.</div>
              <div className={STYLES.formBody}>
                <div className={STYLES.flexGroup}>
                  <TextComboInput
                    label="First Name"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="first_name"
                    value={formData.envoy_referral.first_name}
                    onChange={(e) => {
                      removeErrorHandlr('first_name');
                      setFormDataValue("first_name", e.target.value)
                    }}
                    error={showErrorHandlr('first_name')}
                  />
                  <TextComboInput
                    label="Last Name"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="last_name"
                    value={formData.envoy_referral.last_name}
                    onChange={(e) => {
                      removeErrorHandlr('last_name');
                      setFormDataValue("last_name", e.target.value)
                    }}
                    error={showErrorHandlr('last_name')}

                  />
                </div>
                <div className={STYLES.flexGroup}>
                  <TextComboInput
                    label="Email Address"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="email_address"
                    value={formData.envoy_referral.email}
                    onChange={(e) => {
                      removeErrorHandlr('email');
                      setFormDataValue("email", e.target.value)
                    }}
                    error={showErrorHandlr('email')}
                  />
                  <TextComboInput
                    label="Phone Number"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="phone"
                    value={formData.envoy_referral.phone}
                    onChange={(e) => {
                      removeErrorHandlr('phone');
                      setFormDataValue("phone", e.target.value)
                    }}
                    error={showErrorHandlr('phone')}
                  />
                </div>
                <div className={STYLES.flexGroup}>
                  <Dropdown
                    label='Source'
                    containerClassName="tw-flex-1"
                    options={initFormOptions.sourceOptions}
                    onChange={(e) => {
                      removeErrorHandlr('source_name');
                      setFormDataValue("source_name", e.value)
                    }}
                    error={showErrorHandlr('source_name')}
                    value={formData.envoy_referral.source_name && { label: formData.envoy_referral.source_name, value: formData.envoy_referral.source_name }}
                    placeholder="Select"
                    backspaceRemovesValue={false}
                    isSearchable
                    isRequired
                  />
                  <Dropdown
                    label='What State is the Property In?'
                    containerClassName="tw-flex-1"
                    backspaceRemovesValue={false}
                    placeholder="Select State"
                    options={initFormOptions.stateOptions}
                    onChange={(e) => {
                      removeErrorHandlr('state');
                      setFormDataValue("state", e.value)
                    }}
                    error={showErrorHandlr('state')}
                    value={formData.envoy_referral.state && { label: formData.envoy_referral.state, value: formData.envoy_referral.state }}
                    isSearchable
                    isRequired
                  />
                </div>
                <div className={STYLES.flexGroup}>
                  <Dropdown
                    label='Best Time to Call'
                    containerClassName="tw-flex-1"
                    backspaceRemovesValue={false}
                    isRequired
                    options={initFormOptions.bestTimeToCallOptions}
                    placeholder="Select"
                    value={formData.envoy_referral.best_time_to_call && { label: formData.envoy_referral.best_time_to_call, value: formData.envoy_referral.best_time_to_call }}
                    onChange={(e) => {
                      removeErrorHandlr('best_time_to_call');
                      setFormDataValue("best_time_to_call", e.value)
                    }}
                    error={showErrorHandlr('best_time_to_call')}
                  />
                  <TextComboInput
                    label="Budget"
                    containerClassName="tw-flex-1"
                    data-cy="budget"
                    onChange={(e) => {
                      removeErrorHandlr('budget');
                      setFormDataValue("budget", e.target.value)
                    }}
                  />
                </div>
                <div className={STYLES.flexGroup}>
                  <Dropdown
                    label='Timeframe'
                    containerClassName="tw-flex-1"
                    backspaceRemovesValue={false}
                    options={initFormOptions.timeFrameOptions}
                    onChange={(e) => {
                      removeErrorHandlr('timeframe');
                      setFormDataValue("timeframe", e.value)
                    }}
                    error={showErrorHandlr('timeframe')}
                    placeholder="Select"
                    value={formData.envoy_referral.timeframe && { label: formData.envoy_referral.timeframe, value: formData.envoy_referral.timeframe }}
                    isRequired
                  />
                  <div className="tw-flex-1">
                    <FieldLabel
                      className='tw-mb-8px'
                      label='Buy or Refinance?'
                      isRequired
                    />
                    <div className="tw-flex tw-gap-24px">
                      <RadioButton
                        value='Buy'
                        label='Buy'
                        className='tw-cursor-pointer'
                        checked={formData.envoy_referral.loan_type === 'Buy'}
                        onClick={() => setFormDataValue('loan_type', 'Buy')}
                      />
                      <RadioButton
                        value='Refinance'
                        label='Refinance'
                        className='tw-cursor-pointer'
                        checked={formData.envoy_referral.loan_type === 'Refinance'}
                        onClick={() => setFormDataValue('loan_type', 'Refinance')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className={STYLES.hr} />
              <div className={STYLES.header}>Agent Information</div>
              <div className={STYLES.subHeader}>Enter your contact information.</div>
              <div className={STYLES.formBody}>
                <Dropdown
                  label='Referring Agent'
                  isSearchable
                  backspaceRemovesValue={false}
                  options={initFormOptions.referringAgentOptions}
                  value={{
                    label: formData.envoy_referral.agent_name,
                    value: {
                      agentEmail: formData.envoy_referral.agent_email,
                      agentPhone: formData.envoy_referral.agent_phone
                    }
                  }}
                  onChange={(e) => {
                    removeErrorHandlr('agent_name');
                    const {
                      label,
                      value: { agentEmail, agentPhone }
                    } = e;
                    setFormData(prev => produce(prev, draft => {
                      draft.envoy_referral.agent_name = label;
                      draft.envoy_referral.agent_email = agentEmail;
                      draft.envoy_referral.agent_phone = agentPhone;
                    }))
                  }}
                  error={showErrorHandlr('agent_name')}
                  isRequired />
                <div className={STYLES.flexGroup}>
                  <TextComboInput
                    label="Email Address"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="agent_email_address"
                    value={formData.envoy_referral.agent_email}
                    onChange={(e) => {
                      removeErrorHandlr('agent_email');
                      setFormDataValue("agent_email", e.target.value);
                    }}
                    error={showErrorHandlr('agent_email')}
                  />
                  <TextComboInput
                    label="Phone Number"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="agent_phone_number"
                    value={formData.envoy_referral.agent_phone}
                    onChange={(e) => {
                      removeErrorHandlr('agent_phone');
                      setFormDataValue("agent_phone", e.target.value);
                    }}
                    error={showErrorHandlr('agent_phone')}
                  />
                </div>
                <div className={STYLES.flexGroup}>
                  <TextComboInput
                    label="Team Name"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="agent_team_name"
                    value={formData.envoy_referral.team_name}
                    onChange={(e) => {
                      removeErrorHandlr('team_name');
                      setFormDataValue("team_name", e.target.value);
                    }}
                    error={showErrorHandlr('team_name')}
                  />
                  <TextComboInput
                    label="Brokerage Name"
                    isRequired
                    containerClassName="tw-flex-1"
                    data-cy="agent_brokerage_name"
                    value={formData.envoy_referral.brokerage}
                    onChange={(e) => {
                      removeErrorHandlr('brokerage');
                      setFormDataValue("brokerage", e.target.value);
                    }}
                    error={showErrorHandlr('brokerage')}
                  />
                </div>
                <TextInput label="Additional Notes" multiline name="agent_additional_notes" className='tw-h-[144px]' onChange={(e) => {
                  setFormDataValue("notes", e.target.value);
                }} />
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[32px]">
          <Button size="medium" schema="tertiary" onClick={onHide}>
            CANCEL
          </Button>
          <Button size="medium" schema="primary" onClick={onSubmitHandlr} isLoading={isSubmitting} disabled={showErrors.length}>
            SUBMIT
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

ReferLenderModal.propTypes = {
  show: bool,
  onSubmit: func,
  onHide: func,
  onSetPayload: func,
  isSubmitting: bool,
  hasError: bool,
  initFormOptions: object,
  initFormData: object,
  isLoading: bool,
}

ReferLenderModal.defaultProps = {
  show: false,
  onSubmit: () => { },
  onHide: () => { },
  onSetPayload: () => { },
  isSubmitting: false,
  hasError: false,
  initFormOptions: {
    loanOfficersByState: {},
    originalLOList: [],
  },
  initFormData: {},
  isLoading: true,
}



export default () => {
  const lenderModalOpen = useLenderModalOpen();
  const person = usePerson();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const toggleLenderModalHandlr = () => dispatch(toggleLenderModal());
  const toggleSuccessReferLoanToastHandlr = () => dispatch(toggleSuccessReferLoanToast());
  const updateLastRefferedDateTime = (dateTime) => dispatch(setLastRefferedDateTime(dateTime));

  const {
    formOptionsFromApi,
    formDataFromApi,
    isLoading,
    setFormDataFromApi
  } = useGetEnvoyReferralData(person.data?.id);

  useEffect(() => {
    updateLastRefferedDateTime(formDataFromApi.lastReferredDate);
  }, [formDataFromApi.lastReferredDate])

  return (
    <ReferLenderModal
      show={lenderModalOpen}
      onHide={() => {
        toggleLenderModalHandlr()
        setHasError(false);
      }}
      personId={person.data?.id}
      isSubmitting={isSubmitting}
      hasError={hasError}
      initFormData={{
        ...formDataFromApi,
        email: person?.data?.attributes?.main_email,
        first_name: person?.data?.attributes?.first_name,
        last_name: person?.data?.attributes?.last_name,
        phone: person?.data?.attributes?.primary_phone_number,
        source_name: person?.data?.attributes?.source,
      }}
      initFormOptions={formOptionsFromApi}
      isLoading={isLoading}
      onSubmit={
        async (payload) => {
          setIsSubmitting(true);
          const { res, err } = await postEnvoyReferralApi(person.data?.id, payload);

          setHasError(Boolean(err));
          setIsSubmitting(false);

          if (res) {
            toggleLenderModalHandlr();
            toggleSuccessReferLoanToastHandlr();
            updateLastRefferedDateTime(res.data.last_envoy_referral?.referred_at)
            setFormDataFromApi(prev => ({
              ...prev,
              hub_spot_owner_id: res.data.default_hub_spot_owner_id,
            }))

            setTimeout(() => {
              toggleSuccessReferLoanToastHandlr();
            }, 3000);
          }
        }
      }
    />
  )
};
