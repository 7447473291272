/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Modal, Row, Col } from "react-bootstrap";
import CmaReport from ".";
import IconButton from "../shared/v2/IconButton";

function CmaReportPreview({
  deleteReportFn,
  modalIsOpen,
  person_status,
  person_lead_type,
  person_address,
  person_avatar,
  cmaUrl,
  person,
  onClose,
}) {
  const [modalIsOpenstate, setmodalIsOpenstate] = useState(false);
  const [cmaReports, setCmaReports] = useState([]);

  const fetchCmaReports = () => {
    const { uuid } = person;
    axios.get(`/cma_reports?uuid=${uuid}`).then((res) => setCmaReports(res.data));
  };

  const openCmaReportModal = () => {
    setmodalIsOpenstate(true);
  };

  useEffect(() => {
    if (modalIsOpen) {
      openCmaReportModal();
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (modalIsOpenstate) {
      fetchCmaReports();
    }
  }, [modalIsOpenstate]);

  const closeCmaReportModal = () => {
    setCmaReports([]);
    setmodalIsOpenstate(false);
    if (onClose) {
      onClose();
    }
  };

  const deleteReportFunc = (id) => {
    if (deleteReportFn(id)) {
      setCmaReports((prevState) => prevState.filter((r) => r.id !== id));
    } else {
      alert("Something went wrong...");
    }
  };

  const renderCmaReportModal = () => (
    <Modal show={modalIsOpenstate} onHide={closeCmaReportModal}>
      <div className="tw-flex tw-mt-[32px] tw-mx-[32px]">
        <h2 className="tw-flex-grow m-0 tw-text-[28px] tw-text-neutral-gray-75">CMA Reports</h2>
        <IconButton schema="tertiary" onClick={closeCmaReportModal}>
          <i className="fa fa-close" />
        </IconButton>
      </div>
      <Modal.Body id="idx-modal-body">
        <div className="tw-flex tw-items-center">
          <span className="tw-mr-[12px]" dangerouslySetInnerHTML={{ __html: person_avatar }} />
          <div className="tw-flex-1">
            <div className="tw-flex tw-items-center tw-space-x-[12px]">
              <span className="tw-text-[14px] tw-text-neutral-gray-75 tw-font-semibold">
                {!person?.first_name && !person?.last_name
                  ? "No Name"
                  : `${person?.first_name} ${person?.last_name}`}
              </span>
              {person_lead_type &&
                person_lead_type.split("/").map((t) => (
                  <div key={t} className={`intent-box ${t.toLowerCase()}`}>
                    {t}
                  </div>
                ))}
              <button
                type="button"
                className={`status btn btn-sm ${person_status?.toLowerCase()} tw-text-white tw-px-[23px] tw-py-[4px] tw-leading-4 tw-rounded-[12px] tw-text-[11px]`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {person_status}
              </button>
            </div>
            {person_address && <div className="tw-text-[14px] tw-text-neutral-gray-75">{person_address}</div>}
          </div>
        </div>
        <div className="tw-h-[1px] tw-w-full tw-my-[16px] tw-bg-neutral-gray-10" />
        <Row id="listing-grid">
          {cmaReports &&
            cmaReports.map((report) => (
              <Col key={report.id} xs={6} className="m-b-20">
                <CmaReport
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...report}
                  deleteReportFn={(id) => deleteReportFunc(id)}
                  cmaUrl={cmaUrl}
                  openCmaReportModal={() => {
                    openCmaReportModal();
                    closeCmaReportModal();
                  }}
                />
              </Col>
            ))}
        </Row>
      </Modal.Body>
    </Modal>
  );

  return <div className="flex-row d-flex space-evenly flex-justify-center">{renderCmaReportModal()}</div>;
}

CmaReportPreview.propTypes = {
  deleteReportFn: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  person_status: PropTypes.string,
  person_lead_type: PropTypes.string,
  person_address: PropTypes.string,
  person_avatar: PropTypes.string,
  cmaUrl: PropTypes.string,
  person: PropTypes.shape({
    uuid: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  openCmaReportModal: PropTypes.func,
  onClose: PropTypes.func,
};

CmaReportPreview.defaultProps = {
  deleteReportFn: null,
  modalIsOpen: false,
  person: {},
  openCmaReportModal: null,
  onClose: null,
  person_status: "",
  person_lead_type: "",
  person_address: "",
  person_avatar: "",
  cmaUrl: "",
};

export default CmaReportPreview;
