import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../shared/v2/Modal";
import { Table, TBody, TH, THead, TR } from "../../../Reports/components/BusinessDashboard/Table/Table";
import Loader from "../Loader";
import ErrorBanner from "../../../shared/v2/ErrorBanner";
import Button from "../../../shared/v2/Button";
import ActivityHistoryRow, { ROW_BORDER } from "./ActivityHistoryRow";
import useActivityHistory from "../../services/useActivityHistory";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const ViewActivityHistoryModal = ({ goalType, onClose, userUuids }) => {
  const currentUser = useCurrentUser();
  const { actions, data, error, loading } = useActivityHistory(goalType, userUuids);

  return (
    <Modal
      show={Boolean(goalType)}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onHide={onClose}
      dialogClassName="tw-flex tw-justify-center tw-items-center tw-min-h-full tw-max-h-full"
      contentClassName="tw-space-y-[32px] tw-m-auto tw-max-w-full"
    >
      <Modal.Header title={goalType?.displayName} closeButton />
      <Modal.Body className="tw-flex tw-flex-col tw-gap-[16px] tw-max-h-[50vh] tw-overflow-auto">
        <Table className="tw-border-collapse">
          <THead>
            <TR>
              <TH className={ROW_BORDER}>Logged By</TH>
              <TH className={ROW_BORDER}># of Activity</TH>
              <TH className={ROW_BORDER}>Activity Date</TH>
              <TH className={ROW_BORDER}>Notes</TH>
              <TH className={ROW_BORDER}>Edit</TH>
              <TH className={ROW_BORDER}>Delete</TH>
            </TR>
          </THead>
          <TBody>
            {goalType &&
              data?.map((activity) => (
                <ActivityHistoryRow
                  activity={activity}
                  isEditable={currentUser.id === activity.user.id}
                  onDelete={actions.deleteActivity}
                  onUpdate={actions.updateActivity}
                />
              ))}
          </TBody>
        </Table>
        {data?.length === 0 && <p>Logged activities will show up here.</p>}
        {error && <ErrorBanner errors={["Whoops! Something went wrong."]} />}
        {loading && <Loader />}
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-end tw-items-center">
        <Button size="medium" onClick={onClose}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ViewActivityHistoryModal.propTypes = {
  goalType: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ViewActivityHistoryModal.defaultProps = {
  goalType: null,
};

export default ViewActivityHistoryModal;
