import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@shared/v2/Button";
import { scrollElementToTop } from "@shared/Utilities";
import * as actions from "../actions/personDetailActionCreators";
import PersonInteractions from "./PersonInteractions";
import PersonInfo from "./PersonInfo";
import AssignedTo from "./assigned_to/AssignedTo";
import Details from "./Details";
import ContactInfo from "./ContactInfo";
import Documents from "./Documents";
import Relationships from "./Relationships";
import Transactions from "./Transactions";
import WebActivity from "./WebActivity";
import HomeApp from "./HomeApp";
import AutoPlans from "./auto_plans/AutoPlans";
import ListingAlertCard from "./listing_alerts/ListingAlertCard";
import MarketReports from "./MarketReports";
import CmaReports from "./CmaReports";
import CardTasks from "./tasks/CardTasks";
import CardAppointments from "./tasks/CardAppointments";
import Projects from "./Projects";
import Mortgage from "./Mortgage";
import HubspotRequestDemoModal from "../../shared/HubspotRequestDemoModal";
import AddMarketReportModal from "../../AddMarketReportModal";
import CardNotes from "./notes/CardNotes";
import TimelinesApp from "../../Timelines/components/TimelinesApp";
import AiSummary from "@shared/AiAssistant/AiSummary";
import PersonDetailsModal from "../../Contacts/PersonDetailsModal";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import Agreements from "./Agreements";
import ReferLenderModal from "./ReferLenderModal";
import { CheckSolidV6 } from "@shared/v2/Icomoon";
import { useSuccessMessageOpen } from "../../reducers/personalDetailReducer/personDetailReducer";
import viewPhone from "@shared/models/Communications/viewPhone";

const PersonDetail = ({
  allAgents,
  newMilestoneId,
  gmailUnsynced,
  emailTemplates,
  appointmentOutcomes,
  accountMilestoneNames,
  idxDataToken,
  idxDataUri,
}) => {
  const dispatch = useDispatch();
  const editPersonModalOpen = useSelector((state) => state.personDetail.editPersonModalOpen);
  const person = useSelector((state) => state.personDetail.person);
  const error = useSelector((state) => state.personDetail.error);
  const featureFlags = useFeatureFlags();
  const [state, setState] = useState({ platformCTAOpen: false, marketReportsRefreshKey: 0 });
  const [clickedPhoneNo, setClickedPhoneNo] = useState();
  const successMessageOpen = useSuccessMessageOpen();
  const primaryPhoneNumber = useMemo(
    () =>
      (person.included?.filter((i) => i.type === "phone_detail").map((i) => viewPhone(i)) || []).find(
        (i) => i.isPrimary,
      ),
    [person.included],
  );

  const marketReportPerson = useMemo(
    () => ({
      ...person,
      emails: person.included
        ?.filter((i) => i.type === "email_detail")
        .map((i) => ({
          address: i.attributes.value,
          category: i.attributes.detailable_type,
        })),
      addresses: person.included
        ?.filter((i) => i.type === "address" && i.id !== null)
        .map(
          (i) =>
            `${i.attributes?.street_address} ${i.attributes?.city}, ${i.attributes?.locality} ${i.attributes?.postal_code}`,
        ),
    }),
    [person.data],
  );

  const loadData = () => {
    const slug = window.location.pathname.split("/").pop();
    dispatch(actions.getPerson(slug));
    dispatch(actions.getNavLinks(slug));
    dispatch(actions.fetchProjects(slug));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!person.data?.id) return;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("edit")) {
      dispatch(actions.openEditPersonModal());
    }
  }, [person.data?.id]);

  useEffect(() => {
    const firstName = person.data?.attributes.first_name || "";
    const lastName = person.data?.attributes.last_name || "";
    document.title = `${firstName} ${lastName}`.trim() || "No Name";
  }, [person.data?.attributes.first_name, person.data?.attributes.last_name]);

  const openPlatformCTA = () => setState((prevState) => ({ ...prevState, platformCTAOpen: true }));

  const closePlatformCTA = () => setState((prevState) => ({ ...prevState, platformCTAOpen: false }));

  const editMarketReport = (id) => {
    setState((prevState) => ({ ...prevState, editMarketReportId: id, showAddModal: !!id }));
  };

  const toggleAddMarketModal = () => {
    setState((prevState) => ({
      marketReportsRefreshKey: prevState.showAddModal
        ? prevState.marketReportsRefreshKey + 1
        : prevState.marketReportsRefreshKey,
      showAddModal: !prevState.showAddModal,
      editMarketReportId: null,
    }));
  };

  const openCallTab = (data) => {
    dispatch(actions.setInteractionTab(2));
    setClickedPhoneNo(data);
    scrollElementToTop("person-details");
  };

  return (
    <div className="tw-overflow-auto tw-h-full tw-relative">
      {successMessageOpen && (
        <div className="tw-fixed tw-left-1/2 tw-w-[226px] tw-h-[40px] tw-bg-[#EDFAF1] tw-border tw-border-solid tw-border-[#CAF0D6] tw-rounded-md tw-z-2 tw-flex tw-justify-center tw-items-center tw-gap-[8px]">
          <CheckSolidV6 size="l" className="tw-text-[#36B25F]" />
          <span className="tw-text-12d tw-text-[#177335]">Referral submitted successfully!</span>
        </div>
      )}
      {featureFlags.lenderReferral && <ReferLenderModal />}
      <div className="tw-max-w-[1650px] tw-mx-auto tw-flex tw-p-[16px] tw-gap-[16px]">
        <div id="person-details" className="tw-min-w-[280px] tw-max-w-[375px] tw-flex-1">
          <PersonInfo />
          <ContactInfo openCallTab={openCallTab} />
          <Details />
          <Relationships />
          <CardNotes />
          <Transactions newMilestoneId={newMilestoneId} />
          <Documents />
          <Projects />
        </div>

        <div className="tw-min-w-[654px] tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
          {error && (
            <div className="tw-bg-semantic-red-5 tw-p-[12px] tw-flex tw-justify-between tw-gap-[8px]">
              <span>Something went wrong. Please try again.</span> <Button onClick={loadData}>Refresh</Button>
            </div>
          )}

          {featureFlags.gabbiAi && (
            <AiSummary
              leadPersonUuid={person.data?.attributes?.uuid}
              phoneStatus={primaryPhoneNumber?.aiStatus || "missing"}
            />
          )}

          <PersonInteractions
            allAgents={allAgents}
            clickedPhoneNo={clickedPhoneNo}
            gmailUnsynced={gmailUnsynced}
            emailTemplates={emailTemplates}
            appointmentOutcomes={appointmentOutcomes}
            accountMilestoneNames={accountMilestoneNames}
          />

          <div className="tw-shadow-tdp-card col-xs-12">
            <TimelinesApp newDetailsPage />
          </div>
        </div>

        <div className="tw-min-w-[280px] tw-max-w-[375px] tw-flex-1">
          <AssignedTo />
          {featureFlags.lenderReferral && <Mortgage />}
          <WebActivity
            idxDataUri={idxDataUri}
            idxDataToken={idxDataToken}
            openPlatformCTA={openPlatformCTA}
          />
          <Agreements />
          <CardAppointments allAgents={allAgents} accountMilestoneNames={accountMilestoneNames} />
          <HomeApp />
          <CardTasks accountMilestoneNames={accountMilestoneNames} allAgents={allAgents} />
          <AutoPlans openPlatformCTA={openPlatformCTA} />
          <ListingAlertCard openPlatformCTA={openPlatformCTA} />
          <MarketReports
            refreshKey={state.marketReportsRefreshKey}
            editMarketReport={(id) => editMarketReport(id)}
            openMarketReport={() => toggleAddMarketModal()}
          />
          <CmaReports />
        </div>

        <PersonDetailsModal
          show={editPersonModalOpen}
          personId={person.data?.attributes.id}
          modalType={
            ["Agent", "Administrator", "Account Owner"].includes(person?.personType) ? "user" : "lead"
          }
          onClose={() => {
            dispatch(actions.closeEditPersonModal());
            dispatch(actions.getPerson(person.data?.attributes.slug));
          }}
        />

        <HubspotRequestDemoModal show={state.platformCTAOpen} closeModal={closePlatformCTA} />
        {state.showAddModal && (
          <AddMarketReportModal
            person={marketReportPerson}
            isOpen
            close={toggleAddMarketModal}
            primaryAgent={person.data?.attributes.primary_agent_uuid}
            id={state.editMarketReportId}
          />
        )}
      </div>
    </div>
  );
};

export default PersonDetail;
