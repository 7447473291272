import React, { useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import * as creators from "../../actions/creators";
import { useOnSelectedChange, useSelectedTypesError, usePropTypesChangeOmit } from "./hookHelpers";

import { useDebounce } from "../../../shared/hookHelpers";
import PropertyList from "./list";

const PropertyTypesSelector = ({ value, onChange, setFormError, propertyTypeOptions, waitingForMLS }) => {
  const [selectedTypes, setSelected] = useState(value);
  const hasError = Object.keys(selectedTypes).length === 0 && !waitingForMLS;

  const debouncedSelectedTypes = useDebounce(selectedTypes, 500);
  useOnSelectedChange(debouncedSelectedTypes, hasError, onChange);
  usePropTypesChangeOmit({ selectedTypes, propertyTypeOptions, setSelected, waitingForMLS });
  useSelectedTypesError({ selectedTypes, setFormError });
  return (
    <div data-cy="ptypes-wrapper" className="tw-mb-[24px]">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">
        Property Types
        <sup className={`tw-text-red-500 tw-ml-1 ${!hasError && "tw-invisible"}`}>*</sup>
      </span>
      <PropertyList
        propertyTypeOptions={propertyTypeOptions}
        setSelected={setSelected}
        selectedTypes={selectedTypes}
        hasError={hasError}
      />
      {!waitingForMLS && !propertyTypeOptions && (
        <div className={`tw-mb-[8px] tw-text-12d ${hasError ? "tw-text-red-500" : "tw-text-gray-75"}`}>
          There are no property types available for the selected MLS
        </div>
      )}
      <input type="hidden" name="property_type" value={Object.keys(selectedTypes).join(",")} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  propertyTypeOptions:
    state.addMarketReport.form1.mls_data_source_id &&
    state.addMarketReport.mlsToPropertyTypes &&
    state.addMarketReport.mlsToPropertyTypes[state.addMarketReport.form1.mls_data_source_id],
  waitingForMLS: state.addMarketReport.formErrors.mls_data_source_id,
});

export default connect(mapStateToProps, creators)(PropertyTypesSelector);

PropertyTypesSelector.propTypes = {
  onChange: Proptypes.func,
  value: Proptypes.shape({ name: Proptypes.bool }),
  setFormError: Proptypes.func,
  propertyTypeOptions: Proptypes.arrayOf(Proptypes.string),
  waitingForMLS: Proptypes.bool,
};

PropertyTypesSelector.defaultProps = {
  onChange: () => {},
  value: {},
  setFormError: () => {},
  propertyTypeOptions: null,
  waitingForMLS: false,
};
