import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { Overlay } from "../../../../shared/v1";
import Button from "../../../../shared/v2/Button";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import { CREATE_MEDIA_OPTIONS } from "../api/helpers";

const MarketingHeaderOptions = ({ mlsID }) => {
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleToggle = (event) => {
    setshow(!show);
    setTarget(event.target);
  };

  return (
    <>
      <div ref={ref} className="tw-h-[24px] tw-flex tw-ml-auto">
        <Button
          onClick={handleToggle}
          className="tw-flex tw-gap-4px tw-items-center"
          schema="primary"
          size="small"
        >
          <Plus size="s" />
          CREATE MEDIA
        </Button>
      </div>
      <Overlay
        rootClose
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
        onHide={() => setshow(false)}
      >
        <div className="tw-p-0 tw-font-normal tw-leading-[20px] tw-text-[14px] tw-shadow-bootstrap-18 tw-shrink-0 tw-bg-white tw-px-[0px] tw-absolute tw-ml-[-0.7%] tw-mt-[15px] tw-rounded-[2px] tw-z-1 tw-text-left tw-text-gray-75">
          {CREATE_MEDIA_OPTIONS.map(
            (option, i) =>
              (mlsID || option.name !== "Social Media Ad") && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={() => window.open(option.url, "_blank")}
                  className="tw-h-[32px] tw-w-[138px] hover:tw-bg-tinted-gray-50 active:tw-bg-gray-lightest"
                >
                  <div className="tw-px-[12px] tw-py-[6px] tw-cursor-pointer tw-h-[32px]">{option.name}</div>
                </div>
              ),
          )}
        </div>
      </Overlay>
    </>
  );
};

MarketingHeaderOptions.propTypes = {
  mlsID: Proptypes.string,
};
MarketingHeaderOptions.defaultProps = {
  mlsID: null,
};
const mapStateToProps = (state) => ({
  mlsID: state.tdpMarketingReducer.mlsID,
});

export default connect(mapStateToProps)(MarketingHeaderOptions);
