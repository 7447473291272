import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EmailView from "./EmailView";
import Button from "../../../shared/v2/Button";
import TextButton from "../../../shared/v2/TextButton";
import { Plus } from "../../../shared/v2/Icomoon";
import { useFilterListOptions } from "../../../Settings/Users/helper/hookHelper";

const EmailTypeOptions = [
  { label: "Personal", value: "personal", isTaken: false, index: null },
  { label: "Work", value: "work", isTaken: false, index: null },
  { label: "Other", value: "other", isTaken: false, index: null },
];

function AddEmailSection({ required, emailDetails, setPersonValue, isDisplayByDefault }) {
  const [isAddActive, setIsAddActive] = useState(false);
  const [emailOptions, setEmailOptions] = useState([]);

  useFilterListOptions(EmailTypeOptions, setEmailOptions, emailDetails, "category");
  const onAddEmail = () => {
    const initEmail = {
      category: "personal",
      position: emailDetails.length + 1,
      value: "",
      tempId: Date.now(),
    };
    if (emailDetails.length > 0) {
      setPersonValue({ key: "emailDetailsAttributes", value: [...emailDetails, { ...initEmail }] });
    } else {
      setPersonValue({ key: "emailDetailsAttributes", value: [{ ...initEmail, setMainEmail: true }] });
    }
  };

  useEffect(() => {
    if ((required || isDisplayByDefault) && emailDetails.length <= 0) {
      onAddEmail();
    }
  }, []);

  return (
    <>
      {emailDetails.length > 0 ? (
        <div>
          <div className="tw-flex tw-flex-col tw-gap-[24px]">
            {emailDetails.map((email, index) => (
              <EmailView
                key={email.id ? email.id : email.tempId}
                value={email}
                index={index}
                setIsAddActive={setIsAddActive}
                required={required}
                options={emailOptions
                  .filter((option) => !option.isTaken || option.index === index)
                  .map((option) => ({ label: option.label, value: option.value }))}
                emailDetails={emailDetails}
                setPersonValue={setPersonValue}
                removable={!((required || isDisplayByDefault) && email.position === 1)}
              />
            ))}
          </div>

          {emailDetails.length < EmailTypeOptions.length && (
            <TextButton
              onClick={onAddEmail}
              disabled={!isAddActive}
              className="tw-mt-[8px]"
              data-cy="add_emails_btn"
            >
              <div className="tw-flex tw-gap-1 tw-items-center">
                <Plus size="s" />
                <div className="tw-text-[11px] tw-uppercase tw-font-bold">Email</div>
              </div>
            </TextButton>
          )}
        </div>
      ) : (
        <Button
          onClick={() => {
            onAddEmail();
          }}
          className="tw-w-fit tw-flex tw-items-center"
          data-cy="add_init_email_btn"
        >
          <Plus className="tw-mr-4px" size="s" /> Add Email
        </Button>
      )}
    </>
  );
}

AddEmailSection.propTypes = {
  required: PropTypes.bool,
  isDisplayByDefault: PropTypes.bool,
  emailDetails: PropTypes.arrayOf(PropTypes.shape({})),
  setPersonValue: PropTypes.func.isRequired,
};

AddEmailSection.defaultProps = {
  required: false,
  isDisplayByDefault: false,
  emailDetails: [],
};
export default AddEmailSection;
