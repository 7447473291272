import React, { useEffect, useState } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "../../../../shared/v1";
import Trash from "../../../../shared/v2/Icomoon/Icons/Trash";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import TextButton from "../../../../shared/v2/TextButton";
import IconButton from "../../../../shared/v2/IconButton";
import LoadingOverlay from "../../../../shared/v2/LoadingOverlay";
import Rows from "./Rows";
import Headers from "./Headers";
import DeleteAdPlan from "./DeleteAdPlan";
import AdSite from "../AdSite";
import * as actionCreators from "../actions/thunks";
import errorShape from "../model/error";
import Error from "../Error";
import Footer from "./Footer";
import adSort from "./adSorting";

const AdPlan = ({
  planId,
  getManagePlanAsThunk,
  uuid,
  isLoading,
  getManagePlanError,
  adsites,
  closeModal,
}) => {
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [adsiteModalOpen, setadsiteModalOpen] = useState(false);

  useEffect(() => {
    getManagePlanAsThunk(uuid, planId);
  }, []);
  const deletecloseModal = (isAppliedDelete) => {
    if (isAppliedDelete === "true") {
      closeModal();
    }
    setdeleteModalOpen(false);
  };

  const adsitecloseModal = () => {
    setadsiteModalOpen(false);
  };
  return (
    <>
      <LoadingOverlay isLoading={isLoading}>
        <div className="tw-text-[14px] tw-pb-[34.5px] tw-font-normal tw-leading-[20px] tw-text-gray-75">
          Sharing sites from your advertising plan will add them to the Client Email Update and create an
          event in the client timeline.
        </div>
        {getManagePlanError && <Error message={getManagePlanError.message} />}
        {adsites?.plan && adsites?.sites && (
          <>
            <Row className="tw-flex tw-p-0 tw-pb-[34.5px] tw-m-0 tw-mx-[0px] tw-items-center">
              <Col
                size={10}
                className="tw-py-[0px] tw-px-[0px] tw-p-0 tw-m-0 tw-mx-[0px] tw-text-[18px] tw-font-normal tw-leading-[24px] tw-text-gray-75"
              >
                {adsites?.plan?.name}
              </Col>
              <Col size={2} className="tw-p-0 tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px]">
                <IconButton
                  className="tw-float-right"
                  onClick={() => setdeleteModalOpen(true)}
                  size="medium"
                  schema="misc-trash"
                >
                  <Trash size="m" />
                </IconButton>
              </Col>
            </Row>
            <Headers />
            {adsites.sites.length > 0 ? (
              adSort(adsites.sites).map((adsite, i) => (
                <Rows
                  planId={adsites?.plan?.id}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  adsite={adsite}
                />
              ))
            ) : (
              <div className="tw-h-[24px]" />
            )}

            <hr className="tw-my-[8px] tw-border-solid tw-border-[1px] tw-border-gray-10" />
            <TextButton
              className="tw-flex tw-items-center tw-gap-5px"
              size="medium"
              onClick={() => setadsiteModalOpen(true)}
              data-cy="marketing-advertising-plan-modal-additional-site-button"
            >
              <Plus size="s" />
              ADDITIONAL SITE
            </TextButton>
            <Footer onClose={closeModal} />
          </>
        )}
      </LoadingOverlay>

      {deleteModalOpen && (
        <DeleteAdPlan planId={adsites?.plan?.id} modalOpen={deleteModalOpen} closeModal={deletecloseModal} />
      )}
      {adsiteModalOpen && (
        <AdSite
          isFromManageModal
          planId={adsites?.plan?.id}
          modalOpen={adsiteModalOpen}
          closeModal={adsitecloseModal}
        />
      )}
    </>
  );
};

AdPlan.propTypes = {
  closeModal: Proptypes.func,
  uuid: Proptypes.string.isRequired,
  getManagePlanAsThunk: Proptypes.func.isRequired,
  isLoading: Proptypes.bool,
  adsites: Proptypes.shape({
    plan: Proptypes.shape({
      id: Proptypes.number,
      name: Proptypes.string,
    }),
    sites: Proptypes.arrayOf(Proptypes.shape({})),
  }),
  getManagePlanError: errorShape,
  planId: Proptypes.number,
};
AdPlan.defaultProps = {
  closeModal: null,
  adsites: null,
  getManagePlanError: null,
  isLoading: false,
  planId: null,
};
const mapStateToProps = (state) => ({
  adsites: state.tdpMarketingReducer.adsites,
  uuid: state.tdpMarketingReducer.uuid,
  getManagePlanError: state.tdpMarketingReducer.getManagePlanError,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getManagePlanAsThunk: (uuid, planId) => dispatch(actionCreators.getManagePlanAsThunk(uuid, planId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdPlan);
