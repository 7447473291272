import React from "react";
import PropTypes from "prop-types";
import Result from "./Result";
import css from "./QuickSearch.module.css";

const Results = ({
  searchResults,
  cursor,
  onHover,
  hasResults,
  isFetching,
  searchValid,
  searchTerm,
  searchTermLength,
  saveRecentSearch,
}) => {
  const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || "[]");

  return (
    <div className={`${css.resultsContainer} tw-z-[1000]`} id="resultsContainer">
      {!searchValid && (
        <div className={`${css.searchWarning} tw-border-0 tw-border-b tw-border-solid tw-border-color-ebebeb`}>Enter {1 - searchTermLength} more characters to search</div>
      )}

      {!searchValid && recentSearches.length > 0 && (
        <>
          <div className={css.searchWarning}>
            Recent Searches
          </div>
          {recentSearches.map((result, i) => (
            <Result key={result.object_id} result={result} cursor={cursor} index={i} onHover={onHover} saveRecentSearch={saveRecentSearch} />
          ))}
        </>
      )}

      {hasResults &&
        searchResults.map((result, i) => (
          <Result key={result.object_id} result={result} cursor={cursor} index={i} onHover={onHover} saveRecentSearch={saveRecentSearch} />
        ))}

      {!hasResults && searchValid && (
        <div className={css.searchWarning}>
          No results for <b>{searchTerm}</b>
        </div>
      )}
    </div>
  );
};

export default Results;
