import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useOpenHouseDashboardList = ({ dateRange, selectedHosts, status }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const prevQuery = useRef({ dateRange, selectedHosts, status }).current;

  useEffect(() => {
    if (selectedHosts.length === 0) return () => {};
    if (
      prevQuery.dateRange !== dateRange ||
      prevQuery.selectedHosts !== selectedHosts ||
      prevQuery.status !== status
    ) {
      prevQuery.dateRange = dateRange;
      prevQuery.selectedHosts = selectedHosts;
      prevQuery.status = status;
      if (page !== 1) {
        setPage(1);
        return () => {};
      }
    }
    const abortController = new AbortController();
    const hostPersonUuids = selectedHosts.map((host) => host.value);

    setLoading(true);
    caseTransformingAxios
      .post(
        "/api/v4/reporting/open_house_dashboard/list",
        {
          ...dateRange,
          hostPersonUuids,
          status,
          page,
          perPage: 10,
        },
        { signal: abortController.signal },
      )
      .then(
        ({
          data: {
            data: { data: list, ...rest },
          },
        }) => {
          setData({ list, ...rest });
          setLoading(false);
        },
      )
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setLoading(false);
        }
      });

    return () => abortController.abort();
  }, [dateRange, page, selectedHosts, status]);

  return { actions: { setPage }, data, loading };
};

export default useOpenHouseDashboardList;
