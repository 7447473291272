import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import Proptypes from "prop-types";
import { Modal } from "react-bootstrap";
import HeaderTitle from "../TransactionDetailsPage/Main/Marketing/HeaderTitle";
import { Col, Row } from "../shared/v1";
import SearchPeople from "../QuickSearch/components/SearchPeople";
import TextInput from "../shared/v2/TextInput";
import ClientIntentTextBtns from "./ClientIntentTextButtons";
import FlatPercentInput from "../shared/v2/FlatPercentInput";
import FlatPercentToggle from "../shared/v2/FlatPercentToggle";
import Button from "../shared/v2/Button";
import FieldLabel from "../shared/v2/FieldLabel";
import TextButton from "../shared/v2/TextButton";
import BuyerReferralModal from "./BuyerReferralModal";
import SellerLandlordReferralModal from "./SellerLandlordReferralModal";
import TenantReferralModal from "./TenantReferralModal";
import { tryCatchHandlr } from "../shared/Utilities";
import DropdownTeamMembers from "./DropdownTeamMembers";
import PersonDetailsModal from "../Contacts/PersonDetailsModal";

const CLIENT_INTENT_OPTIONS = [
  { label: "Buyer", value: "buyer" },
  { label: "Seller", value: "seller" },
  { label: "Tenant", value: "tenant" },
  { label: "Landlord", value: "landlord" },
];

class ReferralTransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewReferralModal: true,
      referringAgent: null,
      referringAgentLabel: null,
      referralPartner: null,
      referralPartnerLabel: null,
      brokerage: "",
      team: "",
      client: null,
      clientLabel: null,
      clientIntent: "buyer",
      clientName: null,
      referralFee: "25",
      referralFeeIsPercentage: true,
      showBuyerReferralModal: false,
      showSellerLandlordReferralModal: false,
      showTenantReferralModal: false,
      showLandlordReferralModal: false,
      submitDisabled: false,
      showNewContactModal: false,
      fromClient: false,
      fromReferralPartner: false,
    };
  }

  componentDidMount() {
    const { fullName: referringAgentLabel, currentUserTeamId: referringAgent } = this.props;

    this.setState({
      referringAgent,
      referringAgentLabel,
    });
  }

  selectReferralPartnerOrClient = (person = {}) => {
    const { fromClient, fromReferralPartner } = this.state;
    const fullName = `${person.firstName || ""} ${person.lastName || ""}`.trim();
    if (fromClient) {
      this.setState({
        client: person.id,
        clientLabel: fullName,
        clientName: fullName,
        fromClient: false,
      });
    }
    if (fromReferralPartner) {
      this.setState({
        referralPartner: person.id,
        referralPartnerLabel: fullName,
        fromReferralPartner: false,
      });
    }
  };

  selectClient = (client) => {
    this.setState({
      client: client.object_id,
      clientLabel: client.label,
      clientName: client.full_name,
      fromClient: false,
    });
  };

  selectReferralPartner = (partner) => {
    this.setState({
      referralPartner: partner.object_id,
      referralPartnerLabel: partner.label,
      fromReferralPartner: false,
    });
  };

  setReferralFeeIsPercentage = (bool) => {
    this.setState({ referralFeeIsPercentage: bool });
  };

  handleNext = () => {
    const { clientIntent } = this.state;
    switch (clientIntent) {
      case "buyer":
        this.setState({ showNewReferralModal: false, showBuyerReferralModal: true });
        break;
      case "seller":
        this.setState({ showNewReferralModal: false, showSellerLandlordReferralModal: true });
        break;
      case "tenant":
        this.setState({ showNewReferralModal: false, showTenantReferralModal: true });
        break;
      case "landlord":
        this.setState({ showNewReferralModal: false, showSellerLandlordReferralModal: true });
        break;
      default:
        // TODO: Implement better validation
        alert("Client Intent is required");
    }
  };

  handleBack = () => {
    this.setState({
      showNewReferralModal: true,
      showBuyerReferralModal: false,
      showSellerLandlordReferralModal: false,
      showTenantReferralModal: false,
    });
  };

  handleSubmit = async (secondaryModalState) => {
    const adaptDateForPayload = (date) => moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
    this.setState({ submitDisabled: true });

    const stateData = { ...this.state, ...secondaryModalState };
    const formData = new FormData();
    formData.append("utf8", "✓");
    formData.append("authenticity_token", ReactOnRails.authenticityToken());

    formData.append("listing[title]", stateData.transactionTitle);
    formData.append("listing[type]", "ReferralListing");
    formData.append("listing[status]", stateData.transactionStatus);
    formData.append("listing[source_id]", null || stateData.source?.value);

    formData.append("listing[referral_attributes][brokerage]", stateData.brokerage);
    formData.append("listing[referral_attributes][team]", stateData.team);
    formData.append("listing[referral_attributes][client_intent]", stateData.clientIntent);
    if (stateData.referralFee.length) {
      formData.append("listing[referral_attributes][referral_fee]", stateData.referralFee);
    }
    formData.append("listing[referral_attributes][is_fee_percentage]", stateData.referralFeeIsPercentage);
    formData.append("listing[referral_attributes][transaction_amount]", stateData.transactionAmt);
    if (["buyer", "tenant"].includes(stateData.clientIntent)) {
      formData.append("listing[referral_attributes][desired_locations]", stateData.desiredLocations);
    }

    formData.append("listing[referral_attributes][timeframe]", stateData.timeframe?.value);
    formData.append(
      "listing[referral_attributes][timeframe_start]",
      stateData.timeframe?.value === "custom"
        ? adaptDateForPayload(stateData.exactDate)
        : moment().format("YYYY-MM-DD"),
    );
    if (stateData.expirationDate.length) {
      formData.append(
        "listing[referral_attributes][expiration_date]",
        adaptDateForPayload(stateData.expirationDate),
      );
    }

    if (stateData.closeDate.length) {
      formData.append("listing[referral_attributes][close_date]", adaptDateForPayload(stateData.closeDate));
    }
    if (stateData.acceptanceDate.length) {
      formData.append(
        "listing[referral_attributes][mutual_acceptance_date]",
        adaptDateForPayload(stateData.acceptanceDate),
      );
    }

    if (stateData.clientIntent === "buyer") {
      formData.append("listing[referral_attributes][financing]", stateData.financing);
    }

    formData.append("listing[referral_attributes][client_id]", stateData.client);
    formData.append("listing[referral_attributes][referring_agent_id]", stateData.referringAgent);
    if (stateData.referralPartner) {
      formData.append("listing[referral_attributes][referral_partner_id]", stateData.referralPartner);
    }

    // seller && landlord
    if (["seller", "landlord"].includes(stateData.clientIntent)) {
      formData.append("property_type", stateData.propertyType?.value);
      formData.append("listing[address_attributes][street_address]", stateData.streetAddress);
      formData.append("listing[address_attributes][city]", stateData.city);
      formData.append("listing[address_attributes][locality]", stateData.stateProvince);
      formData.append("listing[address_attributes][postal_code]", stateData.zipPostalCode);
      formData.append("listing[address_attributes][address_type]", "listing");
    }

    const [res, err] = await tryCatchHandlr(axios.post("/listings", formData));
    if (err) {
      this.setState({ submitDisabled: false });
    } else {
      window.location.replace(res.data);
    }
  };

  isNextBtnDisabled = () => {
    const { referringAgentLabel, clientLabel } = this.state;
    return !(referringAgentLabel && clientLabel);
  };

  render() {
    const {
      clientIntent,
      referralFee,
      referralFeeIsPercentage,
      showNewReferralModal,
      showBuyerReferralModal,
      showSellerLandlordReferralModal,
      showTenantReferralModal,
      clientLabel,
      clientName,
      showNewContactModal,
      referringAgentLabel,
      referringAgent,
      referralPartnerLabel,
      brokerage,
      team,
    } = this.state;

    const { closeModal, sources } = this.props;

    return (
      <>
        <BuyerReferralModal
          showModal={showBuyerReferralModal}
          handleBack={this.handleBack}
          handleSubmit={this.handleSubmit}
          clientName={clientName}
          sources={sources}
        />
        <SellerLandlordReferralModal
          showModal={showSellerLandlordReferralModal}
          handleBack={this.handleBack}
          handleSubmit={this.handleSubmit}
          clientIntent={clientIntent}
          clientName={clientName}
          sources={sources}
        />
        <TenantReferralModal
          showModal={showTenantReferralModal}
          handleBack={this.handleBack}
          handleSubmit={this.handleSubmit}
          clientName={clientName}
          sources={sources}
        />
        <PersonDetailsModal
          showAddDetailsButton={false}
          show={showNewContactModal}
          onClose={(person) => {
            this.setState({ showNewContactModal: false });
            if (person) this.selectReferralPartnerOrClient(person);
          }}
        />
        <Modal
          className="modal"
          backdrop="static"
          show={showNewReferralModal}
          onHide={closeModal}
          enforceFocus={!showNewContactModal}
          id="referral-buyer-transaction-modal"
        >
          <Modal.Header className="tw-pb-0 tw-px-[32px] tw-pt-[32px]">
            <Modal.Title className="tw-border-0">
              <HeaderTitle title="Track Outbound Referral" onClose={closeModal} />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
            <div className="tw-grid tw-grid-rows-1 tw-pb-[24px]">
              <div className="tw-flex tw-justify-center">
                <span className="tw-h-[1px] tw-w-full tw-bg-gray-10 tw-lg:w-1/3" />
              </div>
            </div>
            <div className="tw-grid tw-grid-rows-1">
              <FieldLabel label="Referring Agent" isRequired />
              <DropdownTeamMembers
                value={{
                  label: referringAgentLabel,
                  value: referringAgent,
                }}
                setValue={({ label, value }) =>
                  this.setState({
                    referringAgentLabel: label,
                    referringAgent: value,
                  })
                }
              />
            </div>
            <div className="tw-grid tw-grid-rows-1">
              <div className="tw-flex tw-justify-center tw-my-5 tw-mt-16px">
                <span className="tw-h-[1px] tw-w-full tw-bg-gray-10 tw-lg:w-1/3" />
              </div>
            </div>
            <div className="tw-grid tw-grid-rows-1 tw-pt-11px">
              <FieldLabel label="Referral Partner" />
              <SearchPeople
                searchBoxValue={referralPartnerLabel}
                onSelectFunction={this.selectReferralPartner}
              />
              <div className="tw--mt-4px tw-text-12d tw-text-neutral-gray-50">
                <span className="tw--mt-4px tw-text-12d tw-text-neutral-gray-50">
                  Don’t have the referral partner as a contact in your CRM?
                  <TextButton
                    className="tw-cursor-pointer tw-font-normal tw-text-12d"
                    schema="sentence"
                    onClick={() =>
                      this.setState({
                        showNewContactModal: true,
                        fromReferralPartner: true,
                        fromClient: false,
                      })
                    }
                  >
                    Add them here
                  </TextButton>
                </span>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-mt-24px tw-gap-[24px]">
              <div className="tw-col-span-1">
                <TextInput
                  label="Brokerage"
                  value={brokerage}
                  onChange={(e) => this.setState({ brokerage: e.target.value })}
                />
              </div>
              <div className="tw-col-span-1">
                <TextInput
                  label="Team"
                  value={team}
                  onChange={(e) => this.setState({ team: e.target.value })}
                />
              </div>
            </div>
            <div className="tw-grid tw-grid-rows-1">
              <div className="tw-flex tw-justify-center tw-my-5 tw-mt-24px tw-mb-24px">
                <span className="tw-h-[1px] tw-w-full tw-bg-gray-10 tw-lg:w-1/3" />
              </div>
            </div>
            <div className="tw-grid tw-grid-rows-1">
              <FieldLabel label="Client" isRequired />
              <SearchPeople searchBoxValue={clientLabel} onSelectFunction={this.selectClient} />
              <div className="tw--mt-4px tw-text-12d tw-text-neutral-gray-50">
                <span className="tw--mt-4px tw-text-12d tw-text-neutral-gray-50">
                  Don’t have the client as a contact in your CRM?
                  <TextButton
                    className="tw-cursor-pointer tw-font-normal tw-text-12d"
                    schema="sentence"
                    onClick={() =>
                      this.setState({
                        showNewContactModal: true,
                        fromClient: true,
                        fromReferralPartner: false,
                      })
                    }
                  >
                    Add them here
                  </TextButton>
                </span>
              </div>
            </div>
            <div className="tw-grid tw-grid-rows-1 tw-mt-24px">
              <div className="tw-flex tw-flex-col">
                <div className="tw-mb-12px">
                  <FieldLabel label="Client Intent" />
                </div>
                <div>
                  <ClientIntentTextBtns
                    size="medium"
                    options={CLIENT_INTENT_OPTIONS}
                    value={clientIntent}
                    onChange={(e) => this.setState({ clientIntent: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="tw-flex tw-gap-[8px] tw-mb-24px tw-mt-8 tw-items-end">
              <div className="tw-grow">
                <FlatPercentInput
                  label="Referral Fee"
                  value={referralFee}
                  isPercentage={referralFeeIsPercentage}
                  onChange={(e) => {
                    const value = e.target.value.trim() || null;
                    this.setState({ referralFee: value });
                  }}
                  hideDefaultLeading
                  hideDefaultTrailing
                />
              </div>
              <FlatPercentToggle
                isPercentage={referralFeeIsPercentage}
                onChange={(_, newIsPercentage) => this.setReferralFeeIsPercentage(newIsPercentage)}
              />
            </div>
            <div className="tw-grid tw-grid-rows-1 tw-mt-24px">
              <div className="tw-flex tw-justify-center">
                <span className="tw-h-[1px] tw-w-full tw-bg-gray-10 tw-lg:w-1/3" />
              </div>
            </div>

            <Row className="tw-pt-[32px] tw-m-0 tw-p-0 tw-flex ">
              <Col xs={8} className="tw-m-0 tw-p-0">
                <Button
                  onClick={closeModal}
                  className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
                  schema="tertiary"
                  size="medium"
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={4} className="tw-m-0 tw-p-0 tw-flex tw-justify-end">
                <Button
                  onClick={this.handleNext}
                  disabled={this.isNextBtnDisabled()}
                  className="tw-flex tw-items-center"
                  schema="primary"
                  size="medium"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

ReferralTransactionModal.propTypes = {
  fullName: Proptypes.string,
  closeModal: Proptypes.func,
  currentUserTeamId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  sources: Proptypes.arrayOf(Proptypes.shape()).isRequired,
};

ReferralTransactionModal.defaultProps = {
  fullName: "",
  closeModal: null,
  currentUserTeamId: null,
};

export default ReferralTransactionModal;
