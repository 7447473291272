import React, { Component } from "react";

class FilterButton extends Component {
  render() {
    return (
      <span>
        <button
          data-cy="filter-button"
          style={{
            borderRadius: "35px",
            margin: "-44px 10px 0 10px",
            backgroundColor: "#59C4C4",
            color: "white",
          }}
          className="btn pull-right filtered"
          onClick={this.props.handleFilter}
        >
          Filter
        </button>
      </span>
    );
  }
}

export default FilterButton;
