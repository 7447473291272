import React, { useState } from "react";

import { format } from "date-fns";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TabBar from "@shared/v2/TabBar/TabBar";
import Tab from "@shared/v2/Tab/Tab";
import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6, TrashCanSolidV6, PlusSolidV6 } from "@shared/v2/Icomoon";
import Avatar from "@shared/v2/Avatar";

import Error from "../Reports/components/DashboardShared/Error";
import ListingAlert from "../PersonDetail/components/listing_alerts/ListingAlert";

import { getTemplates, createTemplate, updateTemplate, deleteTemplate } from "./service";

const THEAD_CLASSES = "tw-font-normal tw-py-8px";

const ListingAlertTemplates = ({ initialTemplates, personId, geoCodeAccountAddress, mlsIds }) => {
  const [templates, setTemplates] = useState(initialTemplates);
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const saveTemplate = async (templateId, templateName, alert, callback) => {
    if (templateId) {
      const response = await updateTemplate(templateId, templateName, alert);
      callback({ response: response });
    } else {
      const response = await createTemplate(templateName, alert);
      callback({ response: response });
    }

    await reload();
  };

  const closeModals = () => {
    setSelectedTemplate(null);
    setShowModal(false);
    setShowDeleteConfirmationModal(false);
  };

  const editTemplate = (templateId) => {
    setSelectedTemplate(templateId);
    setShowModal(true);
  };

  const confirmDelete = (templateId) => {
    setSelectedTemplate(templateId);
    setShowDeleteConfirmationModal(true);
  };

  const cancelDelete = () => {
    setSelectedTemplate(null);
    setShowDeleteConfirmationModal(false);
  };

  const reload = async () => {
    closeModals();
    const updatedTemplates = await getTemplates();
    setTemplates(updatedTemplates);
  };

  const templateProps = () => {
    if (!selectedTemplate) {
      return null;
    }

    const template = templates.find((template) => template.id === selectedTemplate);
    if (!template) {
      return null;
    }

    return {
      templateId: selectedTemplate,
      templateName: template.name,
      ...template.query,
    };
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-[16px] tw-px-[32px] tw-py-[24px] tw-h-full">
      {showModal && (
        <ListingAlert
          person={{ id: personId, home_app_last_login: Date.now() }}
          mlsIds={mlsIds}
          closeListingAlertModal={closeModals}
          geoCodeAccountAddress={geoCodeAccountAddress}
          type="new"
          handleFormSubmit={(params, callback) =>
            saveTemplate(params.templateId, params.templateName, params.listing_alert, callback)
          }
          alertInstructions="Enter the listing alert criteria you want to save. Anyone on your team will have access to this template."
          submitButton="SAVE TEMPLATE"
          title={selectedTemplate ? "Edit Listing Alert Template" : "New Listing Alert Template"}
          isCreatingTemplate={true}
          {...templateProps()}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmDeleteTemplate
          templateId={selectedTemplate}
          chooseNotToDelete={cancelDelete}
          onClose={cancelDelete}
          onConfirmDelete={() => reload()}
        />
      )}
      <h1 className="tw-text-28px tw-text-gray-75 tw-font-normal tw-my-0">Templates</h1>
      <div className="tw-relative tw-flex tw-justify-between">
        <TabBar>
          <Tab selected={false} onClick={navigateToEmailTemplates} label="Email" />
          <Tab selected={true} label="Listing Alerts" />
        </TabBar>
        <Button size="medium">
          <div
            onClick={() => setShowModal(true)}
            className="tw-flex tw-justify-center tw-items-center tw-gap-6px"
          >
            <PlusSolidV6 />
            Listing Alert Template
          </div>
        </Button>
      </div>
      {templates.length > 0 ? (
        <div className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0">
          <table className="tw-border-collapse tw-overflow-scroll tw-table-auto tw-flex-nowrap">
            <thead className="tw-bg-white tw-sticky tw-top-0">
              <tr className="tw-text-12px tw-text-gray-50 tw-border-1px tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-gray-10">
                <th className={THEAD_CLASSES}>Title</th>
                <th className={THEAD_CLASSES}>Created</th>
                <th className={`tw-text-center ${THEAD_CLASSES}`}>Created By</th>
                <th className={THEAD_CLASSES}>Last Updated</th>
                <th className={`tw-text-center ${THEAD_CLASSES}`}>Last Updated By</th>
                <th className={`tw-text-center ${THEAD_CLASSES}`}></th>
              </tr>
            </thead>
            <tbody>
              {templates.map((template) => (
                <tr key={template.id}>
                  <td className="tw-py-8px">{template.name}</td>
                  <td className="tw-py-8px">{format(new Date(template.created_at), "MMM d, yyyy")}</td>
                  <td className="tw-py-8px tw-flex tw-justify-center">
                    <Avatar
                      key={`${template.id}-${template.created_by_id}`}
                      size="medium"
                      src={template.created_by_avatar_url}
                      alt={template.created_by_name}
                    >
                      {template.created_by_name}
                    </Avatar>
                  </td>
                  <td className="tw-py-8px">{format(new Date(template.updated_at), "MMM d, yyyy")}</td>
                  <td className="tw-py-8px tw-flex tw-justify-center">
                    {template.updated_by_id ? (
                      <Avatar
                        key={`${template.id}-${template.updated_by_id}`}
                        size="medium"
                        src={template.updated_by_avatar_url}
                        alt={template.updated_by_name}
                      >
                        {template.updated_by_name}
                      </Avatar>
                    ) : (
                      <Avatar size="medium"></Avatar>
                    )}
                  </td>
                  <td className="tw-py-8px">
                    <div className="tw-flex tw-gap-[6px] tw-justify-end tw-items-center">
                      {template.can_write ? (
                        <>
                          <IconButton
                            onClick={() => editTemplate(template.id)}
                            aria-label="Edit"
                            schema="tertiary"
                          >
                            <PencilSolidV6 />
                          </IconButton>
                          <IconButton
                            onClick={() => confirmDelete(template.id)}
                            aria-label="Delete"
                            schema="tertiary"
                          >
                            <TrashCanSolidV6 size="l" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton aria-label="Edit" schema="tertiary" disabled={true}>
                            <PencilSolidV6 />
                          </IconButton>
                          <IconButton aria-label="Delete" schema="tertiary" disabled={true}>
                            <TrashCanSolidV6 size="l" />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-mt-[66px] tw-mb-[50px] tw-items-center tw-justify-center tw-gap-[24px]">
          <span className="tw-text-18px tw-whitespace-nowrap">Create your first Listing Alert Template</span>
          <span className="tw-max-w-[750px] tw-text-gray-50 tw-text-center">
            Quickly fill in your frequently-used search areas and property details
          </span>
          <Button size="medium">
            <div
              onClick={() => setShowModal(true)}
              className="tw-flex tw-justify-center tw-items-center tw-gap-6px"
            >
              <PlusSolidV6 />
              New Template
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ListingAlertTemplates;

const ConfirmDeleteTemplate = ({ templateId, onClose, onConfirmDelete, chooseNotToDelete }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");

  const deleteTemplateWrapper = async () => {
    setIsFetching(true);

    const { error: deleteError } = await deleteTemplate(templateId);

    if (deleteError) {
      setError(deleteError);
      setIsFetching(false);
    } else {
      onConfirmDelete();
    }
  };

  return (
    <Modal
      show
      onHide={onClose}
      dialogClassName="tw-overflow-y-auto tw-max-h-full"
      closeOnClickOutside={false}
      contentSize="medium"
      contentClassName="tw-m-auto"
    >
      <Modal.Header title="Are you sure?" className="tw-mb-24px" closeButton>
        <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
      </Modal.Header>
      <Modal.Body className="tw-mb-32px">
        <div className="tw-flex tw-flex-col tw-text-gray-75 tw-gap-24px tw-mb-12px">
          Are you sure you want to delete this Listing Alert Template? It will no longer be available for
          anyone on your team.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button schema="tertiary" size="medium" onClick={chooseNotToDelete}>
            CANCEL
          </Button>
          <Button
            type="button"
            schema="warning"
            size="medium"
            disabled={isFetching}
            onClick={deleteTemplateWrapper}
          >
            YES, DELETE
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const navigateToEmailTemplates = () => {
  window.location.href = "/interaction_templates";
};
