import React, { Component } from "react";
import moment from "moment";
import Proptypes from "prop-types";
import { Modal } from "react-bootstrap";
import Tooltip from "@shared/v2/Tooltip";
import HeaderTitle from "../TransactionDetailsPage/Main/Marketing/HeaderTitle";
import { Col, Row } from "../shared/v1";
import TextInput from "../shared/v2/TextInput";
import Dropdown from "../shared/v2/Dropdown";
import Button from "../shared/v2/Button";
import FieldLabel from "../shared/v2/FieldLabel";
import DatePicker from "../shared/v2/DatePicker";
import FlatPercentInput from "../shared/v2/FlatPercentInput";
import RadioButton from "../shared/v2/RadioButton/RadioButton";
import CustomTimeFrameDateSelector from "./CustomTimeFrameDateSelector";
import { CircleInfoSolidV6 } from "../shared/v2/Icomoon";

const TRANSACTION_STATUS_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Closed", value: "sold" },
  { label: "Expired", value: "expired" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Archived", value: "archived" },
];

const TIMEFRAME_OPTIONS = [
  { label: "Immediate", value: "immediate" },
  { label: "Within 90 days", value: "within_90_days" },
  { label: "Within a year", value: "within_a_year" },
  { label: "After a year", value: "after_a_year" },
  { label: "Custom", value: "custom" },
  { label: "Unknown", value: "unknown" },
];

const TooltipList = () => (
  <div className="tw-text-left">
    <div>
      <span className="tw-font-semibold">Active: </span>Referral contract has been signed.
    </div>
    <div>
      <span className="tw-font-semibold">Pending: </span>Referral partner’s transaction with the client has
      gone under contract.
    </div>
    <div>
      <span className="tw-font-semibold">Closed: </span>Transaction has closed and your team has received the
      referral income payment.
    </div>
    <div>
      <span className="tw-font-semibold">Expired: </span>Referral contract expired prior to the transaction’s
      completion.
    </div>
    <div>
      <span className="tw-font-semibold">Canceled: </span>Referral contract was rejected or terminated after
      acceptance.
    </div>
  </div>
);

class BuyerReferralModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionTitle: "",
      transactionStatus: "active",
      acceptanceDate: "",
      expirationDate: "",
      closeDate: "",
      transactionAmt: "0.00",
      timeframe: { label: "Immediate", value: "immediate" },
      financing: "None",
      desiredLocations: "",
      isCalendarOpen: false,
      exactDate: moment().format("MM/DD/YYYY"),
      source: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { clientName } = this.props;
    if (prevProps.clientName !== clientName) {
      this.setState({ transactionTitle: clientName });
    }
  }

  backHandlr = () => {
    const { handleBack } = this.props;
    handleBack();
    this.setState({ source: null });
  };

  render() {
    const { showModal, sources, handleSubmit } = this.props;
    const {
      transactionTitle,
      source,
      transactionStatus,
      closeDate,
      acceptanceDate,
      expirationDate,
      transactionAmt,
      timeframe,
      isCalendarOpen,
      financing,
      desiredLocations,
    } = this.state;

    return (
      <Modal
        className="modal"
        backdrop="static"
        show={showModal}
        onHide={this.backHandlr}
        id="referral-buyer-transaction-modal"
      >
        <Modal.Header className="tw-pb-0 tw-px-[32px] tw-pt-[32px]">
          <Modal.Title className="tw-border-0">
            <HeaderTitle title="Buyer Referral Details" onClose={this.backHandlr} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
          <div className="tw-grid tw-grid-rows-1 tw-mt-30px tw-pb-[24px]">
            <div className="tw-flex tw-justify-center">
              <span className="tw-h-[1px] tw-w-full tw-bg-gray-10 tw-lg:w-1/3" />
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
            <div className="tw-col-span-1">
              <FieldLabel label="Transaction Title" isRequired className="tw-mb-3" />
              <TextInput
                value={transactionTitle}
                onChange={(e) => this.setState({ transactionTitle: e.target.value })}
              />
            </div>
            <div className="tw-col-span-1">
              <Dropdown
                label="Source"
                onChange={(newSource) => this.setState({ source: newSource })}
                value={source}
                options={sources}
              />
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-mb-24px tw-gap-[24px]">
            <div className="tw-col-span-1">
              <label
                htmlFor={transactionStatus}
                className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
              >
                Transaction Status
                <Tooltip
                  placement="right"
                  trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                  content={<TooltipList />}
                />
              </label>
              <Dropdown
                onChange={(e) => {
                  this.setState({ transactionStatus: e.value });
                }}
                value={TRANSACTION_STATUS_OPTIONS.find((opt) => opt.value === transactionStatus)}
                options={TRANSACTION_STATUS_OPTIONS}
              />
            </div>
            {transactionStatus === "sold" && (
              <div className="tw-col-span-1">
                <label
                  htmlFor={closeDate}
                  className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
                >
                  Close Date
                  <Tooltip
                    placement="top"
                    trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                    content="Date the referral income was received"
                  />
                </label>
                <DatePicker onChange={(e) => this.setState({ closeDate: e })} value={closeDate} />
              </div>
            )}
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-mb-24px tw-gap-[24px]">
            <div className="tw-col-span-1">
              <label
                htmlFor={acceptanceDate}
                className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
              >
                Acceptance Date
                <Tooltip
                  placement="top"
                  trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                  content="Date the referral agreement was signed by both parties"
                />
              </label>
              <DatePicker onChange={(e) => this.setState({ acceptanceDate: e })} value={acceptanceDate} />
            </div>
            <div className="tw-col-span-1">
              <label
                htmlFor={expirationDate}
                className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
              >
                Expiration Date
                <Tooltip
                  placement="top"
                  trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                  content="Date the referral agreement is effective through"
                />
              </label>
              <DatePicker onChange={(e) => this.setState({ expirationDate: e })} value={expirationDate} />
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-mb-24px tw-gap-[24px]">
            <div className="tw-col-span-1">
              <FlatPercentInput
                label="Budget"
                value={transactionAmt}
                isPercentage={false}
                onChange={(e) => this.setState({ transactionAmt: e.target.value })}
              />
            </div>
            <div className="tw-col-span-1">
              <Dropdown
                label="Timeframe"
                onChange={(newTimeframe) => {
                  this.setState({
                    isCalendarOpen: newTimeframe.value === "custom",
                    timeframe: newTimeframe,
                  });
                }}
                value={timeframe}
                options={TIMEFRAME_OPTIONS}
              />
              <CustomTimeFrameDateSelector
                show={isCalendarOpen}
                cancelViewingCalendarHandlr={() =>
                  this.setState({
                    timeframe: { label: "Unknown", value: "unknown" },
                    isCalendarOpen: false,
                  })
                }
                submitHandlr={(exactDate) => {
                  this.setState({
                    isCalendarOpen: false,
                    exactDate,
                  });
                }}
              />
              <span className="tw-text-[12px] tw-text-neutral-gray-50">Client`s timeframe for buying</span>
            </div>
          </div>

          <div className="tw-grid tw-grid-rows-1 tw-mb-24px">
            <FieldLabel label="Financing" />
            <div className="tw-flex tw-justify-between tw-mt-8px">
              <RadioButton
                additionalInfo={{
                  content: "",
                  multiline: false,
                  placement: "right",
                }}
                label="Pre-approved"
                onChange={(e) => this.setState({ financing: e.target.value })}
                value="Pre-Approved"
                checked={financing === "Pre-Approved"}
              />
              <RadioButton
                additionalInfo={{
                  content: "",
                  multiline: false,
                  placement: "right",
                }}
                label="Pre-qualified"
                onChange={(e) => this.setState({ financing: e.target.value })}
                value="Pre-Qualified"
                checked={financing === "Pre-Qualified"}
              />
              <RadioButton
                additionalInfo={{
                  content: "",
                  multiline: false,
                  placement: "right",
                }}
                label="Cash Offer"
                onChange={(e) => this.setState({ financing: e.target.value })}
                value="Cash Offer"
                checked={financing === "Cash Offer"}
              />
              <RadioButton
                additionalInfo={{
                  content: "",
                  multiline: false,
                  placement: "right",
                }}
                label="None"
                onChange={(e) => this.setState({ financing: e.target.value })}
                value="None"
                checked={financing === "None"}
              />
            </div>
          </div>

          <div className="tw-grid tw-grid-rows-1">
            <TextInput
              label="Desired Locations"
              onChange={(e) => this.setState({ desiredLocations: e.target.value })}
              value={desiredLocations}
            />
          </div>
          <div className="tw-grid tw-grid-rows-1 tw-mt-24px">
            <div className="tw-flex tw-justify-center">
              <span className="tw-h-[1px] tw-w-full tw-bg-gray-10 tw-lg:w-1/3" />
            </div>
          </div>

          <Row className="tw-pt-[32px] tw-m-0 tw-p-0 tw-flex ">
            <Col xs={8} className="tw-m-0 tw-p-0">
              <Button
                onClick={this.backHandlr}
                className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
                schema="tertiary"
                size="medium"
              >
                Back
              </Button>
            </Col>
            <Col xs={4} className="tw-m-0 tw-p-0 tw-flex tw-justify-end">
              <Button
                onClick={() => handleSubmit({ ...this.state })}
                className="tw-flex tw-items-center"
                schema="primary"
                size="medium"
              >
                Track Referral
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

BuyerReferralModal.propTypes = {
  handleBack: Proptypes.func,
  showModal: Proptypes.bool,
  clientName: Proptypes.string,
  handleSubmit: Proptypes.func,
  sources: Proptypes.arrayOf(Proptypes.shape({})),
};

BuyerReferralModal.defaultProps = {
  handleBack: null,
  showModal: false,
  clientName: "",
  sources: [],
  handleSubmit: null,
};

export default BuyerReferralModal;
