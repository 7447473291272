import React, { useRef, useState } from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import {
  FloatingArrow,
  arrow,
  autoUpdate,
  flip,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import classes from "./classes.json";

const Tooltip = ({
  trigger,
  content,
  placement,
  multiline,
  className,
  tooltipClassName,
  arrowClassName,
  innerClassName,
  ...otherProps
}) => {
  const tooltipPortal = document.getElementById("tooltip-portal-v2") || document.createElement("div");
  if (!tooltipPortal.id) {
    tooltipPortal.id = "tooltip-portal-v2";
    tooltipPortal.className = "tw-relative";
    document.body.appendChild(tooltipPortal);
  }
  const [show, setShow] = useState(false);
  const arrowRef = useRef(null);
  const { context, floatingStyles, refs } = useFloating({
    placement,
    open: show,
    onOpenChange: setShow,
    middleware: [
      offset(10),
      flip({ crossAxis: placement.includes("-"), fallbackAxisSideDirection: "end" }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
    ...otherProps,
  });
  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const hover = useHover(context, { move: false });
  const role = useRole(context, { role: "tooltip" });
  useInteractions([dismiss, focus, hover, role]);

  const triggerClone = (
    <span ref={refs.setReference} className={className}>
      {trigger}
    </span>
  );

  return (
    <>
      {triggerClone}
      {createPortal(
        <Transition nodeRef={refs.floating} in={show} unmountOnExit timeout={150}>
          {(transitionState) => (
            <div
              className={`tw-transition-opacity tw-duration-150 ${
                transitionState === "entering" || transitionState === "entered"
                  ? "tw-opacity-100"
                  : "tw-opacity-0"
              } ${classes.baseClasses} ${tooltipClassName}`}
              ref={refs.setFloating}
              style={floatingStyles}
            >
              <div
                className={`${classes.inner.baseClasses} ${
                  multiline ? classes.inner.multiLineClasses : classes.inner.singleLineClasses
                } ${innerClassName}`}
              >
                {content}
              </div>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                className={`tw-fill-[var(--tooltip-bg)] tw-stroke-[var(--tooltip-bg)] ${arrowClassName}`}
              />
            </div>
          )}
        </Transition>,
        tooltipPortal,
      )}
    </>
  );
};

Tooltip.propTypes = {
  trigger: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  multiline: PropTypes.bool,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  innerClassName: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: "top",
  multiline: false,
  className: "",
  tooltipClassName: "",
  arrowClassName: "",
  innerClassName: "",
};

export default Tooltip;
