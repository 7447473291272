import axios from "axios";
import { produce } from "immer";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { setError } from "../../Interactions/PhoneCall/actions/phoneCallActionCreators";
import { updateStageAttribute } from "./helper";
import namespaceAction from "../../reducers/helpers";

const REDUCER_NAME = "AgreementReducer";
const wrap = namespaceAction(REDUCER_NAME);

export const agreementUploaded = (data) => wrap({ type: "AGREEMENT_UPLOADED", data });

export const agreementReplaced = (data, agreementId) =>
  wrap({ type: "AGREEMENT_REPLACED", data, agreementId });

export const removeDeletedAgreement = (index) => wrap({ type: "DELETE_AGREEMENT", index });

export const storeFetchedAgreements = (data) => wrap({ type: "FETCH_AGREEMENTS", data });

const updatePersonSuccess = (data) => {
  return {
    type: "UPDATE_PERSON_SUCCESS",
    person: data,
  };
};

const togglePersonError = (value) => {
  return {
    type: "TOGGLE_PERSON_ERROR",
    value,
  };
};

export const updatePersonValues = (name, value) => {
  return {
    type: "UPDATE_PERSON_VALUES",
    name,
    value,
  };
};

export const openEditPersonModal = () => {
  return {
    type: "OPEN_EDIT_PERSON_MODAL",
  };
};

export const closeEditPersonModal = () => {
  return {
    type: "CLOSE_EDIT_PERSON_MODAL",
  };
};

const fetchInitialTimeline = (data) => {
  return {
    type: "FETCH_INITIAL_TIMELINE",
    data: data,
  };
};

const fetchTimelineError = (data) => {
  return {
    type: "FETCH_TIMELINE_ERROR",
    data: data,
  };
};

const fetchingTimeline = () => {
  return {
    type: "FETCHING_TIMELINE",
  };
};

export const updateTimeline = (data, type) => {
  return {
    type: type,
    data: data,
  };
};

const updateTimelineEvent = (data) => {
  return {
    type: "SUBMIT_UPDATE_TIMELINE_EVENT",
    data: data,
  };
};

const deleteTimelineNote = (id) => {
  return {
    type: "DELETE_TIMELINE_NOTE",
    id,
  };
};

const submitSendEmail = (data) => {
  return {
    type: "SUBMIT_SEND_EMAIL",
    data: data,
  };
};

const clearEmailState = (bool) => {
  return {
    type: "CLEAR_EMAIL_STATE",
    bool: bool,
  };
};

const submitEmailError = (data) => {
  return {
    type: "SUBMIT_ERROR_EMAIL",
    data: data,
  };
};

const submitAddAppointment = (data) => {
  return {
    type: "SUBMIT_ADD_APPOINTMENT",
    data: data,
  };
};

export const submitNoteSuccess = (note) => ({
  type: "SUBMIT_NOTE_SUCCESS",
  note,
});

export const updateNoteSuccess = (note) => ({
  type: "UPDATE_NOTE_SUCCESS",
  note,
});

export const deleteNoteSuccess = (note) => ({
  type: "DELETE_NOTE_SUCCESS",
  note,
});

// use to reload person Detail after Edit Contact modal close
export const reloadPerson = (personId) => {
  return (dispatch) => {
    axios
      .get(`/api/v4/person_detail/show/${personId}`, {
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(updatePersonSuccess(res.data));
      });
  };
};
export const updatePerson = (editablePerson) => {
  const updatedPerson = produce(editablePerson, (draft) => {
    updateStageAttribute(draft);
  });

  return (dispatch) => {
    axios
      .patch(`/api/v4/person_detail/${updatedPerson.data.id}`, {
        person: updatedPerson,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        console.log(res.data);
        dispatch(updatePersonSuccess(res.data));
      });
  };
};

export const updatePersonData = (updatedPersonData) => {
  return (dispatch) => {
    dispatch(updatePersonSuccess(updatedPersonData));
  };
};

export const uploadPersonAvatar = (event, person) => {
  return (dispatch) => {
    const fileUploaded = event.target.files[0];
    const url = "/api/v4/person_detail/upload_avatar/" + person.data.attributes.slug;
    let form_data = new FormData();
    form_data.append("authenticity_token", ReactOnRails.authenticityToken());
    form_data.append("person[avatar]", fileUploaded);
    axios
      .post(url, form_data)
      .then((res) => {
        dispatch(updatePersonSuccess(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const addTagToPerson = (tag, person) => {
  return (dispatch) => {
    const url = "/api/v4/person_detail/create_tag/" + person.data.attributes.id;
    axios
      .post(url, { tag_name: tag, authenticity_token: ReactOnRails.authenticityToken() })
      .then((res) => {
        dispatch(updatePersonSuccess(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const deleteTagFromPerson = (tag_id, person) => {
  return (dispatch) => {
    const url = "/api/v4/person_detail/destroy_tag/" + person.data.attributes.id;
    axios
      .delete(url, { data: { tag_id: tag_id, authenticity_token: ReactOnRails.authenticityToken() } })
      .then((res) => {
        dispatch(updatePersonSuccess(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const submitNote = (note) => {
  return (dispatch) => {
    axios
      .post(`/people/${note.notable_id}/notes`, {
        note: { ...note },
        person_details_page: "true",
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(submitNoteSuccess(res.data));
        dispatch(closeAddNoteModal());
        dispatch(updateTimeline(res.data.timeline, "UPDATE_PUBLIC_ACTIVITY_EVENTS"));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateNote = (note) => {
  return (dispatch) => {
    axios
      .patch(`/people/${note.notable_id}/notes/${note.id}`, {
        note,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(updateNoteSuccess(res.data));
        dispatch(updateTimelineEvent(res.data.timeline, "UPDATE_PUBLIC_ACTIVITY_EVENTS"));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getNotes = (person) => {
  return (dispatch) => {
    axios
      .get(`/people/${person.data.id}/notes.json`)
      .then((res) => {
        dispatch(getNotesSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteNote = (note) => {
  return (dispatch) => {
    axios
      .delete(`/people/${note.notable_id}/notes/${note.id}`, {
        data: {
          authenticity_token: ReactOnRails.authenticityToken(),
        },
      })
      .then((res) => {
        dispatch(deleteNoteSuccess(note));
        dispatch(deleteTimelineNote(note.id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const submitUpdateNote = (value, event) => {
  return (dispatch) => {
    axios
      .patch(`/people/${event.object_attributes.notable_id}/notes/${event.object_attributes.id}`, {
        note: { content: value },
        person_details_page: "true",
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(updateTimelineEvent(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const submitUpdateInteraction = (interaction) => {
  return (dispatch) => {
    dispatch(updateTimelineEvent(interaction));
  };
};

export const submitEmail = (sentEmailData, appInviteNeedsUpdating = null, homeAppRecipientId = null) => {
  return (dispatch) => {
    dispatch(submitSendEmail(sentEmailData));

    if (appInviteNeedsUpdating) {
      dispatch(touchHomeAppInviteSent(homeAppRecipientId));
    }

    setTimeout(function () {
      dispatch(clearEmailState(false));
    }, 5000);
  };
};

export const handleSubmitEmailError = (err) => {
  return (dispatch) => {
    const data = err?.response?.data;
    let errors = data?.content || data?.error || "Whoops something went wrong! Try again later.";
    if (typeof data?.errors === "object") {
      const firstKey = Object.keys(data.errors)[0];
      errors = `${firstKey} ${data.errors[firstKey].join(", ")}`;
    }
    dispatch(submitEmailError({ errors, isSupportError: data?.content?.startsWith("We apologize") }));
  };
};

export const submitAppointment = (data) => {
  return (dispatch) => {
    dispatch(submitAddAppointment(data));
  };
};

export const openAddAppointmentModal = () => {
  return {
    type: "OPEN_ADD_APPOINTMENT_MODAL",
  };
};

export const closeAddAppointmentModal = () => {
  return {
    type: "CLOSE_ADD_APPOINTMENT_MODAL",
  };
};

export const addSmsToTimeline = (smsEvent) => {
  return (dispatch) => {
    dispatch(updateTimeline(smsEvent, "UPDATE_PUBLIC_ACTIVITY_EVENTS"));
  };
};

export const submitOtherInteraction = (url, brivityOtherInteractionWrapper) => {
  return (dispatch) => {
    axios
      .post(url, brivityOtherInteractionWrapper)
      .then((res) => {
        dispatch(updateTimeline(res.data, "UPDATE_PUBLIC_ACTIVITY_EVENTS"));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const submitPhoneInteraction = (interaction, person_details_page) => {
  let route = "";
  if (interaction.id) {
    const path = `/people/${interaction.person_id}/interactions/${interaction.id}.json`;
    route = axios.patch(path, {
      interaction: interaction,
      person_details_page: person_details_page === true ? "true" : "false",
      authenticity_token: ReactOnRails.authenticityToken(),
    });
  } else {
    const path = `/people/${interaction.person_id}/interactions`;
    route = axios.post(path, {
      interaction: interaction,
      person_details_page: person_details_page === true ? "true" : "false",
      authenticity_token: ReactOnRails.authenticityToken(),
    });
  }
  return (dispatch) => {
    route
      .then((res) => {
        dispatch(updateTimeline(res.data, "UPDATE_PUBLIC_ACTIVITY_EVENTS"));
      })
      .catch((err) => {
        dispatch(setError(err.response.data.content));
      });
  };
};

export const openManageAccessModal = () => ({ type: "OPEN_MANAGE_ACCESS_MODAL" });

export const closeManageAccessModal = () => ({ type: "CLOSE_MANAGE_ACCESS_MODAL" });

export const fetchTimeline = (slug) => {
  return (dispatch) => {
    dispatch(fetchingTimeline());
    axios
      .get(`/people/${slug}/timeline?remote=true&person_details_page=true`, {
        validateStatus: function (status) {
          return status < 400; // Resolve only if the status code is less than 400
        },
      })
      .then((res) => {
        dispatch(fetchInitialTimeline(res.data));
      })
      .catch((err) => {
        dispatch(fetchTimelineError(err));
      });
  };
};

export const openAddRelationShipModal = () => ({ type: "OPEN_ADD_RELATIONSHIP_MODAL" });

export const closeAddRelationShipModal = () => ({ type: "CLOSE_ADD_RELATIONSHIP_MODAL" });

export const openAddNoteModal = () => ({ type: "OPEN_ADD_NOTE_MODAL" });

export const closeAddNoteModal = () => ({ type: "CLOSE_ADD_NOTE_MODAL" });

const uploadingDocument = (bool) => {
  return {
    type: "UPLOADING_DOCUMENT",
    bool,
  };
};

const documentUploaded = (data) => {
  return {
    type: "DOCUMENT_UPLOADED",
    data: data,
  };
};

const storeFetchedDocments = (data) => {
  return {
    type: "FETCH_DOCUMENTS",
    data: data,
  };
};

const removeDeletedDocument = (index) => {
  return {
    type: "DELETE_DOCUMENT",
    index: index,
  };
};

export const fetchDocuments = (person) => {
  return (dispatch) => {
    axios
      .get(`/people/${person.id}/documents`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(storeFetchedDocments(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const fetchAgreements = (person) => {
  return (dispatch) => {
    axios
      .get(`/api/v4/people/${person.attributes.uuid}/agreements`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(storeFetchedAgreements(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const handleAgreementCreate = (uuid, agreementData) => {
  return (dispatch) => {
    axios
      .post(`/api/v4/people/${uuid}/agreements`, {
        agreement: agreementData,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(agreementUploaded(res.data));
        dispatch(uploadingDocument(false));
        window.location.reload();
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const handleDocumentUpload = (event, person, isAgreement, selectedAgreementName) => {
  return (dispatch) => {
    dispatch(uploadingDocument(true));
    const fileUploaded = isAgreement ? event : event.target.files[0];
    const url = "/people/" + person.slug + "/documents";
    let form_data = new FormData();
    form_data.append("authenticity_token", ReactOnRails.authenticityToken());
    form_data.append("document[object]", fileUploaded);
    form_data.append("person_details_page", "true");
    if (isAgreement) {
      form_data.append("document[document_type]", "agreement");
      form_data.append("document[document_sub_type]", selectedAgreementName);
    }
    axios
      .post(url, form_data)
      .then((res) => {
        if (isAgreement) {
          const agreementData = {
            agreement_type: selectedAgreementName,
            document_id: res.data.id,
          };

          dispatch(handleAgreementCreate(person.uuid, agreementData));
        } else {
          dispatch(documentUploaded(res.data));
          dispatch(uploadingDocument(false));
        }
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const handleDocumentDelete = (person, document_id, index) => {
  return (dispatch) => {
    const path = `/people/${person.id}/documents/${document_id}`;
    axios
      .delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
      .then((res) => {
        dispatch(removeDeletedDocument(index));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const handleAgreementDelete = (person, agreement_id, index) => {
  return (dispatch) => {
    const path = `/api/v4/people/${person.data.attributes.uuid}/agreements/${agreement_id}`;
    axios
      .delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
      .then((res) => {
        dispatch(removeDeletedAgreement(index));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

const storeFetchedRelationships = (data) => {
  return {
    type: "FETCH_RELATIONSHIPS",
    data: data,
  };
};

export const fetchRelationships = (person) => {
  return (dispatch) => {
    axios
      .get(`/people/${person.id}/relationships?view_type=new`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(storeFetchedRelationships(res.data));
      });
  };
};

const relationshipAdded = (data) => {
  return {
    type: "RELATIONSHIP_ADDED",
    data: data,
  };
};

const relationshipEdited = (data) => {
  return {
    type: "RELATIONSHIP_EDITED",
    data: data,
  };
};

export const addRelationship = (relationship) => {
  return (dispatch) => {
    dispatch(relationshipAdded(relationship));
  };
};

export const saveRelationship = (person, relationshipId, primaryPersonLabel, relatedPersonLabel) => {
  return (dispatch) => {
    let editRelationshipUrl = `/people/${person.slug}/relationships/${relationshipId}.json`;
    let form_data = new FormData();
    form_data.append("authenticity_token", ReactOnRails.authenticityToken());
    form_data.append("_method", "patch");
    if (primaryPersonLabel) {
      form_data.append("relationship[primary_person_label]", primaryPersonLabel);
    }
    if (relatedPersonLabel) {
      form_data.append("relationship[related_person_label]", relatedPersonLabel);
    }
    form_data.append("person_details_page", "true");
    axios
      .post(editRelationshipUrl, form_data)
      .then((res) => {
        dispatch(relationshipEdited(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

const storeFetchedTransactions = (desc) => {
  return {
    type: "STORE_FETCHED_TRANSACTIONS",
    data: desc,
  };
};

export const getPerson = (slug) => (dispatch) => {
  dispatch(togglePersonError());
  axios
    .get(`/api/v4/person/${slug}/details`)
    .then(({ data }) => dispatch(updatePersonSuccess(data)))
    .catch(() => dispatch(togglePersonError(true)));
};

export const getNavLinks = (slug) => (dispatch) =>
  caseTransformingAxios
    .get(`/api/v4/person/${slug}/nav_links`)
    .then(({ data }) => dispatch({ type: "GET_NAV_LINKS", data }))
    .catch(console.log);

export const fetchTransactions = (person_id) => {
  return (dispatch) => {
    axios
      .get(`/people/${person_id}/transactions/quick_view`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(storeFetchedTransactions(res.data));
      });
  };
};

export const openTaskModal = () => ({ type: "OPEN_TASK_MODAL" });

export const openAppointmentModal = () => ({ type: "OPEN_APPOINTMENT_MODAL" });

export const closeAppointmentModal = () => ({ type: "CLOSE_APPOINTMENT_MODAL" });

export const closeTaskModal = () => ({ type: "CLOSE_TASK_MODAL" });

export const openAddListingAlertModal = () => ({ type: "OPEN_ADD_LISTING_ALERT_MODAL" });

export const closeAddListingAlertModal = () => ({ type: "CLOSE_ADD_LISTING_ALERT_MODAL" });

export const deleteRelationship = (person, relationshipId) => {
  return (dispatch) => {
    const path = `/people/${person.slug}/relationships/${relationshipId}?view_type=new`;
    axios
      .delete(path, {
        params: { person_details_page: true, authenticity_token: ReactOnRails.authenticityToken() },
      })
      .then((res) => {
        dispatch(relationShipDestroyed(relationshipId));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const relationShipDestroyed = (relationshipId) => {
  return {
    type: "RELATIONSHIP_DELETED",
    data: relationshipId,
  };
};

export const openEditSocialMediaModal = () => {
  return {
    type: "OPEN_EDIT_SOCIAL_MEDIA_MODAL",
  };
};

export const closeEditSocialMediaModal = () => {
  return {
    type: "CLOSE_EDIT_SOCIAL_MEDIA_MODAL",
  };
};

export const openWebActivityModal = () => ({ type: "OPEN_WEB_ACTIVITY_MODAL" });
export const closeWebActivityModal = () => ({ type: "CLOSE_WEB_ACTIVITY_MODAL" });

export const openAddAutoPlanModal = () => ({ type: "OPEN_ADD_AUTO_PLAN_MODAL" });

export const closeAddAutoPlanModal = () => ({ type: "CLOSE_ADD_AUTO_PLAN_MODAL" });

export const closeDeleteAssignedPlanModal = () => ({ type: "CLOSE_DELETE_ASSIGNED_PLAN_MODAL" });

const fetchAssignedPlansSuccess = (assignedPlans) => {
  return {
    type: "FETCH_ASSIGNED_PLANS",
    data: assignedPlans,
  };
};

const assignedPlanSuccess = (assignedPlan) => {
  return {
    type: "CREATE_ASSIGNED_PLAN",
    data: assignedPlan,
  };
};

const updateAssignedPlanSuccess = (assignedPlan) => {
  return {
    type: "UPDATE_ASSIGNED_PLAN",
    data: assignedPlan,
  };
};

export const getAssignedPlans = (assignable_id) => {
  return (dispatch) => {
    // dispatch(fetchAssignedPlansPending());

    axios
      .get("/assigned_plans", {
        responseType: "json",
        contentType: "application/json",
        params: { assignable_type: "Person", assignable_id },
      })
      .then((results) => {
        dispatch(fetchAssignedPlansSuccess(results.data));
      })
      .catch((err) => {
        console.log("error", err);
        // dispatch(fetchAssignedPlansFailure());
      });
  };
};

export const fetchAutoPlans = (autoPlansPath, type, page = null) => {
  const path = `${autoPlansPath}.json?plan_type=${type}&page=${page}`;
  return (dispatch) => {
    // dispatch(fetchAutoPlans())
    axios
      .get(path)
      .then((results) => {
        dispatch(autoPlansSuccess(results.data));
      })
      .catch((err) => {
        dispatch({ type: "AUTO_PLANS_FAILURE", isFetchingAutoPlans: false });
      });
  };
};

export const submitApplyPlan = (plan, assignableId) => {
  const form = { ...plan, assignable_id: assignableId, assignable_type: "Person", auto_plan_id: plan.id };

  return (dispatch) => {
    axios
      .post("/assigned_plans", {
        assigned_plan: form,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((results) => {
        dispatch(assignedPlanSuccess(results.data));
      });
  };
};

export const updateAssignedPlan = (assignedPlanPath, form) => {
  const formWithToken = { ...form, authenticity_token: ReactOnRails.authenticityToken() };

  return (dispatch) => {
    axios
      .patch(assignedPlanPath, formWithToken)
      .then((results) => {
        dispatch(updateAssignedPlanSuccess(results.data.assigned_plan));
      })
      .catch((err) => console.log("ERROR UPDATING PLAN", err));
  };
};

export const openDeleteAssignedPlanModal = (planToDelete) => {
  return (dispatch) => {
    dispatch({
      type: "OPEN_DELETE_ASSIGNED_PLAN_MODAL",
      planToDelete,
    });
  };
};

export const openEditAppointmentModal = () => ({ type: "OPEN_EDIT_APPOINTMENT_MODAL" });
export const closeEditAppointmentModal = () => ({ type: "CLOSE_EDIT_APPOINTMENT_MODAL" });
export const updateAppointment = (data) => {
  return (dispatch) => {
    dispatch(updateAppointmentState(data));
  };
};

const updateAppointmentState = (data) => {
  return {
    type: "UPDATE_APPOINTMENT",
    data: data,
  };
};

export const deleteAppointment = ({ id, person_id }) => {
  return (dispatch) => {
    axios
      .delete(`/people/${person_id}/interactions/${id}`, {
        params: {
          person_details_page: "true",
          authenticity_token: ReactOnRails.authenticityToken(),
        },
      })
      .then((res) => {
        dispatch(removeAppointment(id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const removeAppointment = (id) => {
  return {
    type: "REMOVE_APPOINTMENT",
    id,
  };
};

export const getTasks = (person_id) => {
  return (dispatch) => {
    axios
      .get(`/tasks/quick_view?id=${person_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(getTasksSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAppointments = (person_id) => {
  return (dispatch) => {
    axios
      .get(`/tasks/appointments?id=${person_id}&limit=3`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(getAppointmentsSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const getTasksSuccess = (data) => {
  return {
    type: "GET_TASKS",
    data,
  };
};

const getAppointmentsSuccess = (data) => {
  return {
    type: "GET_APPOINTMENTS",
    data,
  };
};

export const updateTasks = () => {
  return {
    type: "UPDATE_TASKS",
  };
};

export const updateCardTasks = (task) => {
  return {
    type: "UPDATE_CARD_TASKS",
    task,
  };
};

const getNotesSuccess = (data) => {
  return {
    type: "GET_NOTES",
    data,
  };
};

export const openEditAddressModal = () => ({ type: "OPEN_EDIT_ADDRESS_MODAL" });

export const closeEditAddressModal = () => ({ type: "CLOSE_EDIT_ADDRESS_MODAL" });

export const showAutoPlanPreview = (auto_plan_id) => {
  return (dispatch) => {
    axios
      .get(`/auto_plans/${auto_plan_id}/preview`)
      .then((res) => dispatch({ type: "SHOW_AUTO_PLAN_PREVIEW", autoPlan: res.data.auto_plan }))
      .catch((err) => {
        console.log("Failed to preview auto plan");
      });
  };
};

export const previewAction = (actionId) => {
  return (dispatch) => {
    dispatch({
      type: "PREVIEW_ACTION",
      actionId,
    });
  };
};

export const closeAutoPlanPreview = () => {
  return {
    type: "CLOSE_AUTO_PLAN_PREVIEW",
  };
};

export const showPlanDetails = (planId) => {
  return (dispatch) => {
    axios
      .get(`/assigned_plans/${planId}`, {
        responseType: "json",
        contentType: "application/json",
      })
      .then((results) => {
        dispatch({ type: "SHOW_PLAN_DETAILS", assignedPlan: results.data.assigned_plan });
      })
      .catch((err) => {
        console.log("Can't show assigned plan modal");
      });
  };
};

export const closePlanDetails = () => {
  return {
    type: "CLOSE_PLAN_DETAIL",
  };
};

export const assignedActionPreview = (assignedAction) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNED_ACTION_PREVIEW",
      assignedAction,
    });
  };
};

export const showAssignedActionDeleteModal = (actionToDelete) => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_ASSIGNED_ACTION_DELETE_MODAL",
      actionToDelete,
    });
  };
};

export const closeAssignedActionDeleteModal = () => {
  return {
    type: "CLOSE_ASSIGNED_ACTION_DELETE_MODAL",
  };
};

export const deleteAction = (id, assignedActionPath, callback) => {
  return (dispatch) => {
    axios
      .delete(assignedActionPath, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
      .then((results) => {
        dispatch({
          type: "DELETE_ACTION_SUCCESS",
          assignedActionId: id,
        });
        // TODO: is this used?
        debugger;
        if (callback) {
          dispatch(callback);
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_ACTION_FAILURE",
          error: err.response.data.message,
        });
      });
  };
};

export const updateAction = (assignedActionPath, form = {}) => {
  const formWithToken = { ...form, authenticity_token: ReactOnRails.authenticityToken() };

  return (dispatch) => {
    dispatch(updateActionPending());

    axios
      .patch(assignedActionPath, formWithToken)
      .then((results) => {
        dispatch(updateActionSuccess(results.data));
      })
      .catch((err) => {
        dispatch(updateActionFailure());
      });
  };
};

const updateActionPending = () => {
  return {
    type: "UPDATE_ACTION_PENDING",
  };
};

const updateActionSuccess = (assignedAction) => {
  return {
    type: "UPDATE_ACTION_SUCCESS",
    assignedAction,
  };
};

const updateActionFailure = (assignedAction) => {
  return {
    type: "UPDATE_ACTION_FAILURE",
  };
};

export const openDeleteCloseActionsModal = (planToDelete) => {
  return (dispatch) => {
    dispatch({
      type: "OPEN_DELETE_CLOSE_ACTIONS_MODAL",
      planToDelete,
    });
  };
};

export const setInteractionTab = (tabIndex) => {
  return (dispatch) => {
    dispatch({
      type: "SET_INTERACTION_TAB",
      tabIndex,
    });
  };
};

export const setGmailReplyThread = (
  gmailReplyThreadId,
  gmailReplyThreadSubject,
  additionalRecipientsVal,
  CCval,
  BCCval,
) => {
  return (dispatch) => {
    dispatch({
      type: "SET_GMAIL_REPLY_THREAD",
      gmailReplyThreadId,
      gmailReplyThreadSubject,
      additionalRecipientsVal,
      CCval,
      BCCval,
    });
  };
};

export const setInteractionTabWithHomeAppContent = (defaultHomeAppTabContent, tabIndex) => {
  return {
    type: "SET_INTERACTION_TAB_WITH_CONTENT",
    defaultHomeAppTabContent,
    tabIndex,
  };
};

export const clearInteractionTabContent = () => {
  return {
    type: "CLEAR_INTERACTION_TAB_CONTENT",
  };
};

export const touchHomeAppInviteSent = (personId) => {
  return (dispatch) => {
    axios
      .patch(`/api/v4/person_detail/${personId}/touch_home_app_invite_sent`, {
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(updateHomeAppInviteSent(res.data));
      });
  };
};

const updateHomeAppInviteSent = (personData) => {
  return {
    type: "UPDATE_HOME_APP_INVITE_SENT",
    home_app_invite_sent: personData.data.attributes.home_app_invite_sent,
  };
};

export const toggleTransactionModal = (value) => {
  return {
    type: "TOGGLE_TRANSACTION_MODAL",
    value,
  };
};

export const toggleLenderModal = () =>
  wrap({
    type: "TOGGLE_LENDER_MODAL",
  });

export const toggleSuccessReferLoanToast = () =>
  wrap({
    type: "TOGGLE_SUCCESS_REFER_LOAN_TOAST",
  });

export const setLastRefferedDateTime = (dateTime) =>
  wrap({
    type: "SET_LAST_REFERRED_DATE_TIME",
    data: dateTime,
  });

export const toggleStarred = (note) => {
  return async (dispatch) => {
    axios
      .patch(`/people/${note.notable_id}/notes/${note.id}`, {
        note: { ...note, starred: !note.starred },
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(updateNoteSuccess(res.data));
        dispatch(updateTimelineEvent(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        console.log(err);
      });
  };
};

export const toggleExternallyVisible = (note) => {
  return async (dispatch) => {
    axios
      .patch(`/people/${note.notable_id}/notes/${note.id}`, {
        note: { ...note, externallyVisible: !note.externallyVisible },
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((res) => {
        dispatch(updateNoteSuccess(res.data));
        dispatch(updateTimelineEvent(res.data));
      })
      .catch((err) => {
        // TODO: Handle error
        conssole.log(err);
      });
  };
};

export const fetchProjects = (slug) => (dispatch) =>
  axios
    .get(`/api/v4/person/${slug}/projects`)
    .then(({ data }) => dispatch({ type: "FETCH_PROJECTS", data }))
    .catch(console.log);
