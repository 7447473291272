// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PhoneCall from '../components/PhoneCall';
import * as actions from '../actions/phoneCallActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({...state.phoneCall });

export default connect(mapStateToProps, actions)(PhoneCall);
