import React, { useState } from "react";
import ImagePlaceHolder from "./ImagePlaceHolder";
import AddressInfo from "./AddressInfo";
import PricesInfo from "./PricesInfo";
import PrimaryAgentInfo from "./PrimaryAgentInfo";
import Status from "../shared/Status";
import { EllipsisIcon } from "../../../Transactions/components/icons";
import EllipsisDropdown from "./EllipsisDropdown";
import EditTitleModal from "./EditTitleModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import useOnClickOutsideRef from "../api/hooks";
import IconButton from "../../../shared/v2/IconButton";

const Summary = () => {
  const [rename, setRename] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [deleteListing, setDeleteListing] = useState(false);

  const ellipsisModal = useOnClickOutsideRef(() => setToggleDropdown(false));

  return (
    <>
      <div className="tw-flex tw-justify-between tw-gap-[10px] tw-mx-[32px] tw-h-[174px] tw-min-w-[1000px]">
        <div className="tw-flex tw-gap-[32px]">
          <ImagePlaceHolder />

          <AddressInfo setRename={setRename} />
        </div>
        <div className="tw-flex tw-justify-start tw-mt-[29px] tw-mb-[25px]">
          <PricesInfo />
          <div className="tw-flex tw-flex-col tw-justify-between   tw-w-[200px]">
            <PrimaryAgentInfo />
            <Status />
          </div>
          <div className="tw-relative tw-self-center tw-flex tw-items-center tw-justify-center">
            <div ref={ellipsisModal}>
              <IconButton
                role="button"
                onClick={() => setToggleDropdown(!toggleDropdown)}
                onKeyDown={() => setToggleDropdown(!toggleDropdown)}
                tabIndex={0}
                schema="tertiary"
                className="tw-w-[32px] tw-h-[32px] "
                data-cy="header-dropdown-toggle"
              >
                <EllipsisIcon className="tw-text-[18px]" />
              </IconButton>
            </div>
            {toggleDropdown && (
              <EllipsisDropdown
                deleteListing={deleteListing}
                setDeleteListing={setDeleteListing}
                setToggleDropdown={toggleDropdown}
                rename={rename}
                setRename={setRename}
              />
            )}
          </div>
        </div>
      </div>
      <EditTitleModal rename={rename} setRename={setRename} />
      <ConfirmDeleteModal deleteListing={deleteListing} setDelete={setDeleteListing} />
      <div className="tw-flex tw-justify-center tw-items-center" />
    </>
  );
};

export default Summary;
