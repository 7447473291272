import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import Error from "../../../Reports/components/DashboardShared/Error";
import { deleteTrackingNumber } from "./services";

const ConfirmDeleteTrackingNumber = ({ trackingNumberId, onClose, onConfirmDelete, chooseNotToDelete }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");

  const deleteNumber = async () => {
    setIsFetching(true);

    const { error: deleteError } = await deleteTrackingNumber(trackingNumberId);

    if (deleteError) {
      setError(deleteError);
      setIsFetching(false);
    } else {
      onConfirmDelete();
    }
  };

  return (
    <Modal
      show
      onHide={onClose}
      dialogClassName="tw-overflow-y-auto tw-max-h-full"
      closeOnClickOutside={false}
      contentSize="medium"
      contentClassName="tw-m-auto"
    >
      <Modal.Header title="Delete Tracking Number?" className="tw-mb-24px" closeButton>
        <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
      </Modal.Header>
      <Modal.Body className="tw-mb-32px">
        <div className="tw-flex tw-flex-col tw-text-gray-75 tw-gap-24px tw-mb-12px">
          <span>
            <strong>
              This action is permanent. None of your data will be retained, and we will not be held
              accountable for any loss of data.{" "}
            </strong>
          </span>
          <span>
            Any calls made to this number will not be forwarded to your other Brivity Numbers. Additionally,
            if this number is a vanity number, we will not retain this number and cannot guarantee its
            availability if you wish to purchase it again.
          </span>
          <span>
            Make sure to remove the tracking number from relevant materials (flyers, websites, other lead
            sources) before continuing.{" "}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button schema="tertiary" size="medium" onClick={chooseNotToDelete}>
            BACK
          </Button>
          <Button type="button" schema="warning" size="medium" disabled={isFetching} onClick={deleteNumber}>
            DELETE TRACKING NUMBER
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmDeleteTrackingNumber.propTypes = {
  trackingNumberId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  chooseNotToDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteTrackingNumber;
