import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import Pluralize from "react-pluralize";

const mapStateToProps = (state) => ({
  from: state.leadIndex.from,
});

const Name = ({ person, from }) =>
  from === "leads" ? (
    <>
      {(() => {
        if (person.full_name && person.full_name !== " ") {
          return (
            <a className="hover:tw-underline" href={person.person_path_with_query_param}>
              {person.full_name}
            </a>
          );
        }
        if (person.lead_last_search_at && person.lead_last_search_at !== null) {
          return (
            <>
              <a
                className="hover:tw-underline person-name no-name"
                href={person.person_path_with_query_param}
              >
                No Name
              </a>
              <br />
              <span className={person.fetch_results_count > 0 ? "has-matches" : "no-matches"}>
                <Pluralize
                  singular="match"
                  plural="matches"
                  zero="No matches"
                  count={person.fetch_results_count}
                />
                &nbsp;found
              </span>
            </>
          );
        }
        return (
          <>
            <a className="hover:tw-underline" href={person.person_path_with_query_param}>
              No Name
            </a>
            <br />
            <a href="/#" className="find-matches">
              Search for details
            </a>
          </>
        );
      })()}
    </>
  ) : (
    <h6 className={`person-name ${person.full_name !== " " ? "" : "no-name"}`}>
      <a className="hover:tw-underline" href={person.person_path_with_query_param}>
        {person.name_or_no_name}
      </a>
    </h6>
  );

Name.propTypes = {
  person: Proptypes.shape({
    full_name: Proptypes.string,
    person_path_with_query_param: Proptypes.string,
    name_or_no_name: Proptypes.string,
    fetch_results_count: Proptypes.number,
    lead_last_search_at: Proptypes.string,
  }),
  from: Proptypes.string,
};
Name.defaultProps = {
  person: null,
  from: null,
};
export default connect(mapStateToProps)(Name);
