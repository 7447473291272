import React from "react";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";
import { Section, SectionLabel } from "../BusinessDashboard";
import OverviewCard from "./OverviewCard";
import useOpenHouseDashboardOverview from "./hooks/useOpenHouseDashboardOverview";
import Loading from "../../../Goals/components/Loader";

const VerticalRule = () => <div className="tw-h-full tw-w-[1px] tw-bg-neutral-gray-5" />;

const OpenHouseOverview = ({ dateRange, selectedHosts }) => {
  const { data, error, loading } = useOpenHouseDashboardOverview({ dateRange, selectedHosts });

  return (
    <Section className="!tw-p-[32px]">
      <div className="tw-flex tw-flex-col tw-gap-[16px]">
        <SectionLabel>Overview</SectionLabel>
        <div className="tw-flex tw-justify-stretch tw-gap-[12px]">
          <OverviewCard
            centered
            description="Open houses completed in the app"
            title="Open Houses"
            value={data.completed}
          />
          <VerticalRule />
          <OverviewCard
            centered
            description="Total number of open house visitors"
            title="Attendees"
            value={data.attendees}
          />
          <VerticalRule />
          <OverviewCard
            centered
            description="Attendees who signed-in with the app"
            title="Leads"
            value={data.leads}
          />
          <OverviewCard
            centered
            description="Avg. # of leads added per open house"
            title="Avg. Leads"
            value={data.avgLeads}
          />
        </div>
      </div>
      {loading && <Loading />}
      {error && <Alert variant="error" text="Failed to load Overview, try again later." />}
    </Section>
  );
};

OpenHouseOverview.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  selectedHosts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default OpenHouseOverview;
