import React, { useEffect, useState } from "react";
import { number, shape, string, func, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import TextComboInput from "@shared/v2/TextComboInput/TextComboInput";
import { Search as SearchIcon, ArrowLeft } from "@shared/v2/Icomoon";
import { mergeCompareObjects, setDefaultValues } from "../api/helpers";
import { allKeys } from "../api/constants";
import ImportListingDropdown from "./ImportListingDropdown";
import {
  getAllDetailsAsThunk,
  getPropertyTypesAsThunk,
  getsearchListingAsThunk,
  getSelectedListingDataAsThunk,
  importDetailAsThunk,
} from "../actions/thunks";
import ListingPreviewCard from "../../../../Transactions/ListingPreviewCard";
import ReviewImportListingDetails from "./ReviewImportListingDetails";

const getPropertyId = (accountPropertyTypes, labelToFind) => {
  const property = accountPropertyTypes.find((property) => property.label === labelToFind);
  return property ? property.value : null;
};

const addToPayload = (payload, payloadProperty, isAddressAttr, isDetailAttr, propertyValue) => {
  if (isAddressAttr) {
    if (!payload.hasOwnProperty("address_attributes")) {
      payload["address_attributes"] = {};
      payload["address_attributes"]["address_type"] = "listing";
    }
    payload["address_attributes"][payloadProperty] = propertyValue;
  } else if (isDetailAttr) {
    if (!payload.hasOwnProperty("details")) {
      payload["details"] = [];
    }
    payload["details"].push({ name: payloadProperty, value: propertyValue });
  } else {
    payload[payloadProperty] = propertyValue;
  }
  return payload;
};

const setProperty = (payload, data, listingProperty, payloadProperty, isAddressAttr, isDetailAttr) => {
  if (!data[listingProperty]) return;
  const mlsProperty = data[listingProperty][`mls_${listingProperty}`];
  const currentProperty =
    data[listingProperty][`current_${listingProperty}`] === "—"
      ? null
      : data[listingProperty][`current_${listingProperty}`];

  const { isSelectable, mlsChecked } = data[listingProperty];

  if (isSelectable) {
    if (mlsChecked) {
      addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, mlsProperty);
    } else {
      if (isAddressAttr) {
        addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, currentProperty);
      }
    }
  } else {
    if (mlsProperty !== "—") {
      addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, mlsProperty);
    } else {
      if (isAddressAttr) {
        addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, currentProperty);
      }
    }
  }
  return payload;
};

const ImportListingDetailsModal = ({
  modalOpen,
  isFromDetail,
  closeModal,
  setshowAlert,
  uuid,
  isFromIndex,
  listingUuid,
  onGetAllDetailsAsThunk,
  onGetPropertyTypesAsThunk,
  getsearchListingAsThunk,
  getSelectedListingDataAsThunk,
  importDetailAsThunk,
  photosAvailable,
  propertyDetails,
  accountPropertyTypes,
  listingDetailsData,
  checkAddressAndcloseModal,
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [currentTerm, setCurrentTerm] = useState("");
  const [currentChildPosition, setCurrentChildPosition] = useState(0);
  const [isSearched, setIsSearched] = useState(false);
  const [selectedSearchedTerm, setSelectedSearchedTerm] = useState("");
  const [openReview, setOpenReview] = useState(false);
  const [preview, setPreview] = useState(false);
  const [listingDetails, setListingDetails] = useState(null);
  const [listingData, setListingData] = useState(null);

  useEffect(() => {
    if (listingDetails) {
      setPreview(true);
    }
  }, [listingDetails]);

  useEffect(() => {
    if (isFromIndex && listingUuid) {
      onGetAllDetailsAsThunk(listingUuid);
      onGetPropertyTypesAsThunk(listingUuid);
    }
  }, [isFromIndex]);

  useEffect(() => {
    if (currentTerm) {
      const searchTermTrimmed = currentTerm.trim();
      getsearchListingAsThunk(searchTermTrimmed, setSearchResults, setIsSearched);
    }
  }, [currentTerm]);

  const debounceSearchAddress = debounce(async (term) => {
    setSelectedSearchedTerm(term);
    await searchAddress(term);
  }, 150);

  const searchAddress = async (term) => {
    if (!term.length) {
      return;
    }
    setCurrentTerm(term);
  };

  const selectListing = async (term) => {
    setCurrentChildPosition(0);
    setSearchResults([]);
    setSelectedSearchedTerm(term.value);
    getSelectedListingDataAsThunk(term, setListingDetails);
  };

  const handleKeyDown = async (e) => {
    // Enter key
    if (e.keyCode === 13) {
      e.preventDefault();
      await selectListing(searchResults[currentChildPosition]);
    } else if (e.keyCode === 38) {
      // Navigate up
      if (currentChildPosition > 0) {
        setCurrentChildPosition(currentChildPosition - 1);
      }
    } else if (e.keyCode === 40) {
      // Navigate down
      if (currentChildPosition < searchResults.length - 1) {
        setCurrentChildPosition(currentChildPosition + 1);
      }
    } else if (e.keyCode === 8 && currentTerm.length === 1) {
      // Removing last character with backspace
      setCurrentChildPosition(0);
      setSearchResults([]);
    }
  };

  const handleHover = (currentChildPosition) => {
    setCurrentChildPosition(currentChildPosition);
  };

  const handleReview = () => {
    const currentListingData = {
      ...propertyDetails,
      ...listingDetailsData,
      streetAddress: propertyDetails.street_address,
      sqFt: propertyDetails.sqft,
      postalCode: propertyDetails.zip,
      property_type: propertyDetails?.property_type?.name,
      photosAvailable: photosAvailable,
      mlsNumber: listingDetailsData.mls_number,
    };

    const comparableListingData = mergeCompareObjects(
      setDefaultValues(listingDetails, allKeys),
      setDefaultValues(currentListingData, allKeys),
    );
    setListingData(comparableListingData);
    setOpenReview(true);
  };

  const handleImport = () => {
    let payloadListing = {};
    setProperty(payloadListing, listingData, "property_type", "property_type");
    setProperty(payloadListing, listingData, "price", "price");
    setProperty(payloadListing, listingData, "mlsNumber", "mls_number");
    setProperty(payloadListing, listingData, "description", "description");
    setProperty(payloadListing, listingData, "photosAvailable", "photosAvailable");
    setProperty(payloadListing, listingData, "streetAddress", "street_address", true);
    setProperty(payloadListing, listingData, "city", "city", true);
    setProperty(payloadListing, listingData, "state", "locality", true);
    setProperty(payloadListing, listingData, "postalCode", "postal_code", true);
    setProperty(payloadListing, listingData, "county", "county", true);
    setProperty(payloadListing, listingData, "neighborhood", "neighborhood", true);
    setProperty(payloadListing, listingData, "beds", "beds", false, true);
    setProperty(payloadListing, listingData, "baths", "baths", false, true);
    setProperty(payloadListing, listingData, "sqFt", "sqft", false, true);
    setProperty(payloadListing, listingData, "year_built", "year_built", false, true);
    setProperty(payloadListing, listingData, "foundation", "foundation", false, true);
    setProperty(payloadListing, listingData, "parking", "parking", false, true);

    if (payloadListing.property_type) {
      payloadListing.property_type_id = getPropertyId(accountPropertyTypes, payloadListing.property_type);
      delete payloadListing.property_type;
    }
    if (payloadListing.photosAvailable) {
      payloadListing.blossor_id = listingDetails.blossorId;
      payloadListing.use_mls_photos = true;
      delete payloadListing.photosAvailable;
    }
    const userUUID = isFromIndex && listingUuid ? listingUuid : uuid;
    importDetailAsThunk(userUUID, payloadListing, setshowAlert);
    closeModal();
  };

  const handleBack = () => {
    setOpenReview(false);
  };

  const handleClose = () => {
    if (isFromDetail) {
      closeModal();
    } else {
      closeModal();
      checkAddressAndcloseModal();
    }
  };

  const clearListing = () => {
    setSearchResults([]);
    setCurrentTerm("");
    setCurrentChildPosition(0);
    setIsSearched(false);
    setSelectedSearchedTerm("");
    setOpenReview(false);
    setPreview(false);
    setListingDetails(null);
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      // 636px = 616px from Figma + 10px for each horizontal padding (20px)
      dialogClassName="tw-w-[636px] tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      backdrop="static"
      show={modalOpen}
      onHide={handleClose}
      data-cy="import-listing-detail-modal"
    >
      <Modal.Header
        titleClassName="tw-mb-32px tw-text-neutral-gray-75"
        title="Import Listing Details"
        closeButton
      />

      <Modal.Body className="tw-mb-32px">
        {!openReview && !preview && (
          <TextComboInput
            className="tw-w-[392px]"
            inputClassName="tw-p-0"
            autoComplete="no"
            placeholder="Search by listing address or MLS #"
            value={selectedSearchedTerm}
            onChange={(e) => debounceSearchAddress(e.target.value)}
            onKeyDown={handleKeyDown}
            trailing={
              isSearched ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <div className="tw-flex tw-items-center">
                  <SearchIcon className="tw-text-neutral-gray-30" size="l" />
                </div>
              )
            }
          />
        )}
        {!openReview && !preview && searchResults.length > 0 && (
          <div className="tw-mt-[4px] tw-absolute tw-w-[392px] tw-bg-white tw-rounded-sm tw-border-solid tw-border tw-border-gray-15 tw-shadow-bootstrap-18 tw-z-10 tw-overflow-y-scroll">
            {searchResults.map((results, i) => (
              <ImportListingDropdown
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                handleHover={() => handleHover(i)}
                // eslint-disable-next-line  no-return-await
                handleSelect={async () => await selectListing(results)}
                results={results}
              />
            ))}
          </div>
        )}

        {!openReview && preview && (
          <ListingPreviewCard
            containerClassName="tw-w-[392px]"
            listingData={listingDetails}
            clearListing={clearListing}
          />
        )}

        {openReview && (
          <ReviewImportListingDetails
            containerClassName="tw-w-[632px] tw-h-[510px]"
            setListingData={setListingData}
            listingData={listingData}
          />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button
          onClick={openReview ? handleBack : handleClose}
          className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
          schema="tertiary"
          size="medium"
          data-cy="documents-upload-cancel-button"
        >
          {openReview ? (
            <>
              <ArrowLeft />
              <span className="tw-pl-[7px]">BACK</span>
            </>
          ) : (
            "CANCEL"
          )}
        </Button>
        <Button
          className="tw-flex tw-items-center"
          size="medium"
          schema="primary"
          disabled={!preview}
          onClick={openReview ? handleImport : handleReview}
          data-cy="import-listing-detail-review-button"
        >
          {openReview ? "IMPORT" : "REVIEW"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ImportListingDetailsModal.propTypes = {
  modalOpen: bool,
  isFromDetail: bool,
  isFromIndex: bool,
  listingUuid: string,
  closeModal: func,
  checkAddressAndcloseModal: func,
  setshowAlert: func,
  uuid: string,
  getsearchListingAsThunk: func.isRequired,
  getSelectedListingDataAsThunk: func.isRequired,
  onGetAllDetailsAsThunk: func.isRequired,
  onGetPropertyTypesAsThunk: func.isRequired,
  importDetailAsThunk: func.isRequired,
  photosAvailable: number,
  listingDetailsData: shape({
    mls_number: string,
    price: string,
    description: string,
  }),
  accountPropertyTypes: arrayOf(shape({ label: string, value: number })),
  propertyDetails: shape({
    street_address: string,
    city: string,
    state: string,
    zip: string,
    neighborhood: string,
    county: string,
    baths: string,
    beds: string,
    sqft: string,
    year_built: string,
    property_type: shape({ id: number, name: string }),
  }),
};

ImportListingDetailsModal.defaultProps = {
  modalOpen: false,
  isFromDetail: false,
  isFromIndex: false,
  listingUuid: null,
  closeModal: () => {},
  checkAddressAndcloseModal: () => {},
  setshowAlert: () => {},
  photosAvailable: 0,
  listingDetailsData: {
    mls_number: "",
    price: "",
    description: "",
  },
  uuid: null,
  accountPropertyTypes: [],
  propertyDetails: shape({
    street_address: null,
    city: null,
    state: null,
    zip: null,
    neighborhood: null,
    county: null,
    baths: null,
    beds: null,
    sqft: null,
    year_built: null,
    property_type: { id: null, name: null },
  }),
};

const mapStateToProps = (state) => ({
  accountPropertyTypes: state.tdpDetailsReducer.meta.accountPropertyTypes,
  listingDetailsData: state.tdpDetailsReducer.listingDetails,
  uuid: state.tdpDetailsReducer.uuid,
  photosAvailable: state.tdpDetailsReducer.listingImgs.length,
  propertyDetails: state.tdpDetailsReducer.propertyDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getSelectedListingDataAsThunk: (term, setListingDetails) =>
    dispatch(getSelectedListingDataAsThunk(term, setListingDetails)),
  getsearchListingAsThunk: (search, setSearchResults, setIsSearched) =>
    dispatch(getsearchListingAsThunk(search, setSearchResults, setIsSearched)),
  importDetailAsThunk: (uuid, payload, setshowAlert) =>
    dispatch(importDetailAsThunk(uuid, payload, setshowAlert)),
  onGetAllDetailsAsThunk: (uuid) => dispatch(getAllDetailsAsThunk(uuid)),
  onGetPropertyTypesAsThunk: (uuid) => dispatch(getPropertyTypesAsThunk(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportListingDetailsModal);
