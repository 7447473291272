import React from "react";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import useDialer from "./hooks/useDialer";
import Loading from "../Goals/components/Loader";
import CallBar from "./components/CallBar";
import SinglePerson from "./components/SinglePerson";
import CallButton from "./components/CallButton";
import CallOutcome from "./components/CallOutcome";
import CallNotes from "./components/CallNotes";
import CALL_STATES from "./utils/states";
import PlatformMessage from "./components/PlatformMessage";

const CLOSABLE_STATES = [CALL_STATES.Ended, CALL_STATES.Init];

/*
  This component is a modal that is used to make calls to a single person.
  Place <SingleDialerModal /> on the page where you want the modal to appear.
  To use call the function setLeadUuid from dialerReducer with a person uuid.
  The modal does the rest of the work.
*/
const SingleDialerModal = ({ listingId, listingUuid }) => {
  const {
    actions,
    data: { callOutcome, callState, leadUuids, person, phoneNumber },
    error,
    loading,
    disconnectDialer,
  } = useDialer({ listingUuid });

  const onClose = CLOSABLE_STATES.includes(callState)
    ? () => {
        disconnectDialer();
        actions.setLeadUuids(null);
      }
    : null;

  return (
    <Modal
      // Fighting with the Flyout z-index
      containerClassName="!tw-z-[3001]"
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[700px] tw-w-[100vw] tw-flex tw-flex-col tw-gap-[32px]"
      closeOnClickOutside={false}
      onHide={onClose}
      show={leadUuids?.length === 1}
    >
      <Modal.Header title="Call" closeButton={Boolean(onClose)} />
      <Modal.Body className="tw-flex tw-flex-col tw-gap-[32px]">
        <CallBar />

        <PlatformMessage />

        <div className="tw-flex tw-justify-between tw-items-end">
          <SinglePerson />
          <CallButton />
        </div>

        <CallOutcome />

        <CallNotes
          isDnc={phoneNumber?.meta.isDnc}
          mentionableIdOrUuid={person?.uuid}
          mentionableType="Person"
        />

        {error && <Alert variant="error" text={error} />}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={onClose} disabled={!onClose}>
          Close
        </Button>
        <Button
          size="medium"
          onClick={() => actions.logCall({ listingId, listingUuid }).then(() => actions.setLeadUuids(null))}
          disabled={!callOutcome}
        >
          Save & Close
        </Button>
      </Modal.Footer>

      {loading && <Loading />}
    </Modal>
  );
};

SingleDialerModal.propTypes = {
  listingId: PropTypes.string,
  listingUuid: PropTypes.string,
};

SingleDialerModal.defaultProps = {
  listingId: undefined,
  listingUuid: undefined,
};

export default SingleDialerModal;
