import Button from "@shared/v2/Button";
import React from "react";

const EmptyState = ({ isInitialState, onClickSearchButton }) => {
  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-mt-[206px] tw-items-center">
      <div className="tw-text-center">
        <i className="fa fa-search tw-text-36px tw-mb-20px tw-text-theme-primary" />
        <p className="tw-text-gray-75 tw-text-18px tw-font-bold tw-mb-12px">
          {isInitialState ? "Search Properties" : "No matching results"}
        </p>
        <p className="tw-text-gray-75 tw-mb-20px">
          {isInitialState
            ? "Enter an address, neighborhood, city, ZIP code, or county to search"
            : "Please try searching in a different area"}
        </p>
        {isInitialState && (
          <Button size="medium" schema="primary" onClick={onClickSearchButton}>
            SEARCH
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
