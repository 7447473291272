import React from "react";
import PropTypes from "prop-types";

import Modal from "./Modal";
import Button from "../Button";
import TextButton from "../TextButton";
import { availableContentSizes, availableHeaderSizes } from "./Modal.styles";
import {
  cancelDefaultProps,
  cancelPropTypes,
  makeOptionPropsCompatible,
  primaryDefaultProps,
  primaryPropTypes,
  secondaryDefaultProps,
  secondaryPropTypes,
} from "./utils";

/**
 * @summary Extends GenericModal to offer a small modal for title, 1-liner and up to 2 CTAs.
 * @param {string} title - the modal's title.
 * @param {(string|JSX.Element|object)} description - subtitle for the modal.
 * @param {string} primaryOption - content for the primary button.
 * @param {string} primaryProps - props to be passed to the primary button. Check Button for available props.
 * @param {func} onPrimary - function for what happens clicking on primary button.
 * @param {bool} isPrimaryLoading - helper to mark the primary button as loading and disable others.
 * @param {string} secondaryOption - content for the secondary button.
 * @param {string} secondaryProps - props to be passed to the secondary button. Check Text Button for available props.
 * @param {func} onSecondary - function for what happens clicking on secondary button.
 * @param {bool} isSecondaryLoading - helper to mark the secondary button as loading and disable others.
 * @param {string} cancelProps - props to be passed to the cancel button. Check Button for available props.
 * @param {func} onCancel - function for what happens clicking outside, cancel or close button.
 * @param {bool} show - defines if modal is visible.
 * @param {string} contentSize - defines the width of the modal's content.
 * @param {string} headerSize - defines the size of the header.
 * @param {bool} closeOnEscape - Close modal when press ESC
 * @param {bool} closeOnClickOutside - Close modal when click outside
 * */

const Prompt = ({
  title,
  description,
  primaryOption,
  onPrimary,
  isPrimaryLoading,
  secondaryOption,
  onSecondary,
  isSecondaryLoading,
  cancelProps,
  onCancel,
  show,
  contentSize,
  headerSize,
  closeOnEscape,
  closeOnClickOutside,
  ...optionProps
}) => {
  const { primaryProps, secondaryProps } = makeOptionPropsCompatible(optionProps);
  const isSomethingLoading = isPrimaryLoading || isSecondaryLoading;

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full"
      contentClassName="tw-space-y-[32px] tw-m-auto"
      contentSize={contentSize}
      closeOnEscape={closeOnEscape}
      closeOnClickOutside={closeOnClickOutside}
    >
      <Modal.Header title={title} description={description} size={headerSize} closeButton />
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...cancelProps}
          size="medium"
          schema="tertiary"
          disabled={isSomethingLoading || cancelProps.disabled}
          onClick={onCancel}
          data-cy={cancelProps["data-cy"] || cancelProps.dataCy || "prompt-cancel-button"}
        >
          Cancel
        </Button>
        <div className="tw-flex tw-flex-row tw-space-x-[12px]">
          {secondaryOption && (
            <TextButton
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...secondaryProps}
              size="medium"
              disabled={isSomethingLoading || secondaryProps.disabled}
              isLoading={isSecondaryLoading}
              onClick={onSecondary}
              data-cy={secondaryProps["data-cy"] || secondaryProps.dataCy || "prompt-secondary-button"}
            >
              {secondaryOption}
            </TextButton>
          )}
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...primaryProps}
            size="medium"
            disabled={isSomethingLoading || primaryProps.disabled}
            isLoading={isPrimaryLoading}
            onClick={onPrimary}
            data-cy={primaryProps["data-cy"] || primaryProps.dataCy || "prompt-primary-button"}
          >
            {primaryOption}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

Prompt.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  primaryOption: PropTypes.string,
  primaryProps: primaryPropTypes,
  onPrimary: PropTypes.func,
  isPrimaryLoading: PropTypes.bool,
  secondaryOption: PropTypes.string,
  secondaryProps: secondaryPropTypes,
  onSecondary: PropTypes.func,
  isSecondaryLoading: PropTypes.bool,
  cancelProps: cancelPropTypes,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  contentSize: PropTypes.oneOf(availableContentSizes),
  headerSize: PropTypes.oneOf(availableHeaderSizes),
  closeOnEscape: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
};

Prompt.defaultProps = {
  title: null,
  description: null,
  primaryOption: null,
  primaryProps: primaryDefaultProps,
  onPrimary: null,
  isPrimaryLoading: false,
  secondaryOption: null,
  secondaryProps: secondaryDefaultProps,
  onSecondary: null,
  isSecondaryLoading: false,
  cancelProps: cancelDefaultProps,
  onCancel: null,
  show: false,
  contentSize: "small",
  headerSize: "medium",
  closeOnEscape: true,
  closeOnClickOutside: true,
};

export default Prompt;
