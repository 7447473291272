import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@shared/v2/IconButton";
import Trash from "@shared/v2/Icomoon/Icons/Trash";
import DeleteAgreementModal from "./DeleteAgreementModal";

const AgreementSection = ({ agreement: { id, agreement_type }, index, onDelete, person }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onClickDeleteAgreement = () => {
    setIsDeleteModalOpen(true);
  };
  const agreementCloseModal = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <div>
      <div
        key={id}
        className="tw-relative tw-shadow-tdp-card tw-overflow-hidden tw-flex tw-flex-col tw-gap-[12px] tw-rounded-[4px] tw-py-[8px] tw-px-[12px]"
      >
        <div className="tw-flex tw-items-center tw-gap-[12px] tw-overflow-hidden">
          <div className="tw-flex tw-items-center tw-gap-[12px] tw-flex-1 tw-min-w-0">
            <div className="tw-text-[12px] tw-leading-[16px] tw-font-normal tw-flex-1 tw-capitalize tw-relative tw-overflow-hidden">{`${agreement_type} Agreement`}</div>
          </div>
          <div>
            <IconButton
              size="small"
              schema="tertiary"
              onClick={onClickDeleteAgreement}
              data-cy="document-list-item-delete-button"
            >
              <Trash />
            </IconButton>
          </div>
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteAgreementModal
          person={person}
          id={id}
          index={index}
          modalOpen={isDeleteModalOpen}
          onDelete={onDelete}
          closeModal={agreementCloseModal}
        />
      )}
    </div>
  );
};

AgreementSection.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.number,
    agreement_type: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  person: PropTypes.shape({}),
  onDelete: PropTypes.func,
};
AgreementSection.defaultProps = {
  agreement: {},
  person: {},
  onDelete: null,
};
export default AgreementSection;
