import React, { useState } from "react";
import PropTypes from "prop-types";
import { DEFAULT_YEAR_OPTIONS_DROPDOWN } from "../../../Goals/components/HeadingSelect";
import Dropdown from "../../../shared/v2/Dropdown";
import GoalsSection from "../DashboardShared/GoalsSection";
import BusinessByLeadSource from "../DashboardShared/BusinessByLeadSource";
import AgentSummary from "./AgentSummary";
import MonthlyOverview from "../DashboardShared/MonthlyOverview";
import AgentTransactions from "./AgentTransactions";
import { Section } from "../BusinessDashboard";
import ClosingsBreakdown from "../DashboardShared/ClosingsBreakdown";

const AgentDashboard = ({ agentList, isAdmin }) => {
  const [agent, setAgent] = useState(agentList.find(({ meta }) => meta.isCurrentUser));
  const [year, setYear] = useState(DEFAULT_YEAR_OPTIONS_DROPDOWN[DEFAULT_YEAR_OPTIONS_DROPDOWN.length - 2]);
  return (
    <div className="tw-bg-gray-5 tw-flex tw-p-[16px]">
      <div className="tw-relative tw-max-w-[1500px] tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[16px]">
        <Section>
          <div className="tw-flex tw-justify-between tw-items-center">
            <h2 className="tw-text-28d tw-m-0 tw-mr-[8px]">Agent Dashboard</h2>
            <div className="tw-flex tw-flex-wrap tw-gap-[32px]">
              {isAdmin && (
                <div className="tw-flex tw-items-center tw-gap-[16px]">
                  <span>User:</span>
                  <Dropdown className="tw-w-[180px]" options={agentList} value={agent} onChange={setAgent} />
                </div>
              )}
              <Dropdown options={DEFAULT_YEAR_OPTIONS_DROPDOWN} value={year} onChange={setYear} />
            </div>
          </div>
        </Section>
        <div className="tw-flex tw-items-start tw-flex-wrap tw-gap-[16px]">
          <div className="tw-flex tw-flex-col tw-flex-1 tw-basis-[34%] tw-min-w-[320px] tw-gap-[16px]">
            <AgentSummary agentUuid={agent.value} year={year.value} />
            <BusinessByLeadSource agentUuid={agent.value} year={year.value} />
          </div>
          <div className="tw-flex tw-flex-col tw-flex-1 tw-basis-[58%] tw-min-w-[320px] tw-gap-[16px]">
            <AgentTransactions agentUuid={agent.value} agentId={agent.meta.id} year={year.value} />
            <GoalsSection agentUuid={agent.value} year={year.value} />
            <ClosingsBreakdown agentUuid={agent.value} year={year.value} />
            <MonthlyOverview agentUuid={agent.value} year={year.value} />
          </div>
        </div>
      </div>
    </div>
  );
};

AgentDashboard.propTypes = {
  agentList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      meta: PropTypes.shape({ is_current_user: PropTypes.bool }),
    }),
  ).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default AgentDashboard;
