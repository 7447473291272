import axios from "axios";
import { Modal } from "../../shared/v1";
import TaskForm from "./TaskForm";
import RescheduleModal from "./RescheduleModal";
import DeleteModal from "./DeleteModal";
import ReassignModal from "./ReassignModal";
import React from "react";
import { stripHtml } from "../../shared/Utilities";
import NewModalHeader from "../components/NewModalHeader";
import Details from "../../PersonDetail/components/tasks/Details";
export function showSelectAllPopover(e) {
  this.setState({
    ...this.state,
    selectAllTarget: e.target,
    showSelectAll: true,
  });
}

export function closeSelectAllPopover(e) {
  this.setState({ ...this.state, selectAllTarget: null, showSelectAll: false });
}

export function showModal() {
  this.setState({ showModal: true });
}

export function showModalComplete() {
  const { task } = this.state;
  task["state"] = "completed";
  task["completed"] = true;
  this.setState({ task, showModal: true });
}

export function closeModal(response) {
  this.setState({ showModal: false });
}

export function showDetailModal() {
  this.setState({ showDetailModal: true });
}

export function closeDetailModal() {
  this.setState({ showDetailModal: false });
}
export function swapModals() {
  this.setState({ showDetailModal: false, showModal: true });
}
export function showDeleteModal() {
  this.setState({ ...this.state, showDeleteModal: true });
}

export function closeDeleteModal() {
  this.setState({ ...this.state, showDeleteModal: false });
}

export function swapForDelete() {
  this.setState({ showModal: false, showDeleteModal: true });
}

export function handleTaskChange(e) {
  const { task } = this.state;
  const key = e.target.name;
  const value = e.target.type == "checkbox" ? e.target.checked : e.target.value;

  if (key == "multi_select") {
    return this.handleMultiSelect(value);
  }

  if (key === "recurring_every_so_many_days" || key === "recurring_day_of_week") {
    return this.handleRecurrence(key, value);
  }

  if (key === "assigned_to_user_id" && value === "anyone") {
    return this.handleAssignedToUser(key, value);
  }

  task[key] = value;

  this.setState({ task });
  if (key == "complete" && task["complete"] == true) {
    this.completeTask(this.state.task);
  }
  if (key == "complete" && task["complete"] == false) {
    this.uncompleteTask(this.state.task);
  }
}

export function handleAssignedToUser(key, value) {
  const { task } = this.state;
  task["assigned_to_user_id"] = null;
  this.setState({ task });
}

export function handleRecurrence(key, value) {
  var { task } = this.state;
  if (key === "recurring_every_so_many_days") {
    task.recurring_day_of_week = null;
    task.recurring_every_so_many_days = value;
  }
  if (key === "recurring_day_of_week") {
    task.recurring_every_so_many_days = null;
    task.recurring_day_of_week = value;
  }
  this.setState({ task });
}

export function handleMultiSelect(value) {
  let { task } = this.state;
  task["multi_select"] = value;
  this.setState({ ...this.state, ...task });
  this.multiSelectWatcher();
}

export function weeklyOptions() {
  return {
    0: "On Sunday",
    1: "On Monday",
    2: "On Tuesday",
    3: "On Wednesday",
    4: "On Thursday",
    5: "On Friday",
    6: "On Saturday",
  };
}

export function completeAndEditAppointment() {
  let task = { ...this.state.task };
  task["completed"] = !task["completed"];
  task["state"] = "completed";
  this.setState({ task });
}
export function renderDetailModal() {
  var { task } = this.props;
  let default_agents = [{ label: "Anyone", value: "anyone" }];
  // FIXME(rubenochiavone): Add prop to Details component to toggle Modal visibility so that
  // animation can work. Short-circuiting the rendering of Modal, like in the line below, prevents
  // animation from working.
  return (
    this.state.showDetailModal && (
      <Details
        task={task}
        closeTaskDetails={this.closeDetailModal}
        editTask={this.swapModals}
        accountMilestoneNames={this.props.accountMilestoneNames}
        completeTaskFromDetails={this.completeTask}
      />
    )
  );
}

export function renderNewPolymorphicTaskModal() {
  var default_agents = [{ label: "Anyone", value: "anyone" }];
  var { task } = this.state;
  if (!task) task = {};
  task.assigned_to_user_id = task.assigned_to_user_id || this.props.user.uuid;
  return (
    <Modal
      show={this.state.showModal}
      onHide={this.closeModal}
      deprecatedOverrideDialogWidthClasses="tw-w-[750px]"
      contentClassName="tw-px-[32px] tw-py-0"
      id="new-task-modal"
    >
      <NewModalHeader header={`New Task for ${this.state.taskable.task_name}`} closeModal={this.closeModal} />
      <TaskForm
        agents={default_agents.concat(this.setAgentsForModal())}
        accountUuid={this.state.account.uuid}
        task={task}
        taskable={this.state.taskable}
        accountMilestoneNames={this.props.accountMilestoneNames}
        taskChange={this.props.taskChange}
        onCloseAction={this.closeModal}
        closeModal={this.closeModal}
        deleteButton={task !== undefined}
        showErrors={this.showErrors}
        errors={this.state.error_messages}
        editToDelete={this.swapForDelete}
        newTaskFor={true}
        user={this.props.user}
      />
    </Modal>
  );
}

export function renderModal() {
  var default_agents = [{ label: "Anyone", value: "anyone" }];
  var { task } = this.props;
  return (
    <Modal
      show={this.state.showModal}
      onHide={this.closeModal}
      contentClassName="tw-px-[18px] tw-py-0 tw-w-[750px]"
      id="edit-task-modal"
    >
      <NewModalHeader header={`Edit Task for ${task.taskable_name}`} closeModal={this.closeModal} />
      <TaskForm
        agents={default_agents.concat(this.setAgentsForModal())}
        task={task}
        taskable={this.state.taskable}
        accountMilestoneNames={this.props.accountMilestoneNames}
        taskChange={this.props.taskChange}
        onCloseAction={this.closeModal}
        closeModal={this.closeModal}
        deleteButton={true}
        showErrors={this.showErrors}
        editToDelete={this.swapForDelete}
        editTaskFor={true}
        errors={this.state.error_messages}
      />
    </Modal>
  );
}

export function multiSelectWatcher() {
  let multi_select_tasks = this.state.tasks.filter(
    (task) => task.multi_select == true && task.type !== "Appointment",
  );
  if (multi_select_tasks.length == 0 && this.state.header_buttons) {
    this.setState({ header_buttons: false });
  }
  if (multi_select_tasks.length > 0 && !this.state.header_buttons) {
    this.setState({ header_buttons: true });
  }
}

export function selectAll() {
  this.setState({ ...this.state, selectAll: !this.state.selectAll });
  this.setState({ ...this.state, selectNone: false });
  this.setState({
    ...this.state,
    tasks: this.state.tasks.map((task) => this.taskMultiSelect(task, true)),
  });
  this.setState({ showSelectAll: false });
  this.multiSelectWatcher();
}

export function selectNone() {
  this.setState({ ...this.state, selectNone: !this.state.selectNone });
  this.setState({ ...this.state, selectAll: false });
  this.setState({
    ...this.state,
    tasks: this.state.tasks.map((task) => this.taskMultiSelect(task, false)),
  });
  this.setState({ showSelectAll: false });
  this.multiSelectWatcher();
}

export function taskMultiSelect(task, bool) {
  task.type === "Appointment" ? (task.multi_select = false) : (task.multi_select = bool);
  return task;
}
export function renderReassignModal() {
  return (
    <ReassignModal
      showReassignModal={this.state.showReassignModal}
      selectedTasks={this.selectedTasks()}
      assignedToUserId={this.state.assigned_to_user_id}
      form={this.state}
      dropdownUpdater={this.handleState}
      agentsOptions={this.setAgentsForModal()}
      closeReassignModalHandler={this.closeReassignModal}
      reassignTasksHandler={this.reassignTasks}
    />
  );
}

export function showReassignModal() {
  this.setState({ ...this.state, showReassignModal: true });
}
export function closeReassignModal() {
  this.selectNone();
  this.setState({ ...this.state, showReassignModal: false });
  this.multiSelectWatcher();
}

export function showRescheduleModal() {
  this.setState({ ...this.state, showRescheduleModal: true });
}
export function closeRescheduleModal() {
  this.selectNone();
  this.setState({ ...this.state, showRescheduleModal: false });
  this.multiSelectWatcher();
}

export function selectedTasks() {
  var selected_tasks = undefined;
  selected_tasks = this.state.tasks.filter(
    (task) => task.multi_select == true && task.type !== "Appointment",
  );
  return selected_tasks;
}
export function setAgentsForModal() {
  var agents = [];
  for (var obj in this.props.agents) {
    var agent = this.props.agents[`${obj}`];
    agents.push({ label: agent["full_name"], value: agent["uuid"] });
  }
  return agents;
}
export function renderDeleteModal() {
  return (
    <DeleteModal
      showDeleteModal={this.state.showDeleteModal}
      closeDeleteModal={this.closeDeleteModal}
      deleteTasksHandler={this.deleteTasks}
    />
  );
}

export function renderRescheduleModal() {
  return (
    <RescheduleModal
      selectedTasks={this.selectedTasks()}
      showRescheduleModal={this.state.showRescheduleModal}
      closeRescheduleModalHandler={this.closeRescheduleModal}
      rescheduleDate={this.state.reschedule_date}
      rescheduleDateHandler={this.handleRescheduleDate}
      rescheduleTasksHandler={this.rescheduleTasks}
    />
  );
}

export function handleState(e) {
  const { state } = this;
  const key = e.target.name;
  const value = e.target.type == "checkbox" ? e.target.checked : e.target.value;

  state[key] = value;
  this.setState({ ...this.state, ...state });
}

export function handleRescheduleDate(e) {
  this.setState({ reschedule_date: moment(e).format("YYYY-MM-DD") });
}

export function deleteTasks() {
  this.props.toggleTaskLoader(true);
  this.selectedTasks().map((task) => this.deleteTask(task, true));
  this.setState({ ...this.state, selectAll: false, showDeleteModal: false });
}

export function deleteTask(task, bulkOperation = false) {
  let task_id_to_delete = task.id || this.state.task.id;

  axios({
    method: "delete",
    url: `/tasks/${task_id_to_delete}`,
    headers: {
      "X-CSRF-Token": ReactOnRails.authenticityToken(),
    },
  }).then((response) => {
    let event = {
      target: {
        name: "state",
        value: "archived",
      },
    };
    //rerender the parent comp with resposne. Cool!
    this.getTasks();
    let taskChangeEvent = new Event("task:count_change");
    window.dispatchEvent(taskChangeEvent);
  });
}
export function completeTasks() {
  this.props.toggleTaskLoader(true);
  this.selectedTasks().map((task) => this.completeTask(task, true));
  this.getTasks();
  this.setState({ ...this.state, selectAll: false });
}

export function uncompleteTasks() {
  this.props.toggleTaskLoader(true);
  this.selectedTasks().map((task) => this.uncompleteTask(task, true));
  this.getTasks();
  this.setState({ ...this.state, selectAll: false });
}

export function reassignTasks() {
  this.props.toggleTaskLoader(true);
  this.selectedTasks().map((task) => this.reassignTask(task, this.state.assigned_to_user_id, true));
  this.getTasks();
  this.setState({ ...this.state, selectNone: false }, () => this.closeReassignModal());
}

const clientFinder = (agents, clientUuid) => {
  const agent = agents.find((agent) => agent.value === clientUuid);
  return agent || "";
};

const returnAppointmentType = (appointmentType) => {
  const simpleAppointments = [
    "General",
    "Follow Up",
    "Client Meeting",
    "Buyer/Listing Consultation",
    "Recruiting Appointment",
  ];
  return simpleAppointments.includes(appointmentType) ? "Appointment" : appointmentType;
};

const emailConstructor = (contactEmail, taskableEmail) => {
  if (contactEmail != null) {
    return contactEmail;
  }
  if (taskableEmail != null) {
    return taskableEmail;
  }
};

export function handleGoogleCalendar(task, agents, user, contactEmail, addTo) {
  const taskDetails = task.name;
  const location = task.location;
  const scheduledTime = task.scheduled_time;
  const clientUuid = task.assigned_to_user_id;
  const agentEmail = user ? user.email : "";
  const appointmentType = task.appointment_type;
  const taskableEmail = task.taskable_email;
  const taskableName = task.taskable_name.length ? task.taskable_name : "";
  const scheduled_date_time_start = moment.unix(scheduledTime).format("YYYYMMDDTHHmmSSZ");
  const scheduled_date_time_end = moment.unix(scheduledTime).add(0.5, "hour").format("YYYYMMDDTHHmmSSZ");
  const scheduled_date_time_english_format_start = moment.unix(scheduledTime).format("llll");
  const current_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const client = clientFinder(agents, clientUuid);
  const title = `${returnAppointmentType(appointmentType)}: ${taskableName} And ${client.label}`;
  const contact_email = emailConstructor(contactEmail, taskableEmail);
  const emails =
    contact_email === undefined
      ? `${client.email},${agentEmail}`
      : `${client.email},${agentEmail},${contact_email}`;
  if (addTo) {
    window.open(
      `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&location=${
        location || "Location"
      }&details=Details:+${taskDetails}%0A%0ADate+and+Time:+${scheduled_date_time_english_format_start}%0A%0AGuests:%0A+${
        client.label
      }: ${client.email}%0A+${taskableName}${
        `: ${contactEmail}` || ""
      }+&add&dates=${scheduled_date_time_start}/${scheduled_date_time_end}&add=${emails}&ctz=${current_tz}`,
      "_blank",
    );
  }
}

export const tryCatchHandlr = async (promise) => {
  try {
    const data = await promise;
    return [data, null];
  } catch (err) {
    return [null, err];
  }
};

export async function createTask(task, agents, user, contactEmail, addTo, listingUUID) {
  const isNewApi = this.props.v4TaskApi;
  const taskUpdateEvent = new Event("task:update");
  const taskChangeEvent = new Event("task:count_change");
  const taskListener = new Event("task:polymoprhic_count_change");
  const task_path = isNewApi ? "/api/v4/transactions/tasks" : "/tasks";
  const formData = { ...task };
  const baseData = {
    task: formData,
    authenticity_token: ReactOnRails.authenticityToken(),
  };
  const data = isNewApi ? { ...baseData, uuid: listingUUID, person_uuid: task["taskable_id"] } : baseData;

  this.setState({
    ...this.state,
    disabledButton: true,
    saveButtonText: "Saving...",
  });
  const req = axios.post(task_path, data);
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    this.props.showErrors(parseErrors(err));
    this.setState({
      ...this.state,
      disabledButton: false,
      saveButtonText: "Save",
    });
  } else {
    this.handleGoogleCalendar(task, agents, user, contactEmail, addTo);
    window.dispatchEvent(taskUpdateEvent);
    window.dispatchEvent(taskChangeEvent);
    window.dispatchEvent(taskListener);
    this.setState({
      ...this.state,
      disabledButton: false,
      saveButtonText: "Save",
    });
    this.closeModal();
    location.reload();
  }
}

export function updateTask(task, bulkOperation = false) {
  let taskUpdateEvent = new Event("task:update");
  let taskChangeEvent = new Event("task:count_change");
  let taskListener = new Event("task:polymoprhic_count_change");
  var task_path = `/tasks/${task.id}`;
  let formData = { ...task };
  this.setState({
    ...this.state,
    disabledButton: true,
    saveButtonText: "Saving...",
  });
  return axios
    .patch(task_path, {
      task: formData,
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((response) => {
      if (!bulkOperation) {
        window.dispatchEvent(taskUpdateEvent);
      }
      window.dispatchEvent(taskChangeEvent);
      window.dispatchEvent(taskListener);
      // this.props.taskChange(response)
      this.setState({
        ...this.state,
        disabledButton: false,
        saveButtonText: "Save",
      });
      this.closeModal();
    })
    .catch((err) => {
      this.props.showErrors(parseErrors(err));
      this.setState({
        ...this.state,
        disabledButton: false,
        saveButtonText: "Save",
      });
      throw err;
    });
}

export function reassignTask(task, assigned_to_user_id, bulkOperation = false) {
  task.assigned_to_user_id = assigned_to_user_id;
  if (task.assigned_to_user_id == "anyone") {
    task.assigned_to_user_id = null;
  }
  this.updateTask(task, bulkOperation);
}

export function rescheduleTasks(task, reschedule_date) {
  this.props.toggleTaskLoader(true);
  this.selectedTasks().map((task) => this.rescheduleTask(task, true));
  this.getTasks();
  this.setState({ ...this.state, selectNone: false }, () => this.closeRescheduleModal());
}

export function rescheduleTask(task, bulkOperation = false) {
  task.due_on = this.state.reschedule_date;
  this.updateTask(task, bulkOperation);
}

export function completeTask(task, bulkOperation = null) {
  task.type == "Appointment" ? (bulkOperation = true) : (bulkOperation = false);
  const formData = Object.assign({}, task);
  this.setState({ task: { ...this.state.task, state: "completed" } });
  axios
    .patch(`/tasks/${task.id}/complete`, {
      task: formData,
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((response) => {
      let taskChangeEvent = new Event("task:count_change");
      let polymorphicChange = new Event("task:polymorphic_count_change");
      window.dispatchEvent(polymorphicChange);
      window.dispatchEvent(taskChangeEvent);
      if (!bulkOperation) {
        let taskUpdateEvent = new Event("task:update");
        window.dispatchEvent(taskUpdateEvent);
      }
    });
}

export function uncompleteTask(task, bulkOperation = false) {
  const formData = Object.assign({}, this.state.task);
  this.setState({ task: { ...this.state.task, state: "running" } });
  axios
    .patch(`/tasks/${task.id}/uncomplete`, {
      task: formData,
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((response) => {
      let taskChangeEvent = new Event("task:count_change");
      let polymorphicChange = new Event("task:polymorphic_count_change");
      window.dispatchEvent(polymorphicChange);
      window.dispatchEvent(taskChangeEvent);
      if (!bulkOperation) {
        let taskUpdateEvent = new Event("task:update");
        window.dispatchEvent(taskUpdateEvent);
      }
    });
}

export function replaceURLWithHTMLLinks(text) {
  let replacedText, replacePattern1, replacePattern2, strippedText;
  strippedText = stripHtml(text);
  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = strippedText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
  return replacedText;
}

export function nlToBr(text) {
  return text.replace(/\r\n|\n\r|\r|\n/g, "<br />");
}

export const parseErrors = (err) => {
  const genericError = "Uh-oh! Something went wrong.";
  if (!/text|json/.test(err.response?.headers["content-type"])) {
    return [genericError];
  }
  const parsedError = String(err.response?.data || "").split(" - ")[1];
  if (parsedError?.startsWith("[")) {
    return JSON.parse(parsedError);
  } else {
    return [parsedError || err.message || genericError];
  }
};
