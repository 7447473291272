import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import CollapsibleElement from "../CollapsibleElement";
import Button from "@shared/v2/Button";
import { PlusSolidV6 } from "@shared/v2/Icomoon";
import { useLastReferredDateTime, useLenderModalOpen } from "../../../reducers/personalDetailReducer/personDetailReducer";
import { toggleLenderModal } from "../../actions/personDetailActionCreators";

export const timeStampFormat = (dateTime) => moment(dateTime).format('MM/DD/YY [at] hh:mma')

const Mortgage = () => {
  const lenderModalOpen = useLenderModalOpen();
  const lastRefferedDateTime = useLastReferredDateTime();
  const dispatch = useDispatch();

  return (
    <CollapsibleElement
      title="Mortgage"
    >
      <Button
        schema={lenderModalOpen ? "primary" : 'secondary'}
        onClick={() => dispatch(toggleLenderModal())}
        className="tw-mt-[4px] tw-w-full tw-h-[44px] !tw-rounded-[8px] tw-flex !tw-gap-[4px] tw-items-center tw-justify-center tw-text-14d !tw-normal-case"
      >
        <PlusSolidV6 />
        <span>Envoy Referral</span>
      </Button>
      {/* TODO */}
      {lastRefferedDateTime &&
        <div className="tw-text-12d tw-flex tw-justify-between tw-mt-[16px]">
          <span className='tw-text-gray-50'>Last Referral:</span>
          <span className="tw-text-gray-75">{timeStampFormat(lastRefferedDateTime)}</span>
        </div>
      }

    </CollapsibleElement >
  )
};

export default Mortgage;