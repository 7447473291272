import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import Agreementbtn from "./Agreementbtn";
import { handleDocumentUpload, handleAgreementCreate } from "../../actions/personDetailActionCreators";
import UploadAgreement from "./UploadAgreement";
import { useSelector } from "react-redux";

const ACTIVE_CLASS =
  "!tw-border-gray-15 !tw-text-gray-50 hover:!tw-border-gray-15 hover:!tw-text-gray-50 active:!tw-border-gray-15 active:!tw-text-gray-50";

const CONTAINER_CLASS =
  "tw-text-[14px] tw-leading-[20px] tw-font-semibold !tw-h-[52px] !tw-rounded-6px !tw-capitalize";

const AddAgreementModal = ({ agreementName, modalOpen, closeModal, person }) => {
  const dispatch = useDispatch();
  const uploadingDocument = useSelector((state) => state.personDetail.uploadingDocument);

  const [selectedAgreementName, setSelectedAgreementName] = useState(agreementName);

  const [uploadedFile, setUploadedFile] = useState(null);

  const onClickAgreement = (agreementName) => {
    setSelectedAgreementName(agreementName);
  };

  const handleHide = () => {
    closeModal();
    setUploadedFile(null);
  };
  const handleAddAgreement = () => {
    if (!uploadedFile) {
      const agreementData = {
        agreement_type: selectedAgreementName,
        document_id: null,
      };
      dispatch(handleAgreementCreate(person.data.attributes.uuid, agreementData));
      handleHide();
    } else {
      dispatch(handleDocumentUpload(uploadedFile, person.data.attributes, true, selectedAgreementName));
      handleHide();
    }
  };

  const documentUpload = (event) => {
    if (event.target.files[0]) {
      setUploadedFile(event.target.files[0]);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files[0]) {
      setUploadedFile(event.dataTransfer.files[0]);
    }
  };

  const getClassName = (btnName) => {
    if (btnName !== selectedAgreementName) {
      return `${CONTAINER_CLASS} ${ACTIVE_CLASS}`;
    }
    return CONTAINER_CLASS;
  };
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      // 636px = 616px from Figma + 10px for each horizontal padding (20px)
      dialogClassName="tw-w-[636px] tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      backdrop="static"
      show={modalOpen}
      onHide={handleHide}
      data-cy="add-agreement-modal"
    >
      <Modal.Header titleClassName="tw-mb-32px tw-text-neutral-gray-75" title="Add Agreement" closeButton />

      <Modal.Body className="tw-mb-32px">
        <div className="tw-flex tw-grid tw-grid-cols-3 tw-gap-[12px]">
          <Agreementbtn
            agreementName="buyer"
            containerClass={getClassName("buyer")}
            onClickFunc={onClickAgreement}
          />
          <Agreementbtn
            agreementName="seller"
            containerClass={getClassName("seller")}
            onClickFunc={onClickAgreement}
          />
          <Agreementbtn
            agreementName="referral"
            containerClass={getClassName("referral")}
            onClickFunc={onClickAgreement}
          />
        </div>
        <UploadAgreement
          handleFileDrop={handleFileDrop}
          handleDocumentUpload={documentUpload}
          uploadedFile={uploadedFile}
        />
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button
          onClick={handleHide}
          className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
          schema="tertiary"
          size="medium"
          data-cy="documents-upload-cancel-button"
        >
          CANCEL
        </Button>
        <Button
          className="tw-flex tw-items-center"
          size="medium"
          schema="primary"
          disabled={uploadingDocument}
          onClick={handleAddAgreement}
          data-cy="documents-upload-done-button"
        >
          ADD
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddAgreementModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  agreementName: Proptypes.string,
  person: Proptypes.shape({}),
};
AddAgreementModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  agreementName: "buyer",
  person: {},
};
export default AddAgreementModal;
