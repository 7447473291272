import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import Button from "../v2/Button/Button";

const ErrorAlert = ({ errorMessages, onClose }) => (
  <Alert bsStyle="danger" onDismiss={onClose} data-cy="alert_error_messages">
    <div className="tw-flex tw-flex-col tw-gap-[4px]">
      {errorMessages?.map((msg) =>
        typeof msg === "object" ? (
          <div className="tw-flex tw-justify-between tw-items-center">
            <span>
              Email is already in use for an{" "}
              <a className="tw-text-inherit tw-underline" href={`/people/${msg.existing_person_slug}`}>
                existing contact
              </a>
            </span>
            <Button
              schema="warning"
              onClick={() => window.location.assign(`/people/${msg.existing_person_slug}`)}
            >
              View Contact
            </Button>
          </div>
        ) : (
          <div key={msg} data-cy={`error_message_${msg}`} dangerouslySetInnerHTML={{ __html: msg }} />
        ),
      )}
    </div>
  </Alert>
);
ErrorAlert.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
};

ErrorAlert.defaultProps = {
  errorMessages: [],
  onClose: () => {},
};

export default ErrorAlert;
