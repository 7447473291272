import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAgreements, handleAgreementDelete } from "../../actions/personDetailActionCreators";
import CollapsibleElement from "../CollapsibleElement";
import Loading from "../../../Goals/components/Loader";
import Agreementbtn from "./Agreementbtn";
import AddAgreementModal from "./AddAgreementModal";
import AgreementSection from "./AgreementSection";

const Agreements = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const agreements = useSelector((state) => state.personDetail.agreements);

  const agreementsButtonText = agreements.length === 0 ? "" : "+ ADD";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agreementName, setAgreementName] = useState("buyer");

  const onClickAgreement = (agreementName) => {
    setIsModalOpen(true);
    setAgreementName(agreementName);
  };

  const onAddClick = () => {
    onClickAgreement("buyer");
  };
  const agreementCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (person.data?.id) dispatch(fetchAgreements(person.data));
  }, [person.data?.id]);

  return (
    <CollapsibleElement title="Agreements" buttonText={agreementsButtonText} buttonClickFunction={onAddClick}>
      {!person.data && <Loading />}

      <div id="person-agreements-card" className="tw-flex tw-flex-col tw-gap-[12px]">
        {agreements.length === 0 && (
          <>
            <Agreementbtn isEmptyState agreementName="buyer" onClickFunc={onClickAgreement} />
            <Agreementbtn isEmptyState agreementName="seller" onClickFunc={onClickAgreement} />
            <Agreementbtn isEmptyState agreementName="referral" onClickFunc={onClickAgreement} />
          </>
        )}
        {agreements.length > 0 &&
          agreements.map((agreement, index) => (
            <AgreementSection
              key={agreement.id}
              agreement={agreement}
              index={index}
              onDelete={(...args) => dispatch(handleAgreementDelete(...args))}
              person={person}
            />
          ))}
        {isModalOpen && (
          <AddAgreementModal
            person={person}
            agreementName={agreementName}
            modalOpen={isModalOpen}
            closeModal={agreementCloseModal}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default Agreements;
