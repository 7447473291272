import { useEffect, useState } from "react";
import api from "@shared/phoneDncStatusApi";

const useDncStatuses = ({ dialablePhoneNumbers }) => {
  const [dncStatuses, setDncStatuses] = useState({});

  useEffect(() => {
    if (dialablePhoneNumbers.length === 0) return;
    const abortController = new AbortController();
    api
      .index([dialablePhoneNumbers.map((x) => x.value)], abortController.signal)
      .then((x) => setDncStatuses({ ...x.data }))
      .catch(() => console.log("dnc lookup failed"));
    return () => {
      abortController.abort();
    };
  }, [dialablePhoneNumbers]);

  return { dncStatuses, checkDnc: (phoneNumber) => api.isDnc(phoneNumber.value, dncStatuses) };
};

export default useDncStatuses;
