import React from "react";
import Proptypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { useOnMinMaxSelectedChange, useMinMaxState } from "../../hookHelpers";
import { hasMinMaxError } from "../../helpers";
import { useDebounce } from "../../../shared/hookHelpers";

const baseOptions = [
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2016",
    value: "2016",
  },
  {
    label: "2015",
    value: "2015",
  },
  {
    label: "2014",
    value: "2014",
  },
  {
    label: "2013",
    value: "2013",
  },
  {
    label: "2012",
    value: "2012",
  },
  {
    label: "2011",
    value: "2011",
  },
  {
    label: "2010",
    value: "2010",
  },
  {
    label: "2009",
    value: "2009",
  },
  {
    label: "2008",
    value: "2008",
  },
  {
    label: "2007",
    value: "2007",
  },
  {
    label: "2006",
    value: "2006",
  },
  {
    label: "2005",
    value: "2005",
  },
  {
    label: "2000",
    value: "2000",
  },
  {
    label: "1990",
    value: "1990",
  },
  {
    label: "1980",
    value: "1980",
  },
  {
    label: "1970",
    value: "1970",
  },
  {
    label: "1960",
    value: "1960",
  },
  {
    label: "1950",
    value: "1950",
  },
  {
    label: "1940",
    value: "1940",
  },
  {
    label: "1930",
    value: "1930",
  },
  {
    label: "1920",
    value: "1920",
  },
  {
    label: "1910",
    value: "1910",
  },
  {
    label: "1900",
    value: "1900",
  },
  {
    label: "1800",
    value: "1800",
  },
];
const minOptions = [{ label: "No min", value: null }, ...baseOptions];
const maxOptions = [{ label: "No max", value: null }, ...baseOptions];

const YearBuilt = ({ min, max, onChange }) => {
  const [selectedMin, onSelectMin, selectedMax, onSelectMax] = useMinMaxState(min, max);
  const hasError = hasMinMaxError(selectedMin, selectedMax);
  const debouncedMin = useDebounce(selectedMin, 500);
  const debouncedMax = useDebounce(selectedMax, 500);
  useOnMinMaxSelectedChange(debouncedMin, debouncedMax, hasError, onChange);

  return (
    <div data-cy="year-built-wrapper" className="tw-mb-[4px]">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Year Built</span>
      <div className="tw-flex tw-gap-[24px]">
        <Dropdown
          containerClassName="tw-flex-1"
          name="min_year"
          value={minOptions.find((o) => o.value === selectedMin)}
          options={minOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="No Min"
          onChange={(opt) => onSelectMin(opt.value)}
        />
        <Dropdown
          containerClassName="tw-flex-1"
          error={hasError ? "Max must be greater than min" : ""}
          name="max_year"
          value={maxOptions.find((o) => o.value === selectedMax)}
          options={maxOptions}
          isSearchable={false}
          isClearable={false}
          placeholder="No Max"
          onChange={(opt) => onSelectMax(opt.value)}
        />
      </div>
    </div>
  );
};
export default YearBuilt;

YearBuilt.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  onChange: Proptypes.func,
};

YearBuilt.defaultProps = {
  min: undefined,
  max: undefined,
  onChange: () => {},
};
