import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "../../shared/v2/Tooltip/Tooltip";

const HoverButton = ({ children, isSelected, tooltip, ...props }) => {
  const theme = useSelector(({ layout }) => layout.theme);
  const selectedBgColor = theme.name === "brivity-theme" ? "tw-bg-theme-primary" : "tw-bg-white";
  const selectedTextColor = theme.name === "brivity-theme" ? "tw-text-theme-primary" : "tw-text-white";

  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <Tooltip
      innerClassName="!tw-bg-neutral-gray-75"
      arrowClassName="tw-fill-neutral-gray-75"
      trigger={
        <button
          {...props}
          type="button"
          className={`tw-bg-transparent tw-relative tw-leading-0 tw-flex tw-justify-center tw-w-[48px] tw-pl-[12px] tw-pr-0 tw-py-[12px] tw-border-none focus:tw-outline-none ${
            theme.name === "place-theme" ? "tw-text-white" : ""
          } ${isSelected ? selectedTextColor : ""}`}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onMouseDown={() => setIsActive(true)}
          onMouseUp={() => setIsActive(false)}
          onMouseLeave={() => setIsActive(false)}
        >
          <div
            className={`${isHovering || isFocused ? "tw-bg-gray-50" : ""} ${isSelected ? selectedBgColor : ""}
        ${
          isActive ? "tw-my-0" : `${isFocused ? "tw-my-[2px]" : "tw-my-[4px]"}`
        } tw-transition-all tw-rounded-r-[4px] tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-w-5px`}
          />
          {children}
        </button>
      }
      content={tooltip}
      placement="right"
    />
  );
};

HoverButton.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default HoverButton;
