import { useEffect, useState } from "react";
import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useOpenHouseDashboardOverview = ({ dateRange, selectedHosts }) => {
  const [data, setData] = useState({ completed: 0, attendees: 0, leads: 0 });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedHosts.length === 0) return () => {};
    const abortController = new AbortController();
    const hostPersonUuids = selectedHosts.map((host) => host.value);

    setLoading(true);
    setError(false);
    caseTransformingAxios
      .post(
        "/api/v4/reporting/open_house_dashboard/stats",
        {
          ...dateRange,
          hostPersonUuids,
        },
        { signal: abortController.signal },
      )
      .then(({ data: { data: overviewData } }) => {
        setLoading(false);
        setData({
          ...overviewData,
          avgLeads: (overviewData.leads / (overviewData.completed || 1)).toFixed(2),
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setLoading(false);
          setError(true);
        }
      });

    return () => abortController.abort();
  }, [dateRange, selectedHosts]);

  return { data, error, loading };
};

export default useOpenHouseDashboardOverview;
