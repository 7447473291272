import React, { Component } from "react";

class ModalHeader extends Component {
  render() {
    return (
      <span style={{ color: "#59C4C4" }}>
        <div
          style={{
            fontSize: "2.25em",
            fontWeight: "500",
            padding: "10px 0",
          }}
        >
          Filter
          <span
            style={{
              fontSize: "12px",
              padding: "20px 0",
              fontWeight: "300",
              textTransform: "capitalize",
            }}
            className="btn  pull-right"
            onClick={(e) => this.props.handleClear(e)}
          >
            Clear All
          </span>
        </div>
      </span>
    );
  }
}

export default ModalHeader;
