import React from "react";

const HelperText = ({viewPhone, isDnc}) => {
    if (!viewPhone) { return "" }
  
    const actionable = !viewPhone.smsUnsubscribed;
    const textable = viewPhone.smsEnabled;
    const smsHelperText = viewPhone?.smsIcon?.tooltip;
  
    const dncHelperText = (
      <p className="tw-m-0 tw-text-12d tw-text-neutral-gray-50 tw-space-x-1">
        <span className="tw-italic">The number you are contacting might be on the Do Not Call registry.</span>
        <a
          className="tw-font-semibold tw-text-neutral-gray-50 visited:tw-text-neutral-gray-50 active:tw-text-neutral-gray-50 hover:tw-text-neutral-gray-50"
          href="https://www.brivityknowledge.com/v1/docs/do-not-call-registry-warning"
          target="_blank"
          rel="noreferrer"
        >
          Click here to learn more
        </a>
      </p>
    );
  
    return (isDnc && dncHelperText) || (!textable && actionable && smsHelperText);
}

export default HelperText;
