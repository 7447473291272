import React from "react";
import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";

const ExcludeTagFilter = ({ tags, excludedTags }) => (
  <DropdownWithMultiCtrlledRemovable
    options={tags.map(({ id: value, name: label }) => ({ label, value: `${value}` }))}
    selectedOptions={excludedTags?.map((excludedId) => {
      const { id, name } = tags.find(({ id }) => id === excludedId);

      return { label: name, value: `${id}` };
    })}
    removablePrefix={`Tag (Exclude): `}
    placeHolderTxt=" excluded tags selected"
    ctrlName="leads_filter[selected_exclude_tag_filters][]"
  />
);

export default ExcludeTagFilter;
