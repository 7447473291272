import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { requestDataToSnakeCase } from "@shared/v2/caseTransformingAxios";
import * as actions from "../actions/default";
import * as apiActions from "../api/index";
import EmailModal from "./EmailModal";
import { touchHomeAppInviteSent } from "../api/index";
import EmailButton from "./EmailButton";

const mapStateToProps = (state) => ({
  gmailUnsynced: state.leadIndex.gmailUnsynced,
  isEmailSent: state.leadIndex.isEmailSent,
  sendEmailError: state.leadIndex.sendEmailError,
});

const EmailInteraction = ({
  gmailUnsynced,
  personDetails,
  emailTemplates,
  currentUserId,
  person,
  emailDetails,
  unsubscribedMessageTypes,
  isEmailSent,
  sendEmailError,
  submitEmailError,
  submitSendEmail,
  clearEmailState,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const formattedPerson = useMemo(
    () => ({
      ...personDetails,
      included: emailDetails.map((v) => ({
        attributes: {
          ...v,
          unsubscribed_message_types: requestDataToSnakeCase(unsubscribedMessageTypes),
        },
        id: v.id,
        type: "email_detail",
      })),
    }),
    [personDetails],
  );

  const closeModal = () => {
    setModalOpen(false);
    clearEmailState(false);
  };

  const handleSubmitEmailError = (err) => {
    submitEmailError(err.response.data);
  };
  const openModal = () => setModalOpen(true);

  const submitEmail = (sentEmailData, appInviteNeedsUpdating = null, homeAppRecipientId = null) => {
    submitSendEmail({ sentEmailData });
    if (appInviteNeedsUpdating) {
      touchHomeAppInviteSent(homeAppRecipientId);
    }
    setTimeout(() => {
      closeModal();
    }, 5000);
  };

  return (
    <div>
      <div className="interaction-icon">
        <EmailButton
          emailIconDetails={person.email_icon_details}
          emailDetails={emailDetails}
          openModal={openModal}
          primaryEmailAddress={person.primary_email_address}
        />
      </div>
      {modalOpen && (
        <EmailModal
          gmailUnsynced={gmailUnsynced}
          sendEmailError={sendEmailError}
          sendEmailUrl={person.send_email_url}
          isEmailSent={isEmailSent}
          person={formattedPerson}
          currentUserId={currentUserId}
          emailTemplates={emailTemplates}
          modalOpen={modalOpen}
          closeModal={closeModal}
          submitEmail={submitEmail}
          handleSubmitEmailError={handleSubmitEmailError}
        />
      )}
    </div>
  );
};

EmailInteraction.propTypes = {
  gmailUnsynced: Proptypes.bool,
  personDetails: Proptypes.shape({
    attributes: Proptypes.shape({ first_name: Proptypes.string }),
    included: Proptypes.arrayOf(
      Proptypes.shape({
        id: Proptypes.number,
        type: Proptypes.string,
      }),
    ),
  }),
  emailTemplates: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
    }),
  ),
  currentUserId: Proptypes.number,
  person: Proptypes.shape({
    email_icon_details: Proptypes.string,
    primary_email_address: Proptypes.string,
    send_email_url: Proptypes.string,
  }),
  emailDetails: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.number,
    }),
  ),
  unsubscribedMessageTypes: Proptypes.arrayOf(Proptypes.string),
  isEmailSent: Proptypes.bool,
  sendEmailError: Proptypes.shape({ content: Proptypes.string, type: Proptypes.string }),
  submitEmailError: Proptypes.func,
  submitSendEmail: Proptypes.func,
  clearEmailState: Proptypes.func,
};

EmailInteraction.defaultProps = {
  gmailUnsynced: false,
  personDetails: null,
  emailTemplates: [],
  currentUserId: null,
  person: null,
  emailDetails: [],
  unsubscribedMessageTypes: [],
  isEmailSent: false,
  sendEmailError: null,
  submitEmailError: null,
  submitSendEmail: null,
  clearEmailState: null,
};

export default connect(mapStateToProps, { ...actions, ...apiActions })(EmailInteraction);
