import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TextButton from "../../shared/v2/TextButton";
import Tooltip from "../../shared/v2/Tooltip";
import InfoCircleSolidV3 from "../../shared/v2/Icomoon/Icons/InfoCircleSolidV3";
import { PencilSolidV6, CaretDownSolidV6 } from "../../shared/v2/Icomoon";

const DisplayRow = ({
  title,
  tooltip,
  editable,
  alwaysEdit,
  value,
  onChange,
  onSave,
  Comp,
  displayValue,
  isDD,
}) => {
  const firstUpdate = useRef(true);
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isEditing) {
      return;
    }
    onSave(value);
  }, [value, isEditing]);

  const renderValue = value && !alwaysEdit && !isEditing;
  const renderNewBtn = (!value || value <= "") && editable && !alwaysEdit && !isEditing;
  const renderEmptyValue = (!value || value <= "") && !editable && !alwaysEdit;
  const renderEditComp = alwaysEdit || isEditing;
  return (
    <div className="tw-flex tw-items-center tw-mb-[12px] tw-min-h-[36px]">
      <h3 className="tw-text-[14px] tw-font-semibold tw-text-neutral-gray-50 tw-my-0 tw-w-[294px] tw-shrink-0 tw-flex tw-align-center">
        {title}{" "}
        {tooltip && (
          <Tooltip
            className="tw-ml-[4px] tw-h-[12px]"
            multiline
            placement="right"
            content={<div className="tw-w-[264px]">{tooltip}</div>}
            trigger={<InfoCircleSolidV3 className="tw-h-[12px] tw-w-[12px] tw-text-black" />}
          />
        )}
      </h3>
      {renderValue && (
        <button
          type="button"
          onClick={editable ? () => setEditing(true) : null}
          className={`tw-flex tw-items-center tw-overflow-hidden tw-whitespace-nowrap tw-group tw-w-[264px] tw-font-normal tw-p-0 tw-bg-transparent tw-border-0 tw-text-left active:tw-text-[var(--text-button-color)] ${
            !editable ? "tw-cursor-default" : ""
          }`}
        >
          <span className="tw-ellipsis tw-overflow-hidden">{displayValue ? displayValue(value) : value}</span>
          {editable ? (
            <span className="group-hover:tw-inline tw-hidden tw-ml-2">
              {isDD ? (
                <CaretDownSolidV6 size="n" className="tw-w-10px tw-h-10px" />
              ) : (
                <PencilSolidV6 size="m" />
              )}
            </span>
          ) : null}
        </button>
      )}
      {renderNewBtn && (
        <TextButton onClick={() => setEditing(true)} className="tw-px-0">
          Add
        </TextButton>
      )}
      {renderEmptyValue && "—"}
      {renderEditComp && (
        <Comp
          value={value}
          onChange={(val) => onChange(val)}
          onBlur={() => setEditing(false)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.target?.blur();
              setEditing(false);
            }
          }}
        />
      )}
    </div>
  );
};

DisplayRow.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  alwaysEdit: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  Comp: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  displayValue: PropTypes.func,
  isDD: PropTypes.bool,
};

DisplayRow.defaultProps = {
  tooltip: null,
  value: null,
  Comp: null,
  alwaysEdit: false,
  displayValue: null,
  isDD: false,
};
export default DisplayRow;
