import React from "react";
import { useDispatch } from "react-redux";
import TimelineEvent from "./events/TimelineEvent";
import TimelineLeadCreateEvent from "./events/TimelineLeadCreateEvent";
import TimelineLeadCreateUserNotificationEvent from "./events/TimelineLeadCreateUserNotificationEvent";
import TimelineLeadUpdateEvent from "./events/TimelineLeadUpdateEvent";
import TimelineFailedLeadCreateEvent from "./events/TimelineFailedLeadCreateEvent";
import TimelineLeadTransferEvent from "./events/TimelineLeadTransferEvent";
import TimelineSubmittedFormEvent from "./events/TimelineSubmittedFormEvent";
import TimelineQualificationFormEvent from "./events/TimelineQualificationFormEvent";
import TimelineContactFormEvent from "./events/TimelineContactFormEvent";
import TimelineTaggingEvent from "./events/TimelineTaggingEvent";
import TimelineTrackingNumberEvent from "./events/TimelineTrackingNumberEvent";
import PropertyFavoriteEvent from "./events/PropertyFavoriteEvent";
import PropertyFavoriteRemovedEvent from "./events/PropertyFavoriteRemovedEvent";
import PublicActivityEvent from "./events/PublicActivityEvent";
import PublicActivityMessageEvent from "./events/PublicActivityMessageEvent";
import PublicActivitySmsEvent from "./events/PublicActivitySmsEvent";
import PublicActivityNoteEvent from "./events/PublicActivityNoteEvent";
import PublicActivityAppointmentEvent from "./events/PublicActivityAppointmentEvent";
import PublicActivityCallEvent from "./events/PublicActivityCallEvent";
import PublicActivityLoginEvent from "./events/PublicActivityLoginEvent";
import PublicActivityOtherEvent from "./events/PublicActivityOtherEvent";
import GmailInteraction from "./events/GmailInteraction";
import TaskEvent from "./events/TaskEvent";
import AssignedPlanEvent from "./events/AssignedPlanEvent";
import MarketReportSentEvent from "./events/MarketReportSentEvent";
import MarketReportViewedEvent from "./events/MarketReportViewedEvent";
import MarketReportFrequencyUpdatedEvent from "./events/MarketReportFrequencyUpdatedEvent";
import TimelineDocumentEvent from "./events/TimelineDocumentEvent";
import ListingAlertFrequencyEvent from "./events/ListingAlertFrequencyEvent";
import FacebookAdRetargetEvent from "./events/FacebookAdRetargetEvent";
import FacebookAdRemarketEvent from "./events/FacebookAdRemarketEvent";
import KwklyPropertyView from "./events/KwklyPropertyView";
import TimelineValidationUpdateEvent from "./events/TimelineValidationUpdateEvent";
import AdsSellEvent from "./events/AdsSellEvent";
import DirectToTimelineEvent from "./events/DirectToTimelineEvent";
import CmaReportCreatedEvent from "./events/CmaReportCreatedEvent";
import TimelineSmsHardUnsubscribeEvent from "./events/phones/TimelineSmsHardUnsubscribeEvent";
import TimelineSmsHardResubscribeEvent from "./events/phones/TimelineSmsHardResubscribeEvent";
import TimelineCallHardUnsubscribeEvent from "./events/phones/TimelineCallHardUnsubscribeEvent";
import TimelineCallHardResubscribeEvent from "./events/phones/TimelineCallHardResubscribeEvent";
import TimelineSmsHardUntextableEvent from "./events/phones/TimelineSmsHardUntextableEvent";
import TimelineSoftStatusChangeEvent from "./events/phones/TimelineSoftStatusChangeEvent";
import * as actions from "../../PersonDetail/actions/personDetailActionCreators";
import TimelineGroupSmsEvent from "./events/TimelineGroupSmsEvent";
import TimelineLeadPondDeleteEvent from "./events/TimelineLeadPondDeleteEvent";
import TimelineAgreementEvent from "./events/TimelineAgreementEvent";
import TimelinePhoneNumbersArchivedConversations from "./events/phones/TimelinePhoneNumbersArchivedConvesrations";
import EnvoyReferralCreated from "./events/EnvoyReferralCreated";
import HomeValueReportEvent from "./events/HomeValueReportEvent";
import AddressStatusChangeEvent from "./events/AddressStatusChangeEvent";
import OpenHouseEvent from "./events/OpenHouseEvent";
import TimelineAgreementDocumentEvent from "./events/TimelineAgreementDocumentEvent";

const returnTimelineServiceEvent = (event) => {
  switch (event.event_type) {
    case "lead_create": // If this matches, it will run the next case
    case "created":
      return <TimelineLeadCreateUserNotificationEvent event={event} />;
    case "person_created":
      return <TimelineLeadCreateEvent event={event} />;
    case "failed_lead_create":
      return <TimelineFailedLeadCreateEvent event={event} />;
    case "transferred":
      return <TimelineLeadTransferEvent event={event} />;
    case "person_updated":
      return <TimelineLeadUpdateEvent event={event} />;
    case "ads-retarget":
      return <FacebookAdRetargetEvent event={event} event_type={"retargeting"} />;
    case "ads-remarket":
      return <FacebookAdRemarketEvent event={event} event_type={"remarketing"} referral={event.referral} />;
    case "ads-sell":
      return <AdsSellEvent event={event} />;
    case "submitted_form":
    case "registered_for_open_house":
    case "scheduled_showing":
    case "forced_registration_search_page_combined":
    case "forced_registration_property_page_social":
    case "forced_registration_property_page_light":
    case "forced_registration_property_page_combined":
    case "forced_registration_property_page_full":
    case "forced_registration_property_page_sold":
    case "contact_form_ask_question":
    case "contact_form_ask_a_question":
    case "contact_form_questions_about":
    case "signup":
    case "get_accurate_estimate":
    case "contact_form_get_in_touch":
    case "contact_form":
      return <TimelineSubmittedFormEvent event={event} />;
    case "contact_form_block":
      return <TimelineContactFormEvent event={event} />;
    case "qualification_form":
      return <TimelineQualificationFormEvent event={event} />;
    case "tagging_created":
    case "tagging_updated":
    case "tagging_destroyed":
      return <TimelineTaggingEvent event={event} />;
    case "assigned_plan_applied":
    case "assigned_plan_paused":
    case "assigned_plan_resumed":
    case "assigned_plan_completed":
    case "assigned_plan_deleted":
      return <AssignedPlanEvent event={event} />;
    case "envoy_referral_created":
      return <EnvoyReferralCreated event={event} />;
    case "market_report_frequency_updated":
      return <MarketReportFrequencyUpdatedEvent event={event} />;
    case "market_report_viewed":
      return <MarketReportViewedEvent event={event} />;
    case "market_report_sent":
      return <MarketReportSentEvent event={event} />;
    case "property_favorite_created":
      return <PropertyFavoriteEvent event={event} />;
    case "property_favorite_removed":
      return <PropertyFavoriteRemovedEvent event={event} />;
    case "document_created":
    case "document_destroyed":
      return <TimelineDocumentEvent event={event} />;
    case "kwkly_property_view":
      return <KwklyPropertyView event={event} />;
    case "validation_updated":
      return <TimelineValidationUpdateEvent event={event} />;
    case "direct_to_timeline":
      return <DirectToTimelineEvent event={event} />;
    case "cma_report_created":
      return <CmaReportCreatedEvent event={event} />;
    case "phone_sms_hard_unsubscribe.create":
      return <TimelineSmsHardUnsubscribeEvent event={event} />;
    case "phone_sms_hard_unsubscribe.destroy":
      return <TimelineSmsHardResubscribeEvent event={event} />;
    case "phone_call_hard_unsubscribe.create":
      return <TimelineCallHardUnsubscribeEvent event={event} />;
    case "phone_call_hard_unsubscribe.destroy":
      return <TimelineCallHardResubscribeEvent event={event} />;
    case "phone_sms_hard_textable.stop":
      return <TimelineSmsHardUntextableEvent event={event} />;
    case "phone_sms_soft_status.updated":
      return <TimelineSoftStatusChangeEvent event={event} />;
    case "phone_call_soft_status.updated":
      return <TimelineSoftStatusChangeEvent event={event} />;
    case "phone_numbers.archived_conversations":
      return <TimelinePhoneNumbersArchivedConversations event={event} />;
    case "group_sms_in":
    case "group_sms_out":
      return <TimelineGroupSmsEvent event={event} />;
    case "agreement_created":
    case "agreement_deleted":
      return <TimelineAgreementEvent event={event} />;
    case "agreement_document_updated":
      return <TimelineAgreementDocumentEvent event={event} />
    case "lead_called_tracking_number":
      return <TimelineTrackingNumberEvent event={event} />;
    case "lead_pond_deleted":
      return <TimelineLeadPondDeleteEvent event={event} />;
    case "home_value":
    case "market_report":
      return <HomeValueReportEvent event={event} />;
    case "address_status_changed":
      return <AddressStatusChangeEvent event={event} />;
    case "registered_on_open_house_app":
      return <OpenHouseEvent event={event} />;
    default:
      return <TimelineEvent event={event} />;
  }
};

const EventComponentSelector = ({ appliedFilters, event, newDetailsPage, webActivityEventTypes }) => {
  const dispatch = useDispatch();
  // TODO: It seems like this is wanted but is not being set (even with the old code)
  const person_id = undefined;

  const setInteractionTab = (tabIndex) => dispatch(actions.setInteractionTab(tabIndex));
  const setGmailReplyThread = (id, subject, additionalRecipientsVal, CCval, BCCval) =>
    dispatch(actions.setGmailReplyThread(id, subject, additionalRecipientsVal, CCval, BCCval));
  const deleteNote = (note) => dispatch(actions.deleteNote(note));
  const updateNote = (note, starred, externallyVisible) =>
    dispatch(actions.updateNote(note, starred, externallyVisible));
  const toggleStarred = (note) => dispatch(actions.toggleStarred(note));
  const submitUpdateInteraction = (interaction) => dispatch(actions.submitUpdateInteraction(interaction));

  if (event.id.startsWith("timeline_")) {
    if (
      appliedFilters.has("action-event") &&
      !["group_sms_in", "group_sms_out"].includes(event.event_type) &&
      !webActivityEventTypes.includes(event.event_type)
    ) {
      return returnTimelineServiceEvent(event);
    } else if (appliedFilters.has(event.event_type)) {
      return returnTimelineServiceEvent(event);
    } else if (
      appliedFilters.has("listing-alert-event") &&
      event.event_type === "modified_listing_alert_frequency"
    ) {
      return <ListingAlertFrequencyEvent event={event} />;
    }
  }

  if (event.id.startsWith("task_")) {
    return <TaskEvent task_event={event} />;
  }

  if (event.hasOwnProperty("gmail_api_version")) {
    return (
      <GmailInteraction
        interaction={event}
        person_id={person_id}
        setInteractionTab={setInteractionTab}
        newDetailsPage={newDetailsPage}
        setGmailReplyThread={setGmailReplyThread}
      />
    );
  }

  if (event.id.startsWith("public_activity_") && appliedFilters.has(`${event.interaction_type}-event`)) {
    switch (event.interaction_type) {
      case "message":
        return (
          <PublicActivityMessageEvent
            event={event}
            person_id={person_id}
            setInteractionTab={setInteractionTab}
            newDetailsPage={newDetailsPage}
            setGmailReplyThread={setGmailReplyThread}
          />
        );
      case "sms":
        return (
          <PublicActivitySmsEvent
            event={event}
            personId={person_id}
            setInteractionTab={setInteractionTab}
            newDetailsPage={newDetailsPage}
          />
        );
      case "note":
        return (
          <PublicActivityNoteEvent
            event={event}
            person_id={person_id}
            toggleStarred={toggleStarred}
            updateNote={updateNote}
            deleteNote={deleteNote}
          />
        );
      case "appointment":
        return <PublicActivityAppointmentEvent event={event} person_id={person_id} />;
      case "call":
        return (
          <PublicActivityCallEvent
            event={event}
            person_id={person_id}
            submitUpdateInteraction={submitUpdateInteraction}
          />
        );
      case "login":
        return <PublicActivityLoginEvent event={event} person_id={person_id} />;
      case "Other":
      case "Pop by":
      case "Mail":
      case "Social media":
        return <PublicActivityOtherEvent event={event} person_id={person_id} />;
      default:
        return <PublicActivityEvent event={event} />;
    }
  }
  return null;
};

export default EventComponentSelector;
