import React from "react";

import Dropdown from "../../../shared/v2/Dropdown";
import IconButton from "../../../shared/v2/IconButton";
import DatePicker from "../../../shared/v2/DatePicker";
import { Section } from "../BusinessDashboard/Section";
import { CurrentYear } from "./helpers";

const Header = ({year, setYear, timeFrame, setTimeFrame, customMinDate, customMaxDate, setCustomMinDate, setCustomMaxDate, setFullscreen}) => {
  const timeFrameOptions = year === CurrentYear
    ? CurrentYearPeriods
    : createOptionsPerYear(year);

  return (
    <Section>
      <div className="tw-flex tw-justify-between">
        <span className="tw-text-28px tw-text-gray-75">Activity Leaderboard</span>
        <div className="tw-flex tw-gap-[16px]">
          <Dropdown
            className="tw-w-[98px] tw-z-50"
            options={YearOptions}
            value={YearOptions.find(({value}) => value === year)}
            onChange={({value}) => {setYear(value)}}
          />
          <Dropdown
            className="tw-w-[159px] tw-z-50"
            options={timeFrameOptions}
            value={timeFrameOptions.find(({value}) => value === timeFrame)}
            onChange={({label, value}) => setTimeFrame(value, label)}
          />
          {timeFrame === "custom" ? (
            <>
              <DatePicker
                value={moment(customMinDate).format("MM/DD/YYYY")}
                onChange={(value) => setCustomMinDate(moment(value, "MM/DD/YYYY").toISOString())}
                inputProps={{ size: 10 }}
              />
              <DatePicker
                value={moment(customMaxDate).format("MM/DD/YYYY")}
                onChange={(value) => setCustomMaxDate(moment(value, "MM/DD/YYYY").toISOString())}
                inputProps={{ size: 10 }}
              />
            </>
          ) : ""}
          <IconButton
            size="medium"
            schema="transparent"
            onClick={setFullscreen}
            title="Fullscreen"
          >
            <i className="fa fa-arrows-alt" />
          </IconButton>
        </div>
      </div>
    </Section>
  );
};

export default Header;

const StartingYear = 2017;

const YearOptions = (() => {
  const range = CurrentYear - StartingYear;
  const options = [];
  for (let i = range; i > -1; i--) {
    const value = (StartingYear + i);
    options.push({ label: value, value });
  }

  return options;
})();

const createOptionsPerYear = (year) => {
  return PreviousYearPeriods.map((value) => (
    {label: `${value} ${year}`, value }
  ));
};

const CurrentYearPeriods = [
  {
    label: "Today",
    value: "today"
  },
  {
    label: "Yesterday",
    value: "yesterday"
  },
  {
    label: "This Week (Mon - Sun)",
    value: "this-week"
  },
  {
    label: "Last 7 days",
    value: "last-7-days"
  },
  {
    label: "Last Week (Mon - Sun)",
    value: "last-week"
  },
  {
    label: "This Month",
    value: "this-month"
  },
  {
    label: "Last 30 days",
    value: "last-30-days"
  },
  {
    label: "Last Month",
    value: "last-month"
  },
  {
    label: "Last 90 days",
    value: "last-90-days"
  },
  {
    label: "This Year",
    value: "ytd"
  },
  {
    label: "Custom",
    value: "custom"
  }
];

const PreviousYearPeriods = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "Q1",
  "Q2",
  "Q3",
  "Q4"
];
