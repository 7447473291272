import React, {Component} from 'react';
import css from "./person-detail-styles.module.css";
import icons from './icons.module.css';

class TagBubble extends Component {
  render() {
    return(
      <div style={{ display: "inline-block", marginBottom: "5px", marginTop: "8px" }} className={`${css.brivityPersonDetailsTag} ${icons.tagBubble}`}>
        <span>{this.props.name}</span>
        <i
          className="fa fa-times"
          style={{ fontSize: "9px", marginLeft:"6px", cursor:"pointer" }}
          onClick={() => this.props.deleteTagFromPerson(this.props.id, this.props.person)}
        />
      </div>
    )
  }
}

export default TagBubble;
