/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MailboxSolidV6 = (props) => (
  <Icon {...props} name="Mailbox Solid V6">
    <path d="M12 2.667H6.803a4.881 4.881 0 0 1 2.086 4v6.667h6.222c.489 0 .889-.399.889-.864V6.667c0-2.208-1.792-4-4-4zm2.222 5.752c0 .245-.199.419-.444.419h-.889c-.245 0-.444-.199-.444-.419V7.111h-1.333c-.244 0-.444-.2-.444-.419s.2-.419.444-.419h2.667c.245 0 .444.199.444.419V8.42zM4 2.667c-2.208 0-4 1.792-4 4v5.753c0 .489.399.864.889.864l7.111.05V6.667c0-2.208-1.792-4-4-4zm1.778 4.444H2.222c-.245 0-.444-.199-.444-.419s.199-.419.444-.419h3.556c.245 0 .444.199.444.419s-.2.419-.444.419z" />
  </Icon>
);

export default MailboxSolidV6;
