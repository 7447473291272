import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import LinkTextRender from "@shared/LinkTextRender";
import SmsMessage, { Media } from "./SmsMessage";

const LINK_TEXT_CLASSES =
  "tw-rounded-[8px] tw-bg-tinted-gray-50 tw-p-[10px] tw-inline-block tw-max-w-[600px] tw-whitespace-pre-wrap tw-break-words";
const ERROR_CLASSES = {
  allFailed: "!tw-bg-semantic-red-5",
  someFailed: "!tw-bg-semantic-yellow-5",
};
const ERROR_STATUSES = ["undelivered", "failed"];

const ConversationMessage = ({ message, onClickImage, showTimeAgo, conversation }) => {
  const isPlaceTheme = document.body.classList.contains("place-theme");
  const isInbound = !conversation.conversationOwnerPersonUuids.includes(message.createdByUserPersonUuid);
  const isOutbound = !isInbound;
  const failedRecipients = message.deliveryStatuses
    .filter((s) => ERROR_STATUSES.includes(s.messageStatus))
    .map((s) => conversation.conversationUsers.find((u) => u.uuid === s.conversationUserUuid).alias);

  const error =
    (failedRecipients.length === message.deliveryStatuses.length - 1 && "allFailed") ||
    (failedRecipients.length > 0 && "someFailed") ||
    false;

  return (
    <Fragment key={message.id}>
      {showTimeAgo && (
        <div className="tw-text-12d tw-font-semibold tw-text-gray tw-text-center">
          {moment(message.dateCreated).fromNow()}
        </div>
      )}

      {isInbound &&
        message.attachments?.map((media, i) => (
          <SmsMessage
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            isInbound={isInbound}
            content={<Media media={media} onClick={() => onClickImage(message, i)} />}
            message={message}
            error={error}
            failedRecipients={failedRecipients}
          />
        ))}

      {isInbound && message.message && (
        <SmsMessage
          isInbound={isInbound}
          content={<LinkTextRender text={message.message} className={LINK_TEXT_CLASSES} />}
          message={message}
          error={error}
          failedRecipients={failedRecipients}
        />
      )}

      {isOutbound && message.message && (
        <SmsMessage
          isGabbiMessage={message.isGabbi}
          isInbound={isInbound}
          content={
            <LinkTextRender
              text={message.message}
              className={`${LINK_TEXT_CLASSES} ${
                error
                  ? ERROR_CLASSES[error]
                  : `${
                      message.isGabbi
                        ? "!tw-bg-[#f9f7fd] tw-text-[#3b1487] tw-border-solid tw-border-[2px] tw-border-semantic-purple-5"
                        : `${isPlaceTheme ? "!tw-bg-semantic-blue-5" : "!tw-bg-brivity-blue-20"}`
                    }`
              }`}
            />
          }
          message={message}
          error={error}
          failedRecipients={failedRecipients}
        />
      )}

      {isOutbound &&
        message.attachments?.map((media, i) => (
          <SmsMessage
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            isInbound={isInbound}
            content={<Media media={media} onClick={() => onClickImage(message, i)} />}
            message={message}
            error={error}
            failedRecipients={failedRecipients}
          />
        ))}
    </Fragment>
  );
};

ConversationMessage.propTypes = {
  conversation: PropTypes.shape().isRequired,
  message: PropTypes.shape().isRequired,
  onClickImage: PropTypes.func.isRequired,
  showTimeAgo: PropTypes.bool.isRequired,
};

export default ConversationMessage;
