import client from "./apiClient";
import buildPayload from "./payloadBuilders";

export const putDetailToApi = ({ uuid, key: name, value, inOption = null, addressId = null }) => {
  const payload = { listing: buildPayload(inOption, name, value, addressId) };

  return client.save(uuid, payload);
};
export const importDetailToApi = (uuid, payload) => client.save(uuid, payload);

export const putRefDetailtoApi = (uuid, payload) => client.save(uuid, payload);

export const replacePhotoToApi = (uuid, payload) => client.save(uuid, payload);

export const deleteListingImageApi = client.deleteListingImage;
export const getAllDetailsFromApi = client.listDetails;
export const getSourcesFromApi = client.listAccountSources;
export const getPropertyTypesFromApi = client.listAccountPropertyTypes;

export const updatePropertyDetailApi = ({ uuid, ...restOfPayload }) =>
  client.updatePropertyDetail(uuid, restOfPayload);

export const getSelectedListingDataFromApi = client.getlistingDetails;
