import React, { useState } from "react";
import { useSelector } from "react-redux";
import "@brivity/ai-components/dist/style.css";
import { AiComponentsProvider, AskPlace, AskPlaceToggleButton } from "@brivity/ai-components";
import useAiToken from "@shared/AiAssistant/hooks/useAiToken";

const AiAskPlace = () => {
  // If we need to make this component triggerable in more locations we'll need to move this to redux
  const [isAskPlaceOpen, setIsAskPlaceOpen] = useState(false);
  const { apiUrl, token } = useAiToken();
  const isAdmin = useSelector(({ layout }) => layout.currentUser.account.isAdmin);
  const isOwner = useSelector(({ layout }) => layout.currentUser.account.isOwner);

  if (!isAdmin && !isOwner) {
    return null;
  }

  return (
    <AiComponentsProvider apiUrl={apiUrl} token={token}>
      <AskPlaceToggleButton onClick={() => setIsAskPlaceOpen(true)} isAskPlaceOpen={isAskPlaceOpen} />
      {isAskPlaceOpen && <AskPlace onMinimize={() => setIsAskPlaceOpen(false)} />}
    </AiComponentsProvider>
  );
};

export default AiAskPlace;
