import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import Button from "./Button";
import FiltersModal from "./Filters/Filters";
import ListingDetailsModal from "./ListingDetails/ListingDetailsModal";
import AgentDetailsModal from "./AgentDetails/AgentDetailsModal";
import SearchEmptyState from "./SearchEmptyState";
import EmailModal from "./EmailModal";
import Listings from "./Listings";
import SearchAutocomplete from "./SearchAutocomplete";
import {
  getBuyerMatches,
  getListings,
  getPropertyTypes,
  saveCurrentSearch,
  saveViewSelections,
  loadMostRecentSearch,
  loadListingView,
  getFeatureFields,
  loadListingSearches,
} from "./service";
import OptionsBar from "./OptionsBar";
import SaveSearchModal from "./SavedSearches/SaveSearchModal";
import DeleteSearchModal from "./SavedSearches/DeleteSearchModal";
import SearchModel from "./SearchModel";

const SearchListings = ({
  idxDomain,
  brokerImage,
  sender,
  current_user_id: currentUserId,
  team_ids: teamIds,
  agentDetailPageEnabled,
}) => {
  const searchBar = useRef(null);
  const [mlsIds, setMlsIds] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [featureFields, setFeatureFields] = useState({});
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [shouldRunCountQuery, setShouldRunCountQuery] = useState(true);
  const [listingCount, setListingCount] = useState(null);
  const [listings, setListings] = useState([]);
  const [buyerMatches, setBuyerMatches] = useState({});
  const [searchModel, setSearchModel] = useState(loadMostRecentSearch());
  const [viewOptions, setViewOptions] = useState(loadListingView());
  const [listingDetails, setListingDetails] = useState({ modalOpen: false, listingId: null });
  const [agentDetails, setAgentDetails] = useState({
    modalOpen: false,
    agent: null,
    office: null,
    mlsId: null,
  });
  const [selectedListings, setSelectedListings] = useState([]);
  const [viewedListings, setViewedListings] = useState([]);
  const [sharedListing, setSharedListing] = useState(null);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const [saveSearchModalOpen, setSaveSearchModalOpen] = useState(false);
  const [deleteSearchModalOpen, setDeleteSearchModalOpen] = useState(false);
  const [searchIdToDelete, setSearchIdToDelete] = useState("");

  const SharedListingsLimit = 10;
  const AvailableStatuses = [1, 2, 3];
  const areListingsLoaded = listings.length > 0;

  const onSearchModalClosed = async (refreshList = false) => {
    setSaveSearchModalOpen(false);

    if (refreshList) {
      const updatedList = await loadListingSearches(currentUserId);
      setSavedSearches(updatedList);
    }
  };

  const onDeleteModalClosed = async (refreshList = false) => {
    setSearchIdToDelete("");
    setDeleteSearchModalOpen(false);

    if (refreshList) {
      const updatedList = await loadListingSearches(currentUserId);
      setSavedSearches(updatedList);
    }
  };

  const onDeleteSearchSelected = (searchId) => {
    setSearchIdToDelete(searchId);
    setDeleteSearchModalOpen(true);
  };

  const selectAutocompleteResult = (field, newValue, identifier = "") => {
    if (identifier) {
      setSearchModel(searchModel.addToArrayWithIdentifer(field, newValue, identifier));
      return;
    }

    setSearchModel(searchModel.addToArray(field, newValue));
  };

  const clearSearchParams = () => {
    setSearchModel(searchModel.clearSearchParams());
  };

  const clearArrayValue = (field, value) => {
    setSearchModel(searchModel.removeValue(field, value));
  };

  const clearFilterOption = (minField, maxField) => {
    setSearchModel(searchModel.updateMany({ [minField]: "", [maxField]: "" }));
  };

  const clearFeatureOption = (category, value) => {
    setSearchModel(searchModel.removeFeatureValue(category, value));
  };

  const toggleListingTypeFilter = (listingType, value) => {
    setSearchModel(searchModel.update(listingType, value));
  };

  const applyFilters = (appliedFilters) => {
    setSearchModel(searchModel.updateMany(appliedFilters));
  };

  const applySort = (field, order) => {
    setSearchModel(searchModel.setSort(field, order));
    setShouldRunCountQuery(false);
  };

  const openListingDetailsModal = (listingId) => {
    if (!viewedListings.includes(listingId)) {
      setViewedListings([listingId, ...viewedListings]);
    }

    setListingDetails({ modalOpen: true, listingId });
  };

  const closeListingDetailsModal = () => {
    setListingDetails({ modalOpen: false, listingId: null });
  };

  const closeAgentDetailsModal = () => {
    setAgentDetails({ modalOpen: false });
  };

  const openAgentDetailsModal = (listing) => {
    if (agentDetailPageEnabled) {
      const agent = listing.agents.find((a) => a.id === listing.listAgentId);
      const office = {
        id: listing.listOfficeId,
        name: listing.listOfficeName,
        email: listing.listOfficeEmail,
        phone: listing.listOfficePhone,
      };

      setAgentDetails({ modalOpen: true, agent: agent, office: office, mlsId: listing.blueroofMlsId });
    }
  };

  const onToggleShare = (listing) => {
    const alreadySelected = selectedListings.some((l) => l.blossorId === listing.blossorId);
    if (alreadySelected) {
      setSelectedListings(selectedListings.filter((l) => l.blossorId !== listing.blossorId));
    } else {
      setSelectedListings([...selectedListings, listing]);
    }
  };

  const sendToLead = (buyerMatch, listingId) => {
    const listing = listings.find((l) => l.blossorId === listingId);
    setSelectedRecipients([buyerMatch]);
    setSharedListing(listing);
  };

  const maxListingsSelected = () => {
    return selectedListings.length >= SharedListingsLimit;
  };

  const updateSearchPage = (newOffset) => {
    setShouldRunCountQuery(false);
    setSearchModel(searchModel.updateOffset(newOffset));
  };

  const loadSelectedSearch = (searchId) => {
    const savedSearch = savedSearches.find((s) => s.id === searchId);
    if (!savedSearch) {
      return;
    }

    const search = new SearchModel(savedSearch.search);
    setSearchModel(search);
  };

  useEffect(() => {
    if (sharedListing === null) {
      setEmailModalOpen(false);
    } else {
      setEmailModalOpen(true);
    }
  }, [sharedListing]);

  useEffect(() => {
    const get = async () => {
      const promises = [getPropertyTypes(mlsIds, currentUserId), getFeatureFields(mlsIds, currentUserId)];

      const [propertyTypes, featureFields] = await Promise.all(promises);

      setPropertyTypes(propertyTypes);
      setFeatureFields(featureFields);
    };

    setSearchModel(searchModel.update("mlsIds", mlsIds));
    if (mlsIds.length) {
      get();
    }
  }, [mlsIds, currentUserId]);

  useEffect(() => {
    if (!listings.length) {
      return;
    }
    let isCancelled = false;
    const getMatches = async () => {
      const promises = [];
      listings.forEach(({ city, state, price, blossorId }) => {
        if (!buyerMatches[blossorId]) {
          promises.push(getBuyerMatches(city, state, price, blossorId));
        }
      });

      const matches = await Promise.all(promises);
      if (!isCancelled) {
        const mappedMatches = matches.reduce((acc, match) => {
          acc[match.blossorId] = match;
          return acc;
        }, {});

        setBuyerMatches((prevState) => ({
          ...prevState,
          ...mappedMatches,
        }));
      }
    };

    getMatches();

    return () => {
      isCancelled = true;
    };
  }, [listings]);

  useEffect(() => {
    saveCurrentSearch(searchModel);
    saveViewSelections(viewOptions);
  }, [searchModel, viewOptions]);

  useLayoutEffect(() => {
    let isCancelled = false;

    const searchListings = async () => {
      setIsSearching(true);
      const [listings, count] = await getListings(searchModel, teamIds, shouldRunCountQuery);

      if (isCancelled) {
        return;
      }

      if (shouldRunCountQuery) {
        setListingCount(count);
        setBuyerMatches({});
      }

      setListings(listings);
      setShouldRunCountQuery(true);
      setIsSearching(false);
    };

    if (searchModel.isValid()) {
      searchListings();
    } else {
      setListings([]);
    }

    return () => {
      isCancelled = true;
    };
  }, [searchModel]);

  useEffect(() => {
    const init = async () => {
      const searches = await loadListingSearches(currentUserId);
      setSavedSearches(searches);
    };

    init();
  }, []);

  return (
    <div className="tw-bg-white tw-h-full tw-flex tw-flex-col tw-gap-16px">
      <div className="tw-flex tw-pr-16px tw-pt-24px tw-pl-24px tw-items-center">
        <div className="tw-flex tw-flex-col tw-gap-8px">
          <h1 className="tw-my-0 tw-text-28px tw-text-gray-75">Search Listings</h1>
          {areListingsLoaded ? (
            <span className="tw-text-14px tw-text-gray-50">
              {listingCount} results:{" "}
              <span className="tw-font-semibold">{`${selectedListings.length}/${SharedListingsLimit}`}</span>{" "}
              selected
            </span>
          ) : (
            <span className={`tw-text-14px tw-text-gray-50 ${!isSearching && !searchModel.isValid() ? "tw-invisible" : ""}`}>No Results</span>
          )}
        </div>
        <div className="tw-flex tw-ml-auto tw-gap-12px">
          <Button
            color="blue"
            size="large"
            disabled={selectedListings.length > 0 ? "" : "disabled"}
            onClick={() => {
              setEmailModalOpen(true);
              setSharedListing(null);
            }}
          >
            <div className="tw-flex tw-justify-center tw-items-center">
              SHARE
              <i className="fa fa-share-square-o tw-ml-6px" />
            </div>
          </Button>
          <button
            className="tw-text-14px tw-py-6px tw-px-18px tw-h-36px tw-font-bold tw-cursor-pointer tw-tracking-normal tw-text-center tw-uppercase tw-whitespace-nowrap tw-text-none tw-rounded-30px tw-text-teal tw-border-2 tw-border-teal disabled:tw-text-brivity-blue-40 disabled:tw-border-brivity-blue-40 tw-border-solid tw-bg-white"
            onClick={() => setFiltersModalOpen(true)}
          >
            {`Filters (${searchModel.getNumberOfFilters()})`}
          </button>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-pr-16px tw-pb-16px tw-pl-24px tw-gap-12px tw-h-full">
        <div className="tw-relative">
          <SearchAutocomplete
            searchBarRef={searchBar}
            currentUserId={currentUserId}
            mlsIds={mlsIds}
            setMlsIds={setMlsIds}
            statuses={searchModel.statuses}
            selectResult={selectAutocompleteResult}
            selectSearch={loadSelectedSearch}
            deleteSearch={onDeleteSearchSelected}
            savedSearches={savedSearches}
          />
        </div>
        {searchModel.isValid() && (
          <div className="tw-relative">
            <OptionsBar
              searchModel={searchModel}
              viewOptions={viewOptions}
              setViewOptions={setViewOptions}
              applySort={applySort}
              clearSearchParams={clearSearchParams}
              clearArrayValue={clearArrayValue}
              clearFilterOption={clearFilterOption}
              toggleListingTypeFilter={toggleListingTypeFilter}
              featureFields={featureFields}
              clearFeatureOption={clearFeatureOption}
              saveSearch={() => setSaveSearchModalOpen(true)}
            />
          </div>
        )}
        {areListingsLoaded && (
          <Listings
            listings={listings}
            searchModel={searchModel}
            onToggleShare={onToggleShare}
            selectedListings={selectedListings}
            listingCount={listingCount}
            maxListingsSelected={maxListingsSelected()}
            applySort={applySort}
            openListingDetailsModal={openListingDetailsModal}
            openAgentDetailsModal={openAgentDetailsModal}
            agentDetailsEnabled={agentDetailPageEnabled}
            updateSearchPage={updateSearchPage}
            viewOptions={viewOptions}
            isSearching={isSearching}
            viewedListings={viewedListings}
            buyerMatches={buyerMatches}
          />
        )}
        {!areListingsLoaded && !isSearching && (
          <SearchEmptyState isInitialState={!searchModel.isValid()} searchBarRef={searchBar} />
        )}
      </div>
      {filtersModalOpen && (
        <FiltersModal
          existingFilters={searchModel.getFilters()}
          propertyTypes={propertyTypes}
          featureFields={featureFields}
          availableStatuses={AvailableStatuses}
          onApply={applyFilters}
          onClose={() => setFiltersModalOpen(false)}
        />
      )}
      {listingDetails.modalOpen && (
        <ListingDetailsModal
          listing={listings.find((l) => l.blossorId === listingDetails.listingId)}
          selectedLimit={SharedListingsLimit}
          maxListingsSelected={maxListingsSelected()}
          isSelected={selectedListings.some((l) => l.blossorId === listingDetails.listingId)}
          numberSelected={selectedListings.length}
          onToggleShare={onToggleShare}
          setSharedListing={setSharedListing}
          onClose={closeListingDetailsModal}
          buyerMatches={buyerMatches[listingDetails.listingId]}
          sendToLead={sendToLead}
          openAgentDetailsModal={openAgentDetailsModal}
          agentDetailsEnabled={agentDetailPageEnabled}
        />
      )}
      {agentDetails.modalOpen && (
        <AgentDetailsModal
          mlsId={agentDetails.mlsId}
          listAgent={agentDetails.agent}
          listOffice={agentDetails.office}
          onClose={closeAgentDetailsModal}
        />
      )}
      {emailModalOpen && (
        <EmailModal
          idxDomain={idxDomain}
          listings={sharedListing !== null ? [sharedListing] : selectedListings}
          brokerImage={brokerImage}
          sender={sender}
          selectedRecipients={selectedRecipients}
          onClose={() => {
            setEmailModalOpen(false);
            setSharedListing(null);
          }}
        />
      )}
      {saveSearchModalOpen && (
        <SaveSearchModal onClose={onSearchModalClosed} search={searchModel} userId={currentUserId} />
      )}
      {deleteSearchModalOpen && (
        <DeleteSearchModal
          searchId={searchIdToDelete}
          onDelete={onDeleteModalClosed}
          userId={currentUserId}
        />
      )}
    </div>
  );
};

export default SearchListings;
