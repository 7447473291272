import axios from "axios";
import {
  differenceInDays,
  startOfYesterday,
  addSeconds,
  addDays,
  startOfDay,
  startOfWeek,
  startOfToday,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  subDays,
  subWeeks,
  startOfMonth,
  subMonths,
  startOfYear,
  addQuarters,
  format,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const getRollupsByDate = async ({ from, to, signal }) => {
  const params = {
    min_date: from,
    max_date: to,
  };

  const { data } = await axios.get("/leaderboards", { params, signal });

  // TODO: do some of the formatting here as far as dividing goals, etc
  return data.users?.map(formatUser) || [];
};

const formatUser = (user) => ({
  ...user,
  avatar_url: user["avatar_url"]?.length > 3 ? user["avatar_url"] : null,
});

export const getRanksByDate = async ({ from, to, activity, userId, signal }) => {
  if (!activity || !userId) {
    return {};
  }
  const params = {
    min_date: from,
    max_date: to,
    metric: activity,
    user_id: userId,
  };

  const { data } = await axios.get("/leaderboards/ranking", { params, signal });

  return {
    brivityRank: data["global_rank"],
    placeRank: data["place_rank"],
  };
};

export const makeDateRangeFromPhrase = (year, timeFrame) => {
  const { from, to } = timeFrameConverters[timeFrame](year, timeFrame);

  return {
    from: from.toString(),
    to: to.toString(),
  };
};

export const getFormattedWeekDates = (startingDate) => {
  if (!startingDate) {
    return "";
  }

  const dateFormat = "L/d";
  const start = zonedTimeToUtc(startingDate, "America/Los_Angeles");
  const end = addDays(start, 6);

  return `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
};

const timeFrameConverters = {
  today: () => {
    const from = startOfToday();

    return {
      from,
      to: endOfDay(from),
    };
  },
  yesterday: () => {
    const from = startOfYesterday();

    return {
      from,
      to: endOfDay(from),
    };
  },
  "this-week": () => {
    const from = startOfWeek(new Date(), { weekStartsOn: 1 });

    return {
      from,
      to: endOfWeek(from, { weekStartsOn: 1 }),
    };
  },
  "last-7-days": () => {
    const to = new Date();

    return {
      from: startOfDay(subDays(to, 7)),
      to,
    };
  },
  "last-week": () => {
    const to = startOfWeek(new Date(), { weekStartsOn: 1 });

    return {
      from: subWeeks(to, 1),
      to: addSeconds(to, -1),
    };
  },
  "last-14-days": () => {
    const to = new Date();

    return {
      from: subDays(to, 14),
      to,
    };
  },
  "this-month": () => {
    const to = new Date();

    return {
      from: startOfMonth(to),
      to,
    };
  },
  "last-30-days": () => {
    const to = new Date();

    return {
      from: subDays(to, 30),
      to,
    };
  },
  "last-month": () => {
    const to = startOfMonth(new Date());

    return {
      from: subMonths(to, 1),
      to: addSeconds(to, -1),
    };
  },
  "last-90-days": () => {
    const to = new Date();

    return {
      from: subDays(to, 90),
      to,
    };
  },
  ytd: () => {
    const to = new Date();

    return {
      from: startOfYear(to),
      to,
    };
  },
  January: (year, timeFrame) => monthRange(year, timeFrame),
  February: (year, timeFrame) => monthRange(year, timeFrame),
  March: (year, timeFrame) => monthRange(year, timeFrame),
  April: (year, timeFrame) => monthRange(year, timeFrame),
  May: (year, timeFrame) => monthRange(year, timeFrame),
  June: (year, timeFrame) => monthRange(year, timeFrame),
  July: (year, timeFrame) => monthRange(year, timeFrame),
  August: (year, timeFrame) => monthRange(year, timeFrame),
  September: (year, timeFrame) => monthRange(year, timeFrame),
  October: (year, timeFrame) => monthRange(year, timeFrame),
  November: (year, timeFrame) => monthRange(year, timeFrame),
  December: (year, timeFrame) => monthRange(year, timeFrame),
  Q1: (year, timeFrame) => quarterRange(year, timeFrame),
  Q2: (year, timeFrame) => quarterRange(year, timeFrame),
  Q3: (year, timeFrame) => quarterRange(year, timeFrame),
  Q4: (year, timeFrame) => quarterRange(year, timeFrame),
};

const quarterRange = (year, quarter) => {
  const quarterNumber = parseInt(quarter.substring(1));
  const yearStart = new Date(`January 01 ${year}`);
  const from = addQuarters(yearStart, quarterNumber - 1);

  return {
    from,
    to: endOfQuarter(from),
  };
};

const monthRange = (year, month) => {
  const from = new Date(`${month} 01 ${year}`);

  return {
    from,
    to: endOfMonth(from),
  };
};

export const CurrentYear = new Date().getFullYear();

export const Activities = [
  {
    fullName: "Calls",
    shortName: "Calls",
    key: "outbound_calls",
  },
  {
    fullName: "Contacts",
    shortName: "Contacts",
    key: "contacts",
  },
  {
    fullName: "Texts",
    shortName: "Texts",
    key: "texts_sent",
  },
  {
    fullName: "Appointments Set",
    shortName: "Appt. Set",
    key: "appointments_set",
  },
  {
    fullName: "Appointments Held",
    shortName: "Appt. Held",
    key: "appointments_held",
  },
  {
    fullName: "Open Houses",
    shortName: "Open Houses",
    key: "open_houses",
  },
  {
    fullName: "Door Knocking",
    shortName: "Door Knocking",
    key: "door_knocking",
  },
  {
    fullName: "Networking Events",
    shortName: "Networking Ev...",
    key: "networking_events",
  },
  {
    fullName: "Circle Prospecting",
    shortName: "Circle Prosp...",
    key: "circle_prospecting_calls",
  },
  {
    fullName: "Canceled/Expired Calls",
    shortName: "Canceled/Exp...",
    key: "canceled_expired_calls",
  },
  {
    fullName: "For Sale By Owner Calls",
    shortName: "FSBO Calls",
    key: "fsbo_calls",
  },
];

export const getOrdinalNumber = (n) => {
  if (!n) {
    return "";
  }

  const lastOne = n % 10;
  const lastTwo = n % 100;

  if (lastTwo > 10 && lastTwo < 20) {
    return "th";
  } else if (lastOne === 1) {
    return "st";
  } else if (lastOne === 2) {
    return "nd";
  } else if (lastOne === 3) {
    return "rd";
  } else {
    return "th";
  }
};

/**
 * Sorts users by the count on the [activity] object and then
 * adds a rank for that activity. The users
 * array must be sorted by the [count] on the [activity] object. Modifies
 * the original objects.
 * @param {Array} sortedUsers
 * @param {String} activity
 * @returns {Array}
 */
export const sortAndRankUsers = (users = [], activity = "") => {
  const sortedUsers = [...users].sort((a, b) => {
    return b[activity].count - a[activity].count;
  });

  let currentRank = 0;
  let previousCount = -1;
  let rankMatches = 0;
  for (const user of sortedUsers) {
    const count = user[activity].count;

    if (!count) {
      user[activity].rank = null;
      continue;
    }

    if (count === previousCount) {
      rankMatches++;
    } else {
      currentRank += rankMatches + 1;
      rankMatches = 0;
    }

    user[activity].rank = currentRank;
    previousCount = count;
  }

  return sortedUsers;
};
