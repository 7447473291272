import React, { useEffect, useMemo, useState } from "react";
import { PhoneCallIcon, PhoneCallDetails } from "./";
import { FormGroup, Row, Col } from "react-bootstrap";
import Dropdown from "@shared/v2/Dropdown";
import { DncFlag } from "@shared/Sms";
import useTimer from "../hooks/useTimer";
import useDncStatuses from "../hooks/useDncStatuses";
import DisplayName from "./DisplayName";
import StatusText from "./StatusText";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";
import { STATUS_ICON_MODIFIERS } from "@shared/models/Communications/viewPhoneIcons/CallStatusIcon";

const maybeLeadingZero = (num) => {
  return ("0" + num).slice(-2);
};

const prettyPrintSeconds = (seconds) => {
  let hours = parseInt(seconds / 60 / 60);
  let minutes = parseInt((seconds / 60) % 60);
  let secs = seconds % 60;

  return `${hours}:${maybeLeadingZero(minutes)}:${maybeLeadingZero(secs)}`;
};
const OptOutNotice = () => (
  <div>
    <p className="tw-text-14d tw-font-semibold tw-text-neutral-gray-50">Number has opted out</p>
    <p className="tw-text-14d tw-font-normal tw-text-neutral-gray-50">
      This number has opted out of voice communication and therefore is unable to be called.{" "}
    </p>
  </div>
);

const normalizeCategory = (category) => category.charAt(0).toUpperCase() + category.slice(1);

const PhoneNumberOption = ({ viewPhone, isDnc }) => {
  const icon = viewPhone.isMobile ? viewPhone.mobileCallIcon : viewPhone.callIcon;
  const phoneColor = STATUS_ICON_MODIFIERS[viewPhone.callStatus];

  return (
    <div className="tw-flex tw-space-x-[6px] tw-items-center">
      <span className="tw-flex">{icon.icon({ size: "m" })}</span>
      <span className={phoneColor}>{`${normalizeCategory(viewPhone.category)}: ${
        viewPhone.formattedNumber
      }`}</span>
      {isDnc && <DncFlag show={isDnc} />}
    </div>
  );
};

const PhoneCallForm = ({
  callInProgress,
  clickedHangup,
  dialablePhoneNumbers,
  errorMessage,
  handleEndCall,
  handleStartCall,
  hasError,
  interaction,
  linkToContact,
  managedNumber,
  person,
  platformAccount,
  platformIsRequired,
  setStatus,
  showPlatformRequiredMessage,
  updateValues,
  userNumber,
  onPhoneChange,
  clickedPhoneNo,
}) => {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(
    dialablePhoneNumbers.find(({ value }) => value === clickedPhoneNo) || dialablePhoneNumbers[0],
  );
  const { timerCount, startTimer, stopTimer } = useTimer();
  const { dncStatuses, checkDnc } = useDncStatuses({ dialablePhoneNumbers });

  const phoneNumberOptions = useMemo(() => {
    return dialablePhoneNumbers.map((phone) => {
      const viewPhone = viewPhoneFromFlat(phone);

      return {
        label: <PhoneNumberOption viewPhone={viewPhone} isDnc={checkDnc({ value: viewPhone.rawValue })} />,
        value: viewPhone.id,
      };
    });
  }, [dialablePhoneNumbers, dncStatuses]);

  const selectedOption = phoneNumberOptions.find(({ value }) => value === selectedPhoneNumber.id);
  const viewPhone = viewPhoneFromFlat(selectedPhoneNumber);
  const unsubscribed = viewPhone.callUnsubscribed;

  useEffect(() => {
    if (!(platformAccount && managedNumber)) return;

    if (typeof App !== "undefined") {
      App.phone_call = App.cable.subscriptions.create("PhoneCallChannel", {
        connected: function () {
          setTimeout(() => this.followCurrentCall(), 1000);
        },
        received: function (data) {
          setStatus(data["callEvent"], data["duration"]);
        },
        followCurrentCall: function () {
          this.perform("follow");
        },
      });
    }

    return () => {
      stopTimer();
      if (App?.phone_call) {
        App.phone_call.perform("unfollow");
        App.phone_call.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (interaction.status === "answered") startTimer();
    if (interaction.status === "completed") stopTimer();
  }, [interaction.status]);

  const beginCall = () => {
    if (platformAccount && managedNumber) {
      handleStartCall(person.id, selectedPhoneNumber.id);
    } else {
      showPlatformRequiredMessage();
    }
  };

  const endCall = () => {
    handleEndCall(interaction);
    stopTimer();
  };

  const handleBeginOrEndCall = (callInProgress) => {
    setTimeout(() => (callInProgress ? endCall() : beginCall()), 1000);
  };

  const handleSelectPhoneNumber = (selectedId) => {
    const selectedPhoneIndex = dialablePhoneNumbers.findIndex((p) => p.id === selectedId);
    setSelectedPhoneNumber(dialablePhoneNumbers[selectedPhoneIndex]);
    onPhoneChange && onPhoneChange(viewPhoneFromFlat(dialablePhoneNumbers[selectedPhoneIndex]));
  };

  useEffect(
    () => onPhoneChange && onPhoneChange(viewPhoneFromFlat(dialablePhoneNumbers[0])),
    [dialablePhoneNumbers[0]?.value, dialablePhoneNumbers[0]?.id],
  );

  const callStatusClass =
    ["ringing", "in_progress", "answered"].includes(interaction.status) || callInProgress
      ? "active-call"
      : interaction.status === "completed" || !callInProgress
      ? "finished-call"
      : "";

  return (
    <FormGroup>
      <Row className="phone-call">
        <Col xs={12}>
          {interaction.status ? (
            <div className={`call-status ${callStatusClass}`}>
              <StatusText interaction={interaction} person={person} userNumber={userNumber} />
              <span className="pull-right">{prettyPrintSeconds(timerCount)}</span>
            </div>
          ) : platformIsRequired ? (
            <div className="call-status active-call">
              Click to call is a Brivity Platform feature - call (866) 295-3322 or email sales@brivity.com to
              upgrade today!
            </div>
          ) : null}
        </Col>
      </Row>

      <Row style={{ marginBottom: "10px" }}>
        <Col xs={8}>
          <div className="form-group contact-card">
            <img src={person.avatar} />
            <p className="phone-call" style={{ fontSize: "16px", fontWeight: "600", color: "#666666" }}>
              <DisplayName linkToContact={linkToContact} person={person} />
            </p>
            <div className="tw-w-full tw-max-w-[300px]">
              <Dropdown
                value={selectedOption}
                options={phoneNumberOptions}
                onChange={(option) => handleSelectPhoneNumber(option.value)}
                disabled={callInProgress || timerCount > 0}
              />
            </div>
          </div>
        </Col>

        <Col xs={4}>
          <div className="interaction-icon pull-right" style={{ marginRight: "-5px" }}>
            {unsubscribed ||
            interaction.status === "completed" ||
            clickedHangup ||
            (platformAccount && !managedNumber) ? (
              <i className="fa fa-phone phone gray" style={{ cursor: "not-allowed" }}></i>
            ) : (
              <PhoneCallIcon
                style={{ background: callInProgress ? "#f2714d" : undefined }}
                handleClick={() => handleBeginOrEndCall(callInProgress)}
                iconClass="active"
              />
            )}
          </div>
        </Col>
      </Row>

      {unsubscribed ? (
        <OptOutNotice />
      ) : (
        <PhoneCallDetails
          updateValues={updateValues}
          interaction={interaction}
          notableId={person.id}
          status={interaction.status}
          hasError={hasError}
          errorMessage={errorMessage}
          isDnc={checkDnc(selectedPhoneNumber)}
        />
      )}
    </FormGroup>
  );
};

export default PhoneCallForm;
