import React, { Component } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import css from "../../../Dashboard/components/dashboard-styles.module.css";
import { Dropdown } from "../../../shared/v1";
import FilterButton from "./FilterButton";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import AgentDropdown from "./AgentDropdown";
import DateRangeDropdown from "./DateRangeDropdown";
class TransactionFilterModal extends Component {
  state = {
    events: this.props.events || [],
    visibleEvents: [],
    isOpen: false,
    agents: [],
    sources: [],
    selectedAgents: ["all_active_agents"],
    selectedSources: [],
    selectedSource: null,
    selectedDate: null,
    isFetching: true,
    anyTagsSelected: this.props.anyOrAll || "any",
  };

  handleFilter = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleFilterModal = (e) => {
    e.preventDefault();
    let agentsIdsQueryString = `?agent_ids=${this.state.selectedAgents}`;
    window.location.pathname + agentsIdsQueryString, { change: "content", cacheRequest: false };
    this.setState({
      isOpen: !this.state.isOpen,
    });
    location.reload(true);
  };

  option = (props) => {
    const {
      data: { label, value },
    } = props;

    return (
      <div className={`checkbox ${css.filterItem}`}>
        <input
          type="checkbox"
          id={label}
          value={value}
          onChange={(e) => this.handleAgentSelect(value, e.target.checked)}
          checked={this.state.selectedAgents.includes(value)}
        />
        <label htmlFor={label} className={css.checkBoxLabel}>
          {label}
        </label>
      </div>
    );
  };

  handleAgentSelect = (selectedAgentId, isChecked) => {
    this.setState({ isFetching: true });
    const currentSelectedAgents = [...this.state.selectedAgents];
    let selectedAgents = [];
    if (selectedAgentId === "all_active_agents" && isChecked) {
      selectedAgents = ["all_active_agents"];
    } else if (selectedAgentId === "current_agent" && isChecked) {
      selectedAgents = ["current_agent"];
    } else if (currentSelectedAgents.includes("all_active_agents" || "current_agent")) {
      selectedAgents = isChecked
        ? [selectedAgentId]
        : this.state.agents.filter((filter) => filter !== selectedAgentId);
    } else {
      selectedAgents = isChecked
        ? [...currentSelectedAgents, selectedAgentId]
        : currentSelectedAgents.filter((filter) => filter !== selectedAgentId);
    }
    this.setState({
      selectedAgents: selectedAgents.length ? selectedAgents : [],
    });
    this.setAgentCookie(selectedAgents);
  };

  handleSourceSelect = (source) => {
    let selectedSource = source == null ? null : source.value;
    this.setState(
      {
        selectedSource,
        selectedSources: source == null ? [] : [...this.state.selectedSources, selectedSource],
      },
      () => {
        this.setSourceCookie(this.state.selectedSources);
      },
    );
  };

  handleDateSelect = (date) => {
    let selectedDate = date == null ? "" : date.value;
    this.setState({ selectedDate });
    this.setDateRangeCookie(selectedDate);
  };

  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      selectedAgents: [],
      selectedSources: [],
      selectedSource: null,
      selectedDate: null,
    });
    this.setAgentCookie("all_active_agents"), this.setSourceCookie(""), this.setDateRangeCookie("");
  };

  setCookie = (name, value, exp) => {
    let date = new Date();
    date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
    let expires = "; expires=" + date.toGMTString();
    let cookie_string = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/;";
    return (document.cookie = cookie_string);
  };

  getCookie = (name) => {
    let values = [];
    let decodedCookies = decodeURIComponent(document.cookie).split(";");
    decodedCookies.forEach((cookie) => {
      let trimmedCookie = cookie.trim();
      if (trimmedCookie.indexOf(name) == 0) {
        values = decodeURIComponent(trimmedCookie.substring(name.length + 1, trimmedCookie.length)).split(
          ",",
        );
        return;
      }
    });
    return values;
  };

  setDateRangeCookie = (range) => {
    this.setCookie("transaction_close_date_range", range, 365);
  };

  getDateRangeFromCookie = () => {
    let default_range = "all_time";
    if (this.getCookie("transaction_close_date_range").length < 1) {
      this.setCookie("transaction_close_date_range", default_range, 365);
      return default_range;
    } else {
      this.getCookie("transaction_close_date_range");
    }
    this.setState({
      selectedDate: this.getCookie("transaction_close_date_range")[0],
    });
  };

  setSourceCookie = (source) => {
    this.setCookie("transaction_sources", source, 365);
  };

  getSourceFromCookie = () => {
    if (this.getCookie("transaction_sources").length < 1) {
      return false;
    } else {
      this.getCookie("transaction_sources");
    }
    let sourceCookie = this.getCookie("transaction_sources");
    this.setState({
      selectedSources:
        sourceCookie.length > 0
          ? this.getCookie("transaction_sources")
              .filter((id) => id !== "")
              .map((sourceId) => parseInt(sourceId))
          : [],
    });
  };

  setAgentCookie = (agents) => {
    this.setCookie("transaction_agent_ids", agents, 365);
  };

  getAgentsCookie = () => {
    let default_agent = "all_active_agents";
    if (this.getCookie("transaction_agent_ids").length < 1) {
      this.setCookie("transaction_agent_ids", default_agent, 365);
      return default_agent;
    } else {
      this.getCookie("transaction_agent_ids");
    }
    this.setState({
      selectedAgents: this.getCookie("transaction_agent_ids").map((agentId) =>
        agentId == "all_active_agents" || agentId == "current_agent" ? agentId : parseInt(agentId),
      ),
    });
  };
  renderSelectedSource(sourceId) {
    if (sourceId === "") {
      return null;
    }
    const source = this.findSource(sourceId);
    return (
      <div key={sourceId} style={{ display: "inline-block" }}>
        <input
          className="filter-bubble-checkbox"
          type="checkbox"
          name="leads_filter[selected_tag_filters][]"
          id={`leads_filter_selected_tag_filters_${source.id}`}
          value={source.id}
          defaultChecked="checked"
        />
        <div className="removable-bubble" style={{ marginRight: "3px" }}>
          <label className="info-label" htmlFor={`leads_filter_selected_tag_filters_${source.id}`}>
            {source.text}
          </label>
          <i
            onClick={() => this.removeSource(source.id)}
            className="fa fa-remove"
            style={{ paddingLeft: "3px" }}
          ></i>
        </div>
      </div>
    );
  }
  findSource(sourceId) {
    const sources = this.state.sources;
    return sources && sources.find((source) => source.id == sourceId);
  }
  addSource = (sourceId) => {
    const sourceIdString = sourceId.toString();
    if (this.state.selectedSources.includes(sourceId)) {
      return;
    }
  };
  removeSource = (sourceId) => {
    const newSources = this.state.selectedSources.filter((id) => id != sourceId);
    this.setState({ selectedSources: newSources });
    this.setSourceCookie(newSources);
  };

  componentDidMount = () => {
    let agents = this.props.active_assignable_agents;
    this.setState({ agents });
    axios.get(`/sources`).then((res) => {
      let sources = res.data.sources;
      this.setState({ sources });
    });
    this.getDateRangeFromCookie();
    this.getSourceFromCookie();
    this.getAgentsCookie();
  };

  formatOptions = (agents) => {
    const allAgents = agents.map((agent) => {
      return { value: agent.id, label: agent.name };
    });
    return [
      { label: "All Active Users", value: "all_active_agents" },
      { label: "Only my transactions", value: "current_agent" },
      ...allAgents,
    ];
  };

  render() {
    const { selectedAgents, selectedSource, selectedSources, selectedDate, sources } = this.state;
    const sourceOptions = sources.map((source) => ({
      label: source.text,
      value: source.id,
    }));
    const dateOptions = this.props.date_ranges.map((date) => ({
      label: date[0],
      value: date[1],
    }));
    const dateOptionsCopy = [...dateOptions];
    const allTime = dateOptionsCopy.pop();
    dateOptionsCopy.unshift(allTime);
    return (
      <div data-cy="transaction-filter-modal-wrapper">
        <FilterButton handleFilter={this.handleFilter} />
        {this.state.isOpen && (
          <Modal show={true} onHide={this.handleFilter}>
            <form
              data-cy="filter-modal-form"
              style={{ padding: "15px 30px" }}
              onSubmit={this.handleFilterModal}
            >
              <ModalHeader handleClear={this.handleClear} />
              <div>
                <AgentDropdown
                  option={this.option}
                  selectedAgents={this.state.selectedAgents}
                  agents={this.state.agents}
                  formatOptions={this.formatOptions}
                />
                <hr
                  style={{
                    height: "2px",
                    color: "#F3F3F3",
                    backgroundColor: "#F3F3F3",
                    marginBottom: "10px",
                  }}
                />
                <div style={{ paddingBottom: "30px" }}>
                  <label
                    style={{
                      letterSpacing: "0.2px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                    }}
                  >
                    <b>SOURCE</b>
                  </label>
                  <Dropdown
                    options={sourceOptions}
                    onChange={(source) => this.handleSourceSelect(source)}
                    value={null}
                    isClearable={false}
                    variant="flatGray"
                  />
                  <div className="filter-checkboxes m-t-10">
                    {selectedSources.map((source) => this.renderSelectedSource(source))}
                  </div>
                </div>
                {this.props.scope == "pending" || this.props.scope == "sold" ? (
                  <DateRangeDropdown
                    dateOptions={dateOptionsCopy}
                    handleDateSelect={this.handleDateSelect}
                    selectedDate={this.state.selectedDate}
                  />
                ) : null}
              </div>
              <ModalFooter handleFilter={this.handleFilter} />
            </form>
          </Modal>
        )}
      </div>
    );
  }
}

export default TransactionFilterModal;
