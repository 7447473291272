import React from "react";
import Proptypes from "prop-types";

const ImportListingDropdown = ({ handleSelect, handleHover, results }) => (
  <div className="tw-h-[32px] tw-px-[12px] tw-cursor-pointer tw-py-[6px] hover:tw-bg-gray-lighter hover:tw-text-gray-darker">
    <div onMouseOver={handleHover} onMouseDown={handleSelect} role="button" tabIndex={0}>
      {results.value}
    </div>
  </div>
);
ImportListingDropdown.propTypes = {
  results: Proptypes.shape({
    value: Proptypes.string,
    selected_fields: Proptypes.shape({
      id: Proptypes.string,
    }),
  }),
  handleSelect: Proptypes.func,
  handleHover: Proptypes.func,
};
ImportListingDropdown.defaultProps = {
  results: {},
  handleSelect: () => {},
  handleHover: () => {},
};
export default ImportListingDropdown;
