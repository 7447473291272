import React, { useState } from "react";
import "../Filters.sass";
import AutoPlanStateSelect from "./AutoPlanStateSelect";
import AutoPlanNameSelect from "./AutoPlanNameSelect";

const AutoPlanFilters = ({ previousSelectedPlanIds, previousSelectedStates }) => {
  const [isPlanDropdownDisabled, setisPlanDropdownDisabled] = useState(false);

  const togglePlanDropdown = () => {
    setisPlanDropdownDisabled((isDisabled) => !isDisabled);
  };

  return (
    <>
      <AutoPlanStateSelect
        previousSelectedStates={previousSelectedStates}
        togglePlanDropdown={togglePlanDropdown}
      />
      <AutoPlanNameSelect
        isDisabled={isPlanDropdownDisabled}
        previousSelectedPlanIds={previousSelectedPlanIds}
      />
    </>
  );
};

export default AutoPlanFilters;
