export const submitEmailError = (data) => {
  let errors = data?.content || data?.error || "Whoops something went wrong! Try again later.";
  if (typeof data?.errors === "object") {
    const firstKey = Object.keys(data.errors)[0];
    errors = `${firstKey} ${data.errors[firstKey].join(", ")}`;
  }
  return {
    type: "SUBMIT_ERROR_EMAIL",
    errors,
    isSupportError: errors?.startsWith("We apologize"),
  };
};

export const submitSendEmail = () => ({ type: "SUBMIT_SEND_EMAIL" });

export const updateHomeAppInviteSent = (data) => ({
  type: "UPDATE_HOME_APP_INVITE_SENT",
  home_app_invite_sent: data,
});

export const clearEmailState = () => ({ type: "CLEAR_EMAIL_STATE" });
