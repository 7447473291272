import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import { caseTransformingAxios as axios } from "@shared/v2/caseTransformingAxios";
import { CircleExclamationRegularV6 } from "@icons/index";
import FieldSelect from "@shared/UtilsV1/FieldSelect";

function MassUpdateStageModal({ stageOptions, candidateStageOptions }) {
  const [showModal, setshowModal] = useState(false);
  const [stage, setstage] = useState({ label: "New lead", value: "New lead", stage_type: "lead" });
  const [validLeads, setvalidLeads] = useState([]);
  const [saving, setSaving] = useState(false);

  const getValidLeads = () => {
    const path = "/api/v3/leads/valid_leads";
    const ids = Brivity.PeoplePage.grid().multiSelect().getSelectedObjectIds();

    axios.get(path, { params: { people_ids: ids } }).then((res) => {
      setvalidLeads(res.data.leads);
    });
  };

  const openModal = (e) => {
    e.preventDefault();
    setshowModal(true);
    setstage({ label: "New lead", value: "New lead", stage_type: "lead" });
    getValidLeads();
  };

  const closeModal = () => {
    setshowModal(false);
  };

  const massUpdateStage = () => {
    setSaving(true);
    axios
      .put("/api/v3/leads/bulk_update", {
        lead_ids: validLeads,
        stage: stage.value,
        stage_type: stage.stage_type,
        authenticity_token: window.ReactOnRails.authenticityToken(),
      })
      .then(() => {
        window.location.reload();
      });
  };

  const renderModal = () => {
    const dropdownStageOptions = stageOptions.map((str) => ({
      label: str,
      value: str,
      stage_type: "lead",
    }));
    const dropdownCandidateStageOptions = candidateStageOptions.map((str) => ({
      label: `${str} `,
      value: str,
      stage_type: "candidate",
    }));
    // pushed "Lead Stages" and "Candidate Stages" at first place of array to display as header in dropdown option
    dropdownStageOptions.unshift({
      label: "Lead Stages",
      value: "Lead Stages",
      header: "Lead Stages",
    });
    dropdownCandidateStageOptions.unshift({
      label: "Candidate Stages",
      value: "Candidate Stages",
      header: "Candidate Stages",
    });
    const numContacts = Brivity.PeoplePage.grid().multiSelect().getSelectedObjectIds().length;
    const leadsInContacts =
      validLeads.length < numContacts ? `${validLeads.length} of ${numContacts}` : numContacts;
    const showInfoMessage = validLeads.length < numContacts;
    return (
      showModal && (
        <Modal
          id="mass-update-stage-modal"
          className="tw-flex tw-items-center tw-justify-center"
          contentClassName="tw-w-[616px]"
          show={showModal}
          onHide={closeModal}
        >
          <Modal.Header
            title={`Change Stage for ${leadsInContacts} contacts`}
            className="tw-mb-40px"
            closeButton
          />
          <Modal.Body>
            {showInfoMessage && (
              <div>
                <div className="tw-py-12px tw-px-16px tw-rounded-8px tw-text-semantic-yellow-120 tw-bg-semantic-yellow-5 tw-flex tw-space-x-16px tw-items-center tw-mb-20">
                  <div className="tw-text-semantic-yellow-120">
                    <CircleExclamationRegularV6 size="xl" />
                  </div>
                  <div>
                    <p className="tw-text-14d tw-text-semantic-yellow-120 tw-font-semibold tw-mb-[5px]">
                      Some contacts cannot have their stages changed.
                    </p>
                    <p className="tw-text-14d tw-text-semantic-yellow-120 tw-mb-[5px]">
                      You are only able to set a stage for leads. Some of the selected contacts are not able
                      to have their stages updated.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {validLeads.length > 0 && (
              <div>
                <div className="tw-mb-4">
                  What stage would you like to change these {leadsInContacts} contacts to?{" "}
                  <span className="tw-text-[#F2714D]">*</span>
                </div>
                <div>
                  <FieldSelect
                    fieldSelect
                    defaultValue="New lead"
                    options={[...dropdownStageOptions, ...dropdownCandidateStageOptions]}
                    onChange={(option) =>
                      setstage({ label: option.label, value: option.value, stage_type: option.stage_type })
                    }
                    value={stage.value}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[40px]">
            <Button
              schema="tertiary"
              size="medium"
              data-cy="mass-update-stage-modal-cancel-button"
              onClick={closeModal}
            >
              CANCEL
            </Button>

            {validLeads.length > 0 && (
              <Button
                schema="primary"
                size="medium"
                data-cy="mass-update-stage-modal-change-stage-button"
                onClick={massUpdateStage}
                disabled={saving}
              >
                {saving && <i className="fa fa-pulse fa-spinner tw-pr-[4px]" />}
                CHANGE STAGE
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )
    );
  };

  return (
    <div>
      <div
        onClick={openModal}
        role="button"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (e.key !== "Tab") e.preventDefault();
          if (["Enter", " "].includes(e.key)) openModal();
        }}
      >
        Change Stage
      </div>
      {renderModal()}
    </div>
  );
}

const selectOptionsPropTypes = PropTypes.arrayOf(PropTypes.string);

MassUpdateStageModal.propTypes = {
  stageOptions: selectOptionsPropTypes,
  candidateStageOptions: selectOptionsPropTypes,
};

MassUpdateStageModal.defaultProps = {
  stageOptions: [],
  candidateStageOptions: [],
};

export default MassUpdateStageModal;
