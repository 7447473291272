import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const MarketReportPresenceSelect = ({
  previousSelectedReportPresence,
  setIsLastMarketReportDropdownDisabled,
}) => {
  function uncheckInput(inputId) {
    document.getElementById(inputId).checked = false;
  }

  useEffect(() => {
    if (previousSelectedReportPresence === "true") {
      document.getElementById("leads_filter_selected_market_reports_filters_true").checked = true;
    } else if (previousSelectedReportPresence === "false") {
      document.getElementById("leads_filter_selected_market_reports_filters_false").checked = true;
      setIsLastMarketReportDropdownDisabled(true);
    }
  }, []);

  return (
    <Row className="m-b-10">
      <Col xs={12}>
        <label>Market Reports</label>
      </Col>
      <Col xs={12}>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="false"
          name="leads_filter[selected_market_reports_filters][]"
          id="leads_filter_selected_market_reports_filters_false"
        />
        <label
          className="danger-label"
          htmlFor="leads_filter_selected_market_reports_filters_false"
          onClick={() => {
            uncheckInput("leads_filter_selected_market_reports_filters_true");
            setIsLastMarketReportDropdownDisabled((isDisabled) => !isDisabled);
          }}
        >
          None Created
        </label>
        <input
          className="filter-checkbox"
          type="checkbox"
          value="true"
          name="leads_filter[selected_market_reports_filters][]"
          id="leads_filter_selected_market_reports_filters_true"
        />
        <label
          className="info-label"
          htmlFor="leads_filter_selected_market_reports_filters_true"
          onClick={() => {
            uncheckInput("leads_filter_selected_market_reports_filters_false");
            setIsLastMarketReportDropdownDisabled(false);
          }}
        >
          Has Reports
        </label>
      </Col>
    </Row>
  );
};

export default MarketReportPresenceSelect;
