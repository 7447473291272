import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import axios from "axios";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TextButton from "@shared/v2/TextButton";
import { Popover, PopoverItem, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";
import { EyeSolidV6, PlusSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import css from "./person-detail-styles.module.css";
import CmaReport from "../../CmaReport";
import CmaReportPreview from "../../CmaReport/cmaReportPreview";
import Loading from "../../Goals/components/Loader";
import CollapsibleElement from "./CollapsibleElement";

const getApiHeaders = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-csrf-token": window.ReactOnRails.authenticityToken(),
});
const cmaUrl = process.env.NODE_ENV === "production" ? "www.brivitycma.com" : "www.staging.brivitycma.com";

const useCmaReports = ({ person }) => {
  const [cmaReports, setCmaReports] = useState([]);
  const [isCmaReportModalOpen, setIsCmaReportModalOpen] = useState(false);

  useEffect(() => {
    if (person.data?.attributes.uuid) {
      axios
        .get(`/cma_reports?uuid=${person.data?.attributes.uuid}`, { headers: getApiHeaders() })
        .then(({ data }) => setCmaReports(data))
        // TODO: handle error
        .catch(console.log);
    }
  }, [person.data?.attributes.uuid]);

  return {
    actions: {
      deleteCmaReport: ({ id }) =>
        axios
          .delete(`/cma_reports/${id}`, { headers: getApiHeaders() })
          .then(() => setCmaReports((currentReports) => currentReports.filter((r) => r.id !== id)))
          .catch((err) => console.log(err)),
      toggleModal: (value) => setIsCmaReportModalOpen((o) => (typeof value === "boolean" ? value : !o)),
    },
    data: { cmaReports, isCmaReportModalOpen },
  };
};

const CmaReports = () => {
  const [showCmaLinks, setShowCmaLinks] = useState(false);
  const person = useSelector((state) => state.personDetail.person);
  const reportPreviewPerson = person.data?.attributes || {};

  const {
    actions,
    data: { isCmaReportModalOpen, cmaReports },
  } = useCmaReports({ person });

  const mostRecentReport = useMemo(() => {
    const viewedReports = cmaReports.filter((report) => report.last_viewed !== null);
    if (viewedReports.length > 1) {
      return viewedReports.sort((a, b) => new Date(b.last_viewed) - new Date(a.last_viewed))[0];
    }
    return cmaReports[0];
  }, [cmaReports]);

  return (
    <CollapsibleElement
      title="CMA Reports"
      customButton={
        <Popover open={showCmaLinks} onOpenChange={setShowCmaLinks} placement="bottom-end">
          <PopoverTrigger>
            <div>
              <Button onClick={() => setShowCmaLinks((o) => !o)}>+ Add</Button>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverItem
              icon={<PlusSolidV6 />}
              onClick={() => {
                window.open(person.cma_links[0], "_blank");
                setShowCmaLinks(false);
              }}
            >
              New CMA Report
            </PopoverItem>
            <PopoverItem
              icon={<EyeSolidV6 />}
              onClick={() => {
                window.open(person.cma_links[1], "_blank");
                setShowCmaLinks(false);
              }}
            >
              View Report Status
            </PopoverItem>
          </PopoverContent>
        </Popover>
      }
    >
      {!person.data && <Loading />}
      <div id="person-market-reports-card">
        {cmaReports.length === 0 ? (
          <div className={css.personDetailEmptyStateText}>
            Create a new Comparative Market Analysis to create a detailed report of the property.
          </div>
        ) : (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <CmaReport
            {...mostRecentReport}
            deleteReportFn={(id) => actions.deleteCmaReport({ id })}
            cmaUrl={cmaUrl}
          />
        )}

        {cmaReports.length > 1 && <TextButton onClick={() => actions.toggleModal(true)}>VIEW ALL</TextButton>}

        {isCmaReportModalOpen && (
          <CmaReportPreview
            modalIsOpen
            modalOnly
            onClose={() => actions.toggleModal(false)}
            person={reportPreviewPerson}
            person_status={person.data?.attributes.status}
            person_address={
              person.included
                ?.filter((i) => i.type === "address" && i.id !== null)
                .map((i) => `${i.attributes?.street_address}`)[0]
            }
            person_lead_type={person.data?.attributes.lead_type?.charAt(0)}
            person_avatar={person.data?.attributes.avatar_url}
            cmaUrl={cmaUrl}
            deleteReportFn={(id) => actions.deleteCmaReport({ id })}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

CmaReports.propTypes = {
  person: PropTypes.shape({
    status: PropTypes.string,
    display_a_street_address: PropTypes.string,
    person_lead_type: PropTypes.string,
    person_avatar: PropTypes.string,
  }),
};
CmaReports.defaultProps = {
  person: {},
};

export default CmaReports;
