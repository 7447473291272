import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { CircleExclamationRegularV6 } from "@shared/v2/Icomoon";

const MessageErrorTooltip = ({ error, failedRecipients }) => {
  if (!error) return null;

  return (
    <Tooltip
      innerClassName="tw-max-w-[250px] "
      multiline
      placement="bottom"
      trigger={
        <CircleExclamationRegularV6
          size="xl"
          className={error === "allFailed" ? "tw-text-semantic-red-120" : "tw-text-semantic-yellow-120"}
        />
      }
      content={
        <>
          <div>
            This message failed to send to{" "}
            {error === "allFailed"
              ? "all recipients in the group chat. This could be because..."
              : "to the following recipients:"}
          </div>
          {error === "allFailed" && (
            <ul className="tw-px-[16px] tw-py-[4px]">
              <li>Recipients have an opt out or invalid phone number</li>
              <li>Other reasons</li>
            </ul>
          )}
          {error === "someFailed" && (
            <>
              <ul className="tw-m-0 tw-px-[16px] tw-py-[4px]">
                {failedRecipients.map((recipient, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={i}>{recipient}</li>
                ))}
              </ul>
              <div>
                This could be because the user has an invalid phone number, has opted out, or some other
                reason.
              </div>
            </>
          )}
        </>
      }
    />
  );
};

MessageErrorTooltip.propTypes = {
  error: PropTypes.oneOf(["allFailed", "someFailed", false]),
  failedRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MessageErrorTooltip.defaultProps = {
  error: false,
};

export default MessageErrorTooltip;
