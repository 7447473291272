import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { caseTransformingAxios, requestDataToSnakeCase } from "@shared/v2/caseTransformingAxios";
import { differenceWith, fromPairs, isEqual, set, startCase, toPairs } from "lodash";
import {
  transformFormOptions,
  transformPerson,
  transformSources,
  validateLeadForm,
  validateUserForm,
} from "./helpers";
import { getMessagingApi } from "../../Inbox/helpers/messagingApi";

const INIT_USER = {
  addressesAttributes: [],
  agentStatus: null,
  emailDetailsAttributes: [],
  isLead: false,
  loginUserAttributes: {
    active: true,
    role: "Agent",
    licenseId: null,
    showAgentProfilePage: false,
    showOnWebsiteRoster: false,
    bio: null,
    customLinksAttributes: [],
    subdomain: null,
  },
  phoneDetailsAttributes: [],
  exportToRoster: "true",
};

const initialState = {
  customDateOptions: {},
  formOptions: {},
  formOptionsLoading: true,
  loading: false,
  originalPerson: {},
  person: {},
  errors: [],
  showPhoneContext: null,
};

const validOrderFinder = (order) => (customLink) => customLink.order === order && !("_destroy" in customLink);

const getFormData = createAsyncThunk(
  "contacts/getFormOptions",
  ({ modalType, personId, person }, thunkApi) => {
    const messagingServiceUrl = thunkApi.getState().messaging.messagingServiceUrl;
    const messagingApi = getMessagingApi(messagingServiceUrl);
    const getTotalConversationCount = (personUuid, phoneNumber) =>
      messagingApi
        .get(`/Conversation/SmsAndGroupSmsCountForPersonAddressPair/${personUuid}/${phoneNumber}`, {
          signal: thunkApi.signal,
        })
        .then((res) => res.data.total)
        .catch(() => 0);
    const personPromise = personId
      ? axios.get(`/api/v4/${modalType === "lead" ? "contact_people" : "account_people"}/${personId}`, {
          signal: thunkApi.signal,
        })
      : Promise.resolve({ data: modalType === "user" ? INIT_USER : person });
    const augmentedPhoneDetailsPersonPromise = personPromise
      .then((res) => transformPerson(res.data))
      .then(async (person) => {
        if (!person) return person;
        const { phoneDetailsAttributes = [] } = person;
        const modifiedDetailsAttributes = await Promise.all(
          phoneDetailsAttributes.map((phone) =>
            getTotalConversationCount(person.uuid, phone.value).then((archiveConversationsCount) => ({
              ...phone,
              archiveConversationsCount,
            })),
          ),
        ).catch(() => phoneDetailsAttributes);
        return { ...person, phoneDetailsAttributes: modifiedDetailsAttributes };
      });
    return Promise.all([
      augmentedPhoneDetailsPersonPromise,
      caseTransformingAxios
        .get(
          modalType === "lead"
            ? "/api/v4/contact_people/form_options"
            : "/api/v4/account_people/form_options",
          { signal: thunkApi.signal },
        )
        .then((res) => transformFormOptions(modalType === "user" ? res.data.formOptions : res.data)),
      caseTransformingAxios.get("/sources").then((res) => transformSources(res.data.sources)),
    ])
      .then(([person, formOptions, sourceOptions]) => ({
        person,
        formOptions: {
          ...formOptions,
          sourceOptions,
        },
      }))
      .catch((err) => thunkApi.rejectWithValue(err.response?.data));
  },
);

const upsertPerson = createAsyncThunk("contacts/savePerson", ({ modalType }, thunkApi) => {
  const { originalPerson, person } = thunkApi.getState().contactsReducer;
  const response = modalType === "lead" ? validateLeadForm(person) : validateUserForm(person);
  if (response.errors.length > 0) return Promise.reject(thunkApi.rejectWithValue(response));

  const savePerson = originalPerson.id
    ? fromPairs(differenceWith(toPairs(person), toPairs(originalPerson), isEqual))
    : person;

  const phoneChanges = originalPerson.id
    ? differenceWith(
        person.phoneDetailsAttributes,
        originalPerson.phoneDetailsAttributes,
        (a, b) => (a.value || "").replace(/\D/g, "") === (b.value || "").replace(/\D/g, ""),
      )
    : [];

  const changedPhoneDetailsAttributes = phoneChanges.filter((phone) => phone.archiveConversationsCount > 0);
  if (!person.phoneRemovalAck && changedPhoneDetailsAttributes.length > 0) {
    const changedNumbers = changedPhoneDetailsAttributes.map((phone) => {
      return {
        currentNumber: phone._destroy ? null : phone.value,
        previousNumber: originalPerson.phoneDetailsAttributes?.find(
          (originalPhone) => originalPhone.id === phone.id,
        )?.value,
      };
    });

    const conversationCount = changedPhoneDetailsAttributes.reduce(
      (acc, phone) => acc + phone.archiveConversationsCount,
      0,
    );

    return Promise.reject(
      thunkApi.rejectWithValue({
        type: "showPhoneContext",
        changedNumbers,
        conversationCount,
      }),
    );
  }

  return axios[person.id ? "put" : "post"](
    `/api/v4/${modalType === "lead" ? "contact_people" : "account_people"}${person.id ? `/${person.id}` : ""}`,
    {
      person: {
        ...requestDataToSnakeCase({
          ...savePerson,
          phoneDetailsAttributes: person.phoneDetailsAttributes,
          phoneRemovalAck: person.phoneRemovalAck || person.phoneDetailsAttributes?.some((d) => d._destroy),
        }),
        milestones: savePerson.milestones,
      },
    },
    {
      headers: {
        "x-csrf-token": ReactOnRails.authenticityToken(),
      },
      signal: thunkApi.signal,
    },
  )
    .then((res) => transformPerson(res.data))
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const uploadAvatar = createAsyncThunk("contacts/uploadAvatar", ({ file, personId }, thunkApi) => {
  const formData = new FormData();
  formData.append("authenticity_token", ReactOnRails.authenticityToken());
  formData.append("person[avatar]", file);
  return caseTransformingAxios
    .post(`/api/v4/person_detail/upload_avatar/${personId}`, formData, { signal: thunkApi.signal })
    .then((res) => res.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const contactsReducer = createSlice({
  initialState,
  name: "contacts",
  reducers: {
    setErrors: (state, { payload = [] }) => {
      state.errors = payload;
    },
    setPersonValue: (state, { payload }) => {
      set(state.person, payload.key, payload.value);
    },
    setShowPhoneContext: (state, { payload }) => {
      state.showPhoneContext = payload;
    },
    addCustomLink: (state, { payload }) => {
      const { customLinksAttributes } = state.person.loginUserAttributes;
      customLinksAttributes.push({
        icon: "fa6 fa6-link",
        order: customLinksAttributes.filter((customLink) => !customLink._destroy).length + 1,
        category: "social",
      });
    },
    setCustomLink: (state, { payload }) => {
      const { customLinksAttributes } = state.person.loginUserAttributes;
      const customLinkIndex = customLinksAttributes.findIndex(validOrderFinder(payload.order));
      customLinksAttributes[customLinkIndex] = { ...customLinksAttributes[customLinkIndex], ...payload };
    },
    deleteCustomLink: (state, { payload }) => {
      const { customLinksAttributes } = state.person.loginUserAttributes;
      const customLinkIndex = customLinksAttributes.findIndex(validOrderFinder(payload.order));
      customLinksAttributes[customLinkIndex]._destroy = true;
      customLinksAttributes
        .filter((customLink) => customLink._destroy)
        .forEach((customLink, i) => {
          customLink.order = -i - 1;
        });
      customLinksAttributes
        .filter((customLink) => !customLink._destroy)
        .forEach((customLink, i) => {
          customLink.order = i + 1;
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFormData.pending, (state) => {
        state.formOptionsLoading = true;
        state.errors = [];
      })
      .addCase(getFormData.fulfilled, (state, { payload }) => {
        if (payload.person) {
          state.originalPerson = payload.person;
          state.person = payload.person;
        }
        state.formOptions = payload.formOptions;
        state.formOptionsLoading = false;
      })
      .addCase(getFormData.rejected, (state) => {
        state.errors = ["Failed to load form options. Please try again later."];
        state.formOptionsLoading = false;
      });

    builder
      .addCase(upsertPerson.pending, (state) => {
        state.errors = [];
        state.loading = true;
      })
      .addCase(upsertPerson.fulfilled, (state, { payload }) => {
        state.originalPerson = payload;
        state.person = payload;
        state.loading = false;
      })
      .addCase(upsertPerson.rejected, (state, { payload, ...props }) => {
        console.log(payload, props);
        if (payload?.type === "showPhoneContext") {
          state.showPhoneContext = payload;
          state.loading = false;
          return;
        }

        let errors = [];
        Object.entries(payload?.errors?.[0]?.detail || {}).forEach(([key, value]) =>
          errors.push(`${startCase(key.split(".")?.[0])}: ${value}`),
        );

        let phoneValidations = payload?.errors?.[0]?.context?.phone_validations;

        if (phoneValidations?.length > 0) {
          let message = `Mobile phone number is already in use for ${phoneValidations.length} contact(s):<br/>`;

          let formatValidation = (validation) => {
            return `<a style="color: inherit; text-decoration: underline;" href="${validation.url}" target="_blank">${validation.name}</a>`;
          };

          message += phoneValidations.map(formatValidation).join(",");

          errors.push(message);
        }

        if (errors.length === 0) errors = ["Failed to save person. Please try again later."];

        state.errors = errors;
        state.loading = false;
      });

    builder
      .addCase(uploadAvatar.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadAvatar.fulfilled, (state, { payload }) => {
        state.person.avatarUrl = payload.data.attributes.avatarUrl;
        state.loading = false;
      })
      .addCase(uploadAvatar.rejected, (state, { payload }) => {
        let errors = [];
        Object.entries(payload.errors?.[0]?.detail || {}).forEach(([key, value]) =>
          errors.push(`${startCase(key.split(".")?.[0])}: ${value}`),
        );
        if (errors.length === 0) errors = ["Failed to upload profile photo. Please try again later."];

        state.errors = errors;
        state.loading = false;
      });
  },
});

export { getFormData, upsertPerson, uploadAvatar };

export const {
  addCustomLink,
  setCustomLink,
  deleteCustomLink,
  setErrors,
  setPersonValue,
  setShowPhoneContext,
} = contactsReducer.actions;

export default contactsReducer.reducer;
