// Not using shared formatter, the input needs the ability to parse up to 2 decimals with no minimum
import formatters from "@shared/formatters";
const { number: formatNumber } = formatters;
export { formatNumber };

export const pasteNumber = (setValue) => (e) => {
  e.preventDefault();
  const pastedText = (e.clipboardData || window.clipboardData).getData("text");
  const number = pastedText.replaceAll(/[^0-9.]/g, "");
  if (number) setValue(formatNumber(number, maximumFractionDigits));
};
