import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sortBy, uniqBy, xor } from "lodash";
import { caseTransformingAxios } from "../../shared/v2/caseTransformingAxios";
import formatPhoneNumber from "../../shared/FormatPhoneNumber";
import { getMessagingApi } from "../../Inbox/helpers/messagingApi";

const getBaseEndpoint = (currentUser, isAll) => {
  let path = "/Conversation/Recent";
  let method = "get";
  if (currentUser.account.isAdmin && isAll) {
    path = "/Conversation/RecentForOrganization";
  } else if (currentUser.account.isAdmin) {
    path = "/Conversation/RecentForPeople";
    method = "post";
  }
  return { path, method };
};

const getConversationTypes = (currentTab) => {
  switch (currentTab) {
    case "group":
      return ["groupSms"];
    default:
      return ["sms", "groupSms"];
  }
};

export const transformConversation = (conversation) => {
  const { conversationUsers, dateCreated, dateUpdated } = conversation;
  const sortedConversationUsers = sortBy(
    conversationUsers.map((u) => ({
      ...u,
      alias: u.alias || formatPhoneNumber(u.address),
    })),
    "alias",
  );
  return {
    ...conversation,
    conversationOwnerPersonUuids: conversationUsers
      .filter((u) => u.isConversationOwner)
      .map((u) => u.personUuid),
    conversationUsers: sortedConversationUsers,
    friendlyName: sortedConversationUsers
      .map((u) => u.alias)
      .join(sortedConversationUsers.length > 2 ? ", " : " & "),
    dateUpdated: dateUpdated || dateCreated || new Date().toISOString(),
  };
};

export const initialState = {
  accountSenderPlaceholders: [],
  allFilterAgents: [],
  conversations: [],
  conversationsPage: 1,
  conversationsPerPage: 25,
  currentTab: "all",
  draftTextMessages: {}, // Stores in-progress text messages for each text thread
  errors: null,
  focusedConversation: { eventType: "blank" },
  hasMore: false,
  hasMoreMessages: false,
  loadingConversations: false,
  loadingMessages: false,
  messagingServiceUrl: "",
  messagesErrors: null,
  messagesPage: 1,
  messagesPerPage: 25,
  newSmsMediaError: null,
  newSmsMediaFile: null,
  newSmsMediaPreview: null,
  recipients: {},
  selectedAgentFilters: ["all"],
  selectedMessages: [],
  sendingTextMessage: false,
  aiUnreadCount: 0,
  allUnreadCount: 0,
  groupUnreadCount: 0,
  conflictConversation: null,
  isOptedOut: false,
  hideAi: false,
  aiFilters: ["all"],
};

const getUnreadCounts = createAsyncThunk("messaging/getUnreadCounts", (_, thunkApi) => {
  const { messagingServiceUrl } = thunkApi.getState().messaging;
  const messagingApi = getMessagingApi(messagingServiceUrl);
  return Promise.all([
    messagingApi
      .get("/User/UnreadMessageCount", {
        params: { conversationTypes: ["sms", "groupSms"], isAiControlled: true },
      })
      .then((res) => res.data)
      .catch(() => 0),
    messagingApi
      .get("/User/UnreadMessageCount", { params: { conversationTypes: ["groupSms"] } })
      .then((res) => res.data)
      .catch(() => 0),
  ]).then(([aiUnreadCount, groupUnreadCount]) => ({
    aiUnreadCount,
    groupUnreadCount,
  }));
});

const getConversations = createAsyncThunk("messaging/getConversations", (_, thunkApi) => {
  const { currentUser } = thunkApi.getState().layout;
  const {
    currentTab,
    conversationsPage,
    conversationsPerPage,
    messagingServiceUrl,
    selectedAgentFilters,
    hideAi,
    unreadOnly,
    aiFilters,
  } = thunkApi.getState().messaging;
  const isAiControlled = currentTab === "all" && hideAi ? false : undefined;
  const agentUuids =
    selectedAgentFilters?.[0] === "all" ? undefined : selectedAgentFilters.filter((o) => o !== "all");
  const baseEndpoint = getBaseEndpoint(currentUser, !agentUuids);

  const messagingApi = getMessagingApi(messagingServiceUrl);
  return messagingApi({
    method: baseEndpoint.method,
    url: `${baseEndpoint.path}/${conversationsPage}/${conversationsPerPage}`,
    data: agentUuids,
    params: {
      conversationTypes: getConversationTypes(currentTab),
      isAiControlled: currentTab === "ai" || isAiControlled,
      unReadOnly: (currentTab !== "ai" && unreadOnly) || undefined,
      aiConversationStatus: currentTab !== "ai" || aiFilters.includes("all") ? undefined : aiFilters,
    },
    signal: thunkApi.signal,
  })
    .then((res) => res.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const getOptOutStatus = createAsyncThunk("messaging/getOptOutStatus", (_, thunkApi) => {
  const {
    messaging: { focusedConversation },
  } = thunkApi.getState();
  return Promise.all(
    focusedConversation.conversationUsers
      ?.filter((u) => !u.isConversationOwner)
      .map((u) => caseTransformingAxios.get(`/api/v4/leads/${u.personUuid}/phones`)),
  )
    .then((responses) =>
      responses.some((res) =>
        res.data.find((phone) => {
          const conversationUser = focusedConversation.conversationUsers.find(
            (u) => u.address === phone.canonicalNumber,
          );
          if (conversationUser) return phone.smsAccountUnsubscribed || phone.smsSoftUnsubscribed;
          return false;
        }),
      ),
    )
    .catch(() => false);
});

const getMessages = createAsyncThunk("messaging/getMessages", (opts, thunkApi) => {
  const {
    messaging: { focusedConversation, messagesPage, messagesPerPage, messagingServiceUrl },
  } = thunkApi.getState();
  const messagingApi = getMessagingApi(messagingServiceUrl);

  return messagingApi
    .get(
      `/ConversationMessages/${opts?.uuid || focusedConversation.uuid}/${messagesPage}/${messagesPerPage}`,
      {
        signal: thunkApi.signal,
      },
    )
    .then((res) => res.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const markAsRead = createAsyncThunk("messaging/markAsRead", (_, thunkApi) => {
  const { focusedConversation, messagingServiceUrl } = thunkApi.getState().messaging;
  const messagingApi = getMessagingApi(messagingServiceUrl);
  // This will trigger a SignalR message "ResetConversationUnreadMessageCount"
  return Promise.all([
    messagingApi.put(`/ConversationUser/ResetUnreadCount/${focusedConversation.uuid}`),
    caseTransformingAxios.post("/interactions/mark_conversation_as_read", {
      conversationUuid: focusedConversation.uuid,
    }),
  ]).catch(console.log);
});

const sendMessage = createAsyncThunk("messaging/sendMessage", (opts, thunkApi) => {
  const {
    draftTextMessages,
    focusedConversation,
    newSmsMediaFile,
    recipients,
    messagingServiceUrl,
    conversations,
  } = thunkApi.getState().messaging;
  const messagingApi = getMessagingApi(messagingServiceUrl);
  let promise = Promise.resolve({});

  if (focusedConversation.uuid === "temp") {
    let users = [];
    users = Object.values(recipients).map(({ recipient, number }) => ({
      address: number.value,
      personUuid: recipient.value,
      isConversationOwner: false,
    }));

    promise = messagingApi
      .post(
        "/Conversation/WithUsers",
        {
          conversationType: "groupSms",
          isChannel: false,
          users,
        },
        { ...opts, signal: thunkApi.signal },
      )
      .catch((err) => {
        if (err.response?.status === 409 && typeof err.response?.data === "object") {
          return { data: { ...err.response.data, isConflict: true } };
        }
        throw err;
      });
  }

  return promise
    .then(({ data: newConversation }) => {
      const draftMessage = draftTextMessages[focusedConversation.uuid];
      const formData = new FormData();
      formData.append("conversationUuid", newConversation?.uuid || focusedConversation.uuid);
      formData.append("messageType", "standard");
      if (draftMessage) formData.append("message", draftMessage);
      if (newSmsMediaFile) formData.append("attachment", newSmsMediaFile);

      return messagingApi.post("/Message/Sms", formData).then((res) => {
        if (newConversation) {
          const conversation = conversations.find((c) => c.uuid === newConversation.uuid) || {
            conversationOwnerPersonUuids: [res.data.createdByUserPersonUuid],
          };
          thunkApi.dispatch(
            selectNewConversation({ ...conversation, ...newConversation, mostRecentMessage: res.data }),
          );
        }
        return res;
      });
    })
    .catch((err) => {
      let errors = [err?.response?.data || "There was a problem sending your message. Please try again."];
      return thunkApi.rejectWithValue(errors);
    });
});

export const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    addRecipient: (state, { payload: { recipient, number } = {} }) => {
      state.recipients[recipient.value] = { recipient, number };
    },
    removeRecipient: (state, { payload: { recipient } = {} }) => {
      delete state.recipients[recipient.value];
    },
    focusConversation: (state, { payload }) => {
      if (state.focusedConversation.uuid !== payload.uuid || payload.uuid === "temp") {
        state.isOptedOut = false;
        state.selectedMessages = [];
        state.messagesPage = 1;
        state.hasMoreMessages = false;
        state.messagesErrors = null;
      }
      state.focusedConversation = payload;
      state.conflictConversation = null;
    },
    nextConversationPage: (state) => {
      state.conversationsPage = state.conversationsPage + 1;
    },
    nextMessagesPage: (state) => {
      state.messagesPage = state.messagesPage + 1;
    },
    selectNewConversation: (state, { payload }) => {
      state.conversations = uniqBy([transformConversation(payload), ...state.conversations], "uuid");
      state.focusedConversation = payload;
      state.conflictConversation = null;
    },
    receiveSignalRNewConversation: (state, { payload }) => {
      state.conversations = uniqBy([transformConversation(payload), ...state.conversations], "uuid");
    },
    receiveSignalRNewMessage: (state, { payload }) => {
      if (state.focusedConversation.uuid === payload.conversationUuid) {
        state.selectedMessages = uniqBy([...state.selectedMessages, payload], "uuid");
      }
      const conversation = state.conversations.find((o) => o.uuid === payload.conversationUuid);
      if (conversation) {
        conversation.mostRecentMessage = payload;
        conversation.dateUpdated = payload.dateCreated;
        if (state.focusedConversation.uuid !== payload.conversationUuid) {
          conversation.userUnreadMessageCount += 1;
          state.allUnreadCount += 1;
          if (conversation.conversationUsers.length > 2) state.groupUnreadCount += 1;
          if (conversation.isAiControlled) state.aiUnreadCount += 1;
        }
      }
    },
    receiveSignalRMessageResetCount: (state, { payload = {} }) => {
      const conversation = state.conversations.find((o) => o.uuid === payload.conversationUuid);
      state.allUnreadCount = Math.max(state.allUnreadCount - payload.priorCount, 0);
      if (conversation) {
        conversation.userUnreadMessageCount = 0;
        if (conversation.conversationUsers.length > 2) {
          state.groupUnreadCount = Math.max(state.groupUnreadCount - payload.priorCount, 0);
        }
        if (conversation.isAiControlled) {
          state.aiUnreadCount = Math.max(state.aiUnreadCount - payload.priorCount, 0);
        }
      }
    },
    receiveSignalRMessageStatusFailure: (state, { payload }) => {
      const message = state.selectedMessages.find((o) => o.uuid === payload.messageUuid);
      if (message?.deliveryStatuses) {
        message.deliveryStatuses = message.deliveryStatuses.map((s) =>
          s.conversationUserUuid === payload.conversationUserUuid ? payload : s,
        );
      }
    },
    setTab: (state, { payload }) => {
      state.currentTab = payload;
      state.conversationsPage = 1;
    },
    setMedia: (state, { payload }) => {
      const MEBIBYTE = 1048576;
      state.newSmsMediaError = payload?.size >= MEBIBYTE ? "File size must be less than 1MB" : null;
      state.newSmsMediaFile = payload;
      state.newSmsMediaPreview = payload ? URL.createObjectURL(payload) : null;
    },
    updateAgentFilters: (state, { payload: { value, replace } }) => {
      if (replace) {
        state.selectedAgentFilters = [value];
      } else {
        state.selectedAgentFilters = xor(
          state.selectedAgentFilters.filter((o) => o !== "all"),
          [value],
        );
        if (state.selectedAgentFilters.length === 0) state.selectedAgentFilters.push("all");
      }
      state.conversationsPage = 1;
    },
    updateDraft: (state, { payload }) => {
      state.draftTextMessages[state.focusedConversation.uuid] = payload;
    },
    setConflictConversation: (state, { payload }) => {
      state.conflictConversation = payload;
    },
    removeConflictConversation: (state) => {
      state.conflictConversation = null;
    },
    setErrors: (state, { payload }) => {
      if (payload.errors) state.errors = payload.errors;
      if (payload.messagesErrors) state.messagesErrors = payload.messagesErrors;
    },
    setHideAi: (state, { payload }) => {
      state.conversationsPage = 1;
      state.hideAi = payload;
    },
    setUnreadOnly: (state, { payload }) => {
      state.conversationsPage = 1;
      state.unreadOnly = payload;
    },
    toggleAiFilter: (state, { payload }) => {
      state.conversationsPage = 1;
      if (payload === "all") {
        state.aiFilters = ["all"];
      } else {
        if (state.aiFilters.includes("all")) {
          state.aiFilters = [payload];
        } else if (state.aiFilters.includes(payload)) {
          state.aiFilters = state.aiFilters.filter((o) => o !== payload);
        } else {
          state.aiFilters.push(payload);
        }
        if (state.aiFilters.length === 0) state.aiFilters.push("all");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnreadCounts.fulfilled, (state, { payload }) => {
      state.aiUnreadCount = payload.aiUnreadCount;
      state.groupUnreadCount = payload.groupUnreadCount;
    });
    builder.addCase(getConversations.pending, (state) => {
      state.loadingConversations = true;
    });
    builder.addCase(getConversations.fulfilled, (state, { payload }) => {
      state.loadingConversations = false;
      state.errors = null;
      state.conversations = [
        ...(state.conversationsPage === 1 ? [] : state.conversations),
        ...(payload.conversations || []).map(transformConversation),
      ];
      state.hasMore = payload.conversations?.length >= state.conversationsPerPage;
      if (state.currentTab === "all") state.allUnreadCount = payload.totalUserUnreadMessageCount;
      if (state.currentTab === "group") state.groupUnreadCount = payload.totalUserUnreadMessageCount;
      if (state.currentTab === "ai") state.aiUnreadCount = payload.totalUserUnreadMessageCount;
    });
    builder.addCase(getConversations.rejected, (state, { payload, error }) => {
      if (error?.name === "AbortError") return;
      state.loadingConversations = false;
      state.errors = payload?.errorMessage ? [payload.errorMessage] : ["Whoops, something went wrong."];
    });

    builder
      .addCase(getOptOutStatus.pending, (state) => {
        state.isOptedOut = false;
      })
      .addCase(getOptOutStatus.fulfilled, (state, { payload }) => {
        state.isOptedOut = payload;
      });

    builder
      .addCase(getMessages.pending, (state) => {
        state.loadingMessages = true;
      })
      .addCase(getMessages.fulfilled, (state, { payload }) => {
        state.loadingMessages = false;
        state.hasMoreMessages = (payload.items?.length || 0) >= state.messagesPerPage;
        state.selectedMessages = [
          ...(payload.items || []).reverse(),
          ...(state.messagesPage === 1 ? [] : state.selectedMessages),
        ];
      })
      .addCase(getMessages.rejected, (state, { payload, error }) => {
        state.loadingMessages = false;
      });

    builder.addCase(sendMessage.pending, (state) => {
      state.sendingTextMessage = true;
      state.messagesErrors = null;
    });
    builder.addCase(sendMessage.fulfilled, (state, { payload }) => {
      state.sendingTextMessage = false;
      state.draftTextMessages[state.focusedConversation.uuid] = "";
      state.draftTextMessages["temp"] = "";
      state.newSmsMediaFile = null;
      state.newSmsMediaPreview = null;
      state.recipients = {};
      state.selectedMessages = uniqBy([...state.selectedMessages, payload.data], "uuid");
    });
    builder.addCase(sendMessage.rejected, (state, { payload }) => {
      state.sendingTextMessage = false;
      state.messagesErrors = payload;
    });
  },
});

export { getUnreadCounts, getConversations, getOptOutStatus, getMessages, markAsRead, sendMessage };

export const {
  addRecipient,
  removeRecipient,
  focusConversation,
  nextConversationPage,
  nextMessagesPage,
  receiveSignalRNewConversation,
  receiveSignalRNewMessage,
  receiveSignalRMessageResetCount,
  receiveSignalRMessageStatusFailure,
  selectNewConversation,
  setMedia,
  setTab,
  updateAgentFilters,
  updateDraft,
  setConflictConversation,
  removeConflictConversation,
  setErrors,
  setHideAi,
  setUnreadOnly,
  toggleAiFilter,
} = messagingSlice.actions;

export default messagingSlice.reducer;
