import { useSelector } from "react-redux";

const useAccountSenderPlaceholders = () => useSelector((state) => state.messaging.accountSenderPlaceholders);
const useAllFilterAgents = () => useSelector((state) => state.messaging.allFilterAgents);
const useConversations = () => useSelector((state) => state.messaging.conversations);
const useConversationsPage = () => useSelector((state) => state.messaging.conversationsPage);
const useConversationsPerPage = () => useSelector((state) => state.messaging.conversationsPerPage);
const useCurrentTab = () => useSelector((state) => state.messaging.currentTab);
const useDraftTextMessages = () => useSelector((state) => state.messaging.draftTextMessages);
const useErrors = () => useSelector((state) => state.messaging.errors);
const useFocusedConversation = () => useSelector((state) => state.messaging.focusedConversation);
const useHasMore = () => useSelector((state) => state.messaging.hasMore);
const useHasMoreMessages = () => useSelector((state) => state.messaging.hasMoreMessages);
const useLoadingConversations = () => useSelector((state) => state.messaging.loadingConversations);
const useLoadingMessages = () => useSelector((state) => state.messaging.loadingMessages);
const useMessagingServiceUrl = () => useSelector((state) => state.messaging.messagingServiceUrl);
const useMessagesErrors = () => useSelector((state) => state.messaging.messagesErrors);
const useMessagesPage = () => useSelector((state) => state.messaging.messagesPage);
const useMessagesPerPage = () => useSelector((state) => state.messaging.messagesPerPage);
const useNewSmsMediaError = () => useSelector((state) => state.messaging.newSmsMediaError);
const useNewSmsMediaFile = () => useSelector((state) => state.messaging.newSmsMediaFile);
const useNewSmsMediaPreview = () => useSelector((state) => state.messaging.newSmsMediaPreview);
const useRecipientsHash = () => useSelector((state) => state.messaging.recipients);
const useSelectedAgentFilters = () => useSelector((state) => state.messaging.selectedAgentFilters);
const useSelectedMessages = () => useSelector((state) => state.messaging.selectedMessages);
const useSendingTextMessage = () => useSelector((state) => state.messaging.sendingTextMessage);
const useConflictConversation = () => useSelector((state) => state.messaging.conflictConversation);
const useIsOptedOut = () => useSelector((state) => state.messaging.isOptedOut);
const useHideAi = () => useSelector((state) => state.messaging.hideAi);
const useUnreadOnly = () => useSelector((state) => state.messaging.unreadOnly);
const useAiFilters = () => useSelector((state) => state.messaging.aiFilters);

export {
  useAccountSenderPlaceholders,
  useAllFilterAgents,
  useConversations,
  useConversationsPage,
  useConversationsPerPage,
  useCurrentTab,
  useDraftTextMessages,
  useErrors,
  useFocusedConversation,
  useHasMore,
  useHasMoreMessages,
  useLoadingConversations,
  useLoadingMessages,
  useMessagingServiceUrl,
  useMessagesErrors,
  useMessagesPage,
  useMessagesPerPage,
  useNewSmsMediaError,
  useNewSmsMediaFile,
  useNewSmsMediaPreview,
  useRecipientsHash,
  useSelectedAgentFilters,
  useSelectedMessages,
  useSendingTextMessage,
  useConflictConversation,
  useIsOptedOut,
  useHideAi,
  useUnreadOnly,
  useAiFilters,
};
