import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverItem, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";

import { EyeSolidV6, PlusSolidV6 } from "../../shared/v2/Icomoon";

const CMALink = ({ person, setShowModal }) => {
  const [showCmaLinks, setShowCmaLinks] = useState(false);

  const isMarketReport = (link) => link.text === "New Market Report";

  return (
    <div className="tw-relative tw-ml-15px">
      <Popover open={showCmaLinks} onOpenChange={setShowCmaLinks} placement="bottom-end">
        <PopoverTrigger>
          <button
            onClick={() => setShowCmaLinks((o) => !o)}
            type="button"
            className="focus:tw-outline-none cma-links-icon tw-bg-transparent tw-border-0"
          >
            <img className="cma-icon-image" src="/assets/CMA-icon.svg" alt="CMA Icon" />
          </button>
        </PopoverTrigger>
        <PopoverContent>
          {person.cma_links.map((link) =>
            isMarketReport(link) ? (
              <PopoverItem icon={<PlusSolidV6 />} onClick={() => setShowModal(true)}>
                {link.text}
              </PopoverItem>
            ) : (
              <PopoverItem
                icon={link.text === "View Report Status" ? <EyeSolidV6 /> : <PlusSolidV6 />}
                onClick={() => window.open(link.link, "_blank")}
              >
                {link.text}
              </PopoverItem>
            ),
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

CMALink.propTypes = {
  person: PropTypes.shape({
    cma_links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        text: PropTypes.string,
      }),
    ).isRequired,
    email_details: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        address: PropTypes.string,
      }),
    ),
    display_a_street_address: PropTypes.string,
    display_city_state_and_zip: PropTypes.string,
  }).isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CMALink;
