import React from "react";

const DisplayName = ({ linkToContact, person }) => {
  const personName =
    person.first_name || person.last_name ? `${person.first_name} ${person.last_name}` : "No Name";

  if (linkToContact) {
    return (
      <a className="linked-name" target="_blank" href={`/people/${person.slug}`}>
        {personName}
      </a>
    );
  } else {
    return personName;
  }
};

export default DisplayName;
