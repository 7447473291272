import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Dropdown } from "../../shared/v1";
import { Input } from "../../shared";
import PlatformCTA from "./PlatformCTA";

const DisabledHistoryFields = () => {
  return (
    <div>
      <Col xs={12}>
        <FormGroup id="view-and-favorites-selector">
          <label className="brivity-grey-text fa fa-circle-o disabled"></label>
          <span style={{ marginRight: "16px" }}>Property Views & Favorites</span>
          <label className="brivity-grey-text fa fa-circle-o disabled"></label>
          <span style={{ marginRight: "16px" }}>Views only</span>
          <label className="brivity-grey-text fa fa-circle-o disabled"></label>
          <span>Favorites only</span>
        </FormGroup>
      </Col>
      <PlatformCTA>
        <Col xs={6}>
          <FormGroup>
            <label>Min Price</label>
            <Input disabled={true} />
          </FormGroup>
        </Col>
      </PlatformCTA>
      <PlatformCTA>
        <Col xs={6}>
          <FormGroup>
            <label>Max Price</label>
            <Input disabled={true} />
          </FormGroup>
        </Col>
      </PlatformCTA>
      <PlatformCTA>
        <Col xs={6}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <label>Bedrooms</label>
              </Col>
              <Col xs={12}>
                <div className="range-dropdowns tw-cursor-not-allowed">
                  <Dropdown placeholder="No Min" isDisabled={true} />
                  <div className="range-separator"></div>
                  <Dropdown placeholder="No Max" isDisabled={true} />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </PlatformCTA>
      <PlatformCTA>
        <Col xs={6}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <label>Bathrooms</label>
              </Col>
              <Col xs={12}>
                <div className="range-dropdowns tw-cursor-not-allowed">
                  <Dropdown placeholder="No Min" isDisabled={true} />
                  <div className="range-separator"></div>
                  <Dropdown placeholder="No Max" isDisabled={true} />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </PlatformCTA>
      <PlatformCTA>
        <Col xs={12}>
          <FormGroup style={{ position: "relative" }}>
            <label>Location</label>
            <Input disabled={true} />
          </FormGroup>
        </Col>
      </PlatformCTA>
    </div>
  );
};

export default DisabledHistoryFields;
