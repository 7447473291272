import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../../shared/v2/Button";
import { Plus } from "../../../shared/v2/Icomoon";
import TextButton from "../../../shared/v2/TextButton";
import AddressView from "./AddressView";
import { useFilterListOptions } from "../../../Settings/Users/helper/hookHelper";

const ADDRESS_TYPES = [
  { label: "Home", value: "home", isTaken: false, index: null },
  { label: "Work", value: "work", isTaken: false, index: null },
  { label: "Mailing", value: "mailing", isTaken: false, index: null },
  { label: "Investment", value: "investment", isTaken: false, index: null },
];

function AddAddressSection({
  addressList,
  stateOptions,
  required,
  setPersonValue,
  isDisplayByDefault,
  modalType,
}) {
  const [addressOptions, setAddressOptions] = useState([]);

  useFilterListOptions(ADDRESS_TYPES, setAddressOptions, addressList, "addressType");

  const onAddAddress = () => {
    const defaultAddress = {
      addressType: addressList?.length === 0 ? "home" : addressOptions.find((o) => !o.isTaken)?.value,
      city: "",
      country: null,
      locality: "",
      postalCode: "",
      streetAddress: "",
      tempId: Date.now(),
    };

    if (addressList?.length > 0) {
      setPersonValue({ key: "addressesAttributes", value: [...addressList, { ...defaultAddress }] });
    } else {
      setPersonValue({ key: "addressesAttributes", value: [{ ...defaultAddress }] });
    }
  };

  useEffect(() => {
    if ((required || isDisplayByDefault) && addressList.length <= 0) {
      onAddAddress();
    }
  }, []);

  return (
    <>
      {addressList.length > 0 ? (
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <hr className="tw-w-full tw-border-gray-10 tw-m-0" />
          <div className="tw-text-18d tw-text-neutral-gray-50">Addresses</div>
          {modalType === "user" && (
            <div className="tw-text-gray-50">
              Work addresses are displayed on Brivity websites. No other address types will be displayed
              there.
            </div>
          )}
          {addressList.map((address, index) => (
            <AddressView
              key={address.id ? address.id : address.tempId}
              currentAddress={address}
              index={index}
              removable={index !== 0 || !(required || isDisplayByDefault)}
              options={addressOptions
                .filter((option) => !option.isTaken || option.index === index)
                .map((option) => ({ label: option.label, value: option.value }))}
              addressList={addressList}
              stateOptions={stateOptions}
              setPersonValue={setPersonValue}
            />
          ))}
          <TextButton onClick={onAddAddress} className="tw-mt-[-14px]" data-cy="add_addresses_btn">
            <div className="tw-flex tw-gap-1 tw-items-center">
              <Plus size="s" />
              <div className="tw-text-[11px] tw-uppercase tw-font-bold">ADDRESS</div>
            </div>
          </TextButton>
        </div>
      ) : (
        <Button
          onClick={onAddAddress}
          className="tw-w-fit tw-flex tw-items-center"
          data-cy="add_init_address_btn"
        >
          <Plus className="tw-mr-4px" size="s" />
          Add Address
        </Button>
      )}
    </>
  );
}

const addressPropType = PropTypes.shape({
  addressType: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  locality: PropTypes.string,
  streetAddress: PropTypes.string,
  postalCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
});
AddAddressSection.propTypes = {
  modalType: PropTypes.string.isRequired,
  required: PropTypes.bool,
  isDisplayByDefault: PropTypes.bool,
  setPersonValue: PropTypes.func.isRequired,
  addressList: PropTypes.arrayOf(addressPropType),
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    }),
  ).isRequired,
};

AddAddressSection.defaultProps = {
  required: false,
  isDisplayByDefault: false,
  addressList: [],
};

export default AddAddressSection;
