/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { func, shape, string, bool } from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { TriangleExclamationSolidV6, CircleCheckSolidV6 } from "@shared/v2/Icomoon/Icons";

import DetailSectionHeader from "../DetailSectionHeader";
import DetailInput from "../DetailInput";
import BACDetailInput from "../BACDetailInput";

import { putDetailAsThunk } from "../actions/thunks";
import DetailSourceDropdown from "../DetailSourceDropdown";
import { formatExistingNumber } from "../api/helpers";

const ListingDetails = ({ uuid, listingDetails, onPutDetailAsThunk, type }) => {
  const { price, mls_number, valid_mls_number, buyers_agent_commission } = listingDetails;
  const saveValueFor =
    (key, stateKey = null) =>
    (value) => {
      onPutDetailAsThunk({
        uuid,
        key,
        value,
        group: "listingDetails",
        stateKey,
      });
    };

  return (
    <div className="tw-mb-24px">
      <DetailSectionHeader header="Listing Details" />
      <DetailInput
        dataCy="listing-price"
        schema="currency"
        label="Listing Price"
        val={formatExistingNumber(price)}
        onBlur={saveValueFor("price")}
      />
      <DetailSourceDropdown containerClass="tw-mr-[15px]" />
      <DetailInput
        dataCy="mls"
        schema="text"
        label="MLS#"
        val={mls_number}
        onBlur={saveValueFor("mls_number")}
        tooltipComp={
          mls_number.length && (
            <Tooltip
              multiline
              placement="top"
              content={
                <div className="tw-w-[163px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                  {valid_mls_number ? "Valid MLS number" : "Not a valid MLS number"}
                </div>
              }
              trigger={
                valid_mls_number ? (
                  <CircleCheckSolidV6 className="tw-mr-[6px] tw-mt-[6px] tw-w-[12px] tw-h-[12px] tw-text-green-100" />
                ) : (
                  <TriangleExclamationSolidV6 className="tw-mr-[6px] tw-mt-[6px] tw-w-[12px] tw-h-[12px] tw-text-yellow-100" />
                )
              }
            />
          )
        }
      />
      {type === "seller" && <BACDetailInput dataCy="bac" BACval={buyers_agent_commission} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  listingDetails: state.tdpDetailsReducer.listingDetails,
  type: state.tdpDetailsReducer.headerDetails?.type,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (uuid) => dispatch(putDetailAsThunk(uuid)),
});

ListingDetails.propTypes = {
  uuid: string,
  type: string,
  listingDetails: shape({
    mls_number: string,
    price: string,
    valid_mls_number: bool,
  }),
  onPutDetailAsThunk: func,
};

ListingDetails.defaultProps = {
  uuid: "",
  type: "",
  listingDetails: {
    mls_number: "",
    price: "",
    valid_mls_number: false,
  },
  onPutDetailAsThunk: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);
