import {
  BullhornSolidV6,
  CalendarDaySolidV6,
  ChartColumnSolidV6,
  DropletSolidV6,
  GaugeHighSolidV6,
  GearSolidV6,
  GridSolidV6,
  HouseSolidV6,
  InboxSolidV6,
  ListCheckSolidV6,
  MagnifyingGlassSolidV6,
  RocketSolidV6,
  UserSolidV6,
  PhonePlusSolidV6,
  SuitcaseSolidV6,
  MapLocationSolidV6,
} from "../../../shared/v2/Icomoon";

const ICON_MAP = {
  Dashboard: GaugeHighSolidV6,
  Messages: InboxSolidV6,
  "Phone System": PhonePlusSolidV6,
  Leads: DropletSolidV6,
  Opportunities: MapLocationSolidV6,
  People: UserSolidV6,
  Search: MagnifyingGlassSolidV6,
  Transactions: HouseSolidV6,
  Tasks: ListCheckSolidV6,
  Projects: RocketSolidV6,
  Reporting: ChartColumnSolidV6,
  Marketer: BullhornSolidV6,
  "Recruiter.ai": SuitcaseSolidV6,
  Calendar: CalendarDaySolidV6,
  Apps: GridSolidV6,
  Settings: GearSolidV6,
};

export default ICON_MAP;
