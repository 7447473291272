import React, { useState } from "react";
import Proptypes from "prop-types";
import ModifyDate from "../Date/ModifyDate";

const CustomDates = ({ option }) => {
  const [hovered, toggleHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => toggleHovered(true)}
      onMouseLeave={() => toggleHovered(false)}
      key={option}
      className="tw-min-h-[36px] tw-flex tw-cursor-default tw-justify-between tw-items-center tw-px-[16px] hover:tw-bg-tinted-gray-50 active:tw-bg-gray-lightest"
    >
      <span>{option.name}</span>
      <div className={hovered ? "" : "tw-hidden"}>
        <ModifyDate date={option} isNew />
      </div>
    </div>
  );
};

CustomDates.propTypes = {
  option: Proptypes.shape({ name: Proptypes.string, id: Proptypes.string }).isRequired,
};

export default CustomDates;
