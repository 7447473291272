import React from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import InlineEditingTextArea from "../../../../shared/v2/InlineEditingTextArea";
import { putDetailAsThunk } from "../actions/thunks";

const ListingDescription = ({ uuid, description, onPutDetailAsThunk }) => {
  const saveValueFor = (key) => (value) => {
    onPutDetailAsThunk({
      uuid,
      key,
      value,
      group: "listingDetails",
    });
  };

  return (
    <div data-cy="description">
      <InlineEditingTextArea
        value={description || ""}
        displayClassName="tw-font-bs-sans tw-text-14px tw-text-gray-base"
        onChange={saveValueFor("description")}
        showLineBreak
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  description: state.tdpDetailsReducer.listingDetails.description,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (payload) => dispatch(putDetailAsThunk(payload)),
});

ListingDescription.propTypes = {
  uuid: string,
  description: string,
  onPutDetailAsThunk: func,
};

ListingDescription.defaultProps = {
  uuid: null,
  description: "",
  onPutDetailAsThunk: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingDescription);
