/* global tinyMCE */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import TextInput from "@shared/v2/TextInput";
import BrivityTextEditor from "@shared/BrivityTextEditor";
import FieldLabel from "@shared/v2/FieldLabel";
import Alert from "@shared/v2/Alert";
import { PaperclipSolidV6, XmarkLargeSolidV6 } from "@shared/v2/Icomoon";
import AiAssistant from "@shared/AiAssistant";
import FieldError from "@shared/v2/FieldError";
import IconButton from "@shared/v2/IconButton";
import Dropdown from "@shared/v2/Dropdown";
import Checkbox from "@shared/v2/Checkbox";
import useAttachments from "./hooks/useAttachments";
import useMassEmail from "./hooks/useMassEmail";
import Loading from "../../../Goals/components/Loader";

const getRecipientIds = () =>
  Array.from(document.querySelectorAll("input.multi-select:checked")).map(
    (input) => input.closest("tr").dataset.objectId,
  );

const getRecipientCount = () => {
  let count = 0;
  const isAllSelected = document.querySelector("#header-selected-count")?.innerText === "All";
  if (isAllSelected) {
    count = parseInt(document.querySelector("#header-count")?.innerText.replace(/[(),]/g, "") || "0", 10);
  } else {
    count = document.querySelectorAll("input.multi-select:checked").length;
  }
  return { isAllSelected, count };
};

const EmailAllModal = ({ hot_lead_avatar: hotLeadAvatar }) => {
  const formRef = useRef(null);
  const { count: recipientCount, isAllSelected } = getRecipientCount();
  const [acceptSpamRating, setAcceptSpamRating] = useState(false);
  const [disableSendButton, setDisableSendButton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const showSpamWarning = recipientCount > 1000 && !acceptSpamRating;

  const {
    actions: attachmentsActions,
    error: attachmentsError,
    files,
  } = useAttachments({
    accept: ".jpg,.png,.pdf,.doc,.docx,.txt,.xls,.xlsx,.csv,.ai,.ind,.psd",
    multiple: true,
  });

  const { actions, data, errors, loading } = useMassEmail(isOpen);

  useEffect(() => {
    if (selectedTemplate) {
      actions.parseTemplate(selectedTemplate.value).then((parsedTemplate) => {
        formRef.current["[message][subject]"].value = parsedTemplate.subject;
        tinyMCE.activeEditor.setContent(`${parsedTemplate.body}${parsedTemplate.signature}`);
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (!isOpen) {
      attachmentsActions.clear();
      setSelectedTemplate(null);
      setAcceptSpamRating(false);
      setDisableSendButton(true);
    }
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        className="tw-bg-white tw-border-0 tw-block tw-w-full tw-px-[10px] tw-py-[8px] tw-text-left tw-rounded-[4px] tw-text-gray-dark hover:tw-text-gray-dark hover:tw-bg-gray-5"
        onClick={() => setIsOpen(true)}
      >
        Email
      </button>
      <Modal
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[720px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col"
        show={isOpen}
        onHide={() => setIsOpen(false)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form
          ref={formRef}
          className="tw-flex tw-flex-col tw-gap-[24px] tw-overflow-hidden"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            formData.delete("image");
            formData.append("[message][body]", tinyMCE.activeEditor.getContent());
            if (isAllSelected) {
              formData.append("select_all", true);
            } else {
              formData.append("[message][recipient_ids]", getRecipientIds());
            }
            files.forEach((file) => formData.append("[message][attachments][]", file, file.name));
            if (!attachmentsError) {
              actions
                .sendEmail(formData)
                .then(() => setIsOpen(false))
                .catch(() => {});
            }
          }}
        >
          <Modal.Header title={showSpamWarning ? "Bulk Email" : "Interaction: Email"} closeButton />

          {showSpamWarning && (
            <Modal.Body className="tw-flex-1 tw-overflow-auto">
              <div className="tw-rounded tw-border-solid tw-border-semantic-red-10 tw-bg-semantic-red-5 tw-text-semantic-red-100 tw-p-[12px] tw-mb-[12px]">
                <h3 className="tw-text-24d tw-m-0">Warning</h3>
                <p className="tw-m-0">Before sending a mass email to a large list, please consider:</p>
              </div>
              <p>
                Sending through a 3rd party service such as MailChimp or Constant Contact, or limiting your
                recipients to 1000 at a time or less, as sending to a large list can potentially affect your
                SPAM rating.
              </p>
              <p>
                Customizing your content to speak to a targeted audience with useful, specific calls to
                action.
              </p>
              <p>
                For more tips on targeted emails,{" "}
                <a
                  href="https://knowledge.brivity.com/en/articles/5527189-email-reputation-deliverability"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  click here
                </a>
                .
              </p>
              <Checkbox
                checked={!disableSendButton}
                label="I understand that sending this email can affect my spam rating."
                onChange={() => setDisableSendButton((o) => !o)}
              />
            </Modal.Body>
          )}

          {!showSpamWarning && (
            <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-gap-[24px] tw-overflow-auto">
              <input type="hidden" name="[message][save_note]" value="true" />
              <input type="hidden" name="[message][user_submitted]" value="true" />
              <div className="tw-flex tw-items-center tw-gap-[12px]">
                <img src={hotLeadAvatar} alt="hot lead avatar" />
                <div>
                  <p className="tw-m-0">Send to: {recipientCount.toLocaleString()} recipients</p>
                  <h6 className="tw-m-0">
                    (with valid email addresses, subscribed email addresses, not archived, and not trashed)
                  </h6>
                </div>
              </div>

              {errors?.global && <Alert variant="error" text={errors.global} />}

              <div className="tw-flex tw-gap-[12px]">
                <TextInput
                  containerClassName="tw-flex-1"
                  name="[message][subject]"
                  label="Subject"
                  isRequired
                  error={errors?.subject}
                />
                <Dropdown
                  containerClassName="tw-flex-1"
                  options={data?.templates || []}
                  value={selectedTemplate}
                  onChange={(template) => setSelectedTemplate(template)}
                  label="Email Template"
                  placeholder="Choose Email Template"
                  menuShouldComeToFront
                  isClearable
                  isSearchable
                />
              </div>

              <div>
                <FieldLabel className="tw-mb-[8px]" label="Email Body" isRequired />
                <div
                  className={
                    errors?.body
                      ? "tw-relative tw-border-solid tw-border-[1px] tw-border-semantic-red-25"
                      : ""
                  }
                >
                  {loading && <Loading />}
                  {!loading && (
                    <BrivityTextEditor
                      id="email-all-body"
                      placeholders={data?.emailPlaceholders}
                      defaultValue={data?.baseTemplate?.signature}
                    />
                  )}
                </div>
                {errors?.body && <FieldError className="tw-mt-[2px]" error={errors.body} />}

                <div className="tw-mt-[4px] tw-flex tw-items-center tw-gap-[8px]">
                  <Button schema="tertiary" onClick={() => attachmentsActions.addFiles()}>
                    <PaperclipSolidV6 />
                    &nbsp;Add attachment
                  </Button>
                  {!loading && (
                    <AiAssistant
                      align="start"
                      side="bottom"
                      messageType="Email"
                      onInsertClick={(text) => {
                        tinyMCE.activeEditor.execCommand(
                          "mceInsertContent",
                          false,
                          (text || "").replace(/\r\n|\r|\n/g, "<br />"),
                        );
                      }}
                    />
                  )}
                </div>
                {attachmentsError && <FieldError error={attachmentsError} />}
                <div className="tw-flex tw-gap-[8px]">
                  {files.map((file, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i}>
                      {file.name}
                      <IconButton schema="transparent" onClick={() => attachmentsActions.removeFile(file)}>
                        <XmarkLargeSolidV6 />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
            </Modal.Body>
          )}

          <Modal.Footer className="tw-flex tw-justify-between tw-mt-[24px]">
            <Button schema="secondary" size="medium" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            {showSpamWarning ? (
              <Button
                size="medium"
                disabled={disableSendButton}
                onClick={(e) => {
                  e.preventDefault();
                  setAcceptSpamRating(true);
                }}
              >
                Continue
              </Button>
            ) : (
              <Button type="submit" size="medium" isLoading={loading}>
                Save & Send
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

EmailAllModal.propTypes = {
  hot_lead_avatar: PropTypes.string.isRequired,
};

export default EmailAllModal;
