import { useMemo, useState } from "react";
import moment from "moment";

const TIME_FRAME_OPTIONS = [
  { label: "All Time", value: "all_time" },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 14 days",
    value: "last_14_days",
  },
  {
    label: "This Month",
    value: "this_month",
  },
  {
    label: "Last Month",
    value: "last_month",
  },
  {
    label: "This Year",
    value: "this_year",
  },
  {
    label: "Last Year",
    value: "last_year",
  },
];

const useTimeFrameOptions = () => {
  const [timeFrame, setTimeFrame] = useState(TIME_FRAME_OPTIONS[6]);

  const dateRange = useMemo(() => {
    switch (timeFrame.value) {
      case "today":
        return {
          startAt: moment().format("YYYY-MM-DD"),
          endAt: moment().format("YYYY-MM-DD"),
        };
      case "last_7_days":
        return {
          startAt: moment().subtract(7, "days").format("YYYY-MM-DD"),
          endAt: moment().format("YYYY-MM-DD"),
        };
      case "last_14_days":
        return {
          startAt: moment().subtract(14, "days").format("YYYY-MM-DD"),
          endAt: moment().format("YYYY-MM-DD"),
        };
      case "this_month":
        return {
          startAt: moment().startOf("month").format("YYYY-MM-DD"),
          endAt: moment().format("YYYY-MM-DD"),
        };
      case "last_month":
        return {
          startAt: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          endAt: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
        };
      case "this_year":
        return {
          startAt: moment().startOf("year").format("YYYY-MM-DD"),
          endAt: moment().endOf("year").format("YYYY-MM-DD"),
        };
      case "last_year":
        return {
          startAt: moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          endAt: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        };
      default:
        return {};
    }
  }, [timeFrame]);

  return {
    timeFrameOptions: TIME_FRAME_OPTIONS,
    timeFrame,
    setTimeFrame,
    dateRange,
  };
};

export default useTimeFrameOptions;
