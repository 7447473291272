import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col } from "../../shared/v1";

class AutoPlan extends React.Component {
  handleClone() {
    const {
      autoPlansRootPath,
      onCloneAction,
      isCloningAutoPlan,
      autoPlan: { id },
    } = this.props;
    const clonePath = `${autoPlansRootPath}/${id}/clone`;

    if (!isCloningAutoPlan) onCloneAction(clonePath);
  }

  peopleEnrolledCount(numEnrolled, planId, planName) {
    const { baseLeadFilterPath } = this.props;
    if (numEnrolled === 0) return numEnrolled;

    const planNameParam = `leads_filter[selected_auto_plan_name_filters][names][]=${planName}`;
    const planIdParam = `leads_filter[selected_auto_plan_name_filters][ids][]=${planId}`;
    const leadFilterParams = baseLeadFilterPath + encodeURI(`&${planNameParam}&${planIdParam}`);
    const styles = { color: "#59C4C4", textDecoration: "none" };

    return (
      <a href={leadFilterParams} target="_blank" style={styles} rel="noreferrer">
        {numEnrolled}
      </a>
    );
  }

  render() {
    const { autoPlan, onPreview, platformAccount, onShareAutoPlan, onDeleteAutoPlan } = this.props;

    return (
      <Row>
        <Col size={12}>
          <Row className="table-row">
            <Col size={4}>
              <Row>
                <Col size={1}>
                  <i className="fa fa-user action-plan-icon" aria-hidden="true" />
                </Col>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <Col size={11} className="ellipsify pseudo-link underline" onClick={onPreview}>
                  {autoPlan.name}
                </Col>
              </Row>
            </Col>
            <Col size={1}>{autoPlan.actions_count}</Col>
            <Col size={1}>
              {autoPlan.plan_type === "people"
                ? this.peopleEnrolledCount(autoPlan.assigned_plans_count, autoPlan.id, autoPlan.name)
                : autoPlan.assigned_plans_count}
            </Col>
            <Col size={2}>{moment(autoPlan.created_at).format("MM/DD/YYYY")}</Col>
            <Col size={2}>{moment(autoPlan.updated_at).format("MM/DD/YYYY")}</Col>
            <Col size={2}>
              <div className="tw-text-right auto-plan-action-icons">
                <a href={`/auto_plans/${autoPlan.id}`} aria-label="edit">
                  <i className="fa fa-pencil pseudo-link" />
                </a>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <i className="fa fa-files-o pseudo-link p-l-20" onClick={this.handleClone.bind(this)} />
                {platformAccount && (
                  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
                  <i
                    className="fa pseudo-link fa-share-square-o p-l-20"
                    onClick={() => onShareAutoPlan(autoPlan.id)}
                  />
                )}

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <i
                  className="fa fa-trash pseudo-link p-l-20 p-r-10"
                  onClick={() => onDeleteAutoPlan(autoPlan.id)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default AutoPlan;

AutoPlan.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  autoPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  onPreview: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  platformAccount: PropTypes.objectOf(PropTypes.any).isRequired,
  onShareAutoPlan: PropTypes.func.isRequired,
  onDeleteAutoPlan: PropTypes.func.isRequired,
  autoPlansRootPath: PropTypes.string.isRequired,
  onCloneAction: PropTypes.func.isRequired,
  isCloningAutoPlan: PropTypes.bool.isRequired,
  baseLeadFilterPath: PropTypes.string.isRequired,
};
