import React from 'react';
import { Col, Row, ListGroup, ListGroupItem } from 'react-bootstrap';

const FormErrors = (props) => {
  const { formErrors } = props;

  return (
    <Row style={{ marginTop: "8px" }}>
      <Col md={2}>
        There were {Object.keys(formErrors).length} errors.
      </Col>
      <Col md={10}>
        <ListGroup>
          {
            Object.entries(formErrors).map(([name, messages]) =>
                <ListGroupItem key={`error-${name}`}>
                  {name}: {messages.join(', ')}
                </ListGroupItem>
            )
          }
        </ListGroup>
      </Col>
    </Row>
  )
};
export default FormErrors;
