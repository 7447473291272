import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PersonTaskForm from "../../../Tasks/components/PersonTaskForm";
import HeaderTitle from "../modals/HeaderTitle";

class TaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: { ...props.task },
      error_messages: undefined,
    };
  }

  setAgentsForModal = () => {
    const agents = [];
    for (const obj in this.props.agents) {
      const agent = this.props.agents[obj];
      agents.push({ label: agent.full_name, value: agent.uuid, email: agent.email });
    }
    return agents;
  };

  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };

  handleModalClose = () => {
    this.props.closeTaskModal();
  };

  taskTitle = () => {
    switch (true) {
      case this.state.task.id !== undefined:
        return `Edit task for ${this.state.task.taskable_name}`;

      case this.props.isAppointment:
        return "New Appointment";
      default:
        return "New Task";
    }
  };

  render() {
    const default_agents = [{ label: "Anyone", value: "anyone" }];
    const { task } = this.state;
    const { contactEmail } = this.props;
    return (
      <div>
        <Modal
          id="add-task-modal"
          className="person-details-modal"
          show={true}
          onHide={this.handleModalClose}
        >
          <Modal.Header>
            <Modal.Title>
              <HeaderTitle title={this.taskTitle()} onClose={this.handleModalClose} />
            </Modal.Title>
          </Modal.Header>
          <PersonTaskForm
            agents={default_agents.concat(this.setAgentsForModal())}
            account_uuid={this.props.account_uuid}
            task={task}
            taskable={this.props.taskable}
            taskChange={this.newTask}
            onCloseAction={this.props.closeTaskModal}
            closeModal={this.props.closeTaskModal}
            editToDelete={undefined}
            showErrors={this.showErrors}
            errors={this.state.error_messages}
            deleteButton={false}
            accountMilestoneNames={this.props.accountMilestoneNames}
            task_for={undefined}
            showTaskFor={false}
            updateTask={this.props.updateTask}
            getCardTasks={this.props.getCardTasks}
            user={this.props.user}
            newAppointment={true}
            isAppointment={this.props.isAppointment}
            isEditing={this.state.task.id !== undefined ? true : false}
            contactEmail={contactEmail}
          />
        </Modal>
      </div>
    );
  }
}

export default TaskModal;
