/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FieldSelect from "../UtilsV1/FieldSelect";
import FieldSelectWithCategoryOptionsWithSearchableDropdown from "../UtilsV1/FieldSelectWithCategoryOptionsWithSearchableDropdown";
import FieldTextV1 from "../UtilsV1/FieldTextV1";
import RemoveFieldButton from "../v2/RemoveFieldButton";

function AddressView({
  removable,
  currentAddress,
  options,
  stateOptions,
  onRemoveAddressHandlr,
  onTypeHandlr,
  onStreetHandlr,
  onCityHandlr,
  onPostalCodeHandlr,
  onLocalityHandlr
}) {
  const {
    address_type: addressType,
    city,
    postal_code: postalCode,
    street_address: streetAddress,
    locality,
  } = currentAddress;

  const optionsForValueMatch = [].concat(
    ...stateOptions.map((category) =>
      category.options.map((option) => ({ label: option.value, value: option.value })),
    ),
  );

  return (
    <div className='tw-flex tw-flex-col tw-gap-[24px]'>
      <div className="tw-flex tw-gap-2 tw-flex-1">
        <FieldSelect
          label="Address Type"
          options={options}
          onChange={onTypeHandlr}
          value={addressType}
          placeholder="Choose One"
        />

        {removable && (
          <RemoveFieldButton onClick={onRemoveAddressHandlr} className="tw-mt-auto tw-mb-[1px]" />
        )}
      </div>
      <FieldTextV1
        label="Street Address"
        onChange={onStreetHandlr}
        value={streetAddress}
      />
      <div className="tw-flex tw-gap-6">
        <FieldTextV1
          label="City"
          onChange={onCityHandlr}
          value={city}
        />
        <div className="tw-flex tw-gap-3">
          <FieldSelectWithCategoryOptionsWithSearchableDropdown
            label="State / Province"
            options={stateOptions}
            optionsForValueMatch={optionsForValueMatch}
            onChange={onLocalityHandlr}
            value={locality}
            placeholder="Select"
            searchable
          />
          <FieldTextV1
            label="ZIP / Postal Code"
            onChange={onPostalCodeHandlr}
            value={postalCode}
          />
        </div>
      </div>
      <hr className="tw-w-full tw-border-gray-10 tw-m-0" />
    </div>
  );
}
const addressPropType = PropTypes.shape({
  address_type: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  locality: PropTypes.string,
  street_address: PropTypes.string,
  postal_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
  _destroy: PropTypes.bool,
});
AddressView.propTypes = {
  removable: PropTypes.bool,
  currentAddress: addressPropType,
  index: PropTypes.number,
  onRemoveAddressHandlr: PropTypes.func,
  onTypeHandlr: PropTypes.func,
  onStreetHandlr: PropTypes.func,
  onCityHandlr: PropTypes.func,
  onPostalCodeHandlr: PropTypes.func,
  onLocalityHandlr: PropTypes.func,
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    }),
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

AddressView.defaultProps = {
  removable: false,
  currentAddress: null,
  index: null,
  options: [],
  onRemoveAddressHandlr: () => { },
  onTypeHandlr: () => { },
  onStreetHandlr: () => { },
  onCityHandlr: () => { },
  onPostalCodeHandlr: () => { },
  onLocalityHandlr: () => { },
};
export default AddressView;
